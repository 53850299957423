import type { IAssetKey, IJacyContent } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";
import { createRequestPromise, type IPromise } from "@jamango/helpers";

export class JacyCostumeActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];
	editorLoadedPromise: IPromise<boolean>;

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
		this.editorLoadedPromise = createRequestPromise<boolean>();
	}

	applyCostume(pk: IAssetKey) {
		try {
			const avatar = this.#state.costumes.get(pk);

			if (!avatar) {
				this.#actions.setError("Avatar not found");
				return;
			}
			useJacyAvatarEditorStore.getState().editAvatarFromCostume(avatar);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
