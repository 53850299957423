import type { TerrainObject } from "@jamango/content-client";

const BLOCK_CUBE = 15;
const BLOCK_AIR = 0;

const LEAVES_BLOCK = "block#03602E99DA07FBA89E03154EEB0D0A74";

const getLog = (height: number) => {
	const result: TerrainObject = [];
	for (let y = 0; y < height; y++) {
		result.push([0, y, 0, BLOCK_CUBE, LEAVES_BLOCK]);
	}
	return result;
};

const getLeaf = (yOffset: number, height: number, xOffset: number, zOffset: number) => {
	const result: TerrainObject = [];
	const max = yOffset + height;
	for (let y = yOffset; y < max; y++) {
		result.push([xOffset, y, zOffset, BLOCK_CUBE, LEAVES_BLOCK]);
	}

	if (xOffset !== 0) {
		const xDir = Math.sign(xOffset);
		for (let x = xOffset - xDir; x !== 0; x -= xDir) {
			result.push([x, yOffset, zOffset, BLOCK_CUBE, LEAVES_BLOCK]);
		}
	}

	if (zOffset !== 0) {
		const zDir = Math.sign(zOffset);
		for (let z = zOffset - zDir; z !== 0; z -= zDir) {
			result.push([xOffset, yOffset, z, BLOCK_CUBE, LEAVES_BLOCK]);
		}
	}

	return result;
};

export const CactusesObjects: TerrainObject[] = [
	[...getLog(5), ...getLeaf(1, 3, 2, 0)],
	[...getLog(6), ...getLeaf(1, 3, -2, 0), ...getLeaf(2, 3, 0, 2)],
	[...getLog(7), ...getLeaf(1, 3, -2, 0), ...getLeaf(2, 3, 0, -2), ...getLeaf(3, 4, 2, 0)],
];

export const SPACE_BLOCK_NAME = "block#5EF26B15A43647BA994B31346D50AA54";

export const createPlatform = (
	pivotX: number,
	pivotY: number,
	pivotZ: number,
	width: number,
	height: number,
	depth: number,
	blockName: string,
) => {
	const blocks: TerrainObject = [];

	for (let y = 0; y < height; y++) {
		for (let z = 0; z < depth; z++) {
			for (let x = 0; x < width; x++) {
				blocks.push([pivotX + x, pivotY + y, pivotZ + z, BLOCK_CUBE, blockName]);
			}
		}
	}

	return blocks;
};

export const SIMPLE_TREE: TerrainObject = [
	[0, 0, 0, 101, "block#625478D387114C476997337AEF54402D"],
	[0, 0, 1, 15, "block#625478D387114C476997337AEF54402D"],
	[0, 0, 2, 26, "block#625478D387114C476997337AEF54402D"],
	[1, 0, 1, 74, "block#625478D387114C476997337AEF54402D"],
	[0, 1, 1, 15, "block#625478D387114C476997337AEF54402D"],
	[0, 2, 1, 15, "block#625478D387114C476997337AEF54402D"],
	[-1, 3, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 3, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 3, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 3, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 3, 1, 15, "block#625478D387114C476997337AEF54402D"],
	[1, 3, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 3, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 3, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 3, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 3, -1, 84, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 3, -1, 85, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 3, -1, 85, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 3, -1, 85, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 3, -1, 81, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 3, 0, 92, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 3, 0, 83, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 3, 1, 92, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 3, 1, 83, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 3, 2, 92, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 3, 2, 83, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 3, 3, 88, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 3, 3, 90, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 3, 3, 90, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 3, 3, 90, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 3, 3, 82, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 4, -1, 108, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 4, -1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 4, -1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 4, -1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 4, -1, 99, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 4, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 4, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 4, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 4, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 4, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 4, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 4, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 4, 1, 15, "block#625478D387114C476997337AEF54402D"],
	[1, 4, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 4, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 4, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 4, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 4, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 4, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 4, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 4, 3, 28, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 4, 3, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 4, 3, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 4, 3, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 4, 3, 19, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 5, -1, 108, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 5, -1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 5, -1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 5, -1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 5, -1, 99, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 5, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 5, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 5, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 5, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 5, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 5, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 5, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 5, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 5, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 5, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 5, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 5, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 5, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 5, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 5, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 5, 3, 28, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 5, 3, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 5, 3, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 5, 3, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 5, 3, 19, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 6, -1, 40, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 6, -1, 42, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 6, -1, 42, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 6, -1, 42, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 6, -1, 34, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 6, 0, 44, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 6, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 6, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 6, 0, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 6, 0, 35, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 6, 1, 44, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 6, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 6, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 6, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 6, 1, 35, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 6, 2, 44, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 6, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 6, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 6, 2, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 6, 2, 35, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-2, 6, 3, 36, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 6, 3, 37, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 6, 3, 37, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 6, 3, 37, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[2, 6, 3, 33, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 7, 0, 40, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 7, 0, 42, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 7, 0, 34, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 7, 1, 44, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 7, 1, 15, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 7, 1, 35, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[-1, 7, 2, 36, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[0, 7, 2, 37, "block#03602E99DA07FBA89E03154EEB0D0A74"],
	[1, 7, 2, 33, "block#03602E99DA07FBA89E03154EEB0D0A74"],
];

export const CANOPY_TREE: TerrainObject = [
	[0, 0, 0, 58, "block#0C56EF2468FB49EBA662E75EDAF917D8"],
	[1, 0, 0, 15, "block#43A219FD861D4E8B88D56CFA31920873"],
	[2, 0, 0, 74, "block#0C56EF2468FB49EBA662E75EDAF917D8"],
	[1, 0, -1, 101, "block#0C56EF2468FB49EBA662E75EDAF917D8"],
	[1, 0, 1, 26, "block#0C56EF2468FB49EBA662E75EDAF917D8"],
	[1, 1, 0, 15, "block#43A219FD861D4E8B88D56CFA31920873"],
	[1, 2, 0, 15, "block#43A219FD861D4E8B88D56CFA31920873"],
	[1, 3, 0, 15, "block#43A219FD861D4E8B88D56CFA31920873"],
	[1, 4, 0, 15, "block#43A219FD861D4E8B88D56CFA31920873"],
	[1, 5, 0, 15, "block#43A219FD861D4E8B88D56CFA31920873"],
	[1, 6, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 7, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 8, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 8, -1, 44, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 8, -1, 43, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 8, -1, 34, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 8, 0, 44, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 8, 0, 35, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 8, 1, 36, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 8, 1, 37, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 8, 1, 33, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 8, -2, 40, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 8, -2, 34, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 7, -3, 40, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 7, -3, 42, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 7, -3, 34, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 7, -2, 62, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 7, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 7, -2, 103, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 7, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 7, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 7, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 7, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 7, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 7, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 7, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 7, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 7, 2, 30, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 7, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 7, 2, 78, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 7, 3, 36, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 7, 3, 37, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 7, 3, 33, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 7, -1, 40, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 7, -1, 62, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 7, -1, 43, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 7, 0, 44, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 7, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 7, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 7, 1, 50, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 7, 1, 59, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 7, 1, 78, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 7, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 7, 2, 24, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 7, 2, 33, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 7, -2, 56, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 7, -2, 97, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 5, -3, 180, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 5, -3, 110, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 5, -3, 107, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 5, -3, 107, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 5, -3, 193, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 5, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 5, -2, 94, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 5, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 5, -2, 91, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 5, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 5, -1, 107, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 5, -1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[1, 5, -1, 37, "block#43A219FD861D4E8B88D56CFA31920873"],
	[2, 5, -1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[3, 5, -1, 110, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 5, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 5, 0, 35, "block#43A219FD861D4E8B88D56CFA31920873"],
	[2, 5, 0, 44, "block#43A219FD861D4E8B88D56CFA31920873"],
	[3, 5, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 5, 1, 91, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 5, 1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[1, 5, 1, 42, "block#43A219FD861D4E8B88D56CFA31920873"],
	[2, 5, 1, 0, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 5, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 5, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 5, 2, 61, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 5, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 5, 2, 87, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 5, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 5, 3, 177, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 5, 3, 29, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 5, 3, 23, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 5, 3, 77, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 5, 3, 196, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 6, -3, 228, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 6, -3, 109, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 6, -3, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 6, -3, 103, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 6, -3, 225, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 6, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 6, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 6, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 6, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 6, -2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 6, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 6, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 6, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 6, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 6, -1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 6, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 6, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 6, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 6, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 6, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 6, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 6, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 6, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 6, 1, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 6, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 6, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 6, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 6, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 6, 2, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 6, 3, 152, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 6, 3, 30, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 6, 3, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 6, 3, 27, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 6, 3, 146, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 5, -2, 232, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 5, -2, 226, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 5, -1, 61, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 5, -1, 71, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 5, 0, 61, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 5, 0, 71, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 5, 1, 55, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 5, 1, 77, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 5, 2, 148, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 5, 2, 145, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 6, -2, 184, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 6, -2, 194, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 6, -1, 62, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 6, -1, 75, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 6, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 6, 0, 15, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 6, 1, 45, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 6, 1, 78, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 6, 2, 178, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 6, 2, 200, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 7, -1, 34, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 7, 0, 35, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 7, 1, 33, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 4, 2, 84, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 4, -1, 84, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 4, -1, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 4, 0, 84, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 4, 0, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 4, 1, 88, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[4, 4, 1, 82, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[3, 4, -2, 88, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 4, -2, 82, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 4, -1, 84, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 4, -1, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 4, 0, 84, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 4, 0, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 4, 1, 88, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 4, 1, 82, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 4, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 4, 2, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 4, 2, 84, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 4, 2, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 4, 2, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 4, 3, 20, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 4, 3, 17, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 4, 3, 82, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 4, 0, 53, "block#43A219FD861D4E8B88D56CFA31920873"],
	[2, 4, 0, 69, "block#43A219FD861D4E8B88D56CFA31920873"],
	[1, 4, -1, 106, "block#43A219FD861D4E8B88D56CFA31920873"],
	[1, 4, 1, 21, "block#43A219FD861D4E8B88D56CFA31920873"],
	[0, 4, -3, 84, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 4, -3, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 4, -3, 81, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 4, -2, 88, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 4, -2, 82, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 4, -2, 82, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
];

export const TALL_PALM_TREE: TerrainObject = [
	[0, 0, 0, 168, "block#041BF939E4344551A0D8017C16DADBC5"],
	[1, 0, 0, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[2, 0, 0, 162, "block#041BF939E4344551A0D8017C16DADBC5"],
	[0, 0, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 0, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[2, 0, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 0, 2, 164, "block#041BF939E4344551A0D8017C16DADBC5"],
	[1, 0, 2, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[2, 0, 2, 161, "block#041BF939E4344551A0D8017C16DADBC5"],
	[0, 1, 1, 58, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 1, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[2, 1, 1, 74, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 1, 0, 101, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 1, 2, 26, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 2, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 3, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 4, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 5, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 6, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 7, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 8, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 9, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 1, 53, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 10, 1, 35, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 10, 1, 58, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 10, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-3, 9, 1, 58, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 9, 1, 83, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-3, 6, 1, 92, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-3, 7, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-3, 8, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 0, 106, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 10, 0, 37, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 10, -2, 101, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 10, -1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, -3, 101, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, -2, 90, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 7, -3, 85, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 8, -3, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 1, 69, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 9, 1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[2, 10, 1, 44, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 10, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[4, 9, 1, 92, "block#F376B574F0024A69B54C6B3C45E26431"],
	[4, 10, 1, 74, "block#F376B574F0024A69B54C6B3C45E26431"],
	[5, 8, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[5, 9, 1, 74, "block#F376B574F0024A69B54C6B3C45E26431"],
	[5, 7, 1, 83, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 2, 21, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 10, 2, 42, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 10, 3, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 10, 4, 26, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 4, 85, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 5, 26, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 7, 5, 90, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 8, 5, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 8, 2, 210, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 8, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[2, 8, 3, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[3, 8, 3, 110, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 2, 168, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 9, 2, 66, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 3, 24, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 9, 3, 161, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 7, 3, 84, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 7, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[0, 7, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 7, 3, 81, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 7, 3, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 8, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[0, 8, 2, 216, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 8, 3, 71, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, 3, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 9, 2, 56, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 2, 162, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, 3, 164, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 3, 18, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 7, -1, 82, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 7, -1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 7, 0, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[0, 7, 0, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 8, -1, 23, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, -1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 8, 0, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[0, 8, 0, 212, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, -1, 168, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, -1, 97, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, 0, 50, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 0, 161, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 7, -1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[3, 7, -1, 88, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 7, 0, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[3, 7, 0, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[2, 8, -1, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[3, 8, -1, 55, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 8, 0, 209, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 8, 0, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[2, 9, -1, 100, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 9, -1, 162, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 0, 164, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 9, 0, 72, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 6, 0, 15, "block#C1C5D3FF7CBE4A2D8E1A5F2A75045F21"],
	[1, 7, 0, 101, "block#529519B87B324C688A2F8A403AD6B4B6"],
	[1, 8, 0, 101, "block#529519B87B324C688A2F8A403AD6B4B6"],
	[2, 7, 1, 15, "block#C1C5D3FF7CBE4A2D8E1A5F2A75045F21"],
	[2, 8, 1, 74, "block#529519B87B324C688A2F8A403AD6B4B6"],
	[0, 7, 1, 15, "block#C1C5D3FF7CBE4A2D8E1A5F2A75045F21"],
	[0, 8, 1, 58, "block#529519B87B324C688A2F8A403AD6B4B6"],
	[1, 6, 2, 15, "block#C1C5D3FF7CBE4A2D8E1A5F2A75045F21"],
	[1, 7, 2, 26, "block#529519B87B324C688A2F8A403AD6B4B6"],
	[1, 8, 2, 26, "block#529519B87B324C688A2F8A403AD6B4B6"],
];

export const SHORT_PALM_TREE: TerrainObject = [
	[0, 0, 0, 101, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 0, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 0, 2, 26, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[-1, 0, 1, 58, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[1, 0, 1, 35, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 1, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 2, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 3, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 4, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 5, 1, 15, "block#F55C1A72D1334CC197CD9BA0B3B15721"],
	[0, 6, 1, 15, "block#3765F751DF8B4B0A9740978F08D3ED6D"],
	[0, 7, 1, 15, "block#3765F751DF8B4B0A9740978F08D3ED6D"],
	[0, 8, 1, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-2, 7, -1, 82, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 7, -1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-2, 7, 0, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-1, 7, 0, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 8, -1, 77, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 8, -1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-2, 8, 0, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-1, 8, 0, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 9, -1, 168, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, -1, 97, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 9, 0, 50, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, 0, 161, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-4, 6, 1, 92, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-3, 6, 1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-2, 6, 1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-4, 7, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-3, 7, 1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-2, 7, 1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-4, 8, 1, 58, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-3, 8, 1, 83, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 8, 1, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-4, 9, 1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-3, 9, 1, 58, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 9, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 7, 2, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-1, 7, 2, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 7, 3, 81, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 7, 3, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-2, 8, 2, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-1, 8, 2, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 8, 3, 107, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 8, 3, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[-2, 9, 2, 56, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, 2, 162, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-2, 9, 3, 164, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, 3, 18, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 7, 1, 15, "block#776E08DDAC7340FCBE05EFD1BB9E3971"],
	[-1, 8, 1, 53, "block#F376B574F0024A69B54C6B3C45E26431"],
	[-1, 9, 1, 74, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 7, 2, 15, "block#776E08DDAC7340FCBE05EFD1BB9E3971"],
	[0, 7, 3, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[0, 7, 4, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[0, 8, 2, 21, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, 3, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, 4, 106, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 2, 101, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 3, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 4, 37, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 6, 5, 90, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 7, 5, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, 5, 37, "block#F376B574F0024A69B54C6B3C45E26431"],
	[4, 6, 1, 83, "block#F376B574F0024A69B54C6B3C45E26431"],
	[4, 7, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[4, 8, 1, 74, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 8, 1, 53, "block#F376B574F0024A69B54C6B3C45E26431"],
	[3, 9, 1, 74, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 7, 1, 15, "block#776E08DDAC7340FCBE05EFD1BB9E3971"],
	[2, 7, 1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[1, 8, 1, 69, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 8, 1, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 1, 58, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 7, 2, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 7, 2, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[1, 7, 3, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[2, 7, 3, 84, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 8, 2, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 8, 2, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[1, 8, 3, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[2, 8, 3, 61, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 2, 168, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 2, 66, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 3, 24, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 3, 161, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 7, -1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[2, 7, -1, 88, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 7, 0, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 7, 0, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[1, 8, -1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[2, 8, -1, 29, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 8, 0, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 8, 0, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[1, 9, -1, 100, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, -1, 162, "block#F376B574F0024A69B54C6B3C45E26431"],
	[1, 9, 0, 164, "block#F376B574F0024A69B54C6B3C45E26431"],
	[2, 9, 0, 72, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 7, -3, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 7, -2, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[0, 7, -1, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[0, 7, 0, 15, "block#776E08DDAC7340FCBE05EFD1BB9E3971"],
	[0, 8, -3, 101, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, -2, 21, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, -1, 0, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 8, 0, 106, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, -3, 0, "block#AD09BD89CB5C41D29D564717042144E7"],
	[0, 9, -2, 101, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, -1, 15, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 9, 0, 26, "block#F376B574F0024A69B54C6B3C45E26431"],
	[0, 6, -3, 85, "block#F376B574F0024A69B54C6B3C45E26431"],
];

export const FERN_PLANT: TerrainObject = [
	[0, 1, -1, 225, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 1, 0, 37, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 1, 1, 38, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 0, 0, 106, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 0, 1, 15, "block#43A219FD861D4E8B88D56CFA31920873"],
	[-1, 0, 1, 53, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-1, 1, 1, 35, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[-2, 1, 1, 178, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 1, 1, 44, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[2, 1, 1, 194, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[1, 0, 1, 69, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 0, 2, 149, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 1, 2, 170, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
	[0, 1, 3, 152, "block#4FF65E169F2244BAA0460EC9E21BBB49"],
];

export const WILD_WEST_DESERT_CACTUS1: TerrainObject = [
	[0, 0, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 1, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 2, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
];

export const WILD_WEST_DESERT_CACTUS2: TerrainObject = [
	[0, 0, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 1, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 2, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 3, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[1, 1, 0, 69, "block#57473602BFC84B2386DA4CB95FE949E8"],
];

export const WILD_WEST_DESERT_CACTUS3: TerrainObject = [
	[0, 0, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 1, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 2, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 3, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 4, 0, 15, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 1, 1, 21, "block#57473602BFC84B2386DA4CB95FE949E8"],
	[0, 2, -1, 106, "block#57473602BFC84B2386DA4CB95FE949E8"],
];

export const WILD_WEST_DESERT_ROCK1: TerrainObject = [
	[0, 0, 0, 15, "block#DB49545197144DF493699D1A586941E0"],
	[1, 0, 0, 15, "block#DB49545197144DF493699D1A586941E0"],
	[0, 0, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[1, 0, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[0, 1, 0, 46, "block#DB49545197144DF493699D1A586941E0"],
	[1, 1, 0, 43, "block#DB49545197144DF493699D1A586941E0"],
	[0, 1, 1, 45, "block#DB49545197144DF493699D1A586941E0"],
	[1, 1, 1, 39, "block#DB49545197144DF493699D1A586941E0"],
];

export const WILD_WEST_DESERT_ROCK2: TerrainObject = [
	[0, 0, 0, 15, "block#DB49545197144DF493699D1A586941E0"],
	[1, 0, 0, 15, "block#DB49545197144DF493699D1A586941E0"],
	[2, 0, 0, 15, "block#DB49545197144DF493699D1A586941E0"],
	[0, 0, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[1, 0, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[2, 0, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[0, 0, 2, 15, "block#DB49545197144DF493699D1A586941E0"],
	[1, 0, 2, 15, "block#DB49545197144DF493699D1A586941E0"],
	[2, 0, 2, 15, "block#DB49545197144DF493699D1A586941E0"],
	[0, 1, 0, 46, "block#DB49545197144DF493699D1A586941E0"],
	[1, 1, 0, 15, "block#DB49545197144DF493699D1A586941E0"],
	[2, 1, 0, 43, "block#DB49545197144DF493699D1A586941E0"],
	[0, 1, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[1, 1, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[2, 1, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[0, 1, 2, 45, "block#DB49545197144DF493699D1A586941E0"],
	[1, 1, 2, 15, "block#DB49545197144DF493699D1A586941E0"],
	[2, 1, 2, 39, "block#DB49545197144DF493699D1A586941E0"],
	[0, 2, 0, 40, "block#DB49545197144DF493699D1A586941E0"],
	[1, 2, 0, 42, "block#DB49545197144DF493699D1A586941E0"],
	[2, 2, 0, 34, "block#DB49545197144DF493699D1A586941E0"],
	[0, 2, 1, 44, "block#DB49545197144DF493699D1A586941E0"],
	[1, 2, 1, 15, "block#DB49545197144DF493699D1A586941E0"],
	[2, 2, 1, 35, "block#DB49545197144DF493699D1A586941E0"],
	[0, 2, 2, 36, "block#DB49545197144DF493699D1A586941E0"],
	[1, 2, 2, 37, "block#DB49545197144DF493699D1A586941E0"],
	[2, 2, 2, 33, "block#DB49545197144DF493699D1A586941E0"],
];

export const SNOWY_TUNDRA_PINE_TREE: TerrainObject = [
	[0, 0, 0, 58, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[1, 0, 0, 15, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[2, 0, 0, 74, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[1, 0, -1, 101, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[1, 0, 1, 26, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[1, 1, 0, 15, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[1, 2, 0, 15, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[1, 3, 0, 15, "block#C82836EF23DD4EE09C32D5F1517193CC"],
	[1, 4, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 5, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 6, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 7, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 8, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 9, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 10, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 11, 0, 39, "block#7F5554819711994B04E484913CA6A8C3"],
	[1, 12, 0, 33, "block#7F5554819711994B04E484913CA6A8C3"],
	[0, 3, 0, 58, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 4, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 5, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 6, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 7, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 8, 0, 59, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[0, 9, 0, 58, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[0, 10, 0, 44, "block#7F5554819711994B04E484913CA6A8C3"],
	[1, 3, 1, 26, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 4, 1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 5, 1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 6, 1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 7, 1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 8, 1, 30, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[1, 9, 1, 26, "block#7F5554819711994B04E484913CA6A8C3"],
	[1, 10, 1, 26, "block#7F5554819711994B04E484913CA6A8C3"],
	[2, 3, 0, 74, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 4, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 5, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 6, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 7, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 8, 0, 75, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[2, 9, 0, 74, "block#7F5554819711994B04E484913CA6A8C3"],
	[2, 10, 0, 74, "block#7F5554819711994B04E484913CA6A8C3"],
	[1, 3, -1, 101, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 4, -1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 5, -1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 6, -1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 7, -1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 8, -1, 103, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[1, 9, -1, 101, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[1, 10, -1, 101, "block#7F5554819711994B04E484913CA6A8C3"],
	[-1, 3, -2, 100, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 3, -2, 97, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 3, -2, 90, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 3, -2, 100, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 3, -2, 66, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 3, -1, 50, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 3, -1, 210, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 3, -1, 216, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 3, -1, 72, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 3, 0, 83, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 3, 0, 92, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 3, 1, 56, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 3, 1, 209, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 3, 1, 212, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 3, 1, 66, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 3, 2, 50, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 3, 2, 18, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 3, 2, 85, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 3, 2, 24, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 3, 2, 18, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 3, -3, 101, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 3, 3, 26, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-2, 3, 0, 58, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[4, 3, 0, 74, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 4, -2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[0, 4, -2, 109, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 4, -2, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 4, -2, 103, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 4, -2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 4, -1, 109, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 4, -1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 4, -1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 4, -1, 103, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 4, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 4, 0, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 4, 1, 59, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 4, 1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 4, 1, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 4, 1, 27, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 4, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[0, 4, 2, 59, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 4, 2, 15, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 4, 2, 27, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 4, 2, 0, "block#CC96F3CD5C5EA294CD581B7F69E5609B"],
	[-1, 5, 0, 58, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 5, 0, 35, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 5, -2, 42, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 5, 2, 37, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 6, -2, 101, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[1, 6, 2, 26, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[1, 7, -2, 101, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[1, 7, 2, 26, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[-1, 6, 0, 58, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[3, 6, 0, 74, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[-1, 7, 0, 58, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[3, 7, 0, 74, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 5, -1, 46, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 5, -1, 43, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 5, 1, 45, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 5, 1, 39, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 6, -1, 40, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 6, -1, 34, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 6, 1, 36, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[2, 6, 1, 33, "block#ED0678ADC7BC47DDA0710AE8910B54CC"],
	[0, 7, -1, 62, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[2, 7, -1, 103, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[0, 7, 1, 59, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[2, 7, 1, 78, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[0, 8, -1, 168, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[2, 8, -1, 162, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[0, 8, 1, 164, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
	[2, 8, 1, 161, "block#6A3E04C90FE34E5382374D8D18A11DF0"],
];

export const SNOWY_TUNDRA_ICE: TerrainObject = [
	[0, 0, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -1, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -2, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -3, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -4, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
];

export const SNOWY_TUNDRA_CRACK_IN_ICE: TerrainObject = [
	[0, 0, 0, BLOCK_AIR, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -1, 0, BLOCK_AIR, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -2, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -3, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, -4, 0, BLOCK_CUBE, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
];

export const SNOWY_TUNDRA_ICE_SPIKE1: TerrainObject = [
	[0, 1, 0, 40, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 0, 34, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, 1, 1, 36, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 1, 33, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
];

export const SNOWY_TUNDRA_ICE_SPIKE2: TerrainObject = [
	[0, 1, 0, 40, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 0, 34, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, 1, 1, 44, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 1, 35, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, 1, 2, 50, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 2, 33, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
];

export const SNOWY_TUNDRA_ICE_SPIKE3: TerrainObject = [
	[0, 1, 0, 42, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 0, 34, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, 1, 1, 59, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 1, 35, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, 1, 2, 36, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[1, 1, 2, 33, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[-1, 1, 0, 40, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[-1, 1, 1, 36, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
	[0, 2, 1, 36, "block#8E2D2ECAD648EBECF9EC8D3DC1526172"],
];
