import { getExtension, getFileNameWithoutExtension } from "../../lib/helpers/files";
import { generateId } from "../../lib/helpers/general";
import type { IBundle, IParsedBlockTexture } from "../../lib/types";
import { AssetType, type IAssetKey, type IBlockTexture } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";
import { ALL_MATERIAL_SIDES } from "../../lib/constants/files";
import { isNullish } from "@jamango/helpers";

export class JacyBlockTextureState {
	#state: JacyContentState;
	#blockTextures: NonNullable<IBundle["assets"]["blockTextures"]>;
	#builtInBlockTextures: Set<string>;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#blockTextures = {};
		this.#builtInBlockTextures = new Set();
	}

	import(data?: IBundle["assets"]["blockTextures"]) {
		if (!data) return;

		this.#blockTextures = Object.assign(this.#blockTextures, data);
		this.#state.markDirty();
	}

	export() {
		const usedBlockTextures = this.#getUsedBlockTextures();

		return Object.fromEntries(
			Object.entries(this.#blockTextures).filter(([, block]) => {
				return usedBlockTextures.has(block.pk) && !this.#builtInBlockTextures.has(block.pk);
			}),
		);
	}

	set(data: IBlockTexture) {
		this.#blockTextures[data.pk] = data;

		this.#state.markDirty();
	}

	#getUsedBlockTextures() {
		const set = new Set<string>();
		const blocks = this.#state.blocks.getAllUnparsed();

		blocks.forEach((block) => {
			const material = block.material;
			if (!material) return;

			ALL_MATERIAL_SIDES.forEach((side) => {
				const sideKey = side as keyof typeof material;
				const sideValue = material[sideKey];

				if (typeof sideValue === "string" && sideValue.startsWith(AssetType.BLOCK_TEXTURE)) {
					set.add(sideValue);
				}
			});
		});

		return set;
	}

	get(pk: IAssetKey) {
		const texture = this.#blockTextures[pk];
		if (!texture) return null;
		return this.#parseBlockTexture(texture);
	}

	query(query: string) {
		const formattedQuery = query.toLowerCase();
		const search = (texture: IBlockTexture) => texture.name.includes(formattedQuery);

		for (const texture of Object.values(this.#blockTextures)) {
			if (search(texture)) {
				return this.#parseBlockTexture(texture);
			}
		}

		return null;
	}

	delete(pk: IAssetKey) {
		if (this.#builtInBlockTextures.has(pk)) return;
		delete this.#blockTextures[pk];
		//TODO should we remove resources?
	}

	isBuiltIn(url: string) {
		const block = this.getByUrl(url);

		if (!block) return true;

		return this.#builtInBlockTextures.has(block.pk);
	}

	getByUrl(url: string) {
		const blocks = this.getAllCustom();
		const formattedURL = this.#state.getFilePath(url);

		return (
			blocks.find((block) => this.#state.getFilePath(block.resource.file.url) === formattedURL) ?? null
		);
	}

	useBlockTextureResource(resourcePk: string, name: string) {
		const texture = this.#createBlockTexture({ pk: resourcePk, name });

		this.set(texture);

		return texture;
	}

	getAllCustom(): IParsedBlockTexture[] {
		return this.getAll().filter((texture) => !this.#builtInBlockTextures.has(texture.pk));
	}

	getAll(): IParsedBlockTexture[] {
		return Object.values(this.#blockTextures)
			.map((texture) => this.#parseBlockTexture(texture))
			.filter((texture) => !isNullish(texture)) as IParsedBlockTexture[];
	}

	setBuiltIn(url: string) {
		const filename = getFileNameWithoutExtension(url);
		const extension = getExtension(url);

		const builtInResource = this.#state.resources.addBuiltIn({
			resourceType: "blockTexture",
			name: filename,
			file: {
				url,
				name: filename,
				mimeType: `image/${extension}`,
				size: 0,
			},
		});

		const texture = this.#createBlockTexture(builtInResource);

		this.set(texture);
		this.#builtInBlockTextures.add(texture.pk);
		return texture.pk;
	}

	setBlockTexture(blockTexture: Omit<IBlockTexture, "type">) {
		this.set({
			...blockTexture,
			type: AssetType.BLOCK_TEXTURE,
		});
	}

	#createBlockTexture(resource: { pk: string; name: string }): IBlockTexture {
		const id = generateId();
		const pk = `${AssetType.BLOCK_TEXTURE}#${id}`;

		const today = new Date().toISOString();

		return {
			pk,
			id,
			type: AssetType.BLOCK_TEXTURE,
			resourcePk: resource.pk,
			name: resource.name,
			createdAt: today,
			updatedAt: today,
		};
	}

	#parseBlockTexture(data: IBlockTexture): IParsedBlockTexture | null {
		const resource = this.#state.resources.getBlockTexture(data.resourcePk);

		if (!resource) {
			console.error(`Resource ${data.resourcePk} missing for block texture ${data.pk} ${data.name} `);
			return null;
		}

		return {
			...data,
			resource,
			name: (data.name = data.name
				.replace("tex-blocks-", "")
				// Remove UUID suffix if present (it is not always present)
				.replace(/-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/, "")),
		};
	}
}
