import { useJacyRerenderStore } from "@stores/jacy/rerender";
import type { IJacyContent } from "@jamango/content-client";
import { validations } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";

export class JacySettingsActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setDayCycle(dayCycle: boolean) {
		validations.settings.dayCycle(dayCycle);

		this.#state.settings.setDayCycle(dayCycle);
		useJacyRerenderStore.getState().forceRerenderSettings();
	}
}
