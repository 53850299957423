import type { IPublicWorld } from "@jamango/content-service/types/index.ts";
import { cn } from "@lib/helpers/cn";

type IProps = React.ComponentProps<"span"> & {
	status?: IPublicWorld["publish"]["status"];
};

const BASE_STYLES = "ml-2 rounded px-1 py-0.5 font-sans text-xs font-semibold";

const STATUS_STYLE = {
	approved: "bg-green-200 text-green-900",
	rejected: "bg-red-200 text-red-900",
	"under-review": "bg-blue-200 text-blue-900",
};

export function PublishStatusTag({ status, className, ...props }: IProps) {
	if (!status) return null;

	if (status === "approved") {
		return (
			<span {...props} className={cn(BASE_STYLES, STATUS_STYLE.approved, className)}>
				Approved
			</span>
		);
	}

	if (status === "rejected") {
		return (
			<span {...props} className={cn(BASE_STYLES, STATUS_STYLE.rejected, className)}>
				Rejected
			</span>
		);
	}

	return (
		<span {...props} className={cn(BASE_STYLES, STATUS_STYLE["under-review"], className)}>
			Under Review
		</span>
	);
}
