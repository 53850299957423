import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GameMultiplayer } from "@jamango/client";
import { usePlayerStore } from "@stores/player";
import { useWorldPreviewStore } from "@stores/dialogs";
import { useAuthUserStore } from "@stores/auth-user";
import { useWorldFeedStore } from "@stores/feed/feed";
import { regions, type DedicatedServerRegion } from "@jamango/frontend/DedicatedServerRegions.ts";
import { getWorldFeedItem } from "rest-client";
import { WorldPreview } from "@components/world/WorldPreview";
import { Dialog } from "@components/ui/Dialog";

export const WorldPreviewDialog = () => {
	const navigate = useNavigate();
	const { worldId } = useParams();
	const open = useWorldPreviewStore((state) => state.open);
	const closePreview = useWorldPreviewStore((state) => state.close);
	const authUsername = useAuthUserStore((state) => state.username);
	const playerUsername = usePlayerStore((state) => state.username);
	const currentWorld = useWorldPreviewStore((state) => state.currentWorld);
	const setCurrentWorld = useWorldPreviewStore((state) => state.setCurrentWorld);

	const [hasLiked, setHasLiked] = useState(currentWorld?.userStatus?.hasLiked ?? false);
	const [hasFavorited, setHasFavorited] = useState(currentWorld?.userStatus?.hasFavorited ?? false);
	const [likes, setLikes] = useState(currentWorld?.likes ?? 0);
	const toggleLike = useWorldFeedStore((state) => state.toggleLike);
	const toggleFavorite = useWorldFeedStore((state) => state.toggleFavorite);

	const [selectedRegion, setSelectedRegion] = useState<DedicatedServerRegion>(regions[0]);

	useEffect(() => {
		if (currentWorld) {
			setHasLiked(currentWorld.userStatus?.hasLiked ?? false);
			setHasFavorited(currentWorld.userStatus?.hasFavorited ?? false);
			setLikes(currentWorld.likes ?? 0);
		}
	}, [currentWorld]);

	useEffect(() => {
		if (!worldId) {
			handleClose();
			return;
		}

		const getInitialPreview = async () => {
			if (worldId && !currentWorld) {
				const getWorldResponse = await getWorldFeedItem({ shortCode: worldId });

				if (!getWorldResponse.data) return;

				const targetWorld = getWorldResponse.data.world;

				setCurrentWorld({
					id: targetWorld.id,
					shortCode: targetWorld.shortCode,
					name: targetWorld.name,
					owner: targetWorld.owner,
					thumbnailUrl: targetWorld.thumbnailFile?.name,
					createdAt: targetWorld.createdAt,
					updatedAt: targetWorld.updatedAt,
					likes: 0,
					visits: 0, // TODO JACY
					tags: [], // TODO JACY
					userStatus: {
						hasLiked: false,
						hasFavorited: false,
					},
					isFeatured: false,
				});
			}
		};
		getInitialPreview();
	}, [worldId]);

	const handleClose = () => {
		navigate("/");
		closePreview();
	};

	const isUserAuthenticated = () => !!authUsername;

	const handlePlay = () => {
		if (!isUserAuthenticated()) {
			navigate("/signin");
			return;
		}
		if (currentWorld) {
			const username = authUsername ?? playerUsername;

			if (username) {
				GameMultiplayer.joinPublishedWorld(currentWorld.shortCode, selectedRegion.id);
			} else {
				navigate("/signin");
			}
			closePreview();
		}
	};

	const handleToggleLike = async () => {
		if (!isUserAuthenticated()) {
			navigate("/signin");
			return;
		}

		if (currentWorld?.id) {
			const worldId = currentWorld.id;
			const newLikeStatus = !hasLiked;

			setHasLiked(newLikeStatus);
			setLikes((prev) => (newLikeStatus ? prev + 1 : prev - 1));

			await toggleLike(worldId);
		}
	};

	const handleToggleFavorite = async () => {
		if (!isUserAuthenticated()) {
			navigate("/signin");
			return;
		}

		if (currentWorld?.id) {
			const worldId = currentWorld.id;
			const newFavoriteStatus = !hasFavorited;

			setHasFavorited(newFavoriteStatus);

			await toggleFavorite(worldId);
		}
	};

	return (
		<Dialog.Root open={open} onOpenChange={(isOpen) => !isOpen && handleClose()}>
			<Dialog.Content
				className="!max-h-full w-full max-w-xl overflow-hidden rounded-xl"
				overlayClassname="!bg-opacity-50 z-10"
			>
				<WorldPreview
					currentWorld={currentWorld}
					hasLiked={hasLiked}
					hasFavorited={hasFavorited}
					likes={likes}
					selectedRegion={selectedRegion}
					onRegionChange={setSelectedRegion}
					onClose={handleClose}
					onToggleLike={handleToggleLike}
					onToggleFavorite={handleToggleFavorite}
					onPlay={handlePlay}
				/>
			</Dialog.Content>
		</Dialog.Root>
	);
};
