import type React from "react";
import { useRef, useState } from "react";
import { Check, X as CloseIcon, Gear, Pencil } from "@components/icons";
import { InputConfig } from "./InputConfig";
import { ThemeEditor } from "./ThemeEditor";
import { Button } from "@components/ui/Button";
import { useCustomUIModalStore } from "@stores/dialogs";
import { useCustomUIStore } from "@stores/custom-ui";
import { Input } from "@components/ui/Input";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { Jacy } from "@jacy-client";
import { forceRerenderInventory } from "@stores/jacy/rerender";

export function UIEditor() {
	const setShowCustomUIModal = useCustomUIModalStore((state) => state.setOpen);
	const closeCustomUIModal = useCustomUIModalStore((state) => state.close);
	const currentComponent = useCustomUIStore((state) => state.currentComponent);
	const mode = useCustomUIStore((state) => state.mode);
	const toggleInventory = useInventoryStore((state) => state.toggle);
	const setEditorPosition = useCustomUIStore((state) => state.setEditorPosition);
	const [showSettings, setShowSettings] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const [componentName, setComponentName] = useState(currentComponent?.name ?? "Custom Component");

	const handleSaveComponent = () => {
		if (!currentComponent) return;

		const customUiContent = Jacy.state.customUI;

		setShowCustomUIModal(false);

		currentComponent.name = componentName;

		if (mode === "create") {
			// delete from jacy content
			customUiContent.createComponent(currentComponent.id, currentComponent);
		} else {
			customUiContent.updateComponent(currentComponent.id, currentComponent);
		}

		forceRerenderInventory();

		toggleInventory();
	};

	const handleClose = () => {
		closeCustomUIModal();
		toggleInventory();
	};

	if (!currentComponent) return <></>;

	return (
		<div className="">
			<div className="mb-4 flex items-center gap-2">
				<div className="flex gap-2">
					<Button className="" onClick={handleClose} square color="white">
						<CloseIcon className="size-6" />
					</Button>

					<h4 className="relative flex  items-center gap-2 rounded-md bg-white pr-8 text-sm lg:gap-3 lg:text-lg">
						<Input
							value={componentName}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setComponentName(e.target.value)
							}
							className="w-52 cursor-pointer !border-0 bg-transparent !px-2 !py-2 pr-8 text-sm"
							ref={inputRef}
						/>
						<Pencil
							className="absolute right-2 top-1/2 size-5 -translate-y-1/2 cursor-pointer text-gray-500"
							onClick={() => inputRef?.current?.focus()}
						/>
					</h4>
				</div>

				<div className="relative ml-auto flex items-center gap-1">
					<Button className="!hidden" onClick={() => setShowSettings(true)} square color="white">
						<Gear className="size-6" />
					</Button>
					<Button className="hidden lg:block" onClick={handleSaveComponent} square color="primary">
						<Check className="size-6" />
					</Button>
					{showSettings && (
						<div
							className="absolute right-1/2 top-full flex gap-2 rounded-md bg-white p-2"
							onMouseLeave={() => setShowSettings(false)}
						>
							<span className="text-xs uppercase text-gray-400">Settings Side</span>
							<Button
								className="hidden lg:block"
								onClick={() => setEditorPosition("left")}
								square
								color="white"
							>
								<img
									src={Jacy.getFilePath("assets/frontend/icons/icon-window-left.svg")}
									alt=""
									className="size-4"
								/>
							</Button>
							<Button
								className="hidden lg:block"
								onClick={() => setEditorPosition("right")}
								square
								color="white"
							>
								<img
									src={Jacy.getFilePath("assets/frontend/icons/icon-window-right.svg")}
									alt=""
									className="size-4"
								/>{" "}
							</Button>
						</div>
					)}
				</div>
			</div>
			<div
				className="grid overflow-hidden rounded-lg"
				onPointerDown={(e: React.PointerEvent<HTMLDivElement>) => e.stopPropagation()}
			>
				<div className="ui-editor col-span-3 flex h-56 flex-col gap-4 overflow-auto  bg-white p-4 md:h-[20rem] lg:col-span-2 lg:h-[81vh]">
					<InputConfig />
					<ThemeEditor />
				</div>
			</div>
		</div>
	);
}
