import { PARTICLE_SYSTEMS_PACKAGE } from "../../data/particle-systems/particle-systems-package";
import type { IAssetKey } from "../../lib/types";

const PARTICLE_SYSTEMS = PARTICLE_SYSTEMS_PACKAGE.assets.particleSystems!;

export class JacyParticleSystemsState {
	get(pk?: IAssetKey | null) {
		if (!pk) return null;
		return PARTICLE_SYSTEMS[pk];
	}

	getByIdentifier(identifier?: string | null) {
		if (!identifier) return null;
		return this.get(`particleSystem#${identifier}`);
	}

	getConfig(pk?: IAssetKey | null) {
		const component = this.get(pk);
		if (!component) return null;
		return component.config;
	}

	getAll() {
		return Object.values(PARTICLE_SYSTEMS);
	}
}
