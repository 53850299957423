import { PersonSimple, Smiley, TShirt } from "@components/icons";
import { Jacy } from "@jacy-client";
import { createLogger, camelCaseToSnakeCase, snakeCaseToCamelCase } from "@jamango/helpers";
import { useControlsStore } from "@stores/controls";
import { useConfirmPromptStore, useHubStore } from "@stores/dialogs";
import { useInventoryStore } from "@stores/dialogs/inventory";
import type {
	BodyTypeValue,
	IAssetKey,
	IAvatar,
	IAvatarComponent,
	IAvatarConfig,
	IAvatarMonitoringConfig,
	ICostume,
} from "@jamango/content-client";
import {
	AvatarBaseTabs,
	AvatarBodyTabs,
	AvatarHeadTabs,
	AvatarMainTabs,
	AvatarType,
	BodyType,
	DEFAULT_BODY_PARTS,
	DEFAULT_HAIR_COLOR,
	DEFAULT_SKIN_COLOR,
	formatMissingBodyParts,
	getAvatarBodyPart,
	HAIR_COLORS,
	SKIN_COLORS,
} from "@jamango/content-client";
import { create } from "zustand";
import type { AvatarEditor, CameraMode } from "@jamango/engine/AvatarEditor";

const logger = createLogger("avatar-editor-store");

export const MAIN_TABS = [
	{
		label: "Base",
		value: AvatarMainTabs.BASE,
		icon: PersonSimple,
	},
	{
		label: "Head",
		value: AvatarMainTabs.HEAD,
		icon: Smiley,
	},
	{
		label: "Body",
		value: AvatarMainTabs.BODY,
		icon: TShirt,
	},
];

export const SUB_TABS = {
	[AvatarMainTabs.BASE]: AvatarBaseTabs,
	[AvatarMainTabs.HEAD]: AvatarHeadTabs,
	[AvatarMainTabs.BODY]: AvatarBodyTabs,
};

export type AvatarSubTab = AvatarBaseTabs | AvatarHeadTabs | AvatarBodyTabs;

type IJacyAvatarEditorState = {
	open: boolean;
	isCreate: boolean;
	loading: boolean;
	isCharacterEditor: boolean;
	selectedMainTab: AvatarMainTabs;
	selectedSubTab: AvatarSubTab;
	cameraMode: string;

	currentEditor: AvatarEditor | null;

	pk?: IAssetKey | null;
	name?: string;
	avatarType: AvatarType;
	bodyType: BodyType;
	skinColor: (typeof SKIN_COLORS)[0];
	hairColor: (typeof HAIR_COLORS)[0];
	face: IAvatarComponent | null;
	hair: IAvatarComponent | null | "EMPTY";
	hat: IAvatarComponent | null | "EMPTY";
	mask: IAvatarComponent | null | "EMPTY";
	backpack: IAvatarComponent | null | "EMPTY";
	torso: IAvatarComponent | null;
	arms: IAvatarComponent | null;
	legs: IAvatarComponent | null;
	costumeId?: IAssetKey | null;
	errors: string[];
	// Handlers
	setOpen: (open: boolean, togglePointerLock?: boolean) => void;
	close: (runSideEffects?: boolean) => void;
	setLoading: (loading: boolean) => void;
	setSelectedMainTab: (selectedMainTab: AvatarMainTabs) => void;
	setSelectedSubTab: (selectedSubTab: AvatarBaseTabs | AvatarHeadTabs | AvatarBodyTabs) => void;
	setCameraMode: (cameraMode: CameraMode) => Promise<null | File>;
	setBodyType: (bodyType: BodyType) => Promise<void>;
	setSkinColor: (skinColor: (typeof SKIN_COLORS)[0]) => void;
	setHairColor: (hairColor: (typeof HAIR_COLORS)[0]) => void;
	applyItem: (component: IAvatarComponent) => Promise<void>;
	removeItem: (isDefault?: boolean) => void;
	reloadAvatar: () => Promise<void>;
	getMissingBodyPart: () => string[];
	getBodyTypeValue: () => BodyTypeValue;
	setDefaultConfig: (bodyType?: BodyType) => Promise<void>;
	setError: (error: string) => void;
	clearErrors: () => void;

	// Actions
	getAvatar: () => Promise<{
		pk?: IAssetKey | null;
		thumbnail: File | null;
		displayPhoto: File | null;
		config: IAvatarMonitoringConfig;
	}>;
	getConfig: () => IAvatarConfig;
	getMonitoringConfig: () => IAvatarMonitoringConfig;
	newAvatar: (isCharacterEditor?: boolean) => void;
	editAvatar: (avatar: IAvatar, isCharacterEditor?: boolean) => void;
	editAvatarFromCostume: (avatar: IAvatar | ICostume) => void;
};

const DEFAULT_CONFIG = {
	bodyType: BodyType.BODY_TYPE_1,
	skinColor: DEFAULT_SKIN_COLOR,
	hairColor: DEFAULT_HAIR_COLOR,
	face: null,
	hair: null,
	hat: null,
	mask: null,
	torso: null,
	arms: null,
	legs: null,
	backpack: null,
	costumeId: null,
};

const DEFAULT_STATE = {
	selectedMainTab: AvatarMainTabs.BASE,
	selectedSubTab: AvatarBaseTabs.BODY_TYPE,
	cameraMode: "FULL" satisfies CameraMode,
	pk: null,
	name: "",
	avatarType: AvatarType.USER_AVATAR,
	errors: [],
};

export const useJacyAvatarEditorStore = create<IJacyAvatarEditorState>((set, get) => ({
	loading: true,
	open: false,
	isCreate: false,
	isCharacterEditor: false,
	currentEditor: null,
	...DEFAULT_STATE,
	...DEFAULT_CONFIG,

	// Handlers
	setLoading: (loading) => set({ loading }),
	close: (runSideEffects = true) => {
		if (!runSideEffects) {
			set({ open: false });
			return;
		}

		get().setOpen(false);

		const currentEditor = get().currentEditor;

		currentEditor?.hide();
	},
	setOpen: (open) => {
		set({ open });

		if (open) {
			if (get().isCharacterEditor) {
				useInventoryStore.getState().toggle(false);
			} else {
				useHubStore.getState().close();
			}

			useControlsStore.getState().exitPointerLock(true);
		} else {
			if (get().isCharacterEditor) {
				useInventoryStore.getState().toggle(true);
			} else {
				useHubStore.getState().setOpen(true);
			}
		}
	},
	setSelectedMainTab: (selectedMainTab) => {
		let selectedSubTab: AvatarSubTab = AvatarBaseTabs.BODY_TYPE;

		if (selectedMainTab === AvatarMainTabs.HEAD) {
			selectedSubTab = AvatarHeadTabs.HAIR;
		} else if (selectedMainTab === AvatarMainTabs.BODY) {
			selectedSubTab = AvatarBodyTabs.TORSO;
		}

		set({ selectedMainTab, selectedSubTab });
		if (selectedMainTab === AvatarMainTabs.BASE) {
			get().setCameraMode("FULL");
		} else if (selectedMainTab === AvatarMainTabs.HEAD) {
			get().setCameraMode("HEAD");
		} else if (selectedMainTab === AvatarMainTabs.BODY) {
			get().setCameraMode("TOP");
		}
	},
	setSelectedSubTab: (selectedSubTab) => {
		set({ selectedSubTab });

		if (selectedSubTab in AvatarHeadTabs) {
			get().setCameraMode("HEAD");
		} else if ([AvatarBodyTabs.TORSO].includes(selectedSubTab as AvatarBodyTabs)) {
			get().setCameraMode("TOP");
		} else if (selectedSubTab === AvatarBodyTabs.LEGS) {
			get().setCameraMode("BOTTOM");
		} else if ([AvatarBodyTabs.BACKPACK].includes(selectedSubTab as AvatarBodyTabs)) {
			get().setCameraMode("BACK");
		}
	},
	setCameraMode: async (cameraMode) => {
		const avatarEditor = get().currentEditor;
		if (!avatarEditor) return null;

		try {
			const screenshot = await avatarEditor.transitionCamera(cameraMode);
			set({ cameraMode });

			if (screenshot === "COMPLETE" || !screenshot) {
				return null;
			}

			return screenshot;
		} catch (err) {
			logger.error("Something went wrong while moving the camera:", err);
			return null;
		}
	},
	setError: (error) => {
		set({ errors: [...get().errors, error] });
	},
	clearErrors: () => set({ errors: [] }),
	setBodyType: async (bodyType) => {
		const previousBodyType = get().bodyType;

		set({ bodyType });
		const missingBodyParts = get().getMissingBodyPart();

		if (missingBodyParts.length) {
			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Component counterpart Missing !",
				description: `Are you sure you want to swap even if there are missing avatar component counterparts?
						\nThe missing body parts are the following: \n${formatMissingBodyParts(missingBodyParts)}`,
				confirmText: "Continue Body Type Change",
			});

			if (!confirmed) {
				set({ bodyType: previousBodyType });
				return;
			}

			const defaultState: Record<string, null> = {};
			missingBodyParts.forEach((missingBodyPart) => {
				defaultState[missingBodyPart] = null;
			});

			set(defaultState);
		}

		await get().reloadAvatar();
	},
	setSkinColor: (skinColor) => {
		set({ skinColor });

		const avatarEditor = get().currentEditor;
		if (!avatarEditor) return;

		avatarEditor.currentAvatar?.changeSkinColor(skinColor.color);
	},
	setHairColor: (hairColor) => {
		set({ hairColor });

		const avatarEditor = get().currentEditor;
		if (!avatarEditor) return;

		avatarEditor.currentAvatar?.changeHairColor(hairColor.color);
	},
	removeItem: async (isDefault = false) => {
		await Jacy.actions.avatar.editorLoadedPromise;

		const category = get().selectedSubTab;

		set({
			[category]: !isDefault && category === AvatarHeadTabs.HAIR ? "EMPTY" : null,
			loading: true,
		});

		const avatarEditor = get().currentEditor;
		if (!avatarEditor) return null;

		if (category === AvatarHeadTabs.HAIR) {
			set({ hairColor: DEFAULT_HAIR_COLOR });
		} else if (category === AvatarBaseTabs.SKIN) {
			set({ skinColor: DEFAULT_SKIN_COLOR });
		}

		if (
			!isDefault &&
			[
				AvatarHeadTabs.FACE,
				AvatarHeadTabs.HAIR,
				AvatarHeadTabs.MASK,
				AvatarHeadTabs.HAT,
				AvatarBodyTabs.BACKPACK,
			].includes(category as AvatarHeadTabs | AvatarBodyTabs)
		) {
			await avatarEditor.currentAvatar?.removeAccessory(category as any);
		} else if (
			isDefault ||
			[AvatarBodyTabs.TORSO, AvatarBodyTabs.LEGS].includes(category as AvatarBodyTabs)
		) {
			await avatarEditor.currentAvatar?.returnBodyPartToDefault(category as any, get().getConfig());
		} else if (category === AvatarBaseTabs.AVATAR_SETS) {
			await get().setDefaultConfig(get().bodyType);
		}

		set({ loading: false });
	},
	applyItem: async (component) => {
		try {
			await Jacy.actions.avatar.editorLoadedPromise;

			set({ [component.category]: component, loading: true });

			const avatarEditor = get().currentEditor;
			if (!avatarEditor) return;

			const avatar = avatarEditor.currentAvatar;

			if (!avatar) return;

			const { bodyType } = get();

			if (component.category === AvatarHeadTabs.FACE) {
				const facePath = component.thumbnail[bodyType];

				if (!facePath) {
					logger.error("Thumbnail not found for the selected body type", component.name);
					return;
				}

				await avatar.applyAccessory(
					component.category,
					facePath,
					// bodyType, // todo: unused parameter?
				);
			} else if (
				[
					AvatarHeadTabs.HAIR,
					AvatarHeadTabs.HAT,
					AvatarHeadTabs.MASK,
					AvatarBodyTabs.TORSO,
					AvatarBodyTabs.LEGS,
					AvatarBodyTabs.BACKPACK,
				].includes(component.category as AvatarHeadTabs | AvatarBodyTabs)
			) {
				const glbFile = component.glbFile[component.isUniversal ? BodyType.BODY_TYPE_1 : bodyType];
				const config = get().getConfig();
				if (!glbFile) {
					logger.error("Glb file not found for the selected body type", component.name);
					return;
				}

				if (component.category === AvatarBodyTabs.TORSO) {
					const options =
						typeof component.options === "string"
							? JSON.parse(component.options)
							: component.options;

					if (options?.attached?.arms) {
						const armsConnection = Jacy.state.avatarComponents.getByIdentifier(
							options.attached.arms,
						);
						set({ arms: armsConnection });

						if (!armsConnection) {
							logger.error("No arms with the identifier found.");
							return;
						}

						await avatar.applyAccessory(
							"arms",
							armsConnection.glbFile[component.isUniversal ? BodyType.BODY_TYPE_1 : bodyType],
						);
					}

					await avatar.applyAccessory(component.category, glbFile);
				} else if (
					component.category === AvatarHeadTabs.HAIR &&
					config.hat !== null &&
					typeof config.hat === "object" &&
					config.hat?.removeHairOnUse
				) {
					get().setError("Cannot apply hair when hat is equipped");
				} else if (component.category === AvatarHeadTabs.MASK) {
					if (component.options?.fullCoverHead) {
						await avatar.applyAccessory("head" as any, glbFile);
					} else {
						await avatar.applyAccessory(component.category as any, glbFile);
					}
				} else if (component.category === AvatarHeadTabs.HAT && component.options?.removeHairOnUse) {
					set({ hair: "EMPTY" });
					await avatar.removeAccessory("hair");
					await avatar.applyAccessory(component.category as any, glbFile);
				} else {
					await avatar.applyAccessory(
						component.category as any,
						glbFile,
						// bodyType, // todo: unused parameter?
					);
				}

				const hairColor = get().hairColor;
				if (component.category === AvatarHeadTabs.HAIR && hairColor) {
					avatar.changeHairColor(hairColor.color);
				}
			} else {
				logger.error("Unknown category", component.category);
			}
		} catch (error) {
			logger.error(error);
		} finally {
			set({ loading: false });
		}
	},
	reloadAvatar: async () => {
		await Jacy.actions.avatar.editorLoadedPromise;
		const avatarEditor = get().currentEditor;
		if (!avatarEditor) return;

		await avatarEditor.loadAvatarFromConfig(get().getConfig());
	},
	getMissingBodyPart: () => {
		const config = get().getConfig();
		const bodyType = get().getBodyTypeValue();
		const missingBodyParts: string[] = [];

		for (const bodyPart in config) {
			if (["bodyType", "skinColor", "hairColor", "head"].includes(bodyPart)) {
				continue;
			}
			const bodyPartConfig = config[bodyPart as keyof IAvatarConfig];

			if (typeof bodyPartConfig === "string") {
				if (bodyPartConfig === "EMPTY") {
					continue;
				} else {
					logger.error("Invalid body part config", bodyPart, bodyPartConfig);
					missingBodyParts.push(bodyPart);
					continue;
				}
			}

			const bodyPartURL = getAvatarBodyPart(bodyType, bodyPartConfig);
			if (bodyPartURL) continue;

			missingBodyParts.push(bodyPart);
		}

		return missingBodyParts;
	},
	getBodyTypeValue: () => {
		let bodyType = camelCaseToSnakeCase(get().bodyType);
		bodyType = bodyType.slice(0, -1) + "_" + bodyType.at(-1);
		return bodyType as BodyTypeValue;
	},
	setDefaultConfig: async (bodyType) => {
		set({ ...DEFAULT_CONFIG, bodyType: bodyType ?? DEFAULT_CONFIG.bodyType });
		await get().reloadAvatar();
	},

	// Actions
	getAvatar: async () => {
		const config = get().getMonitoringConfig();
		const thumbnail = await get().setCameraMode("FULL");
		const displayPhoto = await get().setCameraMode("AVATAR");

		return {
			pk: get().pk,
			thumbnail,
			displayPhoto,
			config,
		};
	},
	getConfig: () => {
		const { arms, backpack, hair, hat, mask, face, legs, torso } = get();

		return {
			bodyType: get().getBodyTypeValue(),
			skinColor: get().skinColor.color,
			hairColor: get().hairColor?.color, // Note: hairColor can be null when hair is empty.
			arms: arms
				? {
						isUniversal: !!arms.isUniversal,
						body_type_1: arms.glbFile[BodyType.BODY_TYPE_1],
						body_type_2: arms.glbFile[BodyType.BODY_TYPE_2],
					}
				: {
						isUniversal: false,
						body_type_1: DEFAULT_BODY_PARTS.arms.body_type_1,
						body_type_2: DEFAULT_BODY_PARTS.arms.body_type_2,
					},
			shoes: "EMPTY",
			head: {
				isUniversal: false,
				body_type_1: DEFAULT_BODY_PARTS.head.body_type_1,
				body_type_2: DEFAULT_BODY_PARTS.head.body_type_2,
			},
			face: face
				? {
						isUniversal: !!face.isUniversal,
						body_type_1: face.thumbnail[BodyType.BODY_TYPE_1],
						body_type_2: face.thumbnail[BodyType.BODY_TYPE_2],
					}
				: {
						isUniversal: false,
						body_type_1: DEFAULT_BODY_PARTS.face.body_type_1,
						body_type_2: DEFAULT_BODY_PARTS.face.body_type_2,
					},
			head_type: "EMPTY",
			backpack:
				backpack === "EMPTY" || !backpack
					? "EMPTY"
					: {
							isUniversal: !!backpack.isUniversal,
							body_type_1: backpack.glbFile[BodyType.BODY_TYPE_1],
							body_type_2: backpack.glbFile[BodyType.BODY_TYPE_2],
						},
			hair:
				hair === "EMPTY"
					? "EMPTY"
					: hair
						? {
								isUniversal: hair.isUniversal,
								body_type_1: hair.glbFile[BodyType.BODY_TYPE_1],
								body_type_2: hair.glbFile[BodyType.BODY_TYPE_2],
							}
						: {
								isUniversal: false,
								body_type_1: DEFAULT_BODY_PARTS.hair.body_type_1,
								body_type_2: DEFAULT_BODY_PARTS.hair.body_type_2,
							},
			hat:
				hat === "EMPTY" || !hat
					? "EMPTY"
					: {
							isUniversal: !!hat.isUniversal,
							body_type_1: hat.glbFile[BodyType.BODY_TYPE_1],
							body_type_2: hat.glbFile[BodyType.BODY_TYPE_2],
							removeHairOnUse: !!hat.options?.removeHairOnUse,
						},
			mask:
				mask === "EMPTY" || !mask
					? "EMPTY"
					: {
							isUniversal: !!mask.isUniversal,
							body_type_1: mask.glbFile[BodyType.BODY_TYPE_1],
							body_type_2: mask.glbFile[BodyType.BODY_TYPE_2],
							fullCoverHead: !!mask.options?.fullCoverHead,
						},
			legs: legs
				? {
						isUniversal: !!legs.isUniversal,
						body_type_1: legs.glbFile[BodyType.BODY_TYPE_1],
						body_type_2: legs.glbFile[BodyType.BODY_TYPE_2],
					}
				: {
						isUniversal: false,
						body_type_1: DEFAULT_BODY_PARTS.legs.body_type_1,
						body_type_2: DEFAULT_BODY_PARTS.legs.body_type_2,
					},
			torso: torso
				? {
						isUniversal: !!get().torso?.isUniversal,
						body_type_1: get().torso?.glbFile[BodyType.BODY_TYPE_1],
						body_type_2: get().torso?.glbFile[BodyType.BODY_TYPE_2],
					}
				: {
						isUniversal: false,
						body_type_1: DEFAULT_BODY_PARTS.torso.body_type_1,
						body_type_2: DEFAULT_BODY_PARTS.torso.body_type_2,
					},
		};
	},
	getMonitoringConfig: () => {
		const { arms, backpack, hair, hat, mask, face, legs, torso } = get();

		return {
			bodyType: get().getBodyTypeValue(),
			skinColor: get().skinColor.color,
			hairColor: get().hairColor?.color,
			arms: arms?.pk,
			head: "EMPTY",
			head_type: "EMPTY",
			shoes: "EMPTY",
			backpack: typeof backpack === "string" ? backpack : backpack?.pk,
			hat: typeof hat === "string" ? hat : hat?.pk,
			mask: typeof mask === "string" ? mask : mask?.pk,
			face: typeof face === "string" ? face : face?.pk,
			hair: typeof hair === "string" ? hair : hair?.pk,
			legs: legs?.pk,
			torso: torso?.pk,
		};
	},
	getRawConfig: () => {
		const { arms, backpack, hair, hat, mask, face, legs, torso } = get();

		return JSON.stringify({
			bodyType: get().getBodyTypeValue(),
			skinColor: get().skinColor.color,
			hairColor: get().hairColor?.color,
			arms: { id: arms?.id },
			head: "EMPTY",
			head_type: "EMPTY",
			shoes: "EMPTY",
			backpack: { id: typeof backpack === "string" ? backpack : backpack?.id },
			hat: { id: typeof hat === "string" ? hat : hat?.id },
			mask: { id: typeof mask === "string" ? mask : mask?.id },
			face: { id: typeof face === "string" ? face : face?.id },
			hair: { id: typeof hair === "string" ? hair : hair?.id },
			legs: { id: legs?.id },
			torso: { id: torso?.id },
		});
	},
	newAvatar: (isCharacterEditor) => {
		set({
			...DEFAULT_STATE,
			...DEFAULT_CONFIG,
			isCharacterEditor,
			avatarType: isCharacterEditor ? AvatarType.CHARACTER : AvatarType.USER_AVATAR,
			isCreate: true,
		});
		get().setOpen(true);
	},
	editAvatar: (avatar, isCharacterEditor) => {
		set({
			...DEFAULT_STATE,
			...DEFAULT_CONFIG,
			isCharacterEditor,
			open: true,
			isCreate: false,
			pk: avatar.pk,
			name: avatar.name,
			avatarType: avatar.avatarType,
			bodyType: snakeCaseToCamelCase(avatar.config.bodyType) as BodyType,
			skinColor: SKIN_COLORS.find((color) => color.color === avatar.config.skinColor),
			hairColor: HAIR_COLORS.find((color) => color.color === avatar.config.hairColor),
			face: Jacy.state.avatarComponents.get(avatar.config.face) ?? null,
			hair:
				avatar.config.hair === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.hair) ?? null),
			hat:
				avatar.config.hat === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.hat) ?? null),
			mask:
				avatar.config.mask === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.mask) ?? null),
			backpack:
				avatar.config.backpack === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.backpack) ?? null),
			torso: Jacy.state.avatarComponents.get(avatar.config.torso) ?? null,
			arms: Jacy.state.avatarComponents.get(avatar.config.arms) ?? null,
			legs: Jacy.state.avatarComponents.get(avatar.config.legs) ?? null,
		});
		get().setOpen(true);
	},
	editAvatarFromCostume: (avatar) => {
		set({
			...DEFAULT_CONFIG,
			open: true,
			pk: avatar.pk,
			name: avatar.name,
			costumeId: avatar.pk,
			bodyType: snakeCaseToCamelCase(avatar.config.bodyType) as BodyType,
			skinColor: SKIN_COLORS.find((color) => color.color === avatar.config.skinColor),
			hairColor: HAIR_COLORS.find((color) => color.color === avatar.config.hairColor),
			face: Jacy.state.avatarComponents.get(avatar.config.face) ?? null,
			hair:
				avatar.config.hair === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.hair) ?? null),
			hat:
				avatar.config.hat === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.hat) ?? null),
			mask:
				avatar.config.mask === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.mask) ?? null),
			backpack:
				avatar.config.backpack === "EMPTY"
					? ("EMPTY" as const)
					: (Jacy.state.avatarComponents.get(avatar.config.backpack) ?? null),
			torso: Jacy.state.avatarComponents.get(avatar.config.torso) ?? null,
			arms: Jacy.state.avatarComponents.get(avatar.config.arms) ?? null,
			legs: Jacy.state.avatarComponents.get(avatar.config.legs) ?? null,
		});

		get().reloadAvatar();
	},
}));
