import { Button } from "@components/ui/Button";
import { ErrorNote } from "@components/ui/ErrorNote";
import { Input } from "@components/ui/Input";
import { SuccessNote } from "@components/ui/SuccessNote";
import { LOADING_STATE, useAuthUserStore } from "@stores/auth-user";
import React from "react";
import { Link } from "react-router";

export function SignInUser() {
	const login = useAuthUserStore((state) => state.login);
	const loadingStatus = useAuthUserStore((state) => state.loading);
	const error = useAuthUserStore((state) => state.error);
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [message, setMessage] = React.useState("");

	const loading = LOADING_STATE.login === loadingStatus;

	const submit: React.FormEventHandler<HTMLFormElement> = async (evt) => {
		evt.preventDefault();
		const data = await login(email, password);
		if (data) {
			setMessage("Successfully logged in. Redirecting...");
			setEmail("");
			setPassword("");

			setTimeout(() => {
				setMessage("");
			}, 3000);
		}
	};

	return (
		<form action="" className="mb-5 mt-4" onSubmit={submit}>
			<div className="mb-4">
				{!!error && <ErrorNote message={error} />}
				<SuccessNote message={message} />
			</div>
			<label htmlFor="email" className="mb-4 flex w-full flex-col xl:flex-row xl:items-center">
				<Input
					ref={inputRef}
					variant="filled"
					type="email"
					className="block w-full flex-1 text-lg"
					name="email"
					id="email"
					placeholder="Enter email"
					autoComplete="email"
					autoFocus
					required
					value={email}
					onChange={(evt) => setEmail(evt.target.value)}
				/>
			</label>
			<label htmlFor="password" className="mb-4 flex w-full flex-col xl:flex-row xl:items-center">
				<Input
					variant="filled"
					type="password"
					className="block w-full text-lg"
					name="password"
					id="password"
					value={password}
					placeholder="Password"
					onChange={(evt) => setPassword(evt.target.value)}
					required
				/>
			</label>
			<Button isSubmit fullWidth disabled={loading}>
				{loading ? "Logging in..." : "Log in"}
			</Button>
			<div className="mt-3 text-center">
				<p className="text-sm">
					<Link to="/forgot-password" className="text-link">
						Forgot password?
					</Link>
				</p>
			</div>
		</form>
	);
}
