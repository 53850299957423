import { Jacy } from "@jacy-client";
import type { IEngineWorldData, IIdentifier, IWorldBundle } from "@jamango/content-client";
import type { PeerMetadata } from "@jamango/engine/PeerMetadata.ts";
import * as Engine from "@jamango/engine/Runtime";
import type { EngineAPI } from "@jamango/engine/Runtime";
import { regions } from "@jamango/frontend/DedicatedServerRegions.ts";
import { createLogger } from "@jamango/helpers";
import { generateIdentifier } from "@lib/helpers/generateIdentifier";
import { useAuthUserStore } from "@stores/auth-user";
import { useEngineStore } from "@stores/bb";
import { useConfirmPromptStore } from "@stores/dialogs";
import { Loading } from "@stores/game-client/game-client";
import { useMultiplayerStore } from "@stores/game-client/multiplayer";
import { useLoadingScreenStore } from "@stores/loading-screen";
import { getLiveWorldMaxPlayers, getUserAvatars } from "rest-client";
import * as GameClient from "./GameClient";
import * as GameLifeCycle from "./GameLifeCycle";
import * as Router from "./Router";

const logger = createLogger("GameMultiplayer");

export async function hostWorld(options: { restartUpdateLoop?: boolean } = { restartUpdateLoop: false }) {
	useMultiplayerStore.setState({
		loading: Loading.HOST_WORLD,
		isHosting: false,
		serverID: undefined,
		peerID: undefined,
		hostname: undefined,
	});
	useMultiplayerStore.getState().setServer(true);

	try {
		const world = Jacy.state.worldData;
		const maxPeers = Jacy.state.gameMechanics.privateP2PMaxPeers;
		const serverID = generateIdentifier(5);
		const worldShortCode = world.shortCode;
		const worldVersionID = world.version?.id;

		const engine = (await useEngineStore.getState().promise) as EngineAPI;
		await engine.gbi.base.Multiplayer.host({
			serverID,
			maxPeers,
			worldShortCode,
			worldVersionID,
			dedicated: false,
			restartUpdateLoop: options.restartUpdateLoop || false,
			bundle: Jacy.content.export(),
		});

		GameLifeCycle.onAfterHostOrJoinWorld(serverID, world.shortCode);

		useMultiplayerStore.setState({
			loading: null,
			isHosting: true,
			serverID,
			peerID: serverID,
			hostname: undefined,
		});
	} catch (error) {
		logger.error(error);
	}

	useMultiplayerStore.setState({ loading: null });
}

export async function joinPublishedWorld(worldId: IIdentifier, region: string = regions[0].id) {
	useMultiplayerStore.setState({ loading: Loading.JOIN_WORLD });
	await GameLifeCycle.onDisposeWorld();

	const engine = (await useEngineStore.getState().promise) as EngineAPI;
	const maxPlayersResult = await getLiveWorldMaxPlayers(worldId);

	useMultiplayerStore.getState().setPublicServer(true);

	if (maxPlayersResult.data === 1) {
		useMultiplayerStore.getState().setServer(true);

		await GameClient.loadWorld(worldId, {
			isPrivateServer: false,
		});
	} else {
		useMultiplayerStore.getState().setServer(false);

		GameLifeCycle.onBeforeNavigatePageToLoadWorld();
		await engine.gbi.base.Multiplayer.matchmake(worldId, region);
	}

	useMultiplayerStore.setState({ loading: null });
}

async function onJoinLoadWorld(bundle: { content: IWorldBundle; world: IEngineWorldData }) {
	Jacy.content.import(bundle.content);
	GameLifeCycle.onBeforeLoadWorld();

	useMultiplayerStore.getState().setServer(false);

	const world = await Engine.joinWorld(Jacy.content, bundle.world);

	return world;
}

export async function joinWorld(
	serverID: string,
	options: { playerMatchmakingToken?: string; force?: boolean } = { force: true },
) {
	const worldID = Jacy.state.worldData.shortCode;
	const state = useMultiplayerStore.getState();

	if (!options.force && worldID && state.isServer) {
		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Are you sure?",
			description:
				"You are about to join a different server. You will lose any unsaved progress if you leave this server. This action cannot be undone.",
			confirmText: "Yes, join server",
		});

		if (!confirmed) return;
	}

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();
	useLoadingScreenStore.getState().setText("Joining world");
	useLoadingScreenStore.getState().show();
	useMultiplayerStore.setState({ loading: Loading.JOIN_WORLD });
	await GameLifeCycle.onDisposeWorld();

	localStorage.setItem("serverId", serverID);

	const { userId, username } = useAuthUserStore.getState();

	// currently users are authoritative over their own avatars
	// this will have to change in future when avatar component monetization is important
	if (userId) {
		const userAvatars = await getUserAvatars().then((r) => r.data?.avatars ?? []);

		for (const avatar of userAvatars) {
			Jacy.content.importPackage(avatar);
		}
	}

	const engine = (await useEngineStore.getState().promise) as EngineAPI;

	const { peerId } = await engine.gbi.base.Multiplayer.join({
		serverID,
		playerMatchmakingToken: options.playerMatchmakingToken,
		username: username || "anonym",
		onJoinLoadWorld,
	});
	GameLifeCycle.onAfterLoadWorld();
	GameLifeCycle.onAfterHostOrJoinWorld(serverID);

	useMultiplayerStore.setState({
		loading: null,
		isHosting: false,
		serverID,
		peerID: peerId,
		hostname: null,
	});
	useMultiplayerStore.getState().setPublicServer(false);
}

export async function stopHosting() {
	const confirmed = await useConfirmPromptStore.getState().prompt({
		title: "Are you sure?",
		description: "All players that are in this server will be kicked out. This action cannot be undone.",
		confirmText: "Yes, stop hosting",
	});

	if (!confirmed) return;

	useMultiplayerStore.setState({ loading: Loading.STOP_HOSTING });

	await disconnect();

	useMultiplayerStore.setState({ loading: null });
}

export async function disconnect() {
	const url = new URL(window.location.href);
	url.searchParams.delete("game");
	url.searchParams.delete("matchmake");
	Router.replaceHistory(url.pathname + url.search);

	// No need to wait for the engine to load, only disconnect if engine exists.
	const engine = useEngineStore.getState().engine;

	if (engine && engine.gbi?.base.Multiplayer.isConnected()) {
		await engine.gbi.base.Multiplayer.disconnect();
	}

	useMultiplayerStore.setState({
		isHosting: false,
		hostname: null,
		serverID: null,
		peerID: null,
	});
	useMultiplayerStore.getState().setServer(true);
	useMultiplayerStore.getState().setPublicServer(false);
}

export function getJoinLink(serverID?: string | null, worldID?: string) {
	const url = new URL(window.location.href);

	url.searchParams.delete("game");
	url.searchParams.delete("world");
	url.searchParams.delete("versionId");
	url.searchParams.delete("matchmake");

	if (serverID) {
		url.searchParams.append("game", serverID);
	}

	if (worldID) {
		url.searchParams.append("world", worldID);
	}

	return url.href;
}

export function setPeers(peers: PeerMetadata[]) {
	const host = peers.find((player) => player.isHost)?.username;
	useMultiplayerStore.setState({ peers, hostname: host });
}
