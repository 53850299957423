import type { IJacyContent } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { Jacy } from "@jacy-client";
import { uploadAndCreateResource } from "../../upload/upload-resource";

export class JacyCustomLoaderActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	get #customLoader() {
		return this.#state.customLoader;
	}

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setShow(show: boolean) {
		try {
			this.#state.customLoader.setShow(show);

			useJacyRerenderStore.getState().forceRerenderCustomLoader();

			this.#actions.setSuccess(`Custom loader has been ${show ? "enabled" : "disabled"} successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	setTitle(title: string) {
		try {
			this.#state.customLoader.setTitle(title);

			if (!this.#customLoader.banner) {
				throw new Error("Custom loader failed to save.");
			}

			useJacyRerenderStore.getState().forceRerenderCustomLoader();

			this.#actions.setSuccess("Custom loader's title has been updated successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	setDescription(description: string) {
		try {
			this.#state.customLoader.setDescription(description);

			if (!this.#customLoader.banner) {
				throw new Error("Custom loader failed to save.");
			}

			useJacyRerenderStore.getState().forceRerenderCustomLoader();

			this.#actions.setSuccess("Custom loader's description has been updated successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async uploadBanner(initialFile: File) {
		try {
			const { name, resourceType, file } = await this.#state.resources.processBannerImage(initialFile);

			const uploadedResourceData = await uploadAndCreateResource(Jacy, {
				name,
				resourceType,
				file,
			});

			this.#customLoader.useBannerResource(uploadedResourceData.pk);

			useJacyRerenderStore.getState().forceRerenderCustomLoader();

			this.#actions.setSuccess("Banner uploaded successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
