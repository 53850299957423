import { DEFAULT_BLOCK_OPTIONS } from "../../lib/constants/blocks";
import { ALL_MATERIAL_SIDES } from "../../lib/constants/files";
import { generateId } from "../../lib/helpers/general";
import type { IBundle } from "../../lib/types";
import { AssetType, type IAssetKey, type IBlock, type IParsedBlock } from "../../lib/types";
import { validations } from "../../lib/validations";
import type { JacyContentState } from "../JacyContentState";

export class JacyBlocksState {
	assets: Record<IAssetKey, IBlock>;

	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.assets = {};
	}

	import(data?: IBundle["assets"]["blocks"]) {
		if (!data) return;

		for (const block of Object.values(data)) {
			this.set(block);
		}
	}

	export() {
		return this.assets;
	}

	set(data: IBlock) {
		this.assets[data.pk] = {
			...data,
			options: {
				...DEFAULT_BLOCK_OPTIONS,
				...data.options,
			},
		};

		this.#state.markDirty();
	}

	getParsedBlock(pk: IAssetKey): IParsedBlock | null {
		const block = this.get(pk);
		if (!block) return null;
		return this.#parseBlock(block);
	}

	get(pk: IAssetKey): IBlock | null {
		if (!pk) return null;

		return this.assets[pk];
	}

	createPK(id: string) {
		return `${AssetType.BLOCK}#${id}`;
	}

	createId() {
		return generateId();
	}

	getAllUnparsed(): IBlock[] {
		return Object.values(this.assets);
	}

	getAll(): IParsedBlock[] {
		return Object.values(this.assets).map(this.#parseBlock.bind(this));
	}

	create(pk: IAssetKey, block: IParsedBlock, isTexture?: boolean) {
		validations.general.checkAssetKey(AssetType.BLOCK, pk);

		validations.block.create(block, isTexture);

		const today = new Date().toISOString();

		if (this.get(pk)) {
			throw new Error(`Block with pk ${pk} already exists.`);
		}

		const createdBlock = {
			pk,
			type: AssetType.BLOCK,
			displayName: block.displayName,
			description: block.description,
			scripts: block.scripts,
			options: block.options,
			material: {
				default:
					typeof block.material.default === "string"
						? block.material.default
						: block.material.default?.pk,
				nx: typeof block.material.nx === "string" ? block.material.nx : block.material.nx?.pk,
				px: typeof block.material.px === "string" ? block.material.px : block.material.px?.pk,
				py: typeof block.material.py === "string" ? block.material.py : block.material.py?.pk,
				ny: typeof block.material.ny === "string" ? block.material.ny : block.material.ny?.pk,
				pz: typeof block.material.pz === "string" ? block.material.pz : block.material.pz?.pk,
				nz: typeof block.material.nz === "string" ? block.material.nz : block.material.nz?.pk,
			},
			createdAt: today,
			updatedAt: today,
			soundsPackId: block.soundsPackId,
		} satisfies IBlock;

		this.set(createdBlock);
	}

	update(pk: IAssetKey, block: IParsedBlock, isTexture?: boolean) {
		validations.general.checkAssetKey(AssetType.BLOCK, pk);

		validations.block.update(block, isTexture);

		const existingBlock = this.assets[pk];

		if (!existingBlock) {
			throw new Error(`Block not found: ${pk}`);
		}

		const updatedBlock = {
			pk: existingBlock.pk,
			type: existingBlock.type,
			displayName: block.displayName,
			description: block.description,
			scripts: block.scripts,
			options: block.options,
			material: {
				default:
					typeof block.material.default === "string"
						? block.material.default
						: block.material.default?.pk,
				nx: typeof block.material.nx === "string" ? block.material.nx : block.material.nx?.pk,
				px: typeof block.material.px === "string" ? block.material.px : block.material.px?.pk,
				py: typeof block.material.py === "string" ? block.material.py : block.material.py?.pk,
				ny: typeof block.material.ny === "string" ? block.material.ny : block.material.ny?.pk,
				pz: typeof block.material.pz === "string" ? block.material.pz : block.material.pz?.pk,
				nz: typeof block.material.nz === "string" ? block.material.nz : block.material.nz?.pk,
			},
			createdAt: existingBlock.createdAt,
			updatedAt: new Date().toISOString(),
			soundsPackId: block.soundsPackId,
		} satisfies IBlock;

		this.set(updatedBlock);
	}

	delete(pk: IAssetKey) {
		const block = this.assets[pk];

		if (!block) return;

		delete this.assets[pk];

		this.#state.markDirty();
	}

	#parseBlock(block: IBlock) {
		const material: IParsedBlock["material"] = {};

		ALL_MATERIAL_SIDES.forEach((side) => {
			const sideKey = side as keyof typeof block.material;
			const sideValue = block.material[sideKey];

			if (typeof sideValue === "string" && sideValue.startsWith(AssetType.BLOCK_TEXTURE)) {
				const texture = this.#state.blockTextures.get(sideValue);

				if (!texture) {
					console.error(`Block ${block.displayName} has missing texture: ${sideValue}`);
					return;
				}

				material[sideKey] = texture;
			} else {
				material[sideKey] = sideValue;
			}
		});

		return {
			...block,
			material,
		};
	}
}
