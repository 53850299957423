import { PromptDialog } from "@components/ui/PromptDialog";
import { ConfirmDialog } from "@components/ui/ConfirmDialog";
import { ErrorDialog } from "@components/ui/ErrorDialog";
import { OtherAlertDialog } from "@components/ui/OtherAlertDialog";
import { GameCanvas } from "@components/game/GameCanvas";
import { SignInDialog } from "@components/auth/SignInDialog";
import { RegisterDialog } from "@components/auth/RegisterDialog";
import { HUDPopup } from "@components/hud/HUDPopup";
import { CustomUIHUD } from "@components/hud/CustomUIHUD";
import { LoadWorldModal } from "@components/landing/LoadWorldModal";
import { ImportBlockPackModal } from "@components/world/ImportBlockPackModal";
import { AuthModal } from "./auth/AuthModal";
import { LoadWorldVersionsModal } from "@components/landing/LoadWorldVersionsModal";
import { CustomUIEditor } from "./custom-ui/editor/CustomUIEditor";
import { NetworkEvents } from "./network-events";

export function GlobalComponents() {
	return (
		<>
			<NetworkEvents />
			<SignInDialog />
			<RegisterDialog />
			<AuthModal />
			<LoadWorldModal />
			<LoadWorldVersionsModal />
			{/* YOU CAN ADD HUD POPUPS HERE */}
			<GameCanvas>
				<HUDPopup />
				<CustomUIHUD />
			</GameCanvas>
			<CustomUIEditor />
			<ImportBlockPackModal />
			<ConfirmDialog />
			<PromptDialog />
			<ErrorDialog />
			<OtherAlertDialog />
		</>
	);
}
