import { Jacy } from "@jacy-client";
import type { IAssetKey, IJacyContent } from "@jamango/content-client";
import { forceRerenderInventory } from "@stores/jacy/rerender";
import { uploadAndCreateResource } from "../../upload/upload-resource";
import type { JacyActions } from "../JacyActions";

export class JacyAudioActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	async uploadAudio(initialFile: File) {
		try {
			const { name, resourceType, file } = await this.#state.resources.processAudio(initialFile);

			const { pk } = await uploadAndCreateResource(Jacy, {
				name,
				resourceType,
				file,
			});
			const audio = this.#state.audios.useAudioResource(pk, name);

			forceRerenderInventory();

			this.#actions.setSuccess("Successfully created audio.");

			return audio.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	deleteAudio(pk: IAssetKey) {
		try {
			this.#state.audios.delete(pk);
			forceRerenderInventory();
			this.#actions.setSuccess("Successfully deleted audio.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
