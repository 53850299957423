import type {
	AnyControlDef,
	AnyInputDef,
	AnyNodeDef,
	AnyOutputDef,
	CategoryDef,
	Comment,
	Events,
	Frame,
	IDataStructure,
	IDataType,
	Node,
	NodeConnection,
	SelectOption,
} from "@jamango/content-client";
import { CONTROL, CONTROL_DEFAULT_VALUES, generateUUID, SOCKET, SOCKET_TYPE } from "@jamango/content-client";
import { NODE } from "@jamango/engine/Events.ts";
import { NODE_TYPE_ID } from "@jamango/engine/Runtime/base/rete/Constants.ts";
import type { World } from "@jamango/engine/Runtime/base/world/World.js";
import { REMOVED_NODE_NAMES } from "./constants";
import type { ScriptEditorState } from "./store";

type EditorClientImpl = {
	getDef: (defId: string, dynamicDef?: Partial<AnyNodeDef>) => AnyNodeDef;
};

const areSocketDataStructuresCompatible = (source: IDataStructure, target: IDataStructure) => {
	const { list, any, primitive } = SOCKET_TYPE;

	// if source is a primitive, then it is valid as an input for "any", "primitive", and "list" (auto-convert to list)
	if (source === primitive) return true;

	// check if either socket is "any" or if their structures match directly
	if (source === target || source === any || target === any) return true;

	// check if both are list or if source is list and target is any, and vice versa
	if (source === list && (target === list || target === any)) return true;

	return false;
};

const areSocketDataTypesCompatible = (source: IDataType, target: IDataType) => {
	const { any, exec } = SOCKET;

	// if they are the same type, they're compatible
	if (source === target) return true;

	// check compatibility with 'any' type
	if (target === any && source !== exec) return true;
	if (source === any && target !== exec) return true;

	return false;
};

const TOP_LEVEL_ENTRYPOINT_NODE_TYPES = Object.values(NODE_TYPE_ID.entryPointTrigger) as CategoryDef[];

export const isConnectionValid = (
	editorClient: EditorClientImpl,
	events: Events,
	connection: NodeConnection,
): { error: false | string } => {
	const [output, outputType] = getNode(events, connection.source);

	if (!output) return { error: "Output node not found" };

	if (outputType !== "node") return { error: "Output node cannot have connections" };

	const [input, inputType] = getNode(events, connection.target);

	if (!input) return { error: "Input node not found" };

	if (inputType !== "node") return { error: "Input node cannot have connections" };

	const outputNodeDef = getNodeDef(editorClient, output.defId, events, connection.source);

	const inputNodeDef = getNodeDef(editorClient, input.defId, events, connection.target);

	const outputDef = outputNodeDef.outputs?.[connection.sourceOutput];
	const inputDef = inputNodeDef.inputs?.[connection.targetInput];

	if (!outputDef) {
		return { error: "Output socket def not found" };
	}

	if (!inputDef) {
		return { error: "Input socket def not found" };
	}

	if (
		!areSocketDataStructuresCompatible(
			outputDef.structure ?? "primitive",
			inputDef.structure ?? "primitive",
		)
	) {
		return { error: "These data structures are incompatible" };
	}

	if (!areSocketDataTypesCompatible(outputDef.type, inputDef.type)) {
		return { error: "These data types are incompatible" };
	}

	if (TOP_LEVEL_ENTRYPOINT_NODE_TYPES.includes(inputNodeDef.type)) {
		if (outputNodeDef.id !== NODE.Controls) {
			return { error: "Only outputs from the 'Controls' node can be connected to trigger inputs" };
		}
	}

	return { error: false };
};

export const nodeCanHaveParent = (events: Events, nodeId: string) => {
	const [, nodeType] = getNode(events, nodeId);

	return nodeType === "node" || nodeType === "comment";
};

export const nodeCanHaveChildren = (events: Events, nodeId: string) => {
	const [, nodeType] = getNode(events, nodeId);

	return nodeType === "frame";
};

export const isValidNodeParent = (
	_editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	parentId: string,
) => {
	const canParentNodeHaveChildren = nodeCanHaveChildren(events, parentId);

	const canNodeHaveParent = nodeCanHaveParent(events, nodeId);

	if (!canParentNodeHaveChildren || !canNodeHaveParent) return false;

	const [node] = getNode(events, nodeId);
	const [parentNode] = getNode(events, parentId);
	if (!node || !parentNode) return false;

	return true;
};

const trySetNodeParent = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	parentId: string,
) => {
	if (!isValidNodeParent(editorClient, events, nodeId, parentId)) return false;

	const [node, nodeType] = getNode(events, nodeId);
	if (nodeType !== "node" && nodeType !== "comment") return false;

	node.parentId = parentId;

	return true;
};

const tryClearNodeParent = (events: Events, nodeId: string) => {
	const [node, nodeType] = getNode(events, nodeId);

	if (!node) return false;

	if (nodeType !== "node" && nodeType !== "comment") return false;

	delete node.parentId;

	return true;
};

const revalidateNodeConnections = (editorClient: EditorClientImpl, events: Events, nodeId: string) => {
	const [node] = getNode(events, nodeId);

	if (!node) return false;

	const connections = events.connections.filter(
		(connection) => connection.source === nodeId || connection.target === nodeId,
	);

	let removedConnections = false;

	for (const connection of connections) {
		const { error } = isConnectionValid(editorClient, events, connection);

		if (error) {
			removeConnectionById(events, connection.id);
			removedConnections = true;
		}
	}

	return removedConnections;
};

export const getNodeDef = (
	editorClient: EditorClientImpl,
	defId: string,
	events?: Events,
	nodeId?: string,
): AnyNodeDef => {
	let nodeDynamicDef: Partial<AnyNodeDef> | undefined = undefined;

	if (events && nodeId) {
		const [node] = getNode(events, nodeId);

		if (node && "def" in node && node.def) {
			nodeDynamicDef = node.def;
		}
	}

	const def = editorClient.getDef(defId, nodeDynamicDef);

	if (def) return def;

	// create a placeholder "missing def" based on information we can derive from connections to the node.
	// this is used by the editor to display a placeholder node for creators to use when updating their logic.
	const isRemoved = defId in REMOVED_NODE_NAMES;

	const missingDef: AnyNodeDef = {
		id: defId,
		name: isRemoved ? REMOVED_NODE_NAMES[defId] : "Unknown Node",
		description: "This node no longer exists, this serves as a placeholder for you to update your logic.",
		type: "function",
		inputs: {},
		outputs: {},
		style: {
			border: "border-rose-400",
			title: "bg-rose-100",
			folder: "text-rose-600",
			icon: "bg-rose-600",
			indicator: "bg-rose-300",
		},
		folder: isRemoved ? "removed node" : "error",
		expanded: false,
		priority: -1,
		isMissing: true,
	};

	if (events) {
		for (const input of events.connections.filter((c) => c.target === nodeId)) {
			missingDef.inputs![input.targetInput] = {
				name: input.targetInput,
				type: input.targetInput === "exec" ? "exec" : "any",
				structure: "any",
				optional: false,
			};
		}

		for (const output of events.connections.filter((c) => c.source === nodeId)) {
			missingDef.outputs![output.sourceOutput] = {
				name: output.sourceOutput,
				type: output.sourceOutput === "exec" ? "exec" : "any",
				structure: "any",
			};
		}
	}

	return missingDef;
};

type NodeResult = [Node, "node"] | [Comment, "comment"] | [Frame, "frame"];

export const getNode = (events: Events, id: string): NodeResult | [null, null] => {
	if (events.nodes[id]) return [events.nodes[id], "node"];
	if (events.comments[id]) return [events.comments[id], "comment"];
	if (events.frames[id]) return [events.frames[id], "frame"];

	return [null, null];
};

export const getAllNodes = (events: Events): NodeResult[] => {
	return [
		...Object.values(events.nodes).map((node) => [node, "node"] satisfies NodeResult),
		...Object.values(events.comments).map((node) => [node, "comment"] satisfies NodeResult),
		...Object.values(events.frames).map((node) => [node, "frame"] satisfies NodeResult),
	];
};

export const removeNode = (events: Events, id: string) => {
	// remove the node
	if (events.nodes[id]) {
		delete events.nodes[id];
	} else if (events.comments[id]) {
		delete events.comments[id];
	} else if (events.frames[id]) {
		delete events.frames[id];
	}

	const nodes = getAllNodes(events);

	// remove parent references to the node
	for (const [node] of nodes) {
		if ("parentId" in node && node.parentId === id) {
			delete node.parentId;
		}
	}

	// remove related connections
	events.connections = events.connections.filter(
		(connection) => connection.source !== id && connection.target !== id,
	);
};

export const canRemoveNode = (editorClient: EditorClientImpl, events: Events, id: string) => {
	const [node] = getNode(events, id);

	if (!node) return false;

	if ("defId" in node) {
		const def = getNodeDef(editorClient, node.defId);

		if (def.locked) return false;
	}

	return true;
};

export const tryRemoveNode = (
	editorClient: EditorClientImpl,
	events: Events,
	id: string,
	removeChildren = true,
) => {
	if (!canRemoveNode(editorClient, events, id)) return { removed: false };

	if (removeChildren) {
		for (const childId of getNodeChildrenIds(events, id)) {
			removeNode(events, childId);
		}
	} else {
		// change children from local to global positions
		for (const childId of getNodeChildrenIds(events, id)) {
			const [childNode] = getNode(events, childId);

			if (childNode) {
				childNode.position[0] += childNode.position[0];
				childNode.position[1] += childNode.position[1];
			}
		}
	}

	removeNode(events, id);

	return { removed: true };
};

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "variant" });

export const sortSelectOptions = (
	explicitSortOptions: SelectOption[] | undefined,
	autoSortOptions: SelectOption[] | undefined,
) => {
	const options: SelectOption[] = [];

	if (explicitSortOptions) {
		options.push(...explicitSortOptions);
	}

	if (autoSortOptions) {
		const sorted = [...autoSortOptions].sort((a, b) => collator.compare(a.label, b.label));
		options.push(...sorted);
	}

	return options;
};

export const getSelectOptions = (world: World, inputDef: AnyInputDef) => {
	const explicitSortOptionsConfig = inputDef.config?.explicitSortOptions;
	const autoSortOptionsConfig = inputDef.config?.autoSortOptions;
	const optional = inputDef.optional;

	const ctx = { world };

	let explicitSortOptions: SelectOption[] = [];
	let autoSortOptions: SelectOption[] = [];

	if (typeof explicitSortOptionsConfig === "function") {
		explicitSortOptions = explicitSortOptionsConfig(ctx);
	} else if (Array.isArray(explicitSortOptionsConfig)) {
		explicitSortOptions = explicitSortOptionsConfig;
	}

	if (typeof autoSortOptionsConfig === "function") {
		autoSortOptions = autoSortOptionsConfig(ctx);
	} else if (Array.isArray(autoSortOptionsConfig)) {
		autoSortOptions = autoSortOptionsConfig;
	}

	autoSortOptions = autoSortOptions.sort((a, b) => collator.compare(a.label, b.label));

	const options = sortSelectOptions(explicitSortOptions, autoSortOptions);

	if (optional || options.length < 2) {
		options.unshift({ value: undefined, label: "(No Value)" });
	}

	return options;
};

const makeNode = (
	editorClient: EditorClientImpl,
	events: Events,
	defId: string,
	position: [number, number],
	parentId?: string,
) => {
	const baseDef = getNodeDef(editorClient, defId);

	const id = generateUUID();

	const node: Node = {
		id,
		defId,
		position,
		data: {},
	};

	if (baseDef.custom) {
		node.custom = true;
	}

	if (baseDef.dynamic) {
		const def = {
			controls: {},
			inputs: {},
			outputs: {},
		} satisfies Partial<AnyNodeDef>;

		if (typeof baseDef.dynamic === "object") {
			def.controls = baseDef.dynamic.controls ?? {};
			def.inputs = baseDef.dynamic.inputs ?? {};
			def.outputs = baseDef.dynamic.outputs ?? {};
		}

		node.def = structuredClone(def);
	}

	if (parentId) {
		const canHaveChildren = nodeCanHaveChildren(events, parentId);

		if (canHaveChildren) {
			node.parentId = parentId;

			const [parentNode] = getNode(events, parentId);

			if (parentNode && "position" in parentNode) {
				node.position[0] -= parentNode.position[0];
				node.position[1] -= parentNode.position[1];
			}
		}
	}

	return node;
};

const tryAddNodeToEvents = (events: Events, node: Node): boolean => {
	if (node.defId === NODE.Start) {
		const existingStartNode = Object.values(events.nodes).find((node) => node.defId === NODE.Start);
		if (existingStartNode) {
			return false;
		}
	}

	events.nodes[node.id] = node;

	return true;
};

export const addNode = (
	editorClient: EditorClientImpl,
	events: Events,
	defId: string,
	position: [number, number],
	parentId?: string,
) => {
	const node = makeNode(editorClient, events, defId, position, parentId);

	const success = tryAddNodeToEvents(events, node);

	if (!success) return null;

	return node;
};

export const addComment = (events: Events, position: [number, number]) => {
	const id = generateUUID();

	const comment: Comment = {
		id,
		type: "inline",
		text: "Edit me!",
		position,
		width: 200,
		height: 100,
	};

	events.comments[id] = comment;

	return comment;
};

export const addFrame = (events: Events, position: [number, number]) => {
	const id = generateUUID();

	const frame: Frame = {
		id,
		position,
		width: 500,
		height: 400,
	};

	events.frames[id] = frame;

	return frame;
};

export const setCommentText = (events: Events, id: string, text: string) => {
	const [comment, commentType] = getNode(events, id);

	if (!comment || commentType !== "comment") return;

	comment.text = text;
};

export const getNodeChildrenIds = (events: Events, nodeId: string) => {
	const children: string[] = [];

	for (const node of Object.values(events.nodes)) {
		if (node.parentId === nodeId) {
			children.push(node.id);
		}
	}

	for (const comment of Object.values(events.comments)) {
		if (comment.parentId === nodeId) {
			children.push(comment.id);
		}
	}

	return children;
};

export const handleNodeTransformFinalized = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	intersectingNodeIds: string[],
) => {
	const [node] = getNode(events, nodeId);
	if (!node) return { clearedParent: false, setParent: false };

	const canHaveParent = nodeCanHaveParent(events, nodeId);

	if (!canHaveParent) return { error: "node cannot have parent", clearedParent: false, setParent: false };

	const beforeParentNodeId = "parentId" in node ? node.parentId : undefined;

	let intersectingOriginalParent = false;
	let clearedParent = false;
	let setParent = false;

	// prefer retaining the same parent, if there is one
	if (beforeParentNodeId) {
		for (const intersectingNodeId of intersectingNodeIds) {
			if (beforeParentNodeId === intersectingNodeId) {
				intersectingOriginalParent = true;
				break;
			}
		}
	}

	// if no longer intersecting the original parent,
	if (!intersectingOriginalParent) {
		// try setting the parent to the next valid intersecting node
		for (const intersectingNodeId of intersectingNodeIds) {
			if (isValidNodeParent(editorClient, events, node.id, intersectingNodeId)) {
				setParent = trySetNodeParent(editorClient, events, node.id, intersectingNodeId);
				break;
			}
		}
	}

	// if not retaining the original parent, and a new parent hasn't been set,
	// try clearing the parent
	if (!intersectingOriginalParent && !setParent) {
		clearedParent = tryClearNodeParent(events, node.id);
	}

	const afterParentNodeId = "parentId" in node ? node.parentId : undefined;

	// if the node already had a parent, and the parent has changed, change the node position from being a parent relative position to a global position
	if (beforeParentNodeId && ((setParent && afterParentNodeId !== beforeParentNodeId) || clearedParent)) {
		const [oldParentNode] = getNode(events, beforeParentNodeId);

		if (oldParentNode) {
			node.position[0] += oldParentNode.position[0];
			node.position[1] += oldParentNode.position[1];
		}
	}

	// if we have a new parent, change the position from global to parent relative
	if (setParent && afterParentNodeId) {
		const [newParentNode] = getNode(events, afterParentNodeId);

		if (newParentNode) {
			node.position[0] -= newParentNode.position[0];
			node.position[1] -= newParentNode.position[1];
		}
	}

	// if parents have changed, revalidate connections
	let connectionsRemoved = false;
	if (setParent || clearedParent) {
		connectionsRemoved = revalidateNodeConnections(editorClient, events, node.id);
	}

	return { clearedParent, setParent, connectionsRemoved };
};

export const setNodePosition = (events: Events, id: string, position: [number, number]) => {
	const [node] = getNode(events, id);

	if (!node) return;

	node.position[0] = position[0];
	node.position[1] = position[1];
};

export const setNodeDimensions = (events: Events, id: string, width: number, height: number) => {
	const [node, nodeType] = getNode(events, id);

	if (nodeType !== "comment" && nodeType !== "frame") return;

	node.width = width;
	node.height = height;
};

export const getConnectionById = (events: Events, id: string) => {
	return events.connections.find((connection) => connection.id === id);
};

export const findConnectionByNodesPair = (
	events: Events,
	sourceNode: string,
	sourceOutput: string,
	targetNode: string,
	targetInput: string,
) => {
	return events.connections.find(
		(connection) =>
			connection.source === sourceNode &&
			connection.sourceOutput === sourceOutput &&
			connection.target === targetNode &&
			connection.targetInput === targetInput,
	);
};

export const removeConnectionById = (events: Events, id: string) => {
	events.connections = events.connections.filter((connection) => connection.id !== id);
};

type ConnectNodesResult =
	| { error: string }
	| {
			error?: undefined;
			removedConnection?: NodeConnection;
			newConnection: NodeConnection;
	  };

export const removeConnectionByNodesPair = (
	events: Events,
	sourceNode: string,
	sourceOutput: string,
	targetNode: string,
	targetInput: string,
) => {
	events.connections = events.connections.filter(
		(connection) =>
			connection.source !== sourceNode ||
			connection.sourceOutput !== sourceOutput ||
			connection.target !== targetNode ||
			connection.targetInput !== targetInput,
	);
};

export const connectNodes = (
	editorClient: EditorClientImpl,
	events: Events,
	sourceNode: string,
	sourceOutput: string,
	targetNode: string,
	targetInput: string,
): ConnectNodesResult => {
	const [input, inputType] = getNode(events, targetNode);

	if (!input) return { error: "Target node not found" };

	if (inputType !== "node") {
		return { error: "Target node is not a node" };
	}

	const inputNodeDef = getNodeDef(editorClient, input.defId, events, targetNode);

	const newConnection = {
		id: generateUUID(),
		source: sourceNode,
		sourceOutput: sourceOutput,
		target: targetNode,
		targetInput: targetInput,
	};

	// are the sockets compatible?
	const { error } = isConnectionValid(editorClient, events, newConnection);

	if (error) {
		return { error };
	}

	// if this connection already exists, do nothing
	if (
		Object.values(events.connections).some(
			(connection) =>
				connection.source === sourceNode &&
				connection.sourceOutput === sourceOutput &&
				connection.target === targetNode &&
				connection.targetInput === targetInput,
		)
	) {
		return { error: "This connection already exists" };
	}

	// if the input is not an 'exec' socket, remove the existing connection before adding the new one
	let removedConnection: NodeConnection | undefined = undefined;

	if (inputNodeDef.inputs?.[targetInput].type !== "exec") {
		const existingConnection = Object.values(events.connections).find(
			(connection) => connection.target === targetNode && connection.targetInput === targetInput,
		);
		if (existingConnection) {
			removedConnection = existingConnection;
			events.connections = events.connections.filter(
				(connection) => connection.id !== existingConnection.id,
			);
		}
	}

	// add the connection
	events.connections.push(newConnection);

	return { removedConnection, newConnection };
};

export const getNodeInputDefaultValue = (input: AnyControlDef | AnyInputDef, world: any) => {
	const ctx = { world };

	if (input.config?.defaultValue) {
		return input.config.defaultValue;
	} else if (input.control === "select") {
		let explicitSortOptions: SelectOption[] = [];
		let autoSortOptions: SelectOption[] = [];

		if (typeof input.config?.explicitSortOptions === "function") {
			explicitSortOptions = input.config.explicitSortOptions(ctx);
		} else if (Array.isArray(input.config?.explicitSortOptions)) {
			explicitSortOptions = input.config.explicitSortOptions;
		}

		if (typeof input.config?.autoSortOptions === "function") {
			autoSortOptions = input.config.autoSortOptions(ctx);
		} else if (Array.isArray(input.config?.autoSortOptions)) {
			autoSortOptions = input.config.autoSortOptions;
		}

		const options = sortSelectOptions(explicitSortOptions, autoSortOptions);

		return options[0]?.value;
	} else {
		const defaultValue = CONTROL_DEFAULT_VALUES[input.control ?? input.type];

		if (defaultValue !== undefined) {
			return defaultValue;
		}
	}

	return undefined;
};

export const setNodeDefaultValues = (node: Node, def: AnyNodeDef, world: any) => {
	if (def.inputs) {
		for (const [inputId, input] of Object.entries(def.inputs)) {
			node.data[inputId] = getNodeInputDefaultValue(input, world);
		}
	}

	if (def.controls) {
		for (const [controlId, control] of Object.entries(def.controls)) {
			node.data[controlId] = getNodeInputDefaultValue(control, world);
		}
	}
};

export const setNodeInputValue = (
	scriptEditorState: ScriptEditorState,
	editorClient: EditorClientImpl,
	world: World,
	events: Events,
	nodeId: string,
	input: string,
	value: unknown,
): boolean => {
	const [node, nodeType] = getNode(events, nodeId);

	if (!node || nodeType !== "node") return false;

	if (node.data[input] === value) return false;

	node.data[input] = value;

	const def = getNodeDef(editorClient, node.defId, events, node.id);
	const inputOrControl = def.inputs?.[input] ?? def.controls?.[input];
	const onChange = inputOrControl?.config?.onChange;
	if (onChange) {
		onChange({ value, node, editor: scriptEditorState, world });
	}

	return true;
};

export const disableAllOptionalNodeInputs = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
) => {
	const [node, nodeType] = getNode(events, nodeId);

	if (!node || nodeType !== "node") return;

	const def = getNodeDef(editorClient, node.defId, events, node.id);

	if (!def) return;

	for (const [key, input] of Object.entries(def.inputs ?? {})) {
		if (input.optional) {
			delete node.data[key];
		}
	}
};

export const enableAllOptionalNodeInputs = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
) => {
	const [node, nodeType] = getNode(events, nodeId);

	if (!node || nodeType !== "node") return;

	const def = getNodeDef(editorClient, node.defId, events, node.id);

	if (!def) return;

	for (const [key, input] of Object.entries(def.inputs ?? {})) {
		if (input.optional && node.data[key] === undefined) {
			node.data[key] = getNodeInputDefaultValue(input, {});
		}
	}
};

export const validateNodeInputValue = (def: AnyControlDef | AnyInputDef, value: unknown): null | string => {
	const validations = def?.config?.validation;

	if (!validations) return null;

	for (const validation of validations ?? []) {
		if (validation.condition(value)) {
			const error = validation.message(value);

			return error;
		}
	}

	return null;
};

const getDynamicDefNode = (editorClient: EditorClientImpl, events: Events, nodeId: string) => {
	const [node, nodeType] = getNode(events, nodeId);

	if (!node || nodeType !== "node") return null;

	const def = getNodeDef(editorClient, node.defId, events, node.id);

	if (!def.dynamic) return null;

	return node as Node & { def: Partial<AnyNodeDef> };
};

export const setDynamicDefNodeName = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	name?: string,
) => {
	const node = getDynamicDefNode(editorClient, events, nodeId);
	if (!node) return;

	if (!node.def) {
		node.def = {};
	}

	node.def.name = name;

	node.def = structuredClone(node.def);
};

export const setDynamicDefNodeSockets = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	sockets: {
		controls?: Record<string, AnyControlDef>;
		inputs?: Record<string, AnyInputDef>;
		outputs?: Record<string, AnyOutputDef>;
	},
) => {
	const node = getDynamicDefNode(editorClient, events, nodeId);
	if (!node) return;

	const dynamicDef = {
		controls: {} as Record<string, AnyControlDef>,
		inputs: {} as Record<string, AnyInputDef>,
		outputs: {} as Record<string, AnyOutputDef>,
	} satisfies Partial<AnyNodeDef>;

	for (const [key, control] of Object.entries(sockets.controls ?? {})) {
		dynamicDef.controls[key] = {
			dynamic: true,
			...control,
		};
	}

	for (const [key, input] of Object.entries(sockets.inputs ?? {})) {
		dynamicDef.inputs[key] = {
			dynamic: true,
			...input,
		};
	}

	for (const [key, output] of Object.entries(sockets.outputs ?? {})) {
		dynamicDef.outputs[key] = {
			dynamic: true,
			...output,
		};
	}

	node.def = structuredClone(dynamicDef);

	revalidateNodeConnections(editorClient, events, nodeId);
};

export const addDynamicDefNodeSocket = (editorClient: EditorClientImpl, events: Events, nodeId: string) => {
	const [node, nodeType] = getNode(events, nodeId);
	if (!node || nodeType !== "node") return;

	const def = getNodeDef(editorClient, node.defId, events);
	if (!def) return;

	if (!(typeof def.dynamic === "object" && def.dynamic.add)) return;

	const dynamicDef = node.def as Pick<AnyNodeDef, "controls" | "inputs" | "outputs">;

	const maxIndex = Math.max(...Object.values(dynamicDef.inputs!).map((input) => input.index ?? 0));

	const newSockets = def.dynamic.add(maxIndex + 1, def, node.data);

	if (typeof newSockets !== "object") return;

	for (const [key, control] of Object.entries(newSockets.controls ?? {})) {
		control.dynamic = true;

		if (!dynamicDef.controls) dynamicDef.controls = {};
		dynamicDef.controls[key] = control;
	}

	for (const [key, input] of Object.entries(newSockets.inputs ?? {})) {
		input.dynamic = true;

		if (!dynamicDef.inputs) dynamicDef.inputs = {};
		dynamicDef.inputs[key] = input;
	}

	for (const [key, output] of Object.entries(newSockets.outputs ?? {})) {
		output.dynamic = true;

		if (!dynamicDef.outputs) dynamicDef.outputs = {};
		dynamicDef.outputs[key] = output;
	}

	node.def = structuredClone(dynamicDef);
};

export const removeDynamicDefNodeSocket = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	socketKey: string,
) => {
	const node = getDynamicDefNode(editorClient, events, nodeId);
	if (!node) return;

	const dynamicDef = node.def as Pick<AnyNodeDef, "controls" | "inputs" | "outputs">;

	if (dynamicDef.controls) {
		delete dynamicDef.controls[socketKey];
	}

	if (dynamicDef.inputs) {
		delete dynamicDef.inputs[socketKey];
	}

	if (dynamicDef.outputs) {
		delete dynamicDef.outputs[socketKey];
	}

	node.def = structuredClone(dynamicDef);
};

export const changeDynamicDefAllSocketsDataType = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	inputIds: string[],
	outputIds: string[],
	dataType: IDataType,
) => {
	const node = getDynamicDefNode(editorClient, events, nodeId);
	if (!node) return;

	const dynamicDef = node.def as Pick<AnyNodeDef, "controls" | "inputs" | "outputs">;

	for (const controlId of Object.keys(dynamicDef.controls ?? {})) {
		const control = dynamicDef.controls![controlId];
		control.type = dataType;
	}

	for (const inputId of inputIds) {
		const input = dynamicDef.inputs![inputId];
		input.type = dataType;
		input.control = CONTROL[dataType as never];
	}

	for (const outputId of outputIds) {
		if (!dynamicDef.outputs) continue;

		const output = dynamicDef.outputs[outputId];
		output.type = dataType;
	}

	node.def = structuredClone(dynamicDef);

	revalidateNodeConnections(editorClient, events, nodeId);
};

export const renameUtilityNodeSocket = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	inputId: string,
	newName: string,
) => {
	const node = getDynamicDefNode(editorClient, events, nodeId);
	if (!node) return;

	const dynamicDef = node.def as Partial<AnyNodeDef>;

	const control = dynamicDef.controls?.[inputId];
	if (control) {
		control.name = newName;
	}

	const input = dynamicDef.inputs?.[inputId];
	if (input) {
		input.name = newName;
	}

	const output = dynamicDef.outputs?.[inputId];
	if (output) {
		output.name = newName;
	}
};

export const changeUtilityNodeSocketDataType = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	inputId: string,
	dataType: IDataType,
) => {
	const node = getDynamicDefNode(editorClient, events, nodeId);
	if (!node) return;

	const dynamicDef = node.def as Partial<AnyNodeDef>;

	const control = dynamicDef.controls?.[inputId];
	if (control) {
		control.type = dataType;
	}

	const input = dynamicDef.inputs?.[inputId];
	if (input) {
		input.type = dataType;
		input.control = CONTROL[dataType as never];
	}

	const output = dynamicDef.outputs?.[inputId];
	if (output) {
		output.type = dataType;
	}

	node.def = structuredClone(dynamicDef);

	revalidateNodeConnections(editorClient, events, nodeId);
};

export const changeUtilityNodeSocketDataStructure = (
	editorClient: EditorClientImpl,
	events: Events,
	nodeId: string,
	inputId: string,
	dataStructure: IDataStructure,
) => {
	const node = getDynamicDefNode(editorClient, events, nodeId);
	if (!node) return;

	const dynamicDef = node.def as Partial<AnyNodeDef>;

	const control = dynamicDef.controls?.[inputId];
	if (control) {
		control.structure = dataStructure;
	}

	const input = dynamicDef.inputs?.[inputId];
	if (input) {
		input.structure = dataStructure;
	}

	const output = dynamicDef.outputs?.[inputId];
	if (output) {
		output.structure = dataStructure;
	}

	node.def = structuredClone(dynamicDef);

	revalidateNodeConnections(editorClient, events, nodeId);
};

const exportNodes = (target: Events, source: Events, ids: string[]) => {
	// add nodes that are in the selection
	for (const nodeId of ids) {
		if (source.nodes[nodeId]) {
			target.nodes[nodeId] = source.nodes[nodeId];
		} else if (source.comments[nodeId]) {
			target.comments[nodeId] = source.comments[nodeId];
		} else if (source.frames[nodeId]) {
			target.frames[nodeId] = source.frames[nodeId];
		}
	}

	// add connections that have both source and target in the selection
	for (const connection of source.connections) {
		if (ids.includes(connection.source) && ids.includes(connection.target)) {
			target.connections.push(connection);
		}
	}
};

export const exportSelection = (events: Events, selectedNodeIds: string[]): Events => {
	let selectionEvents: Events = {
		nodes: {},
		connections: [],
		comments: {},
		frames: {},
	};

	exportNodes(selectionEvents, events, selectedNodeIds);

	selectionEvents = structuredClone(selectionEvents);

	let selectionAllNodes = getAllNodes(selectionEvents);

	// are we copying a child node without it's parent?
	// - if no, noop
	// - if yes, and the child cannot exist without it's parent, then we should not copy the child
	// - if yes, and the child can exist without it's parent, then we should remove the parent and change the position to be in global space
	selectionAllNodes = getAllNodes(selectionEvents);

	for (const [selectedNode] of selectionAllNodes) {
		if ("parentId" in selectedNode && selectedNode.parentId) {
			const parentInSelection = selectionAllNodes.some(([node]) => node.id === selectedNode.parentId);

			// noop
			if (parentInSelection) continue;

			// the node can exist without it's parent
			// so we should remove the parent and change the position to be in global space
			const [parentNode] = getNode(events, selectedNode.parentId);

			if (parentNode) {
				selectedNode.position[0] += parentNode.position[0];
				selectedNode.position[1] += parentNode.position[1];
			}

			selectedNode.parentId = undefined;
		}
	}

	const topLevelNodes = getAllNodes(selectionEvents).filter(
		([node]) => !("parentId" in node) || !node.parentId,
	);
	const topLevelNodesPositions = topLevelNodes.map(([node]) => node.position);

	const minX = Math.min(...topLevelNodesPositions.map((pos) => pos[0]));
	const minY = Math.min(...topLevelNodesPositions.map((pos) => pos[1]));

	for (const [node] of topLevelNodes) {
		node.position[0] -= minX;
		node.position[1] -= minY;
	}

	const clonedSelectionEvents = structuredClone(selectionEvents);

	return clonedSelectionEvents;
};

export const importEvents = (events: Events, eventsToAdd: Events, position: [number, number]) => {
	const idMap: Record<string, string> = {};

	// generate new ids
	const nodeIds = getAllNodes(eventsToAdd).map(([node]) => node.id);

	for (const nodeId of nodeIds) {
		idMap[nodeId] = generateUUID();
	}

	for (const connection of eventsToAdd.connections) {
		idMap[connection.id] = generateUUID();
	}

	// update ids and parentIds, offset positions
	for (const node of Object.values(eventsToAdd.nodes)) {
		node.id = idMap[node.id];

		if (node.parentId) {
			node.parentId = idMap[node.parentId];
		} else {
			node.position[0] += position[0];
			node.position[1] += position[1];
		}

		tryAddNodeToEvents(events, node);
	}

	for (const comment of Object.values(eventsToAdd.comments)) {
		comment.id = idMap[comment.id];

		if (comment.parentId) {
			comment.parentId = idMap[comment.parentId];
		} else {
			comment.position[0] += position[0];
			comment.position[1] += position[1];
		}

		events.comments[comment.id] = comment;
	}

	for (const frame of Object.values(eventsToAdd.frames)) {
		frame.id = idMap[frame.id];
		frame.position[0] += position[0];
		frame.position[1] += position[1];

		events.frames[frame.id] = frame;
	}

	for (const connection of eventsToAdd.connections) {
		connection.source = idMap[connection.source];
		connection.target = idMap[connection.target];

		connection.id = idMap[connection.id];

		events.connections.push(connection);
	}

	const importedNodeIds = Object.values(idMap);

	return { newEvents: eventsToAdd, importedNodeIds };
};

export const getDefaultScriptControlsData = (editorClient: EditorClientImpl, events: Events) => {
	const data: Record<string, unknown> = {};

	for (const node of Object.values(events.nodes)) {
		const def = getNodeDef(editorClient, node.defId);

		if (!def || def.id !== NODE.Controls) continue;

		for (const controlId in def.controls) {
			const control = def.controls[controlId];

			if (control.config?.defaultValue) {
				data[controlId] = control.config.defaultValue;
			} else {
				const defaultValue = CONTROL_DEFAULT_VALUES[control.type];

				if (defaultValue !== undefined) {
					data[controlId] = defaultValue;
				}
			}
		}
	}

	return data;
};
