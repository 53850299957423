import React from "react";
import { Eye, EyeSlash } from "@components/icons";
import { Button } from "@components/ui/Button";
import { ErrorNote } from "@components/ui/ErrorNote";
import { Input } from "@components/ui/Input";
import { SuccessNote } from "@components/ui/SuccessNote";
import { Checkbox } from "@components/ui/Checkbox";
import { LOADING_STATE, useAuthUserStore } from "@stores/auth-user";
import { PRIVACY_URL, TOS_URL } from "@lib/constants/general";

export function RegisterUser() {
	const register = useAuthUserStore((state) => state.register);
	const error = useAuthUserStore((state) => state.error);
	const loading = useAuthUserStore((state) => state.loading === LOADING_STATE.register);
	const [message, setMessage] = React.useState("");
	const [username, setUsername] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [areTermsChecked, setAreTermsChecked] = React.useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

	const submit: React.FormEventHandler<HTMLFormElement> = async (evt) => {
		evt.preventDefault();

		const newUser = await register({
			username,
			email,
			password,
		});

		if (newUser) {
			setUsername("");
			setEmail("");
			setPassword("");
			setMessage("Account created! Redirecting...");

			setTimeout(() => {
				setMessage("");
			}, 3000);
		}
	};
	return (
		<form action="" className="mt-2 w-full max-w-md" onSubmit={submit}>
			<div className="mb-2">
				{!!error && <ErrorNote message={error} />}
				<SuccessNote message={message} />
			</div>

			<label htmlFor="username" className="mb-4 flex w-full flex-col items-center">
				<Input
					variant="filled"
					className="block w-full flex-1 text-lg"
					name="username"
					id="username"
					placeholder="Username"
					required
					value={username}
					onChange={(evt) => setUsername(evt.target.value)}
				/>
			</label>
			<label htmlFor="email" className="mb-4 flex w-full flex-col items-center">
				<Input
					variant="filled"
					type="email"
					className="block w-full flex-1 text-lg"
					name="email"
					id="email"
					placeholder="Enter email"
					autoComplete="email"
					required
					value={email}
					onChange={(evt) => setEmail(evt.target.value)}
				/>
			</label>
			<label htmlFor="password" className="relative mb-4 flex w-full flex-col items-center">
				<Input
					type={isPasswordVisible ? "text" : "password"}
					variant="filled"
					className="block w-full flex-1 text-lg"
					name="password"
					id="password"
					placeholder="Password"
					value={password}
					onChange={(evt) => setPassword(evt.target.value)}
					required
				/>
				<Button
					color="white-ghost"
					className="absolute right-3 top-1/2 -translate-y-1/2 transform"
					square
					onClick={() => setIsPasswordVisible(!isPasswordVisible)}
				>
					{isPasswordVisible ? (
						<EyeSlash className="pointer-events-none size-5" />
					) : (
						<Eye className="pointer-events-none size-5" />
					)}
				</Button>
			</label>
			<label className="mb-4 flex select-none items-start gap-1">
				<Checkbox
					checked={areTermsChecked}
					onCheckedChange={(checked: boolean) => setAreTermsChecked(checked)}
					className="mt-1"
				/>
				<span className="text-center">
					You accept this is an alpha version and our{" "}
					<a href={TOS_URL} target="_blank" className="text-link" rel="noreferrer">
						Terms of Service
					</a>{" "}
					and{" "}
					<a href={PRIVACY_URL} target="_blank" className="text-link" rel="noreferrer">
						Privacy Policy
					</a>
				</span>
			</label>

			<Button isSubmit fullWidth className="mb-4" disabled={loading || !areTermsChecked}>
				{loading ? "Creating..." : "Sign up"}
			</Button>
		</form>
	);
}
