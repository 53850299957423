import { getStaticFile } from "@jamango/content-client";

export default `
    @font-face {
        font-family: 'Black Ops One';
        src: url(${getStaticFile("assets/frontend/fonts/Black_Ops_One/BlackOpsOne-Regular.woff2")}) format('woff2'); 
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;
