import { generateId } from "../../lib/helpers/general";
import type { IIdentifier, IUser, IWorldBundle } from "../../lib/types";
import { AssetType, type IWorldData } from "../../lib/types";
import type { ContentMetadata } from "../../lib/types/content-metadata";
import { validations } from "../../lib/validations";
import { EDIT_WORLD_MODE, type WorldMode } from "../../lib/validations/world";
import type { JacyContentState } from "../JacyContentState";

export class JacyWorldDataState {
	#worldData: IWorldData;
	#state: JacyContentState;

	#hasLoaded: boolean = false;
	liveVersionId?: IIdentifier | null = null;
	isNew = false;
	isFeatured = false;
	isTutorial = false;
	visits = 0;

	#allTags: string[] = ["obby", "pvp", "quest"];

	#publish: ContentMetadata["publish"];
	#version?: ContentMetadata["version"] | null;

	#owner?: IUser | null;
	#collaborators: IUser[];

	setCollaborators(collaborators: IUser[]) {
		this.#collaborators = collaborators;
	}

	generateNewWorld(defaultBundle: IWorldBundle, id: IIdentifier = generateId()) {
		const newWorld = structuredClone(defaultBundle);

		newWorld.id = id;
		newWorld.assets.worldData.id = id;
		newWorld.assets.worldData.pk = `${AssetType.WORLD_DATA}#${id}`;
		newWorld.assets.worldData.shortCode = "";

		return newWorld;
	}

	setAsNew() {
		const world = this.get();
		const id = generateId();
		this.isNew = true;
		world.id = id;
		world.pk = `${AssetType.WORLD_DATA}#${id}`;
		world.shortCode = "";
		this.#version = null;
		this.set(world);
	}

	setOwner(user?: IUser | null) {
		this.#owner = user;
	}

	assert() {
		if (!this.#hasLoaded) {
			throw new Error("World is not set");
		}

		return this.get();
	}

	onSave(shortCode: string) {
		const world = this.get();
		this.isNew = false;
		world.shortCode = shortCode;
		this.set(world);
	}

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#collaborators = [];
		this.#owner = null;
		this.#publish = {};
		this.#version = null;
		const id = generateId();
		const pk = `${AssetType.WORLD_DATA}#${id}`;

		this.#worldData = {
			pk,
			type: AssetType.WORLD_DATA,
			id,
			name: "Unknown World",
			experiments: [],
			mode: EDIT_WORLD_MODE,
			allowRemix: true,
			shortCode: "",
			tags: [],
			packages: [],
		};
	}

	import(data: IWorldData) {
		this.#hasLoaded = true;
		this.#collaborators = [];
		this.#owner = null;
		this.#publish = {};
		this.#version = null;

		this.set(data);
	}

	export() {
		return this.#worldData;
	}

	get id() {
		return this.#worldData.id;
	}

	get allowRemix() {
		return this.get().allowRemix;
	}

	get owner() {
		return this.#owner;
	}

	get collaborators() {
		return this.#collaborators;
	}

	get pk() {
		return this.#worldData.pk;
	}

	get mode() {
		return this.get().mode;
	}

	get name() {
		return this.get().name;
	}

	get experiments() {
		return this.get().experiments;
	}

	get shortCode() {
		return this.get().shortCode;
	}

	get isExperimental() {
		return this.get().experiments.length > 0;
	}

	get publish() {
		return this.#publish;
	}

	get version() {
		return this.#version;
	}

	setPublishStatus(status: "under-review" | "approved" | "rejected" | null) {
		if (status === null) {
			this.#publish = {};
		} else {
			this.#publish.status = status;
		}
	}

	setMetadata(data: Partial<ContentMetadata>) {
		this.setOwner(data.owner);
		this.setCollaborators(data.collaborators ?? []);
		this.liveVersionId = data.liveVersionId;
		this.isFeatured = !!data.isFeatured;
		this.isTutorial = !!data.isTutorial;
		this.isNew = !data.version;
		this.#publish = data.publish ?? {};
		this.#version = data.version;
	}

	setVersion(version?: ContentMetadata["version"]) {
		this.#version = version;
		this.#publish = {};
	}

	set(data: IWorldData) {
		if (!data.pk) {
			throw new Error("Can't set world data, world is not loaded yet.");
		}
		this.#worldData = data;

		this.#state.markDirty();
	}

	get() {
		return this.#worldData;
	}

	setName(name: string) {
		const worldData = this.get();
		worldData.name = name;
		this.set(worldData);
		return worldData.pk;
	}

	updateMode(value: WorldMode) {
		const canToggleMode = this.#state.isServer;

		if (!canToggleMode) {
			throw new Error("You can only toggle mode if you are an owner/collaborator.");
		}

		const worldData = this.get();

		worldData.mode = value;
		this.set(worldData);
	}

	updateName(name: string) {
		validations.world.name(name);

		const worldData = this.get();
		worldData.name = name;
		this.set(worldData);
	}

	updateExperiment(experiment: string, enabled: boolean) {
		validations.world.experiment(experiment);

		const oldExperiments = this.experiments;

		const experiments = enabled
			? [...oldExperiments, experiment]
			: oldExperiments.filter((exp) => exp !== experiment);

		this.#setExperiments(experiments);
	}

	#setExperiments(experiments: IWorldData["experiments"]) {
		validations.world.experiments(experiments);

		const worldData = this.get();

		worldData.experiments = experiments;

		this.set(worldData);
	}

	setAllowRemix(enabled: boolean) {
		const worldData = this.get();
		worldData.allowRemix = enabled;
		this.set(worldData);
	}

	getUsedTags() {
		return this.get().tags;
	}

	setUsedTags(tags: string[]) {
		const worldData = this.get();
		worldData.tags = [...new Set(tags)];
		this.set(worldData);
	}

	addTag(tag: string) {
		validations.world.tag(tag);

		const oldTags = this.getUsedTags();
		const newTags = [...oldTags, tag];
		this.setUsedTags(newTags);
	}

	deleteTag(tag: string) {
		validations.world.tag(tag);

		const oldTags = this.getUsedTags();
		const newTags = oldTags.filter((t) => t !== tag);
		this.setUsedTags(newTags);
	}

	hasTag(tag: string) {
		return this.getUsedTags().includes(tag);
	}

	getAllTags() {
		return this.#allTags;
	}

	searchTags(query: string) {
		query = query.toLowerCase();
		const result: string[] = [];

		const tags = this.getAllTags();

		for (const tag of tags) {
			if (tag.toLowerCase().includes(query)) {
				result.push(tag);
			}
		}

		return result;
	}

	addPackage({ id, version }: { id: string; version: string }) {
		this.removePackage(id);
		const worldData = this.get();
		worldData.packages.push({ id, version });
		this.set(worldData);
	}

	removePackage(packageId: string) {
		const worldData = this.get();
		worldData.packages = worldData.packages.filter((p) => p.id !== packageId);
		this.set(worldData);
	}

	getExportedAssets() {
		return this.get().exportedAssets ?? [];
	}

	toggleExportedAsset(pk: string) {
		const worldData = this.get();
		const old = worldData.exportedAssets ?? [];

		worldData.exportedAssets = old.includes(pk) ? old.filter((id) => id !== pk) : [...old, pk];

		this.set(worldData);
	}

	removeExportedAsset(pk: string) {
		const worldData = this.get();
		worldData.exportedAssets = worldData.exportedAssets?.filter((id) => id !== pk);
		this.set(worldData);
	}
}
