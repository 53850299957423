import { create } from "zustand";
import { useEngineStore } from "./bb";
import { isAnyModalOpen } from "@lib/helpers/isAnyModalOpen";
import { useHelpersStore } from "./helpers";
import { useLoadingScreenStore } from "./loading-screen";
import { useItemStore } from "./hud/item.ts";
import { CONTROLS, MODE } from "@lib/constants/controls";
import { usePencilStore } from "./pencil.ts";
import { CHARACTER_SCULPT_MODE_CYCLE } from "@jamango/engine/Runtime/base/world/entity/component/CharacterSelector.ts";
import { CHARACTER_VIEW_RAYCAST_MODE_CYCLE } from "@jamango/engine/Runtime/base/world/entity/component/CharacterViewRaycast.ts";
import { useWrenchStore } from "./wrench.ts";
import { useSelectorStore } from "./hud/selector.ts";
import { InputCommandType } from "@jamango/engine/Input.ts";

const CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY = "custom-keyboard-controls";
const UI_IGNORE_NODES = ["INPUT", "TEXTAREA"];

const customKeyboardControlsStorage = {
	get: () => {
		const keys = localStorage.getItem(CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY);

		if (!keys) return {};

		try {
			return JSON.parse(keys);
		} catch {
			localStorage.removeItem(CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY);
			return {};
		}
	},
	set: (keys) => {
		localStorage.setItem(CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY, JSON.stringify(keys));
	},
};

export const useKeyboardStore = create((set, get) => ({
	keys: CONTROLS,
	init: () => {
		const keys = {
			...CONTROLS,
			...customKeyboardControlsStorage.get(),
		};

		set({ keys });

		useEngineStore.getState().promise.then(() => {
			const getKey = (key) => {
				const { engine } = useEngineStore.getState();

				const { BB } = engine;

				const keys = typeof key === "string" ? get().keys[key] : key;

				return BB?.client.inputPoll?.getKeyComboString(keys || CONTROLS[key]);
			};

			set({ getKey });
		});

		return keys;
	},
	getKey: (_key) => {
		return "";
	},
	setKey: (key, value) => {
		const keys = { ...get().keys, [key]: value };

		set({ keys });

		const { BB } = useEngineStore.getState().engine;
		BB?.client.settings.bindKeys({ [key]: value });

		// only persist non-default controls so we can gracefully change defaults
		const nonDefaultKeys = Object.keys(keys).reduce((acc, key) => {
			if (JSON.stringify(keys[key]) !== JSON.stringify(CONTROLS[key])) {
				acc[key] = keys[key];
			}

			return acc;
		}, {});

		customKeyboardControlsStorage.set(nonDefaultKeys);
	},
	reset: () => {
		set({ keys: CONTROLS });

		for (const key in get().keys) {
			get().setKey(key, get().keys[key]);
		}
	},
}));

export const useControlsStore = create((set, get) => ({
	blockMode: MODE.break,
	sculptMode: 0,
	selectionMode: 0,
	emotesWheel: false,
	toolWheel: false,
	tapPointer: { id: null, x: 0, y: 0 },
	cameraPerspective: "FIRST_PERSON",
	canvasTouchStart: (e) => {
		const touch = e.changedTouches[0];
		set({
			tapPointer: { id: touch.identifier, x: touch.clientX, y: touch.clientY },
		});
	},
	canvasTouchEnd: (e) => {
		const curTouch = get().tapPointer;
		if (curTouch.id === null) return;

		for (const touch of e.changedTouches) {
			if (touch.identifier !== curTouch.id) continue;

			const dx = touch.clientX - curTouch.x;
			const dy = touch.clientY - curTouch.y;
			if (Math.sqrt(dx * dx + dy * dy) < 10) get().fireCanvasPointerTap();

			set({ tapPointer: { id: null, x: 0, y: 0 } });

			break;
		}
	},
	fireCanvasPointerTap: () => {
		const { BB } = useEngineStore.getState().engine;
		const input = BB.world.input.local;

		// TODO: this should be expressed as a single command?
		input.cmd.push(InputCommandType.INTERACT, InputCommandType.MELEE);
	},
	interact: () => {
		if (isAnyModalOpen()) return;

		const { BB } = useEngineStore.getState().engine;
		BB.world.input.local.cmd.push(InputCommandType.INTERACT);
	},
	doVehicleDismount: () => {
		const { BB } = useEngineStore.getState().engine;
		BB.world.input.local.doVehicleDismount = true;
	},
	toggleZenMode: (enabled = null) => {
		if (isAnyModalOpen()) return;

		const { BB } = useEngineStore.getState().engine;

		const characterHUD = BB.world.client.camera.target?.characterHUD;
		if (!characterHUD) return;

		const zenModeEnabled = enabled !== null ? enabled : !characterHUD.state.zenMode;

		BB.world.input.local.cmd.push([InputCommandType.CHANGE_ZEN_MODE, zenModeEnabled]);
	},
	toggleSculptMode: (mode) => {
		if (isAnyModalOpen()) return;

		const { BB } = useEngineStore.getState().engine;

		let newSculptMode;
		if (typeof mode === "number") {
			newSculptMode = mode;
		} else {
			const newIndex =
				(CHARACTER_SCULPT_MODE_CYCLE.indexOf(get().sculptMode) + 1) %
				CHARACTER_SCULPT_MODE_CYCLE.length;
			newSculptMode = CHARACTER_SCULPT_MODE_CYCLE[newIndex];
		}

		set({ sculptMode: newSculptMode });
		BB.world.input.local.cmd.push([InputCommandType.CHANGE_SCULPT_MODE, newSculptMode]);

		BB.world.sfxManager?.play({
			asset: "snd-inventory-open",
			loop: false,
			volume: 0.5,
		});
	},
	selectorSetSelectionMode: (mode) => {
		const { BB } = useEngineStore.getState().engine;

		BB.world.input.local.cmd.push([InputCommandType.CHANGE_RAYCAST_MODE, mode]);

		BB.world.sfxManager?.play({
			asset: "snd-inventory-open",
			loop: false,
			volume: 0.5,
		});
	},
	onSelectorSelectionModeChange: (mode) => {
		set({ selectionMode: mode });
	},
	selectorToggleSelectionMode: (checkModalOpen) => {
		if (checkModalOpen && isAnyModalOpen()) return;

		const { BB } = useEngineStore.getState().engine;

		const cameraTarget = BB.world.client.camera.target;
		const viewRaycast = cameraTarget.viewRaycast;
		if (!viewRaycast) return;
		const newIndex =
			(CHARACTER_VIEW_RAYCAST_MODE_CYCLE.indexOf(get().selectionMode) + 1) %
			CHARACTER_VIEW_RAYCAST_MODE_CYCLE.length;
		const newSelectionMode = CHARACTER_VIEW_RAYCAST_MODE_CYCLE[newIndex];

		get().selectorSetSelectionMode(newSelectionMode);
	},
	toggleEmotesWheel: (emotesWheel) => {
		const newState = typeof emotesWheel === "boolean" ? emotesWheel : !get().emotesWheel;

		if (newState !== false && isAnyModalOpen()) return;

		get().toggleUI("emotesWheel", newState);
	},
	toggleToolWheel: (toolWheel) => {
		if (useItemStore.getState().toolWheel.length === 0) toolWheel = false;
		else if (typeof toolWheel !== "boolean") toolWheel = !get().toolWheel;
		if (toolWheel && isAnyModalOpen()) return;

		get().toggleUI("toolWheel", toolWheel);
	},
	toggleCameraPerspective: () => {
		if (isAnyModalOpen()) return;

		const { BB } = useEngineStore.getState().engine;
		const cameraPerspective = BB.world?.client.camera.toggleCameraPerspective();
		set({ cameraPerspective });
		useHelpersStore.getState().markCameraKnown();
	},
	playerAnimate: (name, loop) => {
		get().toggleEmotesWheel(false);

		const { BB } = useEngineStore.getState().engine;
		BB.world.input.local.cmd.push([InputCommandType.EMOTE, name, loop]);
	},
	toggleUI: (currentUI, visible = true) => {
		if (get()[currentUI].visible === visible) return;

		get().exitPointerLock(visible);
		set({ [currentUI]: visible });

		if (!visible) return;

		const ui = {
			emotesWheel: get().emotesWheel,
			toolWheel: get().toolWheel,
			[currentUI]: visible,
		};

		const newState = {};
		for (const key in ui) {
			if (key === currentUI) continue;
			newState[key] = false;
		}

		set(newState);
	},
	shouldIgnoreInput: () => {
		if (isAnyModalOpen()) return true;
		if (useLoadingScreenStore.getState().visible) return true;

		const target = document.activeElement;
		if (!target) return false;

		return (
			UI_IGNORE_NODES.includes(target.nodeName) ||
			target.classList.contains("keyboard-shortcut") ||
			target.classList.contains("ignore-keyshortcut")
		);
	},
	close: () => {
		set({ emotesWheel: false, toolWheel: false });
	},
	exitPointerLock: (close = true) => {
		const { BB } = useEngineStore.getState().engine;
		BB?.client.inputPoll.forcePointerLock(!close);
	},
	copy: () => {
		usePencilStore.getState().copy();
	},
	cut: () => {
		usePencilStore.getState().cut();
	},
	paste: () => {
		usePencilStore.getState().pasteWithPreview();
	},
	pasteImmediately: () => {
		usePencilStore.getState().paste();
	},
	undo: () => {
		usePencilStore.getState().undo();
	},
	redo: () => {
		usePencilStore.getState().redo();
	},
	pencilSolidFill: () => {
		usePencilStore.getState().solidFill();
	},
	pencilHollowFill: () => {
		usePencilStore.getState().hollowFill();
	},
	pencilRemoveAllBlocks: () => {
		usePencilStore.getState().deleteSelection();
	},
	pencilRotateClipboard: () => {
		usePencilStore.getState().rotateClipboard();
	},
	pencilFlipClipboard: () => {
		usePencilStore.getState().flipClipboadByCameraDirection();
	},
	pencilAddToSelection: () => {
		usePencilStore.getState().addToSelection();
	},
	pencilCreateBlockStructure: () => {
		usePencilStore.getState().createBlockStructureFromSelection();
	},
	selectorNudgeLeft: () => {
		useSelectorStore.getState().nudgeSelection([-1, 0, 0]);
	},
	selectorNudgeRight: () => {
		useSelectorStore.getState().nudgeSelection([1, 0, 0]);
	},
	selectorNudgeUp: () => {
		useSelectorStore.getState().nudgeSelection([0, 1, 0]);
	},
	selectorNudgeDown: () => {
		useSelectorStore.getState().nudgeSelection([0, -1, 0]);
	},
	selectorNudgeForward: () => {
		useSelectorStore.getState().nudgeSelection([0, 0, -1]);
	},
	selectorNudgeBack: () => {
		useSelectorStore.getState().nudgeSelection([0, 0, 1]);
	},
	selectorResetBulkSelection: () => {
		useSelectorStore.getState().resetSelection();
	},
	wrenchRemoveBlockGroups: () => {
		useWrenchStore.getState().removeSelectedBlockGroups();
	},
	selectorCycleScriptTargets: () => {
		useWrenchStore.getState().cycleTarget();
	},
}));

const DOUBLE_PRESS_DELAY = 400;

export const useMovementStore = create((set, get) => ({
	lastPressedUp: 0,
	flying: false,
	crouching: false,

	setFlying: (flying) => {
		set({ flying });
	},
	setCrouching: (crouching) => {
		set({ crouching });
	},
	toggleCrouching: (crouching) => {
		const { BB } = useEngineStore.getState().engine;
		BB.world.input.local.isCrouching = crouching;
	},
	up: (touchStart = false) => {
		const { BB } = useEngineStore.getState().engine;
		const input = BB.world.input.local;

		input.isJumping = input.isFlyingUp = touchStart;

		if (touchStart) {
			const time = performance.now();
			const delta = time - get().lastPressedUp;

			if (delta < DOUBLE_PRESS_DELAY) {
				input.requestFlying = !input.requestFlying;
				set({ lastPressedUp: 0 });
			} else {
				set({ lastPressedUp: time });
			}
		}
	},
	down: (touchStart) => {
		const { BB } = useEngineStore.getState().engine;
		BB.world.input.local.isFlyingDown = touchStart;
	},
	move: (x, y) => {
		const { BB } = useEngineStore.getState().engine;
		BB?.client.inputPoll.setLeftStickVec(x, y);
	},
	cancelDoubleTaps: () => {
		set({ lastPressedUp: 0 });
	},
}));

export default {
	useKeyboard: useKeyboardStore.getState,
	useControls: useControlsStore.getState,
	useMovement: useMovementStore.getState,
};
