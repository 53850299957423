import type { ItemRangedWeaponType } from "@jamango/content-client";
import { ItemBehaviourType } from "@jamango/content-client";
import type { ItemAsset, ItemBehaviourRangedWeapon } from "@jamango/content-client/lib/types/item.js";
import { create } from "zustand";

type JacyItemEditorState = {
	open: boolean;
	item: ItemAsset;

	// Handlers
	setName: (name: string) => void;
	setCooldown: (cooldown: number) => void;
	setMesh: (mesh: ItemAsset["mesh"]) => void;
	setCollider: (collider: ItemAsset["collider"]) => void;
	setItemBehaviourType: (type: ItemAsset["itemBehaviour"]["type"]) => void;
	setItemAnimationType: (type: ItemAsset["itemAnimation"]["type"]) => void;
	setRangedWeaponType: (rangedWeaponType: ItemRangedWeaponType) => void;

	// Actions
	close: () => void;
	editItem: (prop: ItemAsset) => void;
	getItem: () => ItemAsset;
};

export const useJacyItemEditorStore = create<JacyItemEditorState>((set, get) => ({
	open: false,
	item: null!,

	setName: (name) => {
		const item = get().item;

		item.name = name;
		set({ item });
	},

	setCooldown: (cooldown) => {
		const item = get().item;
		const itemBehaviour = item.itemBehaviour as ItemBehaviourRangedWeapon;
		itemBehaviour.cooldown = cooldown;
		set({ item });
	},

	setMesh: (mesh) => {
		const item = get().item;
		item.mesh = mesh;
		set({ item });
	},
	setCollider: (collider) => {
		const item = get().item;
		item.collider = collider;
		set({ item });
	},
	setItemBehaviourType: (type) => {
		const item = get().item;
		item.itemBehaviour.type = type;
		if (type !== ItemBehaviourType.RANGED_WEAPON) {
			delete (item.itemBehaviour as Partial<ItemBehaviourRangedWeapon>).rangedWeaponType;
		}
		set({ item });
	},
	setItemAnimationType: (type) => {
		const item = get().item;
		item.itemAnimation.type = type;
		set({ item });
	},
	setRangedWeaponType: (rangedWeaponType) => {
		const item = get().item;
		const itemBehaviour = item.itemBehaviour as ItemBehaviourRangedWeapon;
		if (itemBehaviour.type === ItemBehaviourType.RANGED_WEAPON) {
			itemBehaviour.rangedWeaponType = rangedWeaponType;
			set({ item });
		}
	},

	close: () => set({ open: false }),
	editItem: (item) => {
		set({
			open: true,
			item,
		});
	},
	getItem: () => structuredClone(get().item),
}));
