export const BLOCK_PACKS_LIST = [
	{
		packageId: "343140B9392047ACAFC0A4C60028E982",
		displayName: "Jamango! Mango",
		url: "assets/frontend/block-packs/blocks-tropical.png",
		description:
			"A tropical expansion to the default blocks, in the same art style yet more lively and with much more color.",
		type: "block-pack",
		size: "354 KB",
		blockCount: 154,
		packageName: "tropical",
	},
	{
		packageId: "A08AAD7B8A3349C8A03D0D37B2768BA3",
		displayName: "Wild West",
		url: "assets/frontend/block-packs/blocks-wild-west.png",
		description:
			"Captures the essence of the old west, perfect for creating sun-bleached wooden buildings in a vast desert landscape.",
		type: "block-pack",
		size: "1.37 MB",
		blockCount: 128,
		packageName: "wild-west",
	},
	{
		packageId: "22FF682CAAD6420DBC44018E3DCA0AB4",
		displayName: "Space Station",
		url: "assets/frontend/block-packs/blocks-space-station.png",
		description: "Everything you need to create an industrial space craft drifting through deep space.",
		type: "block-pack",
		size: "356 KB",
		blockCount: 123,
		packageName: "space-station",
	},
	{
		packageId: "B075A37135134A049EFE14BF192C68C9",
		displayName: "Alien Planet",
		url: "assets/frontend/block-packs/blocks-alien-planet.png",
		description:
			"A 'crayon set' of bizarre flora and strangely colored terrain you'll find on your average alien exoplanet",
		type: "block-pack",
		size: "1.68 MB",
		blockCount: 276,
		packageName: "alien-planet",
	},
	{
		packageId: "7DE1A667116B4EC8880CEF1D39948DA1",
		displayName: "Kingdoms and Wizardry",
		url: "assets/frontend/block-packs/blocks-kingdom-and-wizardry.png",
		description: "Blocks from a world where medieval kingdoms meet mystical forces",
		type: "block-pack",
		size: "4.50 MB",
		blockCount: 319,
		packageName: "kingdom-and-wizardry",
	},
	{
		packageId: "5B8BA59E6B34424F997AC778930D97C2",
		displayName: "Urban",
		url: "assets/frontend/block-packs/blocks-urban.jpg",
		description: "All of the concrete, metal, wood and more needed to construct a modern city",
		type: "block-pack",
		size: "2.65 MB",
		blockCount: 294,
		packageName: "urban",
	},
];
