import { AssetType, IAnyAsset, IBundle, IPackageBundle, IPackageData } from "../lib/types";
import { generateId, generateUUID } from "../lib/helpers/general";
import { LATEST_BUNDLE_VERSION } from "../lib/validations/bundle";
import type { JacyContentState } from "./JacyContentState";
import { buildDependencyGraph } from "./dependency-graph";
import { findAssetInBundle, setAssetInBundle } from "./bundle-utils";

export const EXPORTABLE_ASSET_TYPES = [
	AssetType.SKYBOX,
	AssetType.ENVIRONMENT_PRESET,
	AssetType.BLOCK_TEXTURE,
	AssetType.BLOCK,
	AssetType.AUDIO,
	AssetType.AVATAR,
	AssetType.AVATAR_COMPONENT,
	AssetType.CHARACTER,
	AssetType.SCRIPT,
	AssetType.RESOURCE,
	AssetType.PACKAGE_DATA,
	AssetType.CUSTOM_UI,
	AssetType.PARTICLE_SYSTEM,
	AssetType.BLOCK_SOUNDS_PACK,
	AssetType.BLOCK_STRUCTURE,
	AssetType.PROP,
	AssetType.ITEM,
] as const;

export function exportAsPackage(
	bundle: IBundle,
	pks: string[],
	_state: JacyContentState,
	fromOld?: IPackageBundle,
): IPackageBundle & { assets: { packageData: IPackageData } } {
	const old = fromOld?.assets?.packageData;

	const packageId = fromOld?.id ?? generateId();
	const version = old?.version ?? generateUUID();

	const packageBundle = {
		version: LATEST_BUNDLE_VERSION,
		type: "package",
		id: packageId,
		assets: {
			packageData: {
				pk: `packageData#${packageId}`,
				name: old?.name ?? "exported",
				type: "packageData",
				id: packageId,
				version,
				tags: old?.tags ?? [],
				createdAt: old?.createdAt ?? new Date().toISOString(),
				updatedAt: old?.updatedAt ?? new Date().toISOString(),
			},
		},
	} satisfies IPackageBundle & { assets: { packageData: IPackageData } };

	const graph = buildDependencyGraph(bundle, undefined, pks);

	for (const pk of graph.nodes) {
		const asset = findAssetInBundle(bundle, pk);
		if (!asset) continue;

		if (!EXPORTABLE_ASSET_TYPES.includes(asset.type as (typeof EXPORTABLE_ASSET_TYPES)[number])) continue;

		(asset as IAnyAsset & { packageId?: string }).packageId = packageId;

		setAssetInBundle(packageBundle, asset);
	}

	return packageBundle;
}
