const ADJECTIVES = [
	"Mysterious",
	"Radiant",
	"Majestic",
	"Vibrant",
	"Ethereal",
	"Enigmatic",
	"Luminous",
	"Fierce",
	"Serene",
	"Majestic",
	"Whimsical",
	"Intricate",
	"Charming",
	"Spirited",
	"Resplendent",
	"Enchanting",
	"Majestic",
	"Nimble",
	"Energetic",
	"Mystical",
	"Brave",
	"Dazzling",
	"Melodic",
	"Playful",
	"Witty",
	"Grandiose",
	"Ethical",
	"Majestic",
	"Intriguing",
	"Cosmic",
];

const NOUNS = [
	"Paladin",
	"Necromancer",
	"Samurai",
	"Druid",
	"Sorcerer",
	"Bard",
	"Assassin",
	"Monk",
	"Ranger",
	"Alchemist",
	"Knight",
	"Summoner",
	"Berserker",
	"Warlock",
	"Enchanter",
	"Blacksmith",
	"Squire",
	"Tinker",
	"Falconer",
	"Fletcher",
	"Apothecary",
	"Barber-Surgeon",
	"Weaver",
	"Cooper",
	"Minstrel",
	"Innkeeper",
	"Tanner",
	"Bailiff",
	"Jester",
	"Vintner",
];

export function generateRandomCharacterName() {
	const adjective = ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)];
	const noun = NOUNS[Math.floor(Math.random() * NOUNS.length)];

	return `${adjective} ${noun}`;
}

export const TOTAL_VARIATIONS = 841;
