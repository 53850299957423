export const createUndoManager = <T>() => {
	const undoStack: T[] = [];
	const redoStack: T[] = [];

	let lastValue: T = null!;

	const init = (value: T) => {
		undoStack.length = 0;
		redoStack.length = 0;
		lastValue = structuredClone(value);
	};

	const set = (value: T) => {
		undoStack.push(lastValue);
		redoStack.length = 0;
		lastValue = structuredClone(value);
	};

	const get = () => structuredClone(lastValue);

	const undo = () => {
		if (undoStack.length === 0) return false;

		redoStack.push(lastValue!);
		lastValue = undoStack.pop()!;

		return true;
	};

	const redo = () => {
		if (redoStack.length === 0) return false;

		const value = redoStack.pop()!;
		undoStack.push(lastValue!);

		lastValue = value;

		return true;
	};

	return {
		undoStack,
		redoStack,
		init,
		set,
		get,
		undo,
		redo,
	};
};

export type UndoManager<T> = ReturnType<typeof createUndoManager<T>>;
