import { PLAY_WORLD_MODE, type ISelectorSlots } from "@jamango/content-client";
import type { PeerMetadata } from "@jamango/engine/PeerMetadata.ts";
import { Jacy } from "@jacy-client";
import { bigScaryConsoleWarning, delay } from "@jamango/helpers";
import { useAuthUserStore } from "@stores/auth-user";
import { closeAllModal } from "@lib/helpers/closeAllModal";
import { formatErrorMessage } from "@lib/helpers/formatErrorMessage";
import { initEngine } from "@lib/setup/setup-engine";
import { useHelpersStore } from "@stores/helpers";
import { useWorldEditorStore } from "@stores/world-editor";
import { useLoadingScreenStore } from "@stores/loading-screen";
import { useInventorySelectorStore } from "@stores/hud/inventory-selector";
import { useGameStore } from "@stores/game";
import { useOnboardingStore } from "@stores/onboarding";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useCustomUIStore } from "@stores/custom-ui";
import { useGameHudStore } from "@stores/game-hud";
import { useEngineStore } from "@stores/bb";
import { Loading, useGameClientStore } from "@stores/game-client/game-client";
import { useSettingsStore } from "@stores/settings";
import { useCreateWorldModal, useErrorStore } from "@stores/dialogs";
import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";
import { useEditor } from "@editor/editor-store";
import * as GameClient from "./GameClient";
import * as GameMultiplayer from "./GameMultiplayer";
import * as Router from "./Router";
import { useMultiplayerStore } from "@stores/game-client/multiplayer";

export const state = {
	isFirstLoaded: true,
};

export function onChangePage() {
	Jacy.content.reset();

	if (Router.isPlayPage()) return;

	const { engine } = useEngineStore.getState();

	if (!engine) return;

	const { BB } = engine;
	BB.router.endWorld();
}

export function onCanvasLoaded() {
	if (globalEnv.ALLOW_MOBILE === "false") return;

	initEngine();
}

export async function onEngineLoaded() {
	useAuthUserStore.getState().syncFeatureFlags();

	if (!Router.isPlayPage()) {
		if (GameClient.IS_PRODUCTION) {
			bigScaryConsoleWarning();
		}

		return;
	}

	const { worldId, versionId, serverId, matchmake } = Router.getParams();

	const loadWorld = async () => {
		if (worldId) {
			try {
				if (matchmake) {
					await GameMultiplayer.joinPublishedWorld(worldId);
				} else {
					await GameClient.loadWorld(worldId, { versionId });
				}
			} catch (error) {
				Router.navigate("/");
				useAlertDialogStore.getState().setAlert({
					title: "Failed to load the world",
					message: `There was an error loading the world.\n${formatErrorMessage(error)}`,
				});
			}
		} else {
			GameClient.loadDefaultWorld();
		}
	};

	if (serverId) {
		await GameMultiplayer.joinWorld(serverId);
	} else if (worldId) {
		await loadWorld();
	} else {
		GameClient.loadDefaultWorld();
	}
}

export function onEngineLoadingFailed(error: unknown) {
	if (error instanceof Error) {
		useErrorStore.getState().setError(error);
	}
}

export async function onDisposeWorld() {
	closeAllModal();

	useEngineStore.setState({ world: null });

	useSettingsStore.getState().setShowReloadPanel(false);
	useGameHudStore.getState().clearHudUI();
	useCustomUIStore.getState().clearCustomUI();
	useCreateWorldModal.getState().resetPackages();
	useEditor.getState().clearEditor();
	useInventorySelectorStore.getState().clear();

	localStorage.setItem("worldId", "");
	localStorage.setItem("serverId", "");

	const url = new URL(window.location.href);
	url.searchParams.delete("game");
	url.searchParams.delete("world");
	url.searchParams.delete("versionId");
	url.searchParams.delete("matchmake");
	Router.replaceHistory(url.pathname + url.search);

	await GameMultiplayer.disconnect();

	Jacy.content.reset();

	Jacy.refreshContentState();
}

export function onPageUnload(e: BeforeUnloadEvent) {
	const loading = useGameClientStore.getState().loading;

	if (loading !== Loading.SAVE_WORLD) return;

	e.preventDefault();
	// Included for legacy support, e.g. Chrome/Edge < 119
	e.returnValue = true;
}

export async function onBeforeNavigatePageToLoadWorld() {
	closeAllModal();

	// give time to close all modals before redirecting page.
	await delay(10);

	if (!Router.isPlayPage()) {
		Router.navigate("/play");
	}
}

export function onBeforeLoadWorld() {
	if (Jacy.content.state.worldData.shortCode) {
		localStorage.setItem("worldId", Jacy.content.state.worldData.shortCode);
	}

	useHelpersStore.getState().stopMonitoring();
	useInventoryStore.getState().clear();
	useWorldEditorStore.getState().clear();

	Jacy.refreshContentState();

	useLoadingScreenStore.getState().show();

	useAuthUserStore.getState().updateAccess(Jacy.content.state);
}

export function onAfterLoadWorld(inventory?: ISelectorSlots) {
	Jacy.refreshContentState();

	useEngineStore.setState({ world: useEngineStore.getState().engine.BB.world });
	useHelpersStore.getState().startMonitoring();
	useInventorySelectorStore.getState().init(inventory);
	useGameStore.getState().onLoadWorld();

	unlockAudio();

	if (state.isFirstLoaded) {
		state.isFirstLoaded = false;
		useOnboardingStore.getState().start();
	}

	if (Jacy.state.isPrivateServer && Jacy.state.isServer) {
		const initialMode = Jacy.state.worldData.mode;
		Jacy.actions.worldData.toggleMode(initialMode ?? PLAY_WORLD_MODE);
	}

	if (GameClient.IS_PRODUCTION) bigScaryConsoleWarning();
}

export function onAfterHostOrJoinWorld(serverID: string, worldID?: string | null) {
	const url = new URL(window.location.href);
	url.searchParams.set("game", serverID);

	if (worldID) {
		url.searchParams.set("world", worldID);
	}

	Router.pushHistory(url.pathname + url.search);

	localStorage.setItem("serverId", serverID);
}

export async function onFailLoadWorld() {
	await GameClient.leaveGame({ force: true });

	if (!useAuthUserStore.getState().userId) {
		Router.navigate("/signup");
	}
}

export function onPeerJoin(peer: PeerMetadata) {
	const peers = useMultiplayerStore.getState().peers;
	GameMultiplayer.setPeers(peers.concat(peer));
}

export function onPeerLeave(peerID: string) {
	const peers = useMultiplayerStore.getState().peers;
	const players = peers.filter((p) => p.peerID !== peerID);
	GameMultiplayer.setPeers(players);
}

// Note: relocate to jacy audio actions
function unlockAudio() {
	const { BB } = useEngineStore.getState().engine;
	if (!BB.world.client) return;

	const unlockAudio = () => {
		if (BB.client.inputPoll.isMobileBrowser()) {
			BB.world.client!.unlockAudio!();
		}

		document.removeEventListener("click", unlockAudio);
	};

	document.addEventListener("click", unlockAudio);
}
