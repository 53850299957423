import { useEngineStore } from "@stores/bb";
import { useInventorySelectorStore } from "@stores/hud/inventory-selector";
import type { ISelectorSlots } from "@jamango/content-client";

export type IGameState = {
	selector: ISelectorSlots;
};

export function getCurrentState(): IGameState | null | undefined {
	const { BB } = useEngineStore.getState().engine;
	if (!BB.world?.client) return;

	const selector = useInventorySelectorStore.getState().selector;

	return {
		selector,
	};
}

export function setCurrentState(state?: IGameState | null) {
	if (!state) return;

	const { BB } = useEngineStore.getState().engine;
	if (!BB.world?.client) return;

	useInventorySelectorStore.getState().setSelector(state.selector);
}
