import React, { useRef } from "react";
import { Check, CaretDown } from "@components/icons";
import { cn } from "@lib/helpers/cn";
import { useClickOutside } from "@lib/hooks/useClickOutside";

export type IListItem = {
	id: string | number;
	name: string | React.ReactNode;
	suffix?: string | null;
};

type IProps = {
	id: string;
	selected?: IListItem;
	options?: IListItem[];
	onSelect: (value: IListItem) => void;
	className?: string;
	listClassName?: string;
	disabled?: boolean;
	noSelectionText: string;
	squashable?: boolean;
	"aria-label"?: string;
	variant?: "editor";
};

export function Listbox({
	id,
	selected,
	options = [],
	onSelect,
	className = "",
	disabled,
	noSelectionText,
	squashable = false,
	"aria-label": ariaLabel,
	variant,
	listClassName,
}: IProps) {
	const [open, setOpen] = React.useState(false);
	const wrapperRef = useRef(null);
	const handleToggle = () => {
		setOpen((state) => !state);
	};

	useClickOutside(wrapperRef, () => {
		setOpen(false);
	});

	return (
		<div className={cn("relative min-w-0 max-w-sm", className)} ref={wrapperRef}>
			<button
				type="button"
				className={cn(
					"flex w-full items-center justify-between gap-2 rounded-md border border-slate-300 bg-white px-3 text-base text-slate-600 ring-blue-300 placeholder:text-slate-400 hover:bg-slate-50 focus:outline-none focus-visible:border-transparent focus-visible:ring-4 active:bg-slate-100",
					squashable ? "py-1 sm-h:py-2" : "py-2",
					variant === "editor" && "border-0 !bg-gray-100 !py-2 text-base",
				)}
				aria-haspopup="listbox"
				aria-expanded={open ? "true" : "false"}
				aria-labelledby={id}
				aria-label={ariaLabel}
				onClick={handleToggle}
				disabled={disabled}
			>
				<span className="block truncate pr-4">{!selected ? noSelectionText : selected?.name}</span>
				<span className="pointer-events-none">
					<CaretDown className="mt-0.5 size-4 text-slate-600" weight="bold" aria-hidden="true" />
				</span>
			</button>

			{open && (
				<ul
					role="listbox"
					className={cn(
						"pointer-events-auto absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-slate-300 bg-white py-1 text-base focus:ring-blue-300 focus-visible:outline-none focus-visible:ring-4",
						listClassName,
					)}
					tabIndex={0}
					aria-labelledby={id}
					aria-activedescendant={selected ? `${id}-option-${selected.id}` : undefined}
				>
					{options.map((option) => {
						const optionSelected = selected ? option.id === selected.id : false;
						return (
							<li
								key={option.id}
								id={`${id}-option-${option.id}`}
								className="focus-bg-slate-100 relative flex w-full cursor-pointer items-center justify-between gap-1 px-3 py-2 text-slate-900 hover:bg-slate-100"
								role="option"
								aria-selected={optionSelected ? "true" : "false"}
								tabIndex={-1}
								onClick={() => {
									setOpen(false);
									onSelect(option);
								}}
							>
								<span className="block truncate font-normal">
									{option.name}
									{option.suffix}
								</span>
								{optionSelected && (
									<>
										<Check
											className="size-4 text-slate-600"
											weight="bold"
											aria-hidden="true"
										/>
										<span className="sr-only">Selected</span>
									</>
								)}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
}
