import { create } from "zustand";

interface IGameHudState {
	zenMode: boolean;
	playerPosition: { x: number; y: number; z: number; angle: number };
	playerFootBlock?: { x: number; y: number; z: number };
	playerChunk?: { x: number; y: number; z: number };
	showPlayerContextualInfo: boolean;
	hitBlock?: { x: number; y: number; z: number };
	hitBlockTypeName?: string;
	hitBlockTypeDisplay?: string;
	hitBlockShape?: number;
	corner1?: { x: number; y: number; z: number };
	corner2?: { x: number; y: number; z: number };

	setGameHud: (state: IGameHudState) => void;
	setZenMode: (zenMode: boolean) => void;
	toggleShowPlayerContextureInfo: () => void;

	clearHudUI: () => void;
}

export const useGameHudStore = create<IGameHudState>()((set, get) => ({
	zenMode: false,
	showPlayerContextualInfo: false,
	playerPosition: { x: 0, y: 0, z: 0, angle: 0 },
	playerFootBlock: undefined,
	playerChunk: undefined,
	hitBlock: undefined,
	hitBlockTypeName: undefined,
	corner1: undefined,
	corner2: undefined,

	// Handlers
	setGameHud: (state) => set(state),
	setZenMode: (zenMode) => {
		set({ zenMode });
	},

	toggleShowPlayerContextureInfo: () => {
		const nextState = !get().showPlayerContextualInfo;
		set({ showPlayerContextualInfo: nextState });
	},

	// Actions
	clearHudUI: () => {
		set({ zenMode: false, showPlayerContextualInfo: false });
	},
}));

export default {
	useGameHud: useGameHudStore.getState,
};
