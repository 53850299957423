import type { Resource } from "@jamango/content-client";
import { apiResponseToResult, apiClient } from "./index";

/**
 * Request to save a world for a given world.
 */
export async function requestUploadResource({
	name,
	type,
}: {
	name: string;
	type: Resource["resourceType"];
}) {
	return apiResponseToResult(await apiClient.api.resources["request-upload"].post({ name, type }));
}
