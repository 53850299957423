import {
	SceneNodeType,
	type CharacterSceneNode,
	type PropSceneNode,
	type SceneNode,
} from "@jamango/engine/Runtime";
import { createContext, useContext } from "react";
import { useStore } from "zustand";
import type { StoreApi } from "zustand/vanilla";
import { createStore } from "zustand/vanilla";
import { Jacy } from "../../jacy/JacyClient";
import type { IEditorStoreState } from "../editor-types";
import type { UndoManager } from "../undo";
import { createUndoManager } from "../undo";
import type { IScript, PropMotionType } from "@jamango/content-client";
import {
	createDefaultCharacterEvents,
	createDefaultPropEvents,
} from "../../jacy/content/JacyActions/JacyScriptActions";

export type SceneTreeNodeEditorState = IEditorStoreState & {
	state: SceneNode;

	undoManager: UndoManager<SceneTreeNodeEditorState["state"]>;

	setSceneTreeNode: (state: SceneNode) => void;

	setName: (name: string | null) => void;

	addScript: (script: string) => void;
	addNewScript: () => string;
	removeScript: (script: string) => void;

	setScriptData: (script: string, data: Record<string, any>) => void;

	// character actions
	setCharacter: (pk: string) => void;

	// prop actions
	setProp: (pk: string) => void;
	setPropMotionType: (motionType: PropMotionType) => void;
	setPropScale: (scale: number) => void;

	undo: () => void;
	redo: () => void;
};

export type SceneTreeNodeEditorStore = StoreApi<SceneTreeNodeEditorState>;

export const createSceneTreeNodeEditorStore = (): SceneTreeNodeEditorStore => {
	return createStore<SceneTreeNodeEditorState>((set, get) => ({
		state: null!,
		modified: false,
		undoManager: null!,
		undo: () => {
			const { undoManager } = get();

			undoManager.undo();

			set({
				state: undoManager.get(),
				modified: true,
			});
		},
		redo: () => {
			const { undoManager } = get();

			undoManager.redo();

			set({
				state: undoManager.get(),
				modified: true,
			});
		},
		setSceneTreeNode: (sceneTreeNode) => {
			const undoManager = createUndoManager<SceneTreeNodeEditorState["state"]>();
			undoManager.init(sceneTreeNode);

			set({
				state: sceneTreeNode,
				undoManager,
				modified: false,
			});
		},
		setName: (name) => {
			const { state, undoManager } = get();

			state.name = name;

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		addScript: (scriptPk) => {
			const { state, undoManager } = get();

			const defaultScriptData = Jacy.actions.scripts.getDefaultScriptControlsData(scriptPk);
			state.scripts = [...state.scripts, { script: scriptPk, data: defaultScriptData }];

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		addNewScript: () => {
			const { state, undoManager } = get();

			const scriptName = state.name ? `${state.name} script` : "New script";

			let events: IScript["events"] | undefined = undefined;

			if (state.type === SceneNodeType.PROP) {
				events = createDefaultPropEvents();
			} else if (state.type === SceneNodeType.CHARACTER) {
				events = createDefaultCharacterEvents();
			}

			const newScript = Jacy.actions.scripts.create(scriptName, events);

			state.scripts = [...state.scripts, { script: newScript }];

			undoManager.set(state);

			set({
				state,
				modified: true,
			});

			return newScript;
		},
		removeScript: (script) => {
			const { state, undoManager } = get();

			state.scripts = state.scripts.filter((s) => s.script !== script);

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		setScriptData: (id, data) => {
			const { state } = get();

			set({
				state: {
					...state,
					scripts: state.scripts.map((s) => (s.script === id ? { ...s, data } : s)),
				},
				modified: true,
			});
		},

		// character actions
		setCharacter: (pk) => {
			const { state, undoManager } = get();

			(state as CharacterSceneNode).characterPk = pk;

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},

		// prop actions
		setProp: (pk) => {
			const { state, undoManager } = get();

			(state as PropSceneNode).propPk = pk;

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		setPropMotionType: (motionType) => {
			const { state, undoManager } = get();

			(state as PropSceneNode).motionType = motionType;

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		setPropScale: (scale) => {
			const { state, undoManager } = get();

			(state as PropSceneNode).scale = scale;

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
	}));
};

export const SceneTreeNodeEditorContext = createContext<SceneTreeNodeEditorStore>(null!);

export const useSceneTreeNodeEditorStore = <T>(
	store: SceneTreeNodeEditorStore,
	selector: (state: SceneTreeNodeEditorState) => T,
) => {
	return useStore(store, selector);
};

export const useSceneTreeNodeEditorContext = <T>(selector: (state: SceneTreeNodeEditorState) => T) => {
	return useStore(useContext(SceneTreeNodeEditorContext), selector);
};
