import {
	Angle,
	ArrowRight,
	ArrowsClockwise,
	Asterisk,
	BezierCurve,
	CheckCircle,
	Circle,
	CubeTransparent,
	Hash,
	LinkSimple,
	List,
	Package,
	TextAa,
	User,
	Waveform,
} from "@components/icons";
import { SOCKET } from "@jamango/content-client";

export const SOCKET_TYPE_STYLE = {
	[SOCKET.exec]: {
		base: "ring-rose-400",
		connected: "bg-rose-400",
		disconnected: "bg-rose-100 hover:bg-rose-200 focus:bg-rose-200",
		icon: ArrowRight,
	},
	[SOCKET.number]: {
		base: "ring-green-400",
		connected: "bg-green-400",
		disconnected: "bg-slate-100 hover:bg-green-300 focus:bg-green-300",
		icon: Hash,
	},
	[SOCKET.string]: {
		base: "ring-yellow-400",
		connected: "bg-yellow-400",
		disconnected: "bg-slate-100 hover:bg-yellow-300 focus:bg-yellow-300",
		icon: TextAa,
	},
	[SOCKET.boolean]: {
		base: "ring-blue-400",
		connected: "bg-blue-400",
		disconnected: "bg-slate-100 hover:bg-blue-300 focus:bg-blue-300",
		icon: CheckCircle,
	},
	[SOCKET.entity]: {
		base: "ring-yellow-900",
		connected: "bg-yellow-900",
		disconnected: "bg-slate-100 hover:bg-yellow-800 focus:bg-yellow-800",
		icon: User,
	},
	[SOCKET.vector3]: {
		base: "ring-orange-400",
		connected: "bg-orange-400",
		disconnected: "bg-slate-100 hover:bg-orange-300 focus:bg-orange-300",
		icon: BezierCurve,
	},
	[SOCKET.euler]: {
		base: "ring-orange-400",
		connected: "bg-orange-400",
		disconnected: "bg-slate-100 hover:bg-orange-300 focus:bg-orange-300",
		icon: Angle,
	},
	[SOCKET.quaternion]: {
		base: "ring-orange-400",
		connected: "bg-orange-400",
		disconnected: "bg-slate-100 hover:bg-orange-300 focus:bg-orange-300",
		icon: ArrowsClockwise,
	},
	[SOCKET.sound]: {
		base: "ring-yellow-900",
		connected: "bg-yellow-900",
		disconnected: "bg-slate-100 hover:bg-yellow-800 focus:bg-yellow-800",
		icon: Waveform,
	},
	[SOCKET.blockGroup]: {
		base: "ring-slate-400",
		connected: "bg-slate-400",
		disconnected: "bg-slate-100 hover:bg-slate-300 focus:bg-slate-300",
		icon: CubeTransparent,
	},
	[SOCKET.any]: {
		base: "ring-violet-400",
		connected: "bg-violet-400",
		disconnected: "bg-slate-100 hover:bg-violet-300 focus:bg-violet-300",
		icon: Asterisk,
	},
	[SOCKET.object]: {
		base: "ring-pink-400",
		connected: "bg-pink-400",
		disconnected: "bg-slate-100 hover:bg-pink-300 focus:bg-pink-300",
		icon: Package,
	},
	[SOCKET.physicsConstraint]: {
		base: "ring-emerald-400",
		connected: "bg-emerald-400",
		disconnected: "bg-slate-100 hover:bg-emerald-300 focus:bg-emerald-300",
		icon: LinkSimple,
	},
} as const satisfies Record<
	keyof typeof SOCKET,
	{ base: string; connected: string; disconnected: string; icon: any }
>;

export const SOCKET_STRUCTURE_STYLE = {
	primitive: "",
	list: "rotate-45",
	any: "rounded-full",
};

export const SOCKET_STRUCTURE_ICONS = {
	primitive: Circle,
	list: List,
	any: Asterisk,
};

export const CONNECTION_BASE_STYLE = "fill-none pointer-events-none stroke-[4]";

export const CONNECTION_STYLES = {
	exec: {
		connected: "stroke-rose-400",
		disconnected: "stroke-rose-200",
	},
	number: {
		connected: "stroke-green-400",
		disconnected: "stroke-green-300",
	},
	string: {
		connected: "stroke-yellow-400",
		disconnected: "stroke-yellow-200",
	},
	boolean: {
		connected: "stroke-blue-400",
		disconnected: "stroke-blue-200",
	},
	entity: {
		connected: "stroke-yellow-900",
		disconnected: "stroke-yellow-700",
	},
	vector3: {
		connected: "stroke-orange-400",
		disconnected: "stroke-orange-200",
	},
	euler: {
		connected: "stroke-orange-400",
		disconnected: "stroke-orange-200",
	},
	quaternion: {
		connected: "stroke-orange-400",
		disconnected: "stroke-orange-200",
	},
	sound: {
		connected: "stroke-yellow-900",
		disconnected: "stroke-yellow-700",
	},
	blockGroup: {
		connected: "stroke-slate-400",
		disconnected: "stroke-slate-200",
	},
	any: {
		connected: "stroke-violet-400",
		disconnected: "stroke-violet-200",
	},
	object: {
		connected: "stroke-pink-400",
		disconnected: "stroke-pink-200",
	},
	physicsConstraint: {
		connected: "stroke-emerald-400",
		disconnected: "stroke-emerald-200",
	},
} as const satisfies Record<keyof typeof SOCKET, { connected: string; disconnected: string }>;

export const REMOVED_NODE_NAMES: Record<string, string> = {
	"0007-10-0002": "On Chat",
	"0001-00-0001": "NPC On Approach",
	"0001-00-0007": "On Local State Change",
	"0002-02-1002": "Get Game Mode",
	"0002-03-1002": "Entity Get Name",
	"0002-03-1006": "Get Entity Pitch",
	"0002-03-2003": "Entity Set Invincible",
	"0002-03-2007": "Set Entity Pitch",
	"0002-03-0005": "Entity Get Velocity",
	"0002-03-0006": "Entity Set Velocity",
	"0002-03-0007": "Entity Add Velocity",
	"0002-04-1002": "NPC Set Behavior",
	"0002-04-1003": "NPC Set Fire Rate",
	"0002-04-1004": "NPC Set Initial Fire Delay",
	"0002-04-1005": "NPC Set Shoot Hit Percentage",
	"0002-05-1001": "Character Get Can Fly",
	"0002-05-1003": "Character Get Can Jump",
	"0002-05-1004": "Character Get Can Sprint",
	"0002-05-1005": "Character Get Can Crouch",
	"0002-05-1006": "Character Get Can Move",
	"0002-05-1007": "Character Get Can Interact",
	"0002-05-2009": "Character Get Speed Walk",
	"0002-05-2010": "Character Get Speed Sprint",
	"0002-05-2011": "Character Get Jump Velocity",
	"0002-05-2012": "Character Get Scale",
	"0002-05-2001": "Character Set Walk Speed",
	"0002-05-2002": "Character Set Speed Sprint",
	"0002-05-2003": "Character Set Jump Velocity",
	"0002-05-2004": "Character Set Coyote Time",
	"0002-05-2005": "Character Set Fall Damage",
	"0002-05-2006": "Character Set Can Fly",
	"0002-05-2007": "Character Set Can Interact",
	"0002-05-2008": "Character Set Health",
	"0002-05-2013": "Character Set Scale",
	"0002-05-2014": "Character Get Movement Style",
	"0002-05-2015": "Character Set Movement Style",
	"0002-05-2016": "Character Get Max Horizontal Speed Multiplier",
	"0002-05-2017": "Character Set Max Horizontal Speed Multiplier",
	"0002-05-2018": "Character Get Acceleration Ground",
	"0002-05-2019": "Character Set Acceleration Ground",
	"0002-05-2020": "Character Get Acceleration Air",
	"0002-05-2021": "Character Set Acceleration Air",
	"0002-05-2022": "Character Get Friction Ground",
	"0002-05-2023": "Character Set Friction Ground",
	"0002-05-2024": "Character Get Friction Air",
	"0002-05-2025": "Character Set Friction Air",
	"0002-05-2026": "Character Get Friction Air Stop Multiplier",
	"0002-05-2027": "Character Set Friction Air Stop Multiplier",
	"0002-05-2028": "Character Get Friction Ground Stop Multiplier",
	"0002-05-2029": "Character Set Friction Ground Stop Multiplier",
	"0002-05-2030": "Character Get Stop Speed Ground",
	"0002-05-2031": "Character Set Stop Speed Ground",
	"0002-05-2032": "Character Get Speed Air",
	"0002-05-2033": "Character Set Speed Air",
	"0002-09-1001": "Player Get Can Build",
	"0002-09-1006": "Get Player ID",
	"0002-09-2001": "Player Set Can Build",
	"0002-09-0001": "Player Set Tool Cycle",
	"0002-09-0002": "Player Get Tool Cycle",
	"0002-10-2001": "SubGrid Set Linear Velocity",
	"0002-10-2002": "SubGrid Set Angular Velocity",
	"0002-10-0001": "SubGrid Move To",
	"0002-06-1001": "Block Get Position",
	"0002-06-1007": "Block Get Group",
	"0002-06-0001": "Block Explode",
	"0002-06-0005": "Group Convert To SubGrid",
	"0002-07-2011": "Set Team Spawn",
	"0002-07-0001": "Game Start",
	"0002-07-0002": "Game End",
	"0002-08-0001": "Sound Play Globally",
	"0003-01-0002": "Nand",
	"0003-01-0004": "Nor",
	"0003-02-0002": "Not Equals",
	"0003-02-0003": "Greater Than",
	"0003-02-0004": "Greater Than Or Equal",
	"0003-02-0005": "Less Than",
	"0003-02-0006": "Less Than Or Equal",
	"0004-01-0013": "Log",
	"0004-01-0014": "Log 10",
	"0008-03-0003": "Difference of List",
	"0008-03-0004": "Product of List",
	"0008-03-0005": "Quotient of List",
	"0008-05-0005": "List All NPC",
	"0008-05-0006": "List All Players",
	"0008-05-0007": "List All Characters",
	"0008-05-0008": "List All Item",
	"0008-05-0001": "List Any NPC",
	"0008-05-0002": "List Any Player",
	"0008-05-0004": "List Any Item",
	"0008-05-0003": "List Any Characters",
	"0009-01-0000": "Get Value From Object",
	"0010-01-0000": "myFunction",
	"0010-01-0001": "Start",
	"0010-01-0002": "End",
	"0010-01-0003": "myBehavior",
	"0010-01-0004": "myFrame",
	"0006-01-0006": "Any to Vector3",
	"0006-01-0007": "Any to Entity",
	"0006-01-0009": "Any to List",
	"0006-01-0010": "Any to Primitive",
	"0006-03-0000": "Sandbox Mode",
	"0006-03-0001": "Creator Mode",
	"0002-03-200232": "Set Sun Intensity",
	"0002-03-200234": "Set Sun Color",
	"0002-03-200235": "Set Night Luminosity",
	"0002-03-200236": "Set Azimuth",
	"0002-03-200237": "Set Tone Mapping",
	"0002-03-200238": "Set Tone Mapping Exposure",
	"0002-03-200239": "Set Saturation",
	"0002-03-200240": "Set Haze",
	"0002-03-200241": "Set FogY",
	"0002-03-200242": "Set Cloud Size",
	"0002-03-200244": "Set Cloud Density",
	"0002-03-200243": "Set Cloud Coverage",
	"0002-03-200245": "Set Cloud Distance",
	"0002-03-200246": "Set Sample",
	"0002-03-200247": "Set Step",
	"0002-03-200248": "Set Sky Color",
	"0002-03-200249": "Set Cloud Color",
	"0002-03-200250": "Set Fog Color",
	"0002-03-200251": "Set Ground Color",
	"0002-03-200252": "Set Fog Near",
	"0002-03-200253": "Set Fog Far",
	"0002-02-1001": "World Get Time",
	"0002-02-2003": "World Set Time",
};
