import { AVATAR_COMPONENTS_PACKAGE } from "../../data/avatar-components/avatar-components-package";
import { getAvatarComponentConfig } from "../../lib/helpers/avatars";
import type { IAssetKey, IAvatarComponent, IEngineDef } from "../../lib/types";
import { AvatarHeadTabs } from "../../lib/types";

const BASE_COMPONENTS = [
	{
		id: "avatarComponent#base-torso-type-1",
		url: "assets/engine/mods/CharacterDefault/type1/base-torso-type-1.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-torso-type-2",
		url: "assets/engine/mods/CharacterDefault/type2/base-torso-type-2.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-legs-type-1",
		url: "assets/engine/mods/CharacterDefault/type1/base-legs-type-1.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-legs-type-2",
		url: "assets/engine/mods/CharacterDefault/type2/base-legs-type-2.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-hair-type-1",
		url: "assets/engine/mods/CharacterDefault/type1/base-hair-type-1.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-hair-type-2",
		url: "assets/engine/mods/CharacterDefault/type2/base-hair-type-2.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-head-type-1",
		url: "assets/engine/mods/CharacterDefault/type1/base-head-type-1.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-head-type-2",
		url: "assets/engine/mods/CharacterDefault/type2/base-head-type-2.glb",
		type: "glb",
	},
	{
		id: "avatarComponent#base-face-type-1",
		url: "assets/engine/mods/CharacterDefault/type1/base-face-type-1.png",
		type: "image/png",
	},
	{
		id: "avatarComponent#base-face-type-2",
		url: "assets/engine/mods/CharacterDefault/type2/base-face-type-2.png",
		type: "image/png",
	},
	{
		id: "mdl-character-default-type1-tp-geom",
		url: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-tp-geom.glb",
		type: "glb",
	},
	{
		id: "mdl-character-default-type1-tp-anim",
		url: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-tp-anim.glb",
		type: "glb",
	},
	{
		id: "mdl-character-default-type1-fp-geom",
		url: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-fp-geom.glb",
		type: "glb",
	},
	{
		id: "mdl-character-default-type2-tp-geom",
		url: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-tp-geom.glb",
		type: "glb",
	},
	{
		id: "mdl-character-default-type2-tp-anim",
		url: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-tp-anim.glb",
		type: "glb",
	},
	{
		id: "mdl-character-default-type2-fp-geom",
		url: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-fp-geom.glb",
		type: "glb",
	},
];

const AVATAR_COMPONENTS = AVATAR_COMPONENTS_PACKAGE.assets.avatarComponents!;

export class JacyAvatarComponentsState {
	get(pk?: IAssetKey | null) {
		if (!pk) return null;
		return AVATAR_COMPONENTS[pk];
	}

	getByIdentifier(identifier?: string | null) {
		if (!identifier) return null;
		return this.get(`avatarComponent#${identifier}`);
	}

	getConfig(pk?: IAssetKey | null) {
		const component = this.get(pk);
		if (!component) return null;
		return getAvatarComponentConfig(component);
	}

	getAll() {
		const avatarComponents = Object.values(AVATAR_COMPONENTS);
		const list: IAvatarComponent[] = [];

		for (const avatarComponent of avatarComponents) {
			list.push(avatarComponent);
		}

		return list;
	}

	getDefaultQueue() {
		return BASE_COMPONENTS;
	}

	getQueue() {
		const queue: IEngineDef["assets"] = [];

		for (const component of this.getAll()) {
			const type = component.category === AvatarHeadTabs.FACE ? "image/png" : "glb";

			if (component.glbFile.bodyType1) {
				queue.push({
					id: `${component.pk}-bodyType1`,
					url: component.glbFile.bodyType1,
					type,
				});
			}

			if (component.glbFile.bodyType2) {
				queue.push({
					id: `${component.pk}-bodyType2`,
					url: component.glbFile.bodyType2,
					type,
				});
			}

			if (component.thumbnail.bodyType1) {
				queue.push({
					id: `${component.pk}-bodyType1-thumbnail`,
					url: component.thumbnail.bodyType1,
					type: "image/png",
				});
			}

			if (component.thumbnail.bodyType2) {
				queue.push({
					id: `${component.pk}-bodyType2-thumbnail`,
					url: component.thumbnail.bodyType2,
					type: "image/png",
				});
			}
		}

		return queue;
	}
}
