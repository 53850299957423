import React from "react";
import { Jacy } from "@jacy-client";
import { DotsThree } from "@components/icons";
import { Button } from "@components/ui/Button";
import { useVisible } from "@lib/hooks/useVisible";
import { Router } from "@jamango/client";
import { useHubWorldVersionsModal } from "@stores/dialogs";

type Props = {
	id: string;
	name: string;
};

export function WorldOption({ id, name }: Props) {
	const ref = React.useRef<HTMLDivElement>(null);
	const toggleWorldVersionsModal = useHubWorldVersionsModal((state) => state.toggle);
	const setWorldID = useHubWorldVersionsModal((state) => state.setWorldID);
	const [visible, setVisible] = useVisible(ref);
	const [loading, setLoading] = React.useState(false);

	const toggle = () => setVisible((state) => !state);

	const handleDeleteWorld = async () => {
		if (!id || loading) return;
		setLoading(true);
		await Jacy.actions.worldData.deleteWorld(id, name);
		setVisible(false);
		setLoading(false);
	};

	const showVersionsModal = () => {
		toggleWorldVersionsModal();
		setWorldID(id);
	};

	return (
		<div className="relative">
			<Button
				color="white"
				square
				id={`world-${id}-options`}
				onClick={toggle}
				aria-haspopup="true"
				aria-expanded={visible}
			>
				<DotsThree className="size-5" aria-hidden="true" weight="bold" />
				<span className="sr-only">Options</span>
			</Button>

			<div ref={ref}>
				{visible && (
					<div
						aria-labelledby={`world-${id}-options`}
						role="region"
						className="absolute right-0 top-12 z-10"
					>
						<div className="dropdown-container relative z-10">
							<span
								className="absolute -top-[9px] right-3 block size-0 border-x-8 border-b-8 border-solid border-x-transparent border-b-slate-300"
								aria-hidden="true"
							/>
							<ul>
								{Router.isLandingPage() && (
									<li>
										<Button
											color="white-ghost"
											size="md"
											onClick={showVersionsModal}
											disabled={!!loading}
										>
											Versions
										</Button>
									</li>
								)}
								<li>
									<Button
										color="red-ghost"
										size="md"
										onClick={handleDeleteWorld}
										disabled={!!loading}
									>
										{loading ? "Deleting..." : "Delete"}
									</Button>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
