import { buildDependencyGraph, DependencyNodeType, getAllPKs, JacyContent } from "@jamango/content-client";
import { removeNode } from "@editor/script-editor/actions";
import { World, BlockGroupsRouter, SpawnerRouter } from "@jamango/engine/Runtime";

type RemoveHandler = (args: {
	content: JacyContent;
	world: World;
	pk: string;
	id: string;
	removePk: string;
	removeId: string;
}) => void;

const REMOVE_DEPENDENCY_HANDLERS: Record<string, Record<string, RemoveHandler>> = {
	block: {
		script: ({ content, pk, removePk }) => {
			const block = content.state.blocks.assets[pk];
			if (!block) return;

			if (block.scripts) {
				block.scripts = block.scripts.filter((s) => s.script !== removePk);
			}

			content.state.blocks.set(block);
		},
	},
	character: {
		script: ({ content, pk, removePk }) => {
			const character = content.state.characters.characters[pk];
			if (!character) return;

			if (character.scripts) {
				character.scripts = character.scripts.filter((s) => s.script !== removePk);
			}

			content.state.characters.set(character);
		},
	},
	prop: {
		script: ({ content, pk, removePk }) => {
			const prop = content.state.props.props[pk];
			if (!prop) return;

			if (prop.scripts) {
				prop.scripts = prop.scripts.filter((s) => s.script !== removePk);
			}

			content.state.props.set(prop);
		},
	},
	script: {
		script: ({ content, pk, removePk }) => {
			const script = content.state.scripts.scripts[pk];
			if (!script) return;

			if (script.events.nodes[removePk]) {
				removeNode(script.events, removePk);
			}

			content.state.scripts.set(script);
		},
	},
	blockStructure: {
		script: ({ content, pk, removePk }) => {
			const blockStructure = content.state.blockStructures.get(pk);
			if (!blockStructure) return;

			for (const blockGroupDef of Object.values(blockStructure.data.blockGroupDefs ?? {})) {
				blockGroupDef.scripts = blockGroupDef.scripts?.filter((s) => s.script !== removePk);
			}

			content.state.blockStructures.set(blockStructure);
		},
	},
	blockGroup: {
		script: ({ world, id, removePk }) => {
			const blockGroup = world.blockGroups.groups.get(id);
			if (!blockGroup) return;

			const scripts = blockGroup.scripts?.filter((s) => s.script !== removePk);

			BlockGroupsRouter.setScripts({ id, scripts });
		},
	},
	sceneTreeNode: {
		script: ({ world, id, removePk }) => {
			const sceneTreeNode = world.sceneTree.nodes[id];
			if (!sceneTreeNode) return;

			const updatedNode = structuredClone(sceneTreeNode);
			updatedNode.scripts = updatedNode.scripts.filter((s) => s.script !== removePk);

			SpawnerRouter.updateSceneTreeNode(updatedNode);
		},
	},
} satisfies Partial<{
	[type in DependencyNodeType]: Partial<{
		[typeToRemove in DependencyNodeType]: RemoveHandler;
	}>;
}>;

const removeUsage = (content: JacyContent, world: World, pk: string, removePk: string) => {
	const [type, id] = pk.split("#");

	const [removeType, removeId] = removePk.split("#");

	const removeDependencyHandler = REMOVE_DEPENDENCY_HANDLERS[type]?.[removeType];

	if (!removeDependencyHandler) return;

	removeDependencyHandler({ content, world, pk, id, removePk, removeId });
};

export const removeAllAssetUsages = (content: JacyContent, world: World, pk: string) => {
	const bundle = content.state.export();
	const allPks = getAllPKs(bundle, world);
	const graph = buildDependencyGraph(bundle, world, allPks);

	for (const edge of graph.edges) {
		if (edge.target === pk) {
			removeUsage(content, world, edge.source, pk);
		}
	}
};
