import { Jacy } from "@jacy-client";
import { GameLifeCycle, GameMultiplayer } from "@jamango/client";
import { useEngineStore } from "@stores/bb";
import { store } from "@stores/engine";
import { isNullish } from "@jamango/helpers";
import { initJamangoEngine } from "@jamango/engine/Runtime";
import joltPhysicsWasmUrl from "@jamango/jolt-physics/jolt-physics.wasm.wasm?url";
import { useAuthUserStore } from "@stores/auth-user";
import { useGameClientStore } from "@stores/game-client/game-client";
import { saveWorld } from "../../game/GameClient";

//TODO lets fetch it from engine directly?
const workerAmbientOcclusionUrl = import.meta.env.PROD
	? `${(window as any).globalEnv?.FRONTEND_URL}/assets/WorkerAmbientOcclusion.js`
	: new URL("../../../../../lib/engine/src/WorkerAmbientOcclusion.ts", import.meta.url).pathname;

async function loadEngine(deattach = true) {
	try {
		const expose = await initJamangoEngine({
			dedicated: false,
			workers: {
				ao: workerAmbientOcclusionUrl,
			},
			joltPhysicsWasmUrl,
			content: {
				autoSave: () => saveWorld(true, true),
				canAutoSave: () =>
					useAuthUserStore.getState().isCreator &&
					!useGameClientStore.getState().loading &&
					Jacy.ongoingUploads.size === 0,
			},
			ui: {
				gameMultiplayer: {
					hostWorld: (options) => GameMultiplayer.hostWorld(options),
					joinWorld: (serverID) => GameMultiplayer.joinWorld(serverID),
					setPeers: (peers) => GameMultiplayer.setPeers(peers),
				},
				gameLifecycle: {
					onPeerJoin: (peer) => GameLifeCycle.onPeerJoin(peer),
					onPeerLeave: (peer) => GameLifeCycle.onPeerLeave(peer),
					onFailLoadWorld: () => GameLifeCycle.onFailLoadWorld(),
				},
				user: {
					isGuest: () => !useAuthUserStore.getState().userId,
					avatarThumbnailURL: () =>
						useAuthUserStore.getState().getAvatar()?.thumbnailResource.file.url ?? null,
					displayPhotoURL: () =>
						useAuthUserStore.getState().getAvatar()?.displayPhotoResource.file.url ?? null,
					accountId: () => useAuthUserStore.getState().userId ?? null,
					avatarId: () => useAuthUserStore.getState().globalAvatarId ?? null,
				},
				jacy: Jacy,
			},
		});

		useEngineStore.getState().init(expose);

		GameLifeCycle.onEngineLoaded();
	} catch (oops) {
		GameLifeCycle.onEngineLoadingFailed(oops);
	}

	if (!deattach) return;

	//@ts-ignore
	window.removeEventListener("DOMContentLoaded", loadEngine);
}

export function initEngine() {
	window.addEventListener("popstate", function (e) {
		//@ts-ignore
		const prevWindow = e.target?.location.pathname;
		if (!prevWindow.includes("play")) return;
		// disables going into the stack by replacing the top of stack to null
		if (typeof e.state === "object" && e.state !== null) {
			history.replaceState({ obsolete: true }, "");
			history.pushState(e.state, "");
			// Navigates into the landing page when going back
			if (prevWindow.includes("play")) {
				this.window.location.replace("/");
			}
		}
	});

	//@ts-ignore

	window._listeners = {
		state: {
			type: "engineState", // matches engine DOMListener.EVENT_TYPE
			//@ts-ignore
			listener: (payload) => {
				if (!payload.detail) return;
				const { state, resolve, reject } = payload.detail;

				//@ts-ignore
				if (isNullish(store[state])) {
					reject(
						`Invalid React state requested. Please check stores/engine.js for available state. State: ${state}`,
					);
				} else {
					//@ts-ignore
					resolve(store[state]);
				}
			},
		},
	};

	//@ts-ignore
	Object.values(window._listeners).forEach(({ type, listener }) => {
		document.removeEventListener(type, listener);
		document.addEventListener(type, listener);
	});

	loadEngine();
}
