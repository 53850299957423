import { createBlockGroupEditorStore, type BlockGroupEditorStore } from "@editor/block-group-editor/store";
import {
	createSceneTreeNodeEditorStore,
	type SceneTreeNodeEditorStore,
} from "@editor/scene-tree-node-editor/store";
import { importEvents } from "@editor/script-editor/actions";
import { createScriptEditorStore, type ScriptEditorStore } from "@editor/script-editor/store";
import type { IScript } from "@jamango/content-client";
import { EditorClient } from "@jamango/engine/Events.ts";
import type { SceneNode } from "@jamango/engine/Runtime";
import { SceneNodeType } from "@jamango/engine/Runtime";
import * as WrenchRouter from "@jamango/engine/Runtime/router/world/tools/Wrench.ts";
import * as SpawnerRouter from "@jamango/engine/Runtime/router/world/tools/Spawner.ts";
import { isNullish } from "@jamango/helpers";
import { useEngineStore } from "@stores/bb";
import { useConfirmPromptStore, useEditorModal } from "@stores/dialogs";
import * as Inventory from "@stores/dialogs/inventory";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import type { ReteState } from "base/rete/Types";
import { create } from "zustand";
import { Jacy } from "../jacy/JacyClient";

export type ScriptEditorInstance = {
	id: string;
	type: "script";
	store: ScriptEditorStore;
};

export type BlockGroupEditorInstance = {
	id: string;
	type: "blockGroup";
	store: BlockGroupEditorStore;
};

export type SceneTreeNodeEditorInstance = {
	id: string;
	type: "sceneTreeNode";
	store: SceneTreeNodeEditorStore;
};

type EditorInstance = ScriptEditorInstance | BlockGroupEditorInstance | SceneTreeNodeEditorInstance;

export type BlockGroupDetails = {
	id: string;
	name: string;
	named: boolean;
	scripts: { id: string; name: string }[];
	numBlocks: number;
};

export type SceneTreeNodeDetails = SceneNode & { named: boolean; name: string };

export type EditorScriptParseResults = {
	errors: { message: string }[];
};

type EditorState = {
	tabs: string[];
	setTabs: (tabs: string[]) => void;
	clearEditor: () => void;

	current?: string;
	editors: { [script: string]: EditorInstance };

	compilationLogs: ReteState["compilationLogs"];
	scriptsWithErrors: Set<string>;
	refreshCompilationLogs: () => void;

	scriptEditorClient: EditorClient;
	createScriptEditorClient: () => void;

	openBlockGroup: (blockGroupId: string, openModal?: boolean) => BlockGroupEditorInstance | undefined;
	teleportToBlockGroup: (blockGroupId: string) => void;

	openSceneTreeNode: (
		sceneTreeNodeId: string,
		openModal?: boolean,
	) => SceneTreeNodeEditorInstance | undefined;
	teleportToSceneTreeNode: (nodeId: string) => void;

	openInventoryAsset: (pk: string) => void;

	undo: () => void;
	redo: () => void;
	selectAll: () => void;

	_refreshBlockGroupEditor: (tabId: string) => void;
	_refreshSceneTreeNodeEditor: (tabId: string) => void;
	_refreshScriptEditor: (tabId: string) => void;

	openTab: (tabId: string) => void;
	openScript: (scriptId: string) => void;
	openNewScript: () => void;
	refreshEditor: (tabId: string) => void;
	refreshEditors: () => void;
	duplicateScript: (scriptId: string) => string | null;

	blockGroups: Record<string, BlockGroupDetails>;
	sceneTreeNodes: Record<string, SceneTreeNodeDetails>;
	refreshBlockGroups: () => void;
	refreshSceneTreeNodes: () => void;
	refreshEngine: () => void;

	renamingId?: string;
	renamingType?: "script" | "blockGroup" | "sceneTreeNode";
	isRenamingNew?: boolean;
	draftName: string;
	renameScript: (id: string) => void;
	renameBlockGroup: (id: string) => void;
	renameSceneTreeNode: (id: string) => void;
	setDraftName: (name: string) => void;
	_setScriptName: (id: string, name: string) => void;
	_setBlockGroupName: (id: string, name: string | null) => void;
	_setSceneTreeNodeName: (id: string, name: string | null) => void;
	confirmRename: () => void;
	cancelRename: () => void;

	setScriptStarred: (scriptId: string, starred: boolean) => void;

	rerenderEditors: () => void;

	sideNav: EditorSideNavValue;
	setSideNav: (nav: EditorSideNavValue) => void;

	_saveScript: (editor: ScriptEditorStore) => void;
	_saveBlockGroup: (editor: BlockGroupEditorStore) => void;
	_saveSceneTreeNode: (editor: SceneTreeNodeEditorStore) => void;
	save: (id: string) => void;
	saveCurrent: () => void;
	saveAll: () => void;

	closeTab: (tabId: string, force?: boolean) => Promise<void>;
	deleteScript: (scriptId: string, force?: boolean) => Promise<void>;
	deleteBlockGroup: (blockGroupId: string, force?: boolean) => Promise<boolean>;
	deleteSceneTreeNode: (nodeId: string, force?: boolean) => Promise<boolean>;

	setScriptEnabled: (scriptId: string, enabled: boolean) => void;

	onOpenEditor: () => void;
};

export type EditorSideNavValue = "scripts" | "instances" | undefined;

export const useEditor = create<EditorState>((set, get) => ({
	scriptEditorClient: null!,
	createScriptEditorClient: () => {
		const world = useEngineStore.getState().engine.BB.world;
		const editorClient = new EditorClient(world);

		set({ scriptEditorClient: editorClient });
	},
	tabs: [],

	current: undefined,
	editors: {},

	compilationLogs: [],
	scriptsWithErrors: new Set(),
	refreshCompilationLogs: () => {
		const world = useEngineStore.getState().engine.BB.world;

		if (!world) {
			set({ compilationLogs: [], scriptsWithErrors: new Set() });
			return;
		}

		const scriptsWithErrors = new Set<string>();

		for (const log of world.rete.compilationLogs) {
			if (log.type === "error") {
				scriptsWithErrors.add(log.script);
			}
		}

		set({
			compilationLogs: structuredClone(world.rete.compilationLogs),
			scriptsWithErrors,
		});
	},

	sideNav: "scripts",
	blockGroups: {},
	sceneTreeNodes: {},
	refreshBlockGroups: () => {
		const BB = useEngineStore.getState().engine.BB;
		const world = BB.world;
		if (!world) return;

		const BlockGroups = useEngineStore.getState().engine.gbi.base.BlockGroups;

		const blockGroups: EditorState["blockGroups"] = {};

		for (const [id, group] of world.blockGroups.groups.entries()) {
			const name = group.name
				? group.name
				: (BlockGroups.getFallbackBlockGroupName(world.blockGroups, id) ?? "Unnamed");

			const named = !isNullish(group.name);

			const scripts = group.scripts.map((s) => ({
				id: s.script,
				name: world.content.state.scripts.get(s.script)?.name ?? "Unnamed Script",
			}));

			blockGroups[id] = {
				id,
				name,
				named,
				scripts,
				numBlocks: world.blockGroups.groups.get(id)?.blocks.size() ?? 0,
			};
		}
		set({ blockGroups });
	},
	refreshSceneTreeNodes: () => {
		const world = useEngineStore.getState().engine.BB.world;
		if (!world) return;

		const sceneTreeNodes: EditorState["sceneTreeNodes"] = {};

		for (const nodeId in world.sceneTree.nodes) {
			const node = world.sceneTree.nodes[nodeId];
			const nodeDetails: SceneTreeNodeDetails = {
				...structuredClone(node),
				name: node.name ?? "",
				named: node.name !== null,
			};

			if (node.name === null) {
				let fallbackName: string;

				if (nodeDetails.type === SceneNodeType.CHARACTER) {
					const character = world.content.state.characters.get(nodeDetails.characterPk);
					fallbackName = character?.name ?? "Unknown Character Spawner";
				} else if (nodeDetails.type === SceneNodeType.PROP) {
					const prop = world.content.state.props.get(nodeDetails.propPk);
					fallbackName = prop?.name ?? "Unknown Prop Spawner";
				} else {
					fallbackName = "Spawner";
				}

				nodeDetails.name = fallbackName;
			}

			sceneTreeNodes[nodeId] = nodeDetails;
		}

		set({ sceneTreeNodes });
	},
	refreshEngine: () => {
		const { refreshBlockGroups, refreshSceneTreeNodes } = get();

		refreshBlockGroups();
		refreshSceneTreeNodes();
	},
	setSideNav: (nav) => {
		set({ sideNav: nav });
	},
	setTabs: (tabs) => {
		set({ tabs });
	},
	clearEditor: () => {
		set(useEditor.getInitialState());
	},
	openBlockGroup: (blockGroupId, openModal = false) => {
		if (openModal) {
			useEditorModal.getState().setOpen(true);
		}

		const { editors } = get();

		let editor = editors[blockGroupId];

		if (!editor) {
			const BB = useEngineStore.getState().engine.BB;
			const world = BB.world;
			const group = world.blockGroups.groups.get(blockGroupId);

			if (!group) return undefined;

			const store = createBlockGroupEditorStore();

			editor = editors[blockGroupId] = {
				id: blockGroupId,
				type: "blockGroup",
				store,
			};

			get()._refreshBlockGroupEditor(blockGroupId);
		}

		set({ editors, tabs: Array.from(new Set([...get().tabs, blockGroupId])) });

		get().openTab(blockGroupId);

		return editor as BlockGroupEditorInstance;
	},
	teleportToBlockGroup: (blockGroupId) => {
		WrenchRouter.teleportCreatorToBlockGroup(blockGroupId);

		useEditorModal.getState().setOpen(false);
	},
	openSceneTreeNode: (sceneTreeNodeId, openModal = false) => {
		if (openModal) {
			useEditorModal.getState().setOpen(true);
		}

		const { editors } = get();

		let editor = editors[sceneTreeNodeId];

		if (!editor) {
			const world = useEngineStore.getState().engine.BB.world;
			const node = world.sceneTree.nodes[sceneTreeNodeId];

			if (!node) return undefined;

			const store = createSceneTreeNodeEditorStore();

			editor = editors[sceneTreeNodeId] = {
				id: sceneTreeNodeId,
				type: "sceneTreeNode",
				store,
			};

			get()._refreshSceneTreeNodeEditor(sceneTreeNodeId);
		}

		set({ editors, tabs: Array.from(new Set([...get().tabs, sceneTreeNodeId])) });

		get().openTab(sceneTreeNodeId);

		return editor as SceneTreeNodeEditorInstance;
	},
	teleportToSceneTreeNode: (nodeId) => {
		WrenchRouter.teleportCreatorToSceneTreeNode(nodeId);

		useEditorModal.getState().setOpen(false);
	},
	openInventoryAsset: (pk) => {
		useEditorModal.getState().setOpen(false);

		const inventoryStore = Inventory.useInventoryStore.getState();
		inventoryStore.toggle(true);
		inventoryStore.setSelectedTab(Inventory.MainTab.CREATE);
		inventoryStore.setCreateTab(Inventory.CreateTab.ALL);
		inventoryStore.setSelectedAsset(pk);
	},
	undo: () => {
		const { editors, current } = get();
		if (!current) return;

		const editor = editors[current];

		if (!editor) return;
		editor.store.getState().undo();
	},
	redo: () => {
		const { editors, current } = get();
		if (!current) return;

		const editor = editors[current];

		if (!editor) return;
		editor.store.getState().redo();
	},
	selectAll: () => {
		const { editors, current } = get();
		if (!current) return;

		const editor = editors[current];

		if (!editor) return;

		if (editor.type === "script") {
			editor.store.getState().selectAll();
		}
	},
	openTab: (tabId) => {
		const editors = get().editors;
		const editor = editors[tabId];

		if (!editor) return;

		// refresh the editor if there are no unsaved changes
		const newEditorModified = editor.store.getState().modified;
		if (!newEditorModified) {
			get().refreshEditor(tabId);
		}

		const currentTab = get().current;

		if (currentTab === tabId) return;

		set({ current: tabId, editors });
	},
	_refreshScriptEditor: (tabId) => {
		const editor = get().editors[tabId] as ScriptEditorInstance | undefined;

		if (!editor) return;

		const modified = editor.store.getState().modified;
		if (modified) return;

		const script = Jacy.content.state.scripts.get(tabId);
		if (!script) return;

		editor.store.getState().setScript(structuredClone(script));
		editor.store.getState().reload();
	},
	_refreshBlockGroupEditor: (tabId) => {
		const blockGroupId = tabId;

		const editor = get().editors[tabId] as BlockGroupEditorInstance | undefined;

		if (!editor) return;

		const modified = editor.store.getState().modified;
		if (modified) return;

		const BB = useEngineStore.getState().engine.BB;
		const world = BB.world;
		const group = world.blockGroups.groups.get(blockGroupId);

		if (!group) return;

		const blockGroupName = group.name;
		const blockGroupScripts = group.scripts;

		const scripts = blockGroupScripts.map((s) => ({ id: s.script, data: s.data })) ?? [];

		editor.store.getState().setBlockGroup({
			id: blockGroupId,
			name: blockGroupName,
			scripts,
		});
	},
	_refreshSceneTreeNodeEditor: (tabId) => {
		const editor = get().editors[tabId] as SceneTreeNodeEditorInstance | undefined;
		if (!editor) return;

		const modified = editor.store.getState().modified;
		if (modified) return;

		const node = get().sceneTreeNodes[tabId];
		if (!node) return;

		editor.store.getState().setSceneTreeNode(structuredClone(node));
	},
	openScript: (scriptId) => {
		useEditorModal.getState().setOpen(true);

		const script = Jacy.content.state.scripts.get(scriptId);
		if (!script) return;

		const { editors } = get();

		let editor = editors[scriptId] as ScriptEditorInstance | undefined;

		if (!editor) {
			const editorStore = createScriptEditorStore();

			editor = editors[scriptId] = { id: scriptId, type: "script", store: editorStore };
			get()._refreshScriptEditor(scriptId);
		}

		get().createScriptEditorClient();
		editor.store.getState().reload();

		set({
			tabs: Array.from(new Set([...get().tabs, scriptId])),
			editors,
		});

		get().openTab(scriptId);
	},
	openNewScript: () => {
		const { openScript, renameScript } = get();
		const scriptId = Jacy.actions.scripts.create();

		openScript(scriptId);

		renameScript(scriptId);

		set({
			isRenamingNew: true,
		});
	},
	duplicateScript: (scriptId) => {
		const script = Jacy.content.state.scripts.get(scriptId);

		if (!script) return null;

		const newScript = Jacy.content.state.scripts.makeEmptyScript(`${script.name} (Copy)`);
		newScript.type = script.type;

		importEvents(newScript.events, structuredClone(script.events), [0, 0]);

		Jacy.actions.scripts.set(newScript);

		return newScript.pk;
	},
	refreshEditor: (id) => {
		const editor = get().editors[id];

		if (!editor) return;

		if (editor.type === "script") {
			get()._refreshScriptEditor(id);
		} else if (editor.type === "blockGroup") {
			get()._refreshBlockGroupEditor(id);
		} else if (editor.type === "sceneTreeNode") {
			get()._refreshSceneTreeNodeEditor(id);
		}
	},
	refreshEditors: () => {
		const { editors, refreshEditor } = get();

		for (const id of Object.keys(editors)) {
			refreshEditor(id);
		}
	},
	_saveScript: (store: ScriptEditorStore) => {
		const state = store.getState();

		const script: IScript = structuredClone({
			...state.script,
			events: state.events,
		});

		Jacy.actions.scripts.set(script);
		Jacy.actions.scripts.reloadScripts();

		store.setState({ modified: false });
	},
	_saveBlockGroup: (store: BlockGroupEditorStore) => {
		const editorState = store.getState();

		const id = editorState.state.id;

		const name = editorState.state.name;
		const blockGroupScripts = editorState.state.scripts.map((s) => ({ script: s.id, data: s.data }));

		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;

		BlockGroupsRouter.setName({ id, name });
		BlockGroupsRouter.setScripts({ id, scripts: blockGroupScripts });

		store.setState({ modified: false });
	},
	_saveSceneTreeNode: (store: SceneTreeNodeEditorStore) => {
		const { state } = store.getState();

		const world = useEngineStore.getState().engine.BB.world;
		if (!world) return;

		SpawnerRouter.updateSceneTreeNode(state);

		store.setState({ modified: false });
	},
	save: (tabId) => {
		const { editors, _saveScript, _saveBlockGroup, _saveSceneTreeNode } = get();
		const editor = editors[tabId];
		if (!editor) return;

		if (editor.type === "script") {
			_saveScript(editor.store);
		} else if (editor.type === "blockGroup") {
			_saveBlockGroup(editor.store);
		} else if (editor.type === "sceneTreeNode") {
			_saveSceneTreeNode(editor.store);
		}
	},
	saveCurrent: () => {
		const { current: currentTab, save } = get();
		if (!currentTab) return;

		save(currentTab);
	},
	saveAll: () => {
		const { editors, save } = get();

		for (const scriptId in editors) {
			const modified = editors[scriptId].store.getState().modified;

			if (modified) {
				save(scriptId);
			}
		}
	},
	closeTab: async (tabId, force = false) => {
		const editor = get().editors[tabId];

		if (!editor) return;

		if (!force && editor.store.getState().modified) {
			const confirmed = await useConfirmPromptStore.getState().prompt({
				title: "Unsaved Changes",
				description: "Are you sure you want to close the editor without saving your changes?",
				confirmText: "Yes, close without saving",
			});

			if (!confirmed) return;
		}

		const { editors, tabs } = get();

		delete editors[tabId];
		const newTabs = [...tabs].filter((tab) => tab !== tabId);

		const currentScript = newTabs[newTabs.length - 1];

		set({ editors, tabs: newTabs, current: currentScript });
	},

	renamingId: undefined,
	renamingType: undefined,
	draftName: "",
	isRenamingNew: undefined,
	renameScript: (id) => {
		const script = Jacy.content.state.scripts.get(id);
		set({ renamingId: id, renamingType: "script", draftName: script.name });
	},
	renameBlockGroup: (id) => {
		const BB = useEngineStore.getState().engine.BB;
		const world = BB.world;

		const blockGroup = world.blockGroups.groups.get(id);
		if (!blockGroup) return;

		const name = blockGroup.name;

		set({ renamingId: id, renamingType: "blockGroup", draftName: name ?? "" });
	},
	renameSceneTreeNode: (id) => {
		const world = useEngineStore.getState().engine.BB.world;
		if (!world) return;

		const node = world.sceneTree.nodes[id];
		if (!node) return;

		const name = node.name;

		set({ renamingId: id, renamingType: "sceneTreeNode", draftName: name ?? "" });
	},
	setDraftName: (name) => {
		set({ draftName: name });
	},
	_setScriptName: (id, name) => {
		const script = Jacy.content.state.scripts.get(id);
		if (!script) return;

		Jacy.actions.scripts.setName(script.pk, name);

		const editor = get().editors[id];

		if (editor && editor.type === "script") {
			editor.store.getState().script.name = name;
		}
	},
	_setBlockGroupName: (id, name) => {
		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;
		BlockGroupsRouter.setName({ id, name });

		const editor = get().editors[id];

		if (editor && editor.type === "blockGroup") {
			editor.store.getState().state.name = name;
		}
	},
	_setSceneTreeNodeName: (id, name) => {
		const world = useEngineStore.getState().engine.BB.world;
		if (!world) return;

		const node = world.sceneTree.nodes[id];
		if (!node) return;

		SpawnerRouter.setSceneTreeNodeName({ nodeId: id, name });
	},
	confirmRename: () => {
		const { draftName, renamingId: renaming } = get();

		if (!renaming) return;

		const name = draftName?.trim() ?? "";

		if (get().renamingType === "script") {
			const script = Jacy.content.state.scripts.get(renaming);
			const newName = name === "" ? script.name : name;

			get()._setScriptName(renaming, newName);
		} else if (get().renamingType === "blockGroup") {
			let newBlockGroupName: string | null = name;

			if (newBlockGroupName === "") {
				newBlockGroupName = null;
			}

			get()._setBlockGroupName(renaming, newBlockGroupName);
		} else if (get().renamingType === "sceneTreeNode") {
			let newSceneTreeNodeName: string | null = name;

			if (newSceneTreeNodeName === "") {
				newSceneTreeNodeName = null;
			}

			get()._setSceneTreeNodeName(renaming, newSceneTreeNodeName);
		}

		set({
			renamingId: undefined,
			isRenamingNew: undefined,
		});

		get().rerenderEditors();
	},
	cancelRename: () => {
		const { renamingId, renamingType, isRenamingNew, deleteScript } = get();

		if (renamingId && isRenamingNew && renamingType === "script") {
			deleteScript(renamingId, true);
		}

		set({ renamingId: undefined, renamingType: undefined, isRenamingNew: undefined });
	},
	setScriptStarred: (scriptId, starred) => {
		const script = Jacy.content.state.scripts.get(scriptId);
		if (!script) return;

		Jacy.actions.scripts.setStarred(script.pk, starred);

		const editor = get().editors[scriptId];

		if (editor && editor.type === "script") {
			editor.store.getState().script.starred = starred;
		}
	},

	rerenderEditors: () => {
		const { editors } = get();

		set({
			editors: Object.fromEntries(Object.entries(editors).map(([id, editor]) => [id, { ...editor }])),
		});
	},

	deleteScript: async (scriptId, force) => {
		if (!force) {
			const confirmed = await useConfirmPromptStore.getState().prompt({
				title: "Delete Script?",
				description: "Are you sure you want to delete this script?",
				confirmText: "Yes",
				cancelText: "No",
			});

			if (!confirmed) return;
		}

		get().closeTab(scriptId, true);

		Jacy.actions.scripts.delete(scriptId);
	},
	deleteBlockGroup: async (blockGroupId, force = false) => {
		const BB = useEngineStore.getState().engine.BB;
		const world = BB.world;

		const blockGroup = world.blockGroups.groups.get(blockGroupId);

		if (!blockGroup) return false;

		const blockGroupDetails = get().blockGroups[blockGroupId];
		const groupName = blockGroupDetails?.name ?? blockGroup.name;

		if (!force) {
			const confirmed = await useConfirmPromptStore.getState().prompt({
				title: "Delete Block Group?",
				description: `Are you sure you want to delete the block group "${groupName}"?`,
				confirmText: "Yes",
				cancelText: "No",
			});

			if (!confirmed) return false;
		}

		get().closeTab(blockGroupId, true);

		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;

		BlockGroupsRouter.deleteGroup(blockGroupId);

		return true;
	},
	deleteSceneTreeNode: async (nodeId, force = false) => {
		const world = useEngineStore.getState().engine.BB.world;
		if (!world) return false;

		const node = world.sceneTree.nodes[nodeId];
		if (!node) return false;

		const nodeName = node.name ?? "Unnamed Node";

		if (!force) {
			const confirmed = await useConfirmPromptStore.getState().prompt({
				title: "Are you sure?",
				description: `Are you sure you want to delete "${nodeName}"?`,
				confirmText: "Yes",
				cancelText: "No",
			});

			if (!confirmed) return false;
		}

		SpawnerRouter.deleteSceneTreeNode(nodeId);

		const { editors, tabs } = get();

		delete editors[nodeId];
		const newTabs = [...tabs].filter((tab) => tab !== nodeId);

		set({
			editors,
			tabs: newTabs,
			current: newTabs[newTabs.length - 1],
		});

		return true;
	},
	onOpenEditor: () => {
		const { refreshEditors } = get();

		refreshEditors();
	},

	setScriptEnabled: (scriptId, enabled) => {
		Jacy.actions.scripts.setEnabled(scriptId, enabled);
	},
}));

useJacyRerenderStore.subscribe((state, prevState) => {
	if (state.reRenderScripts !== prevState.reRenderScripts) {
		useEditor.getState().refreshEditors();
		useEditor.getState().refreshEngine();
	}
});

export default {
	useEditor: useEditor.getState,
};
