import { X as CloseIcon } from "@components/icons";
import { useHubWorldVersionsModal } from "@stores/dialogs";
import { Dialog } from "@components/ui/Dialog";
import { Button } from "@components/ui/Button";
import useSWR from "swr";
import { SkeletonHubWorldVersionCard, HubWorldVersionCard } from "@components/hub/HubWorldVersionCard";
import { swrFetcher } from "@lib/helpers/swr";
import { HubReturnButton } from "@components/hub/HubDialog/HubReturnButton";
import { getWorldVersions } from "rest-client";

export function LoadWorldVersionsModal() {
	const showHubWorldVersions = useHubWorldVersionsModal((state) => state.open);
	const setShowHubWorldVersions = useHubWorldVersionsModal((state) => state.setOpen);
	const worldID = useHubWorldVersionsModal((state) => state.worldID);

	return (
		<Dialog.Root open={showHubWorldVersions} onOpenChange={() => setShowHubWorldVersions(false)}>
			<Dialog.Content overlayClassname="" className="z-10 max-w-7xl rounded-lg bg-white py-4 shadow-lg">
				<Button
					className="absolute right-6 top-4 z-10"
					onClick={() => setShowHubWorldVersions(false)}
					square
					color="white-ghost"
				>
					<CloseIcon className="size-6" />
				</Button>
				<Dialog.Title className="sr-only">Load World Versions</Dialog.Title>
				<HubVersions selected worldID={worldID} />
			</Dialog.Content>
		</Dialog.Root>
	);
}

type IProps = {
	selected?: boolean;
	worldID: string;
};

function HubContainer({ selected, children }: Omit<IProps, "worldID"> & { children: React.ReactNode }) {
	return (
		<div
			id="tabpanel-world-versions"
			role="tabpanel"
			tabIndex={0}
			aria-labelledby="world-versions"
			className="h-full-dialog overflow-y-auto sm-h:h-m-dialog sm-h:md:h-dialog"
			hidden={!selected}
		>
			<div className="relative flex h-[40px] items-center justify-center">
				{location.pathname !== "/" && (
					<HubReturnButton onClick={() => {}} className="absolute left-0" />
				)}

				<h2 className="text-center text-2xl font-medium text-gray-900">Load a Version</h2>
			</div>
			{children}
		</div>
	);
}

export function HubVersions({ worldID, ...props }: IProps) {
	const { data: versionsResult, error } = useSWR(
		["/worlds/versions", worldID],
		swrFetcher(() => getWorldVersions(worldID)),
	);

	if (error || !versionsResult?.data) {
		return (
			<HubContainer {...props}>
				<div className="flex flex-col gap-2">
					<span className="sr-only">Loading...</span>
					<SkeletonHubWorldVersionCard />
					<SkeletonHubWorldVersionCard />
					<SkeletonHubWorldVersionCard />
				</div>
			</HubContainer>
		);
	}

	const versions = versionsResult.data;

	if (versions.length === 0) {
		return (
			<HubContainer {...props}>
				<div className="my-6 rounded border-b-2 border-gray-400 px-4 py-8 shadow">
					<p className="text-center text-base font-semibold text-gray-600">
						No versions available.
					</p>
				</div>
			</HubContainer>
		);
	}

	return (
		<HubContainer {...props}>
			<ul className="flex flex-col gap-1 divide-y divide-slate-200">
				{versions.map((world) => (
					<HubWorldVersionCard key={world.version.id} world={world} />
				))}
			</ul>
		</HubContainer>
	);
}
