import React from "react";
import { useAuthDialogStore } from "@stores/auth-user";
import { Dialog } from "@components/ui/Dialog";
import { Button } from "@components/ui/Button";
import { LetterLogo } from "@components/branding/LetterLogo";
import { SignInUser } from "./SignInUser";
import { RegisterUser } from "./RegisterUser";

function DialogContainer({ children }: React.PropsWithChildren<{ isAlert?: boolean }>) {
	const open = useAuthDialogStore((state) => state.open === "open-alpha");
	const openAlphaModal = useAuthDialogStore((state) => state.openAlphaModal);
	const close = useAuthDialogStore((state) => state.close);

	return (
		<Dialog.Root open={open} onOpenChange={(value) => (value ? openAlphaModal() : close())}>
			<Dialog.Content overlayClassname="!bg-opacity-80 !z-[70]" className="!z-[80] w-full max-w-lg">
				<Dialog.Title className="sr-only">Auth Popup</Dialog.Title>
				{children}
			</Dialog.Content>
		</Dialog.Root>
	);
}

export function AuthModal() {
	const [authMode, setAuthMode] = React.useState("signin");

	return (
		<DialogContainer>
			<div className="flex pt-4 lg:h-full lg:items-center lg:justify-center">
				<div className="relative bottom-16 w-full lg:max-w-[400px] sm-h:bottom-0">
					<div className="relative flex-1 rounded-2xl bg-white px-8 pb-8 pt-6 shadow-2xl lg:pt-[58px]">
						<LetterLogo className="absolute -top-12 left-1/2 hidden -translate-x-1/2 lg:block" />
						<h1 className="mb-3 text-center text-3xl font-medium text-gray-800">Welcome!</h1>
						<div className="mb-6 text-center">
							<p className="text-md text-gray-500 ">
								Sign in or register to Jamango! to continue
							</p>
						</div>
						{authMode === "signin" ? <SignInUser /> : <RegisterUser />}
						<div className="relative py-1">
							<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-3 text-lg text-gray-500">
								OR
							</div>
							<hr className="border-gray-200" />
						</div>
						<div className="mt-6">
							<Button
								fullWidth
								color="primary-outline"
								onClick={() => setAuthMode(authMode === "signin" ? "register" : "signin")}
							>
								{authMode === "signin" ? "Register" : "Login"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</DialogContainer>
	);
}
