import editor from "@editor/editor-store";
import controlStore from "./controls";
import dialogStore from "./dialogs";
import multiplayerStore from "./game-client/multiplayer";
import worldStore from "./world";
import wrenchStore from "./wrench";
import playerStore from "./player";
import playerContextStore from "./player-context";
import weaponStore from "./weapon";
import * as authUserStore from "./auth-user";
import worldEditor from "./world-editor";
import gameStore from "./game";
import settingsStore from "./settings";
import onboardingStore from "./onboarding";
import loadingScreenStore from "./loading-screen";
import chatStore from "./chat";
import debugStore from "./debug";
import engineStore from "./bb";
import inventoryStore from "./dialogs/inventory";
import inventorySelectorStore from "./hud/inventory-selector";
import selectorStore from "./hud/selector";
import itemStore from "./hud/item";
import pencilStore from "./pencil";
import gameHud from "./game-hud";
import gameHudPopup from "./hud/game-hud-popup";
import dialogue from "./dialogue";
import helpersStore from "./helpers";
import alertDialogStore from "./dialogs/alert-dialog";
import customUIStore from "./custom-ui";

export const store = {
	...editor,
	...authUserStore,
	...controlStore,
	...dialogStore,
	...multiplayerStore,
	...worldStore,
	...wrenchStore,
	...playerStore,
	...playerContextStore,
	...weaponStore,
	...worldEditor,
	...gameStore,
	...settingsStore,
	...onboardingStore,
	...loadingScreenStore,
	...chatStore,
	...debugStore,
	...engineStore,
	...dialogStore,
	...inventoryStore,
	...inventorySelectorStore,
	...selectorStore,
	...itemStore,
	...pencilStore,
	...gameHud,
	...gameHudPopup,
	...dialogue,
	...helpersStore,
	...alertDialogStore,
	...customUIStore,
} as const;
