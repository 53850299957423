import { assertNever } from "../../../helpers/src/AssertNever";
import { getAssetType } from "../lib/helpers/general";
import { AssetType, IAnyAsset, IBundle } from "../lib/types";

export const findAssetInBundle = (bundle: IBundle, pk: string): IAnyAsset | undefined => {
	const assetType = getAssetType(pk);

	switch (assetType) {
		case AssetType.BLOCK:
			return bundle.assets?.blocks?.[pk];
		case AssetType.CHARACTER:
			return bundle.assets?.characters?.[pk];
		case AssetType.AUDIO:
			return bundle.assets?.audios?.[pk];
		case AssetType.AVATAR:
			return bundle.assets?.avatars?.[pk];
		case AssetType.BLOCK_STRUCTURE:
			return bundle.assets?.blockStructures?.[pk];
		case AssetType.SCRIPT:
			return bundle.assets?.scripts?.[pk];
		case AssetType.CUSTOM_UI:
			return bundle.assets?.customUI?.[pk];
		case AssetType.ENVIRONMENT_PRESET:
			return bundle.assets?.environmentPresets?.[pk];
		case AssetType.SKYBOX:
			return bundle.assets?.skyboxes?.[pk];
		case AssetType.PARTICLE_SYSTEM:
			return bundle.assets?.particleSystems?.[pk];
		case AssetType.PROP:
			return bundle.assets?.props?.[pk];
		case AssetType.ITEM:
			return bundle.assets?.items?.[pk];
		case AssetType.MAP:
			if (bundle.type !== "world") return undefined;
			if (bundle.assets.map.pk !== pk) return undefined;
			return bundle.assets.map;
		case AssetType.SETTINGS:
			if (bundle.type !== "world") return undefined;
			if (bundle.assets.settings.pk !== pk) return undefined;
			return bundle.assets.settings;
		case AssetType.GAME_MECHANICS:
			if (bundle.type !== "world") return undefined;
			if (bundle.assets.gameMechanics.pk !== pk) return undefined;
			return bundle.assets.gameMechanics;
		case AssetType.CUSTOM_LOADER:
			if (bundle.type !== "world") return undefined;
			if (bundle.assets.customLoader.pk !== pk) return undefined;
			return bundle.assets.customLoader;
		case AssetType.THUMBNAIL:
			if (bundle.type !== "world") return undefined;
			if (bundle.assets.thumbnail?.pk !== pk) return undefined;
			return bundle.assets.thumbnail;
		case AssetType.BLOCK_TEXTURE:
			return bundle.assets?.blockTextures?.[pk];
		case AssetType.AVATAR_COMPONENT:
			return bundle.assets?.avatarComponents?.[pk];
		case AssetType.RESOURCE:
			return bundle.assets?.resources?.[pk];
		case AssetType.WORLD_DATA:
			if (bundle.type !== "world") return undefined;
			if (bundle.assets.worldData.pk !== pk) return undefined;
			return bundle.assets.worldData;
		case AssetType.PACKAGE_DATA:
			if (bundle.type !== "package") return undefined;
			if (bundle.assets.packageData?.pk !== pk) return undefined;
			return bundle.assets.packageData;
		case AssetType.BLOCK_SOUNDS_PACK:
			return bundle.assets?.blockSoundsPacks?.[pk];
		default:
			assertNever(assetType);
	}
};

export const setAssetInBundle = (bundle: IBundle, asset: IAnyAsset) => {
	const pk = asset.pk;

	switch (asset.type) {
		case AssetType.BLOCK:
			bundle.assets.blocks ||= {};
			bundle.assets.blocks[pk] = asset;
			break;
		case AssetType.CHARACTER:
			bundle.assets.characters ||= {};
			bundle.assets.characters[pk] = asset;
			break;
		case AssetType.AUDIO:
			bundle.assets.audios ||= {};
			bundle.assets.audios[pk] = asset;
			break;
		case AssetType.AVATAR:
			bundle.assets.avatars ||= {};
			bundle.assets.avatars[pk] = asset as typeof bundle.assets.avatars[typeof pk];
			break;
		case AssetType.BLOCK_STRUCTURE:
			bundle.assets.blockStructures ||= {};
			bundle.assets.blockStructures[pk] = asset;
			break;
		case AssetType.SCRIPT:
			bundle.assets.scripts ||= {};
			bundle.assets.scripts[pk] = asset;
			break;
		case AssetType.CUSTOM_UI:
			bundle.assets.customUI ||= {};
			bundle.assets.customUI[pk] = asset;
			break;
		case AssetType.ENVIRONMENT_PRESET:
			bundle.assets.environmentPresets ||= {};
			bundle.assets.environmentPresets[pk] = asset;
			break;
		case AssetType.SKYBOX:
			bundle.assets.skyboxes ||= {};
			bundle.assets.skyboxes[pk] = asset;
			break;
		case AssetType.PROP:
			bundle.assets.props ||= {};
			bundle.assets.props[pk] = asset;
			break;
		case AssetType.ITEM:
			bundle.assets.items ||= {};
			bundle.assets.items[pk] = asset;
			break;
		case AssetType.MAP:
			if (bundle.type !== "world") return undefined;
			bundle.assets.map = asset;
			break;
		case AssetType.SETTINGS:
			if (bundle.type !== "world") return undefined;
			bundle.assets.settings = asset;
			break;
		case AssetType.GAME_MECHANICS:
			if (bundle.type !== "world") return undefined;
			bundle.assets.gameMechanics = asset;
			break;
		case AssetType.CUSTOM_LOADER:
			if (bundle.type !== "world") return undefined;
			bundle.assets.customLoader = asset;
			break;
		case AssetType.THUMBNAIL:
			if (bundle.type !== "world") return undefined;
			bundle.assets.thumbnail = asset;
			break;
		case AssetType.BLOCK_TEXTURE:
			bundle.assets.blockTextures ||= {};
			bundle.assets.blockTextures[pk] = asset;
			break;
		case AssetType.RESOURCE:
			bundle.assets.resources ||= {};
			bundle.assets.resources[pk] = asset;
			break;
		case AssetType.WORLD_DATA:
			if (bundle.type !== "world") return undefined;
			bundle.assets.worldData = asset;
			break;
		case AssetType.PACKAGE_DATA:
			if (bundle.type !== "package") return undefined;
			bundle.assets.packageData = asset;
			break;
		case AssetType.PARTICLE_SYSTEM:
		case AssetType.AVATAR_COMPONENT:
		case AssetType.BLOCK_SOUNDS_PACK:
			throw new Error("Cannot set, asset type is a built-in");
		default:
			assertNever(asset);
	}
};
