import { create } from "zustand";
import { useEngineStore } from "./bb";
import { isNullish } from "@jamango/helpers";
import { InputCommandType } from "@jamango/engine/Input.ts";

export const WEAPON_TYPE = {
	melee: "melee",
	ranged: "ranged",
};

export const useWeaponStore = create((set, get) => ({
	type: null,
	needsReload: false,
	isReloading: false,
	ammoCount: 0,
	magazineSize: 0,

	init: (weapon) => {
		if (isNullish(weapon)) {
			set({ type: null });
			return;
		}

		if (weapon.type === WEAPON_TYPE.ranged) {
			set({
				type: WEAPON_TYPE.ranged,
				magazineSize: weapon.magazineSize,
			});
			get().setAmmoCount(weapon.ammoCount);
		} else if (weapon.type === WEAPON_TYPE.melee) {
			set({ type: WEAPON_TYPE.melee });
		}
	},
	setIsReloading: (isReloading) => {
		set({ isReloading });
	},
	setAmmoCount: (ammoCount) => {
		if (get().type !== WEAPON_TYPE.ranged) return;

		const needsReload = ammoCount / get().magazineSize <= 0.2;
		set({ needsReload, ammoCount });
	},
	reload: () => {
		const { BB } = useEngineStore.getState().engine;
		BB.world.input.local.cmd.push(InputCommandType.RELOAD);
	},
	toggleIronSights: () => {
		const { BB } = useEngineStore.getState().engine;
		const input = BB.world.input.local;
		input.isIronSights = !input.isIronSights;
	},
	pullTrigger: (enable) => {
		const { BB } = useEngineStore.getState().engine;
		const input = BB.world.input.local;

		input.isPullingTrigger = enable;

		if (enable) {
			input.cmd.push([InputCommandType.ITEM_PRIMARY]);
		}
	},
}));

export default {
	useWeapon: useWeaponStore.getState,
};
