import { cn } from "@lib/helpers/cn";
import { Input } from "@components/ui/Input";
import { Button } from "./Button";
import { DropSlash } from "@components/icons";

type IProps = {
	className?: string;
	id: string;
	name: string;
	color: string;
	onColorChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
	hideTextBox?: boolean;
	handleRemove?: () => void;
	removable?: boolean;
	variant?: string;
} & React.ComponentPropsWithoutRef<"div">;

export function ColorInput({
	className = "",
	id,
	name,
	color,
	onColorChange,
	hideTextBox = false,
	handleRemove,
	removable,
	variant,
	...props
}: IProps) {
	const isTransparent = color === "transparent";
	return (
		<div
			className={cn(
				"relative flex items-center gap-1 overflow-auto",
				variant === "editor" && "w-full rounded-lg bg-gray-100 px-1",
				className,
			)}
			{...props}
		>
			{isTransparent ? (
				<div className="bg-checker size-8 flex-shrink-0 overflow-hidden rounded-lg !p-0" />
			) : (
				<Input
					type="color"
					className={cn(
						variant === "editor" ? "size-8 flex-shrink-0" : "h-11 w-20",
						"overflow-hidden rounded-lg border-2 border-slate-300 bg-gray-200 !p-0",
					)}
					value={color}
					onChange={onColorChange}
					aria-label={props["aria-label"]}
					squashable={variant !== "editor"}
				/>
			)}
			{!hideTextBox && (
				<Input
					type="text"
					variant={variant}
					id={id}
					name={name}
					className={cn(
						"min-w-0 max-w-sm",
						variant === "editor" && "bg-transparent py-0 text-base",
					)}
					value={color}
					onChange={onColorChange}
					aria-label={props["aria-label"]}
				/>
			)}
			{removable && (
				<div className="inline-flex text-base">
					<Button
						color={isTransparent ? "white" : "primary"}
						onClick={handleRemove}
						square
						className=""
					>
						<DropSlash className="size-5" />
					</Button>
				</div>
			)}
		</div>
	);
}
