import { useEditor } from "@editor/editor-store";
import { getDefaultScriptControlsData } from "@editor/script-editor/actions";
import {
	AssetType,
	type IAssetKey,
	type IJacyContent,
	type IScript,
	ScriptType,
	findUsages,
	generateUUID,
} from "@jamango/content-client";
import { defs, EditorClient, NODE } from "@jamango/engine/Events.ts";
import { NODE_THIS_SCENE_TREE_NODE } from "@jamango/engine/Runtime/base/rete/NodeSharedUtil.ts";
import { useEngineStore } from "@stores/bb";
import { useEditorModal } from "@stores/dialogs";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import type { JacyActions } from "../JacyActions";
import { NODE_TYPE_ID } from "@jamango/engine/Runtime/base/rete/Constants.js";
import { removeAllAssetUsages } from "../dependency-actions";

export const createDefaultCharacterEvents = (): IScript["events"] => {
	const id = generateUUID();
	return {
		nodes: {
			[id]: {
				id,
				defId: NODE.GetCharacter,
				position: [0, 0],
				data: {
					character: NODE_THIS_SCENE_TREE_NODE,
				},
			},
		},
		connections: [],
		frames: {},
		comments: {},
	};
};

export const createDefaultPropEvents = (): IScript["events"] => {
	const id = generateUUID();
	return {
		nodes: {
			[id]: {
				id,
				defId: NODE.GetProp,
				position: [0, 0],
				data: {
					prop: NODE_THIS_SCENE_TREE_NODE,
				},
			},
		},
		connections: [],
		frames: {},
		comments: {},
	};
};

export class JacyScriptActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	create(name?: string, events?: IScript["events"]) {
		const script = this.#state.scripts.makeEmptyScript(
			name ?? `Script ${this.#state.scripts.getAll().length + 1}`,
		);

		if (events) {
			script.events = events;
		}

		this.#state.scripts.set(script);

		useJacyRerenderStore.getState().forceRerenderScripts();

		return script.pk;
	}

	set(script: IScript) {
		this.#state.scripts.set(script);
		useJacyRerenderStore.getState().forceRerenderScripts();
	}

	setName(pk: IAssetKey, name: string) {
		const script = this.#state.scripts.get(pk);
		script.name = name;
		this.#state.scripts.set(script);

		useJacyRerenderStore.getState().forceRerenderScripts();
	}

	setEnabled(pk: IAssetKey, enabled: boolean) {
		const script = this.#state.scripts.get(pk);
		script.enabled = enabled;
		this.#state.scripts.set(script);

		useJacyRerenderStore.getState().forceRerenderScripts();
	}

	setStarred(pk: IAssetKey, starred: boolean) {
		const script = this.#state.scripts.get(pk);
		script.starred = starred;
		this.#state.scripts.set(script);

		useJacyRerenderStore.getState().forceRerenderScripts();
	}

	delete(pk: IAssetKey) {
		try {
			this.removeUsages(pk);

			this.#state.scripts.delete(pk);
			useJacyRerenderStore.getState().forceRerenderScripts();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	reloadScripts() {
		const world = useEngineStore.getState().engine.BB.world;
		world.rete.needsReload = true;
	}

	editScript(pk: IAssetKey) {
		useEditorModal.getState().setOpen(true);
		useEditor.getState().openScript(pk);
	}

	findScriptSuggestions(type: "character" | "prop" | "blockGroup") {
		const suggestions: IScript[] = [];

		for (const script of this.#state.scripts.getAll()) {
			if (script.scriptType !== ScriptType.UTILITY) continue;

			if (type === "character") {
				// if there is a "Get Character" node
				if (Object.values(script.events.nodes).some((node) => node.defId === NODE.GetCharacter)) {
					suggestions.push(script);
				}
			} else if (type === "prop") {
				// if there is a "Get Prop" node
				if (Object.values(script.events.nodes).some((node) => node.defId === NODE.GetProp)) {
					suggestions.push(script);
				}
			} else if (type === "blockGroup") {
				// if there is global block trigger
				if (
					Object.values(script.events.nodes)
						.map((n) => defs.get(n.defId))
						.filter((nodeDef) => !!nodeDef)
						.some((nodeDef) => nodeDef.type === NODE_TYPE_ID.entryPointTrigger.block)
				) {
					suggestions.push(script);
				}
			}
		}

		suggestions.sort((a, b) => {
			if (a.starred && !b.starred) return -1;
			if (!a.starred && b.starred) return 1;
			return a.name.localeCompare(b.name);
		});

		return suggestions;
	}

	findUsages(pk: IAssetKey) {
		const scriptUsages: Array<{
			type: "blockGroup" | "sceneTreeNode" | AssetType,
			id: string;
			name: string | null;
		}> = [];

		const world = useEngineStore.getState().engine.BB.world;

		const usages = findUsages(this.#state.export(), world, pk);

		for (const usage of usages) {
			const [type, id] = usage.split("#");

			if (type === "blockGroup") {
				const blockGroup = world.blockGroups.groups.get(id);
				if (blockGroup) {
					scriptUsages.push({ type: "blockGroup", id, name: blockGroup.name });
				}
			} else if (type === "sceneTreeNode") {
				const sceneTreeNode = world.sceneTree.nodes[id];
				if (sceneTreeNode) {
					scriptUsages.push({ type: "sceneTreeNode", id, name: sceneTreeNode.name });
				}
			} else {
				const asset = this.#state.findAsset(usage);

				if (asset) {
					let name: string | null = null;
					if (asset.type === "block") {
						name = asset.displayName;
					} else if ("name" in asset) {
						name = asset.name;
					}
					scriptUsages.push({ type: asset.type, id: asset.pk, name });
				}
			}
		}

		return scriptUsages;
	}

	removeUsages(pk: IAssetKey) {
		const BB = useEngineStore.getState().engine.BB;
		const world = BB.world;

		removeAllAssetUsages(BB.world.content, world, pk)

		this.reloadScripts();
	}

	getDefaultScriptControlsData(scriptId: IAssetKey) {
		const world = useEngineStore.getState().engine.BB.world;
		const editorClient = new EditorClient(world);

		const script = this.#state.scripts.get(scriptId);

		return getDefaultScriptControlsData(editorClient, script.events);
	}
}
