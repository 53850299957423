import type { IAssetKey, IJacyContent } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";
import { useJacyBlockEditorStore } from "@stores/jacy/block-editor";
import { forceRerenderInventory } from "@stores/jacy/rerender";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useInventorySelectorStore } from "@stores/hud/inventory-selector";
import { useInventoryStore } from "@stores/dialogs/inventory";

export class JacyBlockActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	newBlock() {
		try {
			useJacyBlockEditorStore.getState().newBlock();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editBlock(pk: IAssetKey) {
		try {
			const block = this.#state.blocks.getParsedBlock(pk);

			if (!block) {
				this.#actions.setError("Block not found");
				return;
			}

			useJacyBlockEditorStore.getState().editBlock(block);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	createBlock() {
		try {
			const { block } = useJacyBlockEditorStore.getState().getBlock();
			const isTexture = useJacyBlockEditorStore.getState().isTexture;

			this.#state.blocks.create(block.pk, block, isTexture);
			const createdBlock = this.#state.blocks.get(block.pk);

			if (!createdBlock) throw new Error(`can't create block`);

			useInventorySelectorStore.getState().refresh();
			forceRerenderInventory();
			useInventoryStore.getState().setSelectedAsset(createdBlock.pk);
			useJacyBlockEditorStore.getState().close();

			this.#actions.setSuccess(`Block "${createdBlock.displayName}" created successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	updateBlock() {
		try {
			const { block } = useJacyBlockEditorStore.getState().getBlock();
			const isTexture = useJacyBlockEditorStore.getState().isTexture;

			this.#state.blocks.update(block.pk, block, isTexture);

			const updatedBlock = this.#state.blocks.get(block.pk);

			if (!updatedBlock) throw new Error(`Block not found`);

			forceRerenderInventory();
			useInventoryStore.getState().setSelectedAsset(updatedBlock.pk);
			useJacyBlockEditorStore.getState().close();

			this.#actions.setSuccess(`Block "${updatedBlock.displayName}" updated successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteBlock() {
		try {
			const { block } = useJacyBlockEditorStore.getState().getBlock();
			const isCreate = useJacyBlockEditorStore.getState().isCreate;

			if (isCreate) {
				useJacyBlockEditorStore.getState().close();
				return;
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Block",
				description: `Are you sure you want to delete "${block.displayName}" block? You will lose all the data associated with this block.`,
				confirmText: "Yes, delete this block",
			});

			if (!confirmed) return;

			this.#state.blocks.delete(block.pk);

			useInventorySelectorStore.getState().refresh();
			forceRerenderInventory();
			useJacyBlockEditorStore.getState().close();

			this.#actions.setSuccess(`Block "${block.displayName}" deleted successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
