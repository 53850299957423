import { CaretLeft } from "@components/icons";

import { Button } from "@components/ui/Button";
import { cn } from "@lib/helpers/cn";

type IProps = React.ComponentProps<typeof Button> & {
	text?: string;
};

export function HubReturnButton({ onClick, text, className = "" }: IProps) {
	return (
		<Button
			color="white-ghost"
			size="md"
			className={cn("px-2 xs:pl-2 xs:pr-3", className)}
			onClick={onClick}
		>
			<CaretLeft className="size-6" aria-hidden="true" weight="bold" />
			<div className="hidden text-lg xs:block">{text ?? "Back"}</div>
		</Button>
	);
}
