import { useNavigate } from "react-router";
import { Button } from "@components/ui/Button";
import { getStaticFile } from "@jamango/content-client";

const LOADING_SCREEN_LOGO = getStaticFile("assets/frontend/jamango-icon-192.png");

export function MobileNotSupported() {
	const navigate = useNavigate();

	return (
		<main className="fixed inset-0 z-[100] h-dvh w-full bg-white px-4 py-8 lg:hidden">
			<div className="flex h-full flex-col items-center justify-between">
				<div></div>
				<div className="mb-20 grid gap-4 text-center">
					<img
						src={LOADING_SCREEN_LOGO}
						alt="Jamango Logo"
						className="mx-auto block h-36 w-36 object-cover"
					/>
					<h1 className="text-xl font-semibold">Mobile is not supported yet</h1>
					<p className="">{`We'll announce it when it's ready in Discord`}</p>
				</div>
				<div>
					<a href="https://discord.gg/TURGychW" target="_blank" rel="noreferrer">
						<Button fullWidth size="lg" className="mb-2 py-4">
							Join Discord
						</Button>
					</a>
					<Button
						onClick={() => navigate("/")}
						fullWidth
						size="lg"
						className="py-4"
						color="primary-outline"
					>
						Return to homepage
					</Button>
				</div>
			</div>
		</main>
	);
}
