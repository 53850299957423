import type { World } from "@jamango/engine/Runtime";
import { assertNever } from "@jamango/helpers";
import { AssetType, IBundle } from "../lib/types";
import { ItemMeshType } from "../lib/validations/item";
import { PropColliderType, PropMeshType } from "../lib/validations/prop";
import { findAssetInBundle } from "./bundle-utils";

export type DependencyNodeType = AssetType | "blockGroup" | "sceneTreeNode";

/** `source` has a dependency on `target` */
export type DependencyGraphEdge = {
	source: string;
	target: string;
};

export type DependencyGraph = {
	nodes: string[];
	edges: DependencyGraphEdge[];
};

/** gets immediate dependencies of an asset or world construct (block group, scene tree node) */
const getDependencies = (bundle: IBundle, world: World | undefined, pk: string) => {
	const [type, id] = pk.split("#");

	if (type === "blockGroup" && world) {
		const blockGroup = world.blockGroups.groups.get(id);
		if (blockGroup) {
			return blockGroup.scripts.map((s) => s.script);
		}
	}

	if (type === "sceneTreeNode" && world) {
		const sceneTreeNode = world.sceneTree.nodes[id];
		if (sceneTreeNode) {
			return sceneTreeNode.scripts.map((s) => s.script);
		}
	}

	return getAssetDependencies(bundle, pk);
};

/** gets immediate dependencies of an asset */
const getAssetDependencies = (bundle: IBundle, pk: string) => {
	const dependencies = new Set<string>();

	const asset = findAssetInBundle(bundle, pk);
	if (!asset) return dependencies;


	switch (asset.type) {
		case "block": {
			for (const side of Object.values(asset.material)) {
				if (side && side.startsWith("blockTexture#")) {
					dependencies.add(side);
				}
			}

			for (const side of asset.scripts ?? []) {
				dependencies.add(side.script);
			}

			break;
		}
		case "character": {
			for (const script of asset.scripts ?? []) {
				dependencies.add(script.script);
			}

			if (asset.aiAvatar) {
				dependencies.add(asset.aiAvatar);
			}
			break;
		}
		case "avatar": {
			if (asset.displayPhotoResourcePk) {
				dependencies.add(asset.displayPhotoResourcePk);
			}

			if (asset.thumbnailResourcePk) {
				dependencies.add(asset.thumbnailResourcePk);
			}

			break;
		}
		case "blockStructure": {
			for (const blockType of asset.data.blockTypes ?? []) {
				dependencies.add(blockType);
			}

			for (const blockGroupDef of Object.values(asset.data.blockGroupDefs ?? {})) {
				for (const scriptAttachment of blockGroupDef.scripts ?? []) {
					dependencies.add(scriptAttachment.script);
				}
			}

			break;
		}
		case "script": {
			for (const node of Object.values(asset.events.nodes)) {
				if (node.custom) {
					dependencies.add(node.defId);
				}
			}

			break;
		}
		case "prop": {
			for (const script of asset.scripts ?? []) {
				dependencies.add(script.script);
			}

			if (asset.mesh.type === PropMeshType.BLOCK_STRUCTURE) {
				dependencies.add(asset.mesh.blockStructurePk);
			}

			if (asset.physics.collider.type === PropColliderType.BLOCK_STRUCTURE) {
				dependencies.add(asset.physics.collider.blockStructurePk);
			}

			break;
		}
		case "item": {
			if (asset.mesh.type === ItemMeshType.BLOCK_STRUCTURE) {
				dependencies.add(asset.mesh.blockStructurePk);
			}

			break;
		}
		case "blockTexture": {
			dependencies.add(asset.resourcePk);

			break;
		}
		case "audio": {
			dependencies.add(asset.resourcePk);

			break;
		}
		case "map": {
			for (const file of asset.files) {
				dependencies.add(file.resourcePk)
			}

			break;
		}
		case "thumbnail": {
			dependencies.add(asset.resourcePk);

			break;
		}
		case "customLoader": {
			if (asset.banner?.resourcePk) {
				dependencies.add(asset.banner?.resourcePk)
			}

			break;
		}
		case "skybox": {
			dependencies.add(asset.pxResourcePk);
			dependencies.add(asset.nxResourcePk);
			dependencies.add(asset.pyResourcePk);
			dependencies.add(asset.nyResourcePk);
			dependencies.add(asset.pzResourcePk);
			dependencies.add(asset.nzResourcePk);

			break;
		}
		case "worldData":
		case "packageData":
		case "customUI":
		case "avatarComponent":
		case "resource":
		case "particleSystem":
		case "settings":
		case "gameMechanics":
		case "environmentPreset":
		case "blockSoundsPack":
			break;
		default:
			assertNever(asset);
	}

	return Array.from(dependencies);
};

export const getAllPKs = (bundle: IBundle, world: World | undefined) => {
	const pks = new Set<string>();

	const assets = Object.values(bundle.assets) as IBundle["assets"][keyof IBundle["assets"]][];

	for (const value of assets) {
		if (!value) continue;

		if ("pk" in value && typeof value.pk === "string") {
			pks.add(value.pk);
		} else {
			for (const [pk] of Object.entries(value)) {
				pks.add(pk);
			}
		}
	}

	if (world) {
		for (const blockGroup of world.blockGroups.groups.values()) {
			pks.add(`blockGroup#${blockGroup.id}`);
		}

		for (const sceneTreeNode of Object.values(world.sceneTree.nodes)) {
			pks.add(`sceneTreeNode#${sceneTreeNode.id}`);
		}
	}

	return Array.from(pks);
};

export const buildDependencyGraph = (
	bundle: IBundle,
	world: World | undefined,
	pks: string[],
): DependencyGraph => {
	const graph: DependencyGraph = {
		nodes: [],
		edges: [],
	};

	const explored = new Set<string>();
	const frontier = [...pks];

	while (frontier.length > 0) {
		const pk = frontier.pop()!;
		if (explored.has(pk)) continue;

		explored.add(pk);
		const dependencies = getDependencies(bundle, world, pk);

		for (const dependency of dependencies) {
			graph.edges.push({ source: pk, target: dependency });

			if (!explored.has(dependency)) {
				frontier.push(dependency);
			}
		}
	}

	graph.nodes = Array.from(explored);

	return graph;
};

export const findUsages = (bundle: IBundle, world: World, pk: string): Set<string> => {
	const allPks = getAllPKs(bundle, world);
	const graph = buildDependencyGraph(bundle, world, allPks);

	const usages = new Set<string>();
	for (const edge of graph.edges) {
		if (edge.target === pk) usages.add(edge.source);
	}

	return usages;
};
