import { ItemWrench } from "@jamango/engine/Runtime/mods/defs/ItemWrench.js";
import { isNullish } from "@jamango/helpers";
import { NET_CLIENT } from "@jamango/ibs";
import { create } from "zustand";
import type { BlockGroupEditorInstance, SceneTreeNodeEditorInstance } from "../editor/editor-store";
import { useEditor } from "../editor/editor-store";
import { Jacy } from "../jacy/JacyClient";
import { useEngineStore } from "./bb";
import { useControlsStore } from "./controls";
import { CreateTab, useInventoryStore } from "./dialogs/inventory";
import { usePermissionStore } from "./player";
import type { SelectorTarget } from "@jamango/engine/Runtime/base/world/entity/component/CharacterSelector.ts";
import { SelectorTargetType } from "@jamango/engine/Runtime/base/world/entity/component/CharacterSelector.ts";
import { WrenchMode } from "@jamango/engine/Runtime/client/world/tools/Wrench.ts";
import * as Wrench from "@jamango/engine/Runtime/client/world/tools/Wrench.ts";

enum UIModals {
	INVENTORY = "inventory",
	HUB = "hub",
}

type WrenchUI = "editGroup" | "editSceneTreeNode" | "selectionOptions";

type IWrenchStore = {
	lastOpenUI: UIModals | null;
	ui?: WrenchUI;
	mode: WrenchMode;
	setMode: (mode: WrenchMode) => void;

	open: boolean;
	isRefreshing: boolean;

	inspect: (target: SelectorTarget) => void;

	showSelectionOptions: () => void;
	selectionCreateNewGroup: () => void;
	selectionAddToGroup: (groupId: string) => void;
	selectionRemoveFromGroup: (groupId: string) => void;
	selectionRemoveFromAllGroups: () => void;

	createNewGroup: (blocks: [number, number, number][]) => void;

	blockGroupEditor: null | BlockGroupEditorInstance;
	isNewBlockGroup: boolean;
	editGroup: (groupId: string, isNew?: boolean) => void;

	sceneTreeNodeEditor: null | SceneTreeNodeEditorInstance;
	editSceneTreeNode: (nodeId: string) => void;

	editBlockType: (blockNameId: string) => void;
	editScript: (scriptId: string) => void;

	deleteBlockGroup: (force?: boolean) => Promise<void>;
	deleteSceneTreeNode: (force?: boolean) => Promise<void>;
	save: () => void;
	cancel: () => void;
	openBlockGroupInEditor: () => void;
	openSceneTreeNodeInEditor: () => void;
	addGroupBlocksToExistingGroup: (groupId: string) => void;

	resetBulkSelection: () => void;
	removeSelectedBlockGroups: () => void;
	cycleTarget: () => void;

	close: (runSideEffects?: boolean) => void;
};

const dispatchWrenchEvent = (type: string, data: any = {}) => {
	const BB = useEngineStore.getState().engine.BB;
	const equippedItem = BB.world.client!.getEquippedItem();

	if (!equippedItem || equippedItem.def !== ItemWrench.name) {
		return;
	}

	equippedItem.modding!.state.dispatcher.dispatchEvent(NET_CLIENT, {
		type,
		...data,
	});
};

const resetWrench = () => {
	dispatchWrenchEvent("wrenchReset");
};

export const useWrenchStore = create<IWrenchStore>((set, get) => ({
	ui: undefined,
	lastOpenUI: null,

	mode: WrenchMode.NO_CORNERS_SELECTED,
	setMode: (mode: WrenchMode) => {
		set({ mode });
	},

	open: false,
	isRefreshing: false,

	groupId: null,
	groupName: null,
	scripts: null,

	inspect: (target: SelectorTarget) => {
		if (target.type === SelectorTargetType.BLOCK_TYPE) {
			get().editBlockType(target.id);
		} else if (target.type === SelectorTargetType.GROUP) {
			get().editGroup(target.id);
		} else if (
			target.type === SelectorTargetType.PROP_SCENE_TREE_NODE ||
			target.type === SelectorTargetType.CHARACTER_SCENE_TREE_NODE ||
			target.type === SelectorTargetType.ITEM_SCENE_TREE_NODE
		) {
			get().editSceneTreeNode(target.id);
		}
	},

	showSelectionOptions: () => {
		if (!usePermissionStore.getState().permissions.canUseWrench) return;

		set({
			open: true,
			ui: "selectionOptions",
		});

		useControlsStore.getState().exitPointerLock(true);
	},
	selectionCreateNewGroup: () => {
		const BB = useEngineStore.getState().engine.BB;
		const cameraTarget = BB.world.client!.camera.target;
		const selector = cameraTarget.selector;
		if (!selector) return;

		const blocks: [number, number, number][] = [];
		for (const { x, y, z } of selector.state.bulkSelectionBlocks.iterate()) {
			blocks.push([x, y, z]);
		}

		get().createNewGroup(blocks);

		resetWrench();
	},
	selectionAddToGroup: (groupId: string) => {
		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;

		const BB = useEngineStore.getState().engine.BB;
		const cameraTarget = BB.world.client!.camera.target;
		const selector = cameraTarget.selector;
		if (!selector) return;

		const blocks: [number, number, number][] = [];
		for (const { x, y, z } of selector.state.bulkSelectionBlocks.iterate()) {
			blocks.push([x, y, z]);
		}

		BlockGroupsRouter.addBlocks({ id: groupId, blocks });

		resetWrench();
		get().close();
	},
	selectionRemoveFromGroup: (groupId: string) => {
		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;

		const BB = useEngineStore.getState().engine.BB;
		const cameraTarget = BB.world.client!.camera.target;
		const selector = cameraTarget.selector;
		if (!selector) return;

		const blocks: [number, number, number][] = [];
		for (const { x, y, z } of selector.state.bulkSelectionBlocks.iterate()) {
			blocks.push([x, y, z]);
		}

		BlockGroupsRouter.removeBlocks({ id: groupId, blocks });

		resetWrench();
		get().close();
	},
	selectionRemoveFromAllGroups: () => {
		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;

		const BB = useEngineStore.getState().engine.BB;
		const cameraTarget = BB.world.client!.camera.target;
		const selector = cameraTarget.selector;
		if (!selector) return;

		const blocks: [number, number, number][] = [];
		for (const { x, y, z } of selector.state.bulkSelectionBlocks.iterate()) {
			blocks.push([x, y, z]);
		}

		BlockGroupsRouter.clearAtPositions(blocks);

		resetWrench();
		get().close();
	},

	blockGroupEditor: null,
	isNewBlockGroup: false,

	editGroup: (groupId: string, isNew = false) => {
		if (!usePermissionStore.getState().permissions.canUseWrench) return;

		const editor = useEditor.getState().openBlockGroup(groupId, false);

		set({
			open: true,
			ui: "editGroup",
			blockGroupEditor: editor,
			isNewBlockGroup: isNew,
		});

		useInventoryStore.getState().playSound("snd-ui-wrench-menu-open");

		useControlsStore.getState().exitPointerLock(true);
	},

	sceneTreeNodeEditor: null,

	editSceneTreeNode: (nodeId: string) => {
		if (!usePermissionStore.getState().permissions.canUseWrench) return;

		const editor = useEditor.getState().openSceneTreeNode(nodeId);

		set({
			open: true,
			ui: "editSceneTreeNode",
			sceneTreeNodeEditor: editor,
		});

		useInventoryStore.getState().playSound("snd-ui-wrench-menu-open");

		useControlsStore.getState().exitPointerLock(true);
	},
	editBlockType: (blockPK: string) => {
		const block = Jacy.content.state.blocks.get(blockPK);

		useInventoryStore.getState().toggle(true);
		useInventoryStore.getState().setCreateTab(CreateTab.ALL);
		useInventoryStore.getState().setSelectedAsset(blockPK);

		if (!block) return;

		Jacy.actions.block.editBlock(block.pk);
	},
	editScript: (scriptId: string) => {
		get().save();
		useEditor.getState().openScript(scriptId);
	},
	deleteBlockGroup: async (force = false) => {
		const { BB, gbi } = useEngineStore.getState().engine;
		const { blockGroupEditor } = get();

		if (!gbi || !BB || !blockGroupEditor) {
			return;
		}

		const deleted = await useEditor.getState().deleteBlockGroup(blockGroupEditor.id, force);

		if (!deleted) return;

		get().close();
	},
	deleteSceneTreeNode: async (force = false) => {
		const { sceneTreeNodeEditor } = get();
		if (!sceneTreeNodeEditor) return;

		const deleted = await useEditor.getState().deleteSceneTreeNode(sceneTreeNodeEditor.id, force);
		if (!deleted) return;

		get().close();
	},
	save: () => {
		const { blockGroupEditor, sceneTreeNodeEditor } = get();

		if (blockGroupEditor) {
			useEditor.getState().save(blockGroupEditor.id);
			useEditor.getState().closeTab(blockGroupEditor.id);
		}

		if (sceneTreeNodeEditor) {
			useEditor.getState().save(sceneTreeNodeEditor.id);
			useEditor.getState().closeTab(sceneTreeNodeEditor.id);
		}

		get().close();
	},
	cancel: () => {
		const { blockGroupEditor, isNewBlockGroup, deleteBlockGroup, close } = get();

		if (blockGroupEditor) {
			if (isNewBlockGroup) {
				deleteBlockGroup(true);
			} else {
				useEditor.getState().closeTab(blockGroupEditor.id, true);
			}
		}
		useInventoryStore.getState().playSound("snd-ui-wrench-menu-close");
		close();
	},
	openBlockGroupInEditor: () => {
		const { blockGroupEditor } = get();

		if (blockGroupEditor) {
			const id = blockGroupEditor.store.getState().state.id;

			useEditor.getState().openBlockGroup(id, true);
			useEditor.getState().setSideNav("instances");
		}

		set({
			blockGroupEditor: null,
		});

		get().close(false);
	},
	openSceneTreeNodeInEditor: () => {
		const { sceneTreeNodeEditor } = get();

		if (sceneTreeNodeEditor) {
			const id = sceneTreeNodeEditor.store.getState().state.id;

			useEditor.getState().openSceneTreeNode(id, true);
			useEditor.getState().setSideNav("instances");
		}

		set({
			sceneTreeNodeEditor: null,
		});

		get().close(false);
	},
	addGroupBlocksToExistingGroup: (toGroupId: string) => {
		const BB = useEngineStore.getState().engine.BB;
		const world = BB.world;
		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroups = gbi.base.BlockGroups;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;

		const fromGroupId = get().blockGroupEditor?.id;
		if (!fromGroupId) return;

		const blocks = BlockGroups.getBlocks(world.blockGroups, fromGroupId);

		BlockGroupsRouter.addBlocks({ id: toGroupId, blocks });

		BlockGroupsRouter.deleteGroup(fromGroupId);

		get().close();
	},
	createNewGroup: async (blocks) => {
		const gbi = useEngineStore.getState().engine.gbi;
		const BlockGroupsRouter = gbi.router.BlockGroupsRouter;

		const result = await BlockGroupsRouter.create({ name: null });

		if (!result.ok) return;

		const groupId = result.data.id;

		BlockGroupsRouter.addBlocks({ id: groupId, blocks });

		get().editGroup(groupId, true);
	},

	resetBulkSelection: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Wrench.resetBulkSelection(world.client.wrench, world);

		get().close();
	},
	removeSelectedBlockGroups: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Wrench.removeBlockGroups(world.client.wrench, world);

		get().close();
	},
	cycleTarget: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Wrench.cycleTarget(world.client.wrench, world);

		get().close();
	},

	close: (runSideEffects = true) => {
		const { lastOpenUI } = get();

		set({
			lastOpenUI: null,
			open: false,
			ui: undefined,
			blockGroupEditor: null,
			isRefreshing: false,
		});

		if (!runSideEffects) return;

		if (isNullish(useWrenchStore.getState().lastOpenUI)) {
			useControlsStore.getState().exitPointerLock(false);
		} else {
			if (lastOpenUI === "inventory") {
				useInventoryStore.getState().toggle(true);
			}
		}
	},
}));

export default {
	useWrench: useWrenchStore.getState,
};
