import { cn } from "@lib/helpers/cn";

const SIZES = {
	xs: "h-3",
	sm: "h-3.5",
	base: "h-4",
	lg: "h-5",
};

type IProps = React.ComponentProps<"div"> & {
	size?: keyof typeof SIZES;
};

export function Skeleton({ size = "base", className = "", ...props }: IProps) {
	return (
		<div
			className={cn("my-1 animate-pulse rounded bg-gray-200", SIZES[size], className)}
			{...props}
			aria-hidden="true"
		/>
	);
}
