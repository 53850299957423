import { create } from "zustand";

const DIALOGUE_AUTO_DISMISS_DELAY = 8_000;
let dialogueAutoDismissTimeout = null;

export const useDialogueStore = create((set, get) => ({
	dialogues: [],
	isRevealing: false,

	setDialogues: (dialogues) => {
		set({ dialogues, isRevealing: true });
		clearTimeout(dialogueAutoDismissTimeout);
	},
	dequeue: () => {
		if (get().dialogues.length === 0) return;

		set({
			dialogues: get().dialogues.slice(1),
			isRevealing: get().dialogues.length > 1,
		});
		clearTimeout(dialogueAutoDismissTimeout);
	},
	clear: () => set({ dialogues: [] }),
	stopRevealing: () => {
		set({ isRevealing: false });
		dialogueAutoDismissTimeout = setTimeout(get().dequeue, DIALOGUE_AUTO_DISMISS_DELAY);
	},
	continue: () => {
		if (get().isRevealing) {
			get().stopRevealing();
		} else {
			get().dequeue();
		}
	},
}));

export default {
	useDialogue: useDialogueStore.getState,
};
