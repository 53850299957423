import { BLOCK_CUBE } from "@jamango/engine/Runtime/base/world/block/Util.js";
import * as Pencil from "@jamango/engine/Runtime/client/world/tools/Pencil.ts";
import { create } from "zustand";
import { useEngineStore } from "./bb";
import { useControlsStore } from "./controls";
import type { SelectorTarget } from "@jamango/engine/Runtime/base/world/entity/component/CharacterSelector.js";
import { useWrenchStore } from "./wrench";

type PencilState = {
	open: boolean;
	modal: "selectionOptions" | null;
	mode: Pencil.PencilMode | null;
	setMode: (mode: Pencil.PencilMode) => void;
	showSelectionOptions: () => void;
	close: (runSideEffects?: boolean) => void;

	inspect: (target: SelectorTarget) => void;

	undo: () => void;
	redo: () => void;

	resetBulkSelection: () => void;
	createBlockStructureFromSelection: (includeBlockGroups?: Pencil.CopyBlockGroupsOption) => void;
	createPropInWorldFromSelection: () => void;
	removeAirFromSelection: () => void;
	cut: (cutBlockGroups?: Pencil.CopyBlockGroupsOption) => void;
	copy: (copyBlockGroups?: Pencil.CopyBlockGroupsOption) => void;
	pasteWithPreview: () => void;
	paste: () => void;
	solidFill: () => void;
	hollowFill: () => void;
	deleteSelection: () => void;
	rotateClipboard: () => void;
	flipClipboadByCameraDirection: () => void;
	addToSelection: () => void;

	clipboardHasContents: boolean;
	canUndo: boolean;
	canRedo: boolean;
	setClipboardHasContents: (clipboardHasContents: boolean) => void;
	setCanUndo: (canUndo: boolean) => void;
	setCanRedo: (canRedo: boolean) => void;
};

export const usePencilStore = create<PencilState>((set, get) => ({
	open: false,
	modal: null,
	mode: null,
	setMode: (mode) => {
		set({ mode });
	},

	showSelectionOptions: () => {
		set({ open: true, modal: "selectionOptions" });

		useControlsStore.getState().exitPointerLock(true);
	},

	close: (runSideEffects = true) => {
		set({ open: false, modal: null });

		if (!runSideEffects) return;

		useControlsStore.getState().exitPointerLock(false);
	},

	inspect: (target) => {
		get().close(false);

		useWrenchStore.getState().inspect(target);
	},

	solidFill: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		const selectedBlockPK = Pencil.getSelectedSlotPKIfBlockSelected(world);
		if (!selectedBlockPK) return;

		Pencil.solidFillSelection(world.client.pencil, world, BLOCK_CUBE, selectedBlockPK, false);

		get().close();
	},

	hollowFill: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		const selectedBlockPK = Pencil.getSelectedSlotPKIfBlockSelected(world);
		if (!selectedBlockPK) return;

		Pencil.hollowFillSelection(world.client.pencil, world, BLOCK_CUBE, selectedBlockPK, false);

		get().close();
	},

	deleteSelection: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.deleteSelection(world.client.pencil, world);

		get().close();
	},

	rotateClipboard: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.rotateClipboard(world.client.pencil, world, 0, 90, 0, "center");

		get().close();
	},

	flipClipboadByCameraDirection: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.flipClipboardByCameraDirection(world.client.pencil, world);

		get().close();
	},

	addToSelection: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.addToSelection(world.client.pencil, world);

		get().close();
	},

	undo: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.undo(world.client.pencil, world);

		get().close();
	},

	redo: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.redo(world.client.pencil, world);

		get().close();
	},

	resetBulkSelection: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.resetPencil(world.client.pencil, world);

		get().close();
	},

	createBlockStructureFromSelection: (includeBlockGroups = false) => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		const blocks = Pencil.getSelectorBlocks(world, false, includeBlockGroups);
		Pencil.createBlockStructureFromBlocks(world.client.pencil, world, blocks);
		Pencil.resetPencil(world.client.pencil, world);
		Pencil.displayNotification(world, `Blueprint of ${blocks.data.blocks.length} blocks created!`);

		get().close();
	},

	createPropInWorldFromSelection: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.createPropInWorldFromSelection(world.client.pencil, world);
		Pencil.resetPencil(world.client.pencil, world);
		Pencil.displayNotification(world, "Prop created!");

		get().close();
	},

	removeAirFromSelection: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.removeAirFromSelection(world.client.pencil, world);

		get().close();
	},

	cut: (copyBlockGroups = false) => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.cut(world.client.pencil, world, false, copyBlockGroups);

		get().close();
	},

	copy: (copyBlockGroups = false) => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.copy(world.client.pencil, world, false, copyBlockGroups);

		get().close();
	},

	pasteWithPreview: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.pasteWithPreview(world.client.pencil, world);

		get().close();
	},

	paste: () => {
		const { world } = useEngineStore.getState();
		if (!world) return;

		Pencil.paste(world.client.pencil, world);

		get().close();
	},

	// current mode of the pencil tool. only valid when equippedItem === ItemPencil
	clipboardHasContents: false,
	canUndo: false,
	canRedo: false,

	setClipboardHasContents: (clipboardHasContents) => {
		set({ clipboardHasContents });
	},

	setCanUndo: (canUndo) => {
		set({ canUndo });
	},

	setCanRedo: (canRedo) => {
		set({ canRedo });
	},
}));

export default {
	usePencil: usePencilStore.getState,
};
