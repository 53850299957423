import { cn } from "@lib/helpers/cn";

type IProps = {
	className?: string;
	title: React.ReactNode;
	titleClassName?: string;
	description?: React.ReactNode;
	descriptionClassName?: string;
	main?: React.ReactNode;
	colBreakpoint?: "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
	expandedContent?: React.ReactNode;
};

export function InventoryFormSection({
	className,
	title,
	titleClassName,
	description,
	descriptionClassName,
	main,
	colBreakpoint,
	expandedContent,
}: IProps) {
	const titleClass = cn("font-medium sm:sm-h:text-lg", titleClassName);

	return (
		<div className="flex flex-col gap-2 px-3 py-2.5 sm:px-4 sm-h:gap-3 sm-h:py-4">
			<div
				className={cn(
					"flex justify-between gap-1 sm-h:gap-2",
					!colBreakpoint && "flex-row items-center",
					colBreakpoint === "2xs" && "flex-col 2xs:flex-row 2xs:items-center",
					colBreakpoint === "xs" && "flex-col xs:flex-row xs:items-center",
					colBreakpoint === "sm" && "flex-col sm:flex-row sm:items-center",
					colBreakpoint === "md" && "flex-col md:flex-row md:items-center",
					colBreakpoint === "lg" && "flex-col lg:flex-row lg:items-center",
					colBreakpoint === "xl" && "flex-col xl:flex-row xl:items-center",
					colBreakpoint === "2xl" && "flex-col 2xl:flex-row 2xl:items-center",
					className,
				)}
			>
				{description ? (
					<div className="flex flex-col justify-between">
						<div className={titleClass}>{title}</div>
						<div
							className={cn(
								"text-sm font-normal text-slate-500 sm:sm-h:text-base",
								descriptionClassName,
							)}
						>
							{description}
						</div>
					</div>
				) : (
					<div className={titleClass}>{title}</div>
				)}
				{main}
			</div>
			{expandedContent}
		</div>
	);
}
