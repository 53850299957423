import type { IBundle, IParsedSkybox, ISkybox, SkyboxSide } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";

export class JacySkyboxState {
	#state: JacyContentState;
	#skyboxes: Record<string, ISkybox>;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#skyboxes = {};
	}

	import(data?: IBundle["assets"]["skyboxes"]) {
		if (!data) return;

		for (const skybox of Object.values(data)) {
			this.set(skybox);
		}
	}

	export() {
		return this.#skyboxes;
	}

	getAll() {
		return Object.values(this.#skyboxes);
	}

	useSideResource(side: SkyboxSide, resourcePk: string) {
		const skybox = this.#getSkybox();

		if (!skybox) {
			throw new Error("Can't set skybox, world is not loaded yet.");
		}

		const sideResourceKey = `${side}ResourcePk` as const;
		const sideResourceId = skybox[sideResourceKey];

		const existingResource = this.#state.resources.get(sideResourceId);

		if (existingResource) {
			this.#state.resources.remove(existingResource.pk);
		}

		this.set({
			...skybox,
			[sideResourceKey]: resourcePk,
		});
	}

	#getSkybox(): ISkybox | null {
		const all = [...Object.values(this.#skyboxes)];

		const used = all.find((s) => s.isUsed);

		if (used) {
			return used;
		}

		if (all.length <= 1) {
			const first = all[0];

			first.isUsed = true;
			return first;
		}

		return null;
	}

	get(pk: string) {
		return this.#skyboxes[pk];
	}

	getParsed(pk: string): IParsedSkybox | null {
		const skybox = this.get(pk);

		if (!skybox) {
			return null;
		}

		const nxResource = this.#state.resources.get(skybox.nxResourcePk);
		const nyResource = this.#state.resources.get(skybox.nyResourcePk);
		const nzResource = this.#state.resources.get(skybox.nzResourcePk);
		const pxResource = this.#state.resources.get(skybox.pxResourcePk);
		const pyResource = this.#state.resources.get(skybox.pyResourcePk);
		const pzResource = this.#state.resources.get(skybox.pzResourcePk);

		if (!nxResource || !nyResource || !nzResource || !pxResource || !pyResource || !pzResource) {
			return null;
		}

		return {
			pk: skybox.pk,
			type: skybox.type,
			name: skybox.name,
			nxName: nxResource?.file.name,
			nxUrl: nxResource?.file.url,
			nyName: nyResource?.file.name,
			nyUrl: nyResource?.file.url,
			nzName: nzResource?.file.name,
			nzUrl: nzResource?.file.url,
			pxName: pxResource?.file.name,
			pxUrl: pxResource?.file.url,
			pyName: pyResource?.file.name,
			pyUrl: pyResource?.file.url,
			pzName: pzResource?.file.name,
			pzUrl: pzResource?.file.url,
			isUsed: skybox.isUsed,
			packageId: skybox.packageId,
		};
	}

	set(data: ISkybox) {
		this.#skyboxes[data.pk] = structuredClone(data);

		this.#state.markDirty();

		return data.pk;
	}
}
