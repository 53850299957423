import { DotsSix, X } from "@components/icons";
import { cn } from "@lib/helpers/cn";
import * as Popover from "@radix-ui/react-popover";
import type { ReactNode } from "react";
import { forwardRef, useRef } from "react";

type IProps = {
	children: ReactNode;
	open: boolean;
	setOpen: (open: boolean) => void;
	variant?: "default" | "base";
};

export const Panel = forwardRef<HTMLDivElement, IProps>(function MainPanel(
	{ children, open, setOpen, variant = "default" },
	ref: any,
) {
	const panelRef = useRef<HTMLDivElement>(null);
	const offset = useRef({ x: 20, y: 20 });

	const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
		if (ref) {
			if (!ref.current) return;

			offset.current = {
				x: e.clientX - ref.current.getBoundingClientRect().left,
				y: e.clientY - ref.current.getBoundingClientRect().top,
			};
		} else {
			if (!panelRef.current) return;
			offset.current = {
				x: e.clientX - panelRef.current.getBoundingClientRect().left,
				y: e.clientY - panelRef.current.getBoundingClientRect().top,
			};
		}
		document.addEventListener("pointermove", handlePointerMove);
		document.addEventListener("pointerup", handlePointerUp);
	};

	const handlePointerMove = (e: React.PointerEvent<HTMLDivElement> | PointerEvent) => {
		if (ref) {
			if (!ref.current) return;
			ref.current.style.transform = `translate(${e.clientX - offset.current.x}px, ${e.clientY - offset.current.y}px)`;
		} else {
			if (!panelRef.current) return;
			panelRef.current.style.transform = `translate(${e.clientX - offset.current.x}px, ${e.clientY - offset.current.y}px)`;
		}
	};

	const handlePointerUp = () => {
		document.removeEventListener("pointermove", handlePointerMove);
		document.removeEventListener("pointerup", handlePointerUp);
	};

	return (
		<Popover.Root open={open}>
			<Popover.Trigger className="hidden" /* required even if nothing is shown */ />
			<Popover.Portal>
				<Popover.Content align="start" side="bottom" sideOffset={8} className="relative !z-[10000]">
					{ref ? (
						<div onPointerDown={handlePointerDown} ref={ref}>
							{children}
						</div>
					) : (
						<>
							<div
								ref={panelRef}
								className={cn(
									variant === "default" &&
										"relative z-10 max-h-[80vh] min-h-[100px] w-[400px] select-none resize overflow-y-auto rounded bg-gray-900/40 text-white backdrop-blur-sm",
									variant === "base" && "right-0 top-0 h-auto w-auto",
								)}
								style={{ transform: "translate(20px, 20px)" }}
							>
								<div
									className="flex cursor-move justify-between rounded-t bg-gray-800/40 p-2"
									onPointerDown={handlePointerDown}
								>
									<DotsSix />
									<div onClick={() => setOpen(false)}>
										<X />
									</div>
								</div>
								<div className="p-4">{children}</div>
							</div>
						</>
					)}
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
});
