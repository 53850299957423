import { X, CheckCircle } from "@components/icons";

type Props = {
	message?: string;
	dismiss?: () => void;
	className?: string;
};
export function SuccessNote({ message, className = "", dismiss }: Props) {
	if (!message?.length) return null;

	return (
		<div className={"my-1 flex items-center gap-2 rounded-md bg-green-600 px-4 py-3 " + className}>
			<CheckCircle className="h-max w-auto text-white" aria-hidden="true" />
			<p className="flex-1 text-base text-white">{message}</p>
			{!!dismiss && (
				<button
					type="button"
					className="rounded bg-transparent px-1.5 py-1 text-white hover:bg-green-600 focus:bg-green-600"
					onClick={dismiss}
				>
					<X className="size-5" aria-hidden="true" />
					<span className="sr-only">Dismiss</span>
				</button>
			)}
		</div>
	);
}
