import { create } from "zustand";
import { generateUUID } from "@jamango/content-client";
import { useEngineStore } from "./bb";
import { useControlsStore } from "./controls";
import { isAnyModalOpen } from "@lib/helpers/isAnyModalOpen";

export const CHAT_HISTORY_LIMIT = 100;
export const LATEST_CHAT_MESSAGES_COUNT = 3;

export const useChatStore = create((set, get) => ({
	open: false,
	latestMessages: [],
	messages: [],

	// Handlers
	openChat: () => {
		if (isAnyModalOpen()) return;

		get().setOpen(true);
	},
	close: () => set({ open: false }),
	setOpen: (open, delay = false) => {
		if (open && isAnyModalOpen()) return;

		set({ open });

		setTimeout(
			() => {
				useControlsStore.getState().exitPointerLock(open);
			},
			delay ? 200 : 0,
		);
	},

	sendChat: (message) => {
		if (!message) return;

		const { gbi, BB } = useEngineStore.getState().engine;
		gbi.client.sendChat(BB.world, message);
		set({ open: false });
		useControlsStore.getState().exitPointerLock(false);
	},
	displayChat: ({ time, username, message, color }) => {
		set((state) => {
			const messages = [
				...state.messages,
				{
					id: generateUUID(),
					time,
					username,
					message,
					timestamp: Date.now(),
					color,
				},
			];

			return {
				messages,
				latestMessages: messages.slice(-LATEST_CHAT_MESSAGES_COUNT),
			};
		});
	},
}));

export default {
	useChat: useChatStore.getState,
};
