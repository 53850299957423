import { getStaticFile } from "@jamango/content-client";

// The original version of this font is vertically offset too high.
// To fix the font, go to https://www.fontsquirrel.com/tools/webfont-generator
// Keep Optimal mode

export default `
  @font-face {
    font-family: 'ClashGrotesk';
    src: url(${getStaticFile("assets/frontend/fonts/clash-grotesk-extralight.woff2")}) format('woff2');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ClashGrotesk';
    src: url(${getStaticFile("assets/frontend/fonts/clash-grotesk-light.woff2")}) format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ClashGrotesk';
    src: url(${getStaticFile("assets/frontend/fonts/clash-grotesk-regular.woff2")}) format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ClashGrotesk';
    src: url(${getStaticFile("assets/frontend/fonts/clash-grotesk-medium.woff2")}) format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ClashGrotesk';
    src: url(${getStaticFile("assets/frontend/fonts/clash-grotesk-semibold.woff2")}) format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ClashGrotesk';
    src: url(${getStaticFile("assets/frontend/fonts/clash-grotesk-bold.woff2")}) format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
`;
