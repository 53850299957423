import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useEvent } from "./useEvent";
import { useUnmounted } from "./useUnmounted";
import type {
	ProtocolError,
	PubEventByName,
	PubNames,
	PubParamsByNameAsArgs,
	ClientSubscription,
} from "@jamango/content-service/types/index.ts";
import { ws } from "rest-client";
import { useRender } from "./useRender";
import { createLogger } from "@jamango/helpers";

const logger = createLogger("useSub");

const noop = () => {};

type SubOptions<T extends PubNames> = {
	onEvent?: (event: PubEventByName<T>) => void;
	onDispose?: () => void;
	onError?: (error: ProtocolError) => void;
};

/**
 * Subscribes to a publication.
 * The subscription will be automatically unsubscribed when the component is unmounted
 * and automatically re-subscribed when the connection type changed from offline to online.
 *
 * @param {PubNames} pubName The name of the publication.
 * @param {SubOptions<T>} [options] The options for the subscription.
 * @param {...PubParamsByNameAsArgs<T>} params The parameters for the subscription.
 * @returns {ClientSubscription<T> | null} The subscription or null if the subscription failed.
 *
 * @example
 * import { useSub } from "@jamango/react";
 *
 * const sub = useSub("myPub", {
 *   onEvent: (event) => console.log(event),
 *   onDispose: () => console.log("Disposed"),
 *   onError: (error) => console.error(error),
 * }, "foo", "bar");
 *
 * // sub is now an instance of ClientSubscription
 */
export const useSub = <T extends PubNames>(
	pubName: T,
	options: SubOptions<T> = {},
	...params: PubParamsByNameAsArgs<T>
): ClientSubscription<T> | null => {
	const unmounted = useUnmounted();
	const eventCallback = useEvent(options.onEvent ?? noop);
	const disposeCallback = useEvent(options.onDispose ?? noop);
	const errorCallback = useEvent(options.onError ?? noop);
	const [_, setState] = useState<{ id: string | null }>({
		id: null,
	});

	const subRef = useRef<ClientSubscription<T>>(null);
	const { renderId, refresh } = useRender();

	useEffect(() => {
		logger.debug(
			`[${pubName}] render, status: ${ws.client.connectionType}, unmounted: ${unmounted.current}`,
		);
		subRef.current?.unsubscribe();

		if (unmounted.current) return;

		if (ws.client.connectionType !== "online") return;

		const sub = ws.client.subscribe(pubName, ...params);
		subRef.current = sub;

		sub.listen((event) => {
			logger.debug(`[${pubName}] received event: ${JSON.stringify(event)}`);
			eventCallback(event);
		});
		sub.onDispose(() => {
			logger.debug(`[${pubName}] disposed`);

			disposeCallback();
			subRef.current = null;
			setState({ id: null });
		});
		sub.onError(errorCallback);

		// trigger rerender
		setState({
			id: sub.id,
		});
	}, [renderId, pubName, eventCallback, errorCallback, disposeCallback, ...params]);

	useLayoutEffect(() => {
		const d = ws.client.onConnectionChanged.add(() => {
			if (ws.client.connectionType === "online") {
				logger.debug(`[${pubName}] We are online, lets re-subscribe`);
				refresh();
			}
		});

		return () => {
			d();
			subRef.current?.unsubscribe();
			logger.debug(`[${pubName}] has been disposed`);
		};
	}, [pubName, refresh]);

	return subRef.current;
};
