import { Jacy } from "@jacy-client";
import type { IAssetKey, IJacyContent } from "@jamango/content-client";
import { AvatarType, getIdentifier, MAX_AVATARS } from "@jamango/content-client";
import { createRequestPromise, type IPromise } from "@jamango/helpers";
import { trackEvent } from "@lib/helpers/analytics/analyzeMe";
import { useAuthDialogStore } from "@stores/auth-user";
import { useEngineStore } from "@stores/bb";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";
import { useJacyCharacterEditorStore } from "@stores/jacy/character-editor";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { useAuthUserStore } from "@stores/auth-user";
import { uploadAndCreateResource, uploadResource } from "../../upload/upload-resource";
import type { JacyActions } from "../JacyActions";
import { createUserAvatar, deleteUserAvatar, setAsUserAvatar, updateUserAvatar } from "rest-client";
import { setLocalPlayerAvatar } from "@jamango/engine/Runtime/mods/defs/CharacterDefault/Character.js";

export class JacyAvatarActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];
	editorLoadedPromise: IPromise<boolean>;

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
		this.editorLoadedPromise = createRequestPromise<boolean>();
	}

	setEditorAsLoaded() {
		this.editorLoadedPromise.resolve(true);
		useJacyAvatarEditorStore.getState().setLoading(false);
	}

	newAvatar(isCharacterEditor?: boolean) {
		try {
			if (!isCharacterEditor && !useAuthUserStore.getState().userId) {
				useAuthDialogStore.getState().openAlphaModal();
				return;
			}

			if (!this.canSave(isCharacterEditor)) {
				throw new Error("User is not allowed to create a new avatar.");
			}

			useJacyAvatarEditorStore.getState().newAvatar(isCharacterEditor);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async createAvatar() {
		try {
			const userId = useAuthUserStore.getState().userId;

			if (!userId) {
				throw new Error("User is not logged in.");
			}

			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor = useJacyAvatarEditorStore.getState().isCharacterEditor;

			if (!isCharacterEditor) {
				throw new Error("Must be in the character editor to use this API.");
			}

			if (!this.canSave(isCharacterEditor)) {
				throw new Error("User is not allowed to create a new avatar.");
			}

			if (!isCharacterEditor && this.getUserAvatars().length >= MAX_AVATARS) {
				throw new Error("You already exceed the maximum number of avatars available");
			}

			const newAvatar = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!newAvatar.thumbnail || !newAvatar.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			const [thumbnailFileResult, photoFileResult] = await Promise.all([
				this.#state.resources.processAvatarThumbnail(newAvatar.thumbnail),
				this.#state.resources.processAvatarPhoto(newAvatar.displayPhoto),
			]);

			const [thumbnailResource, photoResource] = await Promise.all([
				uploadAndCreateResource(Jacy, thumbnailFileResult),
				uploadAndCreateResource(Jacy, photoFileResult),
			]);

			const id = this.#state.avatars.createId();

			const avatar = this.#state.avatars.create(
				id,
				userId,
				AvatarType.CHARACTER,
				newAvatar.config,
				thumbnailResource.pk,
				photoResource.pk,
			);

			this.#state.avatars.set(avatar);

			trackEvent("event", "save_new_avatar", { avatar_id: avatar.pk });

			useJacyCharacterEditorStore.getState().setAvatar(avatar.pk);
			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			this.#actions.setSuccess(`Avatar "${avatar.name}" created successfully.`);

			return avatar.pk;
		} catch (error) {
			useJacyAvatarEditorStore.getState().setLoading(false);
			this.#actions.setError(error);
		}
	}

	async createUserAvatarAPI() {
		try {
			const userId = useAuthUserStore.getState().userId;

			if (!userId) {
				throw new Error("User is not set.");
			}

			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor = useJacyAvatarEditorStore.getState().isCharacterEditor;

			if (isCharacterEditor) {
				throw new Error("Must be in the avatar editor to use this API.");
			}

			const newAvatar = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!newAvatar.thumbnail || !newAvatar.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			const thumbnailFileResult = await this.#state.resources.processAvatarThumbnail(
				newAvatar.thumbnail,
			);

			const photoFileResult = await this.#state.resources.processAvatarPhoto(newAvatar.displayPhoto);

			const { resourceId: thumbnailResourceId } = await uploadResource(Jacy, thumbnailFileResult);

			const { resourceId: photoResourceId } = await uploadResource(Jacy, photoFileResult);

			const avatarPackageResponse = await createUserAvatar({
				thumbnailResourceId,
				photoResourceId,
				config: newAvatar.config,
			});

			if (!avatarPackageResponse.data) throw new Error(`Error while creating user avatar`);

			const avatarPackage = avatarPackageResponse.data.bundle;

			this.#actions.content.importPackage(avatarPackage);

			const unparsedAvatar = Object.values(avatarPackage.assets.avatars ?? {})[0];

			if (!unparsedAvatar) throw new Error(`no avatar`);

			const avatarId = unparsedAvatar.id;

			const userAvatar = this.getUserAvatarById(avatarId);

			if (!userAvatar) throw new Error(`no user avatar`);

			useAuthUserStore.setState({
				globalAvatarId: avatarId,
				photoUrl: userAvatar.displayPhotoResource.file.url,
			});

			trackEvent("event", "save_new_avatar", { avatar_id: unparsedAvatar.pk });

			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			this.#actions.setSuccess(`Avatar "${unparsedAvatar.name}" created successfully.`);

			this.loadAvatarInGame();

			return unparsedAvatar.pk;
		} catch (error) {
			useJacyAvatarEditorStore.getState().setLoading(false);

			this.#actions.setError(error);
		}
	}

	editAvatar(pk: IAssetKey, isCharacterEditor?: boolean) {
		try {
			if (!this.canEdit(pk, isCharacterEditor)) {
				throw new Error("User is not allowed to edit the avatar.");
			}

			const avatar = this.#state.avatars.get(pk);

			if (!avatar) {
				this.#actions.setError("Avatar not found");
				return;
			}

			useJacyAvatarEditorStore.getState().editAvatar(avatar, isCharacterEditor);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async updateAvatar() {
		try {
			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor = useJacyAvatarEditorStore.getState().isCharacterEditor;

			if (!isCharacterEditor) {
				throw new Error("Must be in the character editor to use this API.");
			}

			const updatedAvatar = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!updatedAvatar.thumbnail || !updatedAvatar.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			if (!updatedAvatar.pk) {
				throw new Error("Avatar id is not set.");
			}

			if (!this.canEdit(updatedAvatar.pk, isCharacterEditor)) {
				throw new Error("User is not allowed to update the avatar.");
			}

			const [thumbnailFileResult, photoFileResult] = await Promise.all([
				this.#state.resources.processAvatarThumbnail(updatedAvatar.thumbnail),
				this.#state.resources.processAvatarPhoto(updatedAvatar.displayPhoto),
			]);

			const oldAvatar = this.#state.avatars.get(updatedAvatar.pk);

			if (oldAvatar) {
				this.#state.resources.remove(oldAvatar.displayPhotoResourcePk);
				this.#state.resources.remove(oldAvatar.thumbnailResourcePk);
			}

			const [thumbnailResource, photoResource] = await Promise.all([
				uploadAndCreateResource(Jacy, thumbnailFileResult),
				uploadAndCreateResource(Jacy, photoFileResult),
			]);

			const avatar = this.#state.avatars.update(
				updatedAvatar.pk,
				updatedAvatar.config,
				thumbnailResource.pk,
				photoResource.pk,
				oldAvatar?.name ?? "",
			);

			trackEvent("event", "update_avatar", { avatar_id: avatar.pk });

			useJacyCharacterEditorStore.getState().setAvatar(avatar.pk);
			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			this.#actions.setSuccess(`Avatar "${avatar.name}" updated successfully.`);

			return avatar.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async updateUserAvatarAPI() {
		try {
			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor = useJacyAvatarEditorStore.getState().isCharacterEditor;
			const avatarToUpdate = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!avatarToUpdate.thumbnail || !avatarToUpdate.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			if (!avatarToUpdate.pk) {
				throw new Error("Avatar id is not set.");
			}

			const oldAvatar = this.#state.avatars.getUnparsed(avatarToUpdate.pk);

			if (!oldAvatar) {
				throw new Error("Avatar not found");
			}

			const avatarId = oldAvatar.id;

			const thumbnailFileResult = await this.#state.resources.processAvatarThumbnail(
				avatarToUpdate.thumbnail,
			);

			const photoFileResult = await this.#state.resources.processAvatarPhoto(
				avatarToUpdate.displayPhoto,
			);

			const { resourceId: thumbnailResourceId } = await uploadResource(Jacy, thumbnailFileResult);

			const { resourceId: photoResourceId } = await uploadResource(Jacy, photoFileResult);

			const avatarPackageResponse = await updateUserAvatar({
				id: avatarId,
				thumbnailResourceId,
				photoResourceId,
				config: avatarToUpdate.config,
			});

			if (!avatarPackageResponse.data) throw new Error(`Error while creating user avatar`);

			const avatarPackage = avatarPackageResponse.data.bundle;

			this.#actions.content.importPackage(avatarPackage);

			const userAvatar = this.getUserAvatarById(avatarId);

			if (!userAvatar) {
				throw new Error(`User Avatar not found`);
			}

			useAuthUserStore.setState({
				globalAvatarId: avatarId,
				photoUrl: userAvatar.displayPhotoResource.file.url,
			});

			trackEvent("event", "update_avatar", { avatar_id: userAvatar.pk });

			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			this.#actions.setSuccess(`Avatar "${userAvatar.name}" updated successfully.`);

			if (isCharacterEditor) {
				useJacyCharacterEditorStore.getState().setAvatar(userAvatar.pk);
			}

			this.loadAvatarInGame();

			return userAvatar.pk;
		} catch (error) {
			useJacyAvatarEditorStore.getState().setLoading(false);
			this.#actions.setError(error);
		}
	}

	updateAvatarName(pk: IAssetKey, name: string) {
		try {
			if (!this.canEdit(pk, true)) {
				throw new Error("User is not allowed to update this avatar.");
			}

			this.#state.avatars.setName(pk, name);

			this.#actions.setSuccess(`Avatar "${name}" has been updated successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async updateUserAvatarNameAPI(pk: IAssetKey, name: string) {
		try {
			const avatar = this.#state.avatars.setName(pk, name);
			await updateUserAvatar({
				id: avatar.id,
				name,
			});

			this.#actions.setSuccess(`Avatar "${name}" has been updated successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async setAsUserAvatarAPI(pk: IAssetKey) {
		try {
			const { userId, globalAvatarId } = useAuthUserStore.getState();

			if (!userId) {
				throw new Error("User is not set.");
			}

			const avatarId = getIdentifier(pk);

			const userAvatar = this.getUserAvatarById(avatarId);

			if (!userAvatar) {
				throw new Error(`User avatar not found`);
			}

			if (globalAvatarId === userAvatar.id) return;

			await setAsUserAvatar(avatarId);

			useAuthUserStore.setState({
				globalAvatarId: avatarId,
				photoUrl: userAvatar.displayPhotoResource.file.url,
			});

			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess(`Avatar "${userAvatar.name}" has been selected successfully.`);

			this.loadAvatarInGame();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	restoreAvatar(pk: IAssetKey) {
		try {
			if (!this.canEdit(pk, true)) {
				throw new Error("User is not allowed to restore this avatar.");
			}

			this.#state.avatars.restore(pk);

			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess("Avatar has been restored successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteAvatar(pk: IAssetKey) {
		try {
			const avatar = this.#state.avatars.get(pk);

			if (!avatar) {
				throw new Error("Avatar not found");
			}

			if (!this.canDelete(pk, true)) {
				throw new Error("User is not allowed to delete this avatar.");
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Avatar",
				description: `Are you sure you want to delete "${avatar.name}" avatar?`,
				confirmText: "Yes, delete this avatar.",
			});

			if (!confirmed) return;

			this.#state.avatars.delete(pk);

			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess(`Avatar "${avatar.name}" has been deleted successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteUserAvatarAPI(pk: IAssetKey) {
		try {
			const avatar = this.#state.avatars.get(pk);

			if (!avatar) {
				throw new Error("Avatar not found");
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Avatar",
				description: `Are you sure you want to delete "${avatar.name}" avatar?`,
				confirmText: "Yes, delete this avatar.",
			});

			if (!confirmed) return;

			const response = await deleteUserAvatar(avatar.id);

			if (!response.data) throw new Error(`Error while removing avatar`);
			const { nextAvatarId } = response.data;

			this.#state.avatars.delete(pk);

			if (nextAvatarId) {
				const userAvatar = this.getUserAvatarById(nextAvatarId);

				useAuthUserStore.setState({
					globalAvatarId: nextAvatarId,
					photoUrl: userAvatar?.displayPhotoResource.file.url ?? null,
				});
			} else {
				useAuthUserStore.setState({
					globalAvatarId: null,
					photoUrl: null,
				});
			}

			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess("Avatar has been deleted successfully.");

			this.loadAvatarInGame();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	loadAvatarInGame() {
		const { gbi } = useEngineStore.getState().engine;
		if (!gbi) return;

		const { globalAvatarId, getAvatarObject } = useAuthUserStore.getState();
		const avatarObject = getAvatarObject();

		setLocalPlayerAvatar(globalAvatarId, avatarObject);
	}

	hasAccess(pk: IAssetKey) {
		const avatar = this.#state.avatars.get(pk);
		if (!avatar) return false;

		const userId = useAuthUserStore.getState().userId;
		if (!userId) return false;

		if (avatar.avatarType === AvatarType.CHARACTER) {
			return true;
		}

		// Prevent updating other user's avatar
		return avatar.owner === userId;
	}

	canEdit(pk: IAssetKey, isCharacterEditor?: boolean) {
		if (isCharacterEditor) {
			return useAuthUserStore.getState().isCreator;
		}

		return this.hasAccess(pk);
	}

	canDelete(pk: IAssetKey, isCharacterEditor?: boolean) {
		if (isCharacterEditor) {
			return useAuthUserStore.getState().isCreator;
		}

		return this.hasAccess(pk);
	}

	canSave(isCharacterEditor?: boolean) {
		const { userId, isCreator } = useAuthUserStore.getState();
		if (isCharacterEditor) {
			return isCreator;
		}

		return !!userId;
	}

	getUserAvatars() {
		const userId = useAuthUserStore.getState().userId;
		if (!userId) return [];

		return this.#state.avatars
			.getAll()
			.filter(
				(avatar) =>
					avatar.avatarType === AvatarType.USER_AVATAR &&
					!avatar.isDeleted &&
					avatar.owner === userId,
			);
	}

	getUserAvatarById(id: string) {
		return this.getUserAvatars().find((a) => a.id === id);
	}

	getNextAvatar(pk: IAssetKey) {
		return this.getUserAvatars().find((avatar) => !avatar.isDeleted && avatar.pk !== pk);
	}
}
