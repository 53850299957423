import { useMobileStore } from "@stores/game-client/mobile";
import { mobileMedia } from "@lib/helpers/mobile";
import { isMobileBrowser } from "@jamango/helpers";

export const state = {
	isMobile: isMobileBrowser(),
};

export function init() {
	const handleDeviceTypeChange = () => {
		state.isMobile = isMobileBrowser();
	};

	// @ts-expect-error - currently unused
	mobileMedia()?.addEventListener("change", handleDeviceTypeChange);
	useMobileStore.setState({ isMobile: state.isMobile });
}
