import React from "react";
import { getHash } from "@jamango/content-client";
import { GameClient } from "@jamango/client";
import { Jacy } from "@jacy-client";

import { LOADING_TEXT } from "@stores/game-client/game-client";
import { useGameLoading } from "@lib/hooks/game-client/useGameLoading";
import { formatDate } from "@lib/helpers/date";

import { GlobeHemisphereWest, LockKey, PlayCircle, ShareNetwork } from "@components/icons";
import { Button } from "@components/ui/Button";
import { Tooltip } from "@components/ui/Tooltip";
import { Skeleton } from "@components/ui/Skeleton";
import { PublishStatusTag } from "@components/ui/PublishStatusTag";
import { useJacyWorldVersionState } from "@lib/hooks/jacy/useJacyContent";
import type { IPublicWorld } from "@jamango/content-service/types/index.ts";

type IProps = {
	world: IPublicWorld;
};

export function HubWorldVersionCard({ world }: IProps) {
	const gameLoading = useGameLoading();
	const currentlyLoadedVersion = useJacyWorldVersionState();
	const thumbnailUrl = world.thumbnailFile?.name;
	const [loading, setLoading] = React.useState<"set_as_live" | "unpublish" | boolean>(false);

	const disableButtons = !!loading || !!gameLoading;

	const handleUnpublishWorld = () => {
		setLoading("unpublish");
		Jacy.actions.worldData.unpublishWorld(world.id);
		setLoading(false);
	};

	const handleSetAsLive = () => {
		setLoading("set_as_live");
		Jacy.actions.worldData.setWorldVersionAsLive(world.id, world.version.id);
		setLoading(false);
	};

	const handleLoadWorld = () => {
		GameClient.loadWorld(world.shortCode, {
			versionId: world.version.id,
		});
	};

	return (
		<li key={world.id} className="flex flex-col justify-between gap-4 p-4 xs:flex-row sm:items-center">
			{thumbnailUrl ? (
				<div className="size-16 rounded-md">
					<img src={thumbnailUrl} alt="thumbnail" className="size-full rounded-md  object-cover" />
				</div>
			) : (
				<div
					aria-hidden="true"
					className="hidden rounded-md border-b border-gray-300 bg-gray-100 p-4 text-gray-900 sm:block"
				>
					<GlobeHemisphereWest className="size-8" />
				</div>
			)}
			<div className="flex-1">
				<div className="text-xl font-medium text-gray-900">
					{world.name}
					{world.version.isLive && (
						<span className="ml-2 rounded bg-emerald-200 px-1 py-0.5 font-sans text-xs font-semibold text-emerald-900">
							Live
						</span>
					)}
					{!world.version.isLive && <PublishStatusTag status={world.publish.status} />}
					{currentlyLoadedVersion?.id === world.version.id && (
						<span className="ml-2 rounded bg-blue-200 px-1 py-0.5 font-sans text-xs font-semibold text-blue-900">
							Currently loaded
						</span>
					)}
					{world.version.saveType === "autosave" && (
						<span className="ml-2 rounded bg-indigo-200 px-1 py-0.5 font-sans text-xs font-semibold text-blue-900">
							Auto save
						</span>
					)}
					{world.version.saveType === "manual" && (
						<span className="ml-2 rounded bg-indigo-200 px-1 py-0.5 font-sans text-xs font-semibold text-blue-900">
							Manual save
						</span>
					)}
				</div>
				<p className="text-sm text-gray-600">
					Version: {getHash(world.version.id)}&nbsp;&middot; Updated at{" "}
					{formatDate(new Date(world.version.updatedAt))}
				</p>
			</div>
			<div className="flex flex-col gap-2 2xl:flex-row 2xl:items-center">
				<div className="flex gap-2">
					{world.version.isLive && (
						<Tooltip content={gameLoading ? LOADING_TEXT[gameLoading] : null}>
							<Button
								size="md"
								color="white"
								onClick={handleUnpublishWorld}
								className="grow"
								disabled={disableButtons}
							>
								<LockKey className="hidden size-5 xs:block" aria-hidden="true" />
								{loading === "unpublish" ? "Unpublishing world..." : "Unpublish world"}
							</Button>
						</Tooltip>
					)}
					{world.publish.status === "approved" && !world.version.isLive && (
						<Tooltip content={gameLoading ? LOADING_TEXT[gameLoading] : null}>
							<Button
								size="md"
								color="white"
								onClick={handleSetAsLive}
								className="grow"
								disabled={disableButtons}
							>
								<ShareNetwork className="hidden size-5 xs:block" aria-hidden="true" />
								{loading === "set_as_live" ? "Setting version as live..." : "Set as live"}
							</Button>
						</Tooltip>
					)}
					<Tooltip content={gameLoading ? LOADING_TEXT[gameLoading] : null}>
						<Button
							size="md"
							onClick={handleLoadWorld}
							className="grow"
							disabled={disableButtons}
						>
							<PlayCircle className="hidden size-5 xs:block" aria-hidden="true" />
							Load version
						</Button>
					</Tooltip>
				</div>
			</div>
		</li>
	);
}

export function SkeletonHubWorldVersionCard() {
	return (
		<div
			aria-hidden="true"
			className="flex flex-col items-center justify-between gap-4 rounded border-b-2 border-gray-400 p-4 shadow md:flex-row"
		>
			<div
				aria-hidden="true"
				className="rounded border-b-2 border-gray-400 bg-gray-200 p-4 text-gray-900"
			>
				<GlobeHemisphereWest className="size-6" />
			</div>
			<div className="flex-1">
				<Skeleton className="w-2/5" />
				<Skeleton size="sm" className="w-4/5" />
			</div>
		</div>
	);
}
