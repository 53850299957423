import { Jacy } from "@jacy-client";
import type { IJacyContent, SkyboxSide } from "@jamango/content-client";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { uploadAndCreateResource } from "../../upload/upload-resource";
import type { JacyActions } from "../JacyActions";

export class JacySkyboxActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	async uploadSkybox(side: SkyboxSide, initialFile: File) {
		try {
			const { name, resourceType, file } =
				await this.#state.resources.processSkyboxSideTexture(initialFile);

			const { pk } = await uploadAndCreateResource(Jacy, {
				name,
				resourceType,
				file,
			});

			await this.#state.skybox.useSideResource(side, pk);

			useJacyRerenderStore.getState().forceRerenderSkybox();
			this.#actions.setSuccess(`Skybox side ${side} has been updated successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
