export const VALIDATOR_TYPE = {
	username: "username",
} as const;

function validateUsername(value: string) {
	if (value.length < 3) throw new Error("Username must be at least 3 characters long.");

	if (value.length > 20) throw new Error("Username must be at most 20 characters long.");

	if (!/^[a-zA-Z0-9_]+$/.test(value))
		throw new Error("Username can only contain alphanumeric characters and underscores.");

	return true;
}

export function validate(validator: keyof typeof VALIDATOR_TYPE, value: any) {
	switch (validator) {
		case VALIDATOR_TYPE.username:
			return validateUsername(value);
		default:
			return true;
	}
}
