import {
	useHubStore,
	useWorldPreviewStore,
	useConfirmPromptStore,
	useGeneralPromptStore,
	usePromptStore,
	useErrorStore,
	useHubWorldModal,
	useCreateWorldModal,
	useEditorModal,
	useCustomUIModalStore,
	useWorldPortalStore,
} from "@stores/dialogs";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useChatStore } from "@stores/chat";
import { useControlsStore } from "@stores/controls";
import { useWrenchStore } from "@stores/wrench";
import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";

// The goal of this helper function is mainly to determine if some in-game actions are available (for example pressing T to open the chat, C to change the camera, etc.). If no modal is open, then the player is currently looking at the game world without any UI getting in the way.

export function isAnyModalOpen() {
	return (
		useInventoryStore.getState().open ||
		useHubStore.getState().open ||
		useChatStore.getState().open ||
		useWorldPreviewStore.getState().open ||
		useWorldPortalStore.getState().open ||
		useConfirmPromptStore.getState().open ||
		useGeneralPromptStore.getState().open ||
		usePromptStore.getState().open ||
		useErrorStore.getState().open ||
		useAlertDialogStore.getState().open ||
		useHubWorldModal.getState().open ||
		useEditorModal.getState().open ||
		useWrenchStore.getState().open ||
		useControlsStore.getState().emotesWheel !== false ||
		useControlsStore.getState().toolWheel !== false ||
		useCreateWorldModal.getState().open ||
		useJacyAvatarEditorStore.getState().open ||
		useCustomUIModalStore.getState().open
	);
}
