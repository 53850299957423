import type { IGameMechanics } from "../../lib/types";
import { AssetType } from "../../lib/types";
import { DEFAULT_GAME_MECHANICS, P2P_MAX_PLAYERS } from "../../lib/constants/game-mechanics";
import type { JacyContentState } from "../JacyContentState";
import { generateId } from "../../lib/helpers/general";
import { validations } from "../../lib/validations";
import { isNullish } from "@jamango/helpers";

type GameMechanics = Omit<IGameMechanics, "pk" | "type">;

export class JacyGameMechanicsState {
	#gameMechanics: IGameMechanics;
	#state: JacyContentState;

	get pk() {
		return this.#gameMechanics.pk;
	}

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#gameMechanics = this.getDefault();
	}

	import(data: IGameMechanics = this.getDefault()) {
		this.set(data);
	}

	export() {
		return this.#gameMechanics;
	}

	get maxPlayers() {
		return this.#gameMechanics.maxPlayers;
	}

	get privateP2PMaxPeers() {
		const HOST = 1;
		return this.privateP2PMaxPlayers - HOST;
	}

	get privateP2PMaxPlayers() {
		return Math.min(P2P_MAX_PLAYERS, this.maxPlayers);
	}

	get characterCollisions() {
		return this.#gameMechanics.characterCollisions;
	}

	#validateField(key: keyof GameMechanics, value: any) {
		switch (key) {
			case "characterCollisions":
				return validations.gameMechanics.characterCollisions(value);
			case "maxPlayers":
				return validations.gameMechanics.maxPlayers(value);
			default:
				throw new Error(`unhandled`);
		}
	}

	#validate(mechanics?: Partial<GameMechanics>) {
		if (!mechanics) return;

		for (const key in mechanics) {
			const mecanic = key as keyof GameMechanics;
			const value = mechanics[mecanic];

			if (isNullish(value)) continue;

			this.#validateField(mecanic, value);
		}

		const { maxPlayers } = mechanics;

		if (!isNullish(maxPlayers)) {
			validations.gameMechanics.maxPlayers(maxPlayers);
		}
	}

	get() {
		if (!this.#gameMechanics) {
			throw new Error("No settings loaded. The world is not initialized.");
		}

		return this.#gameMechanics;
	}

	update(data: Partial<IGameMechanics>) {
		if (!this.pk) {
			throw new Error("Can't update game mechanics, world is not loaded yet.");
		}

		this.#validate(data);

		this.set({
			...this.#gameMechanics,
			...data,
		} as IGameMechanics);

		return this.pk;
	}

	set(data: IGameMechanics) {
		if (!this.pk) {
			throw new Error("Can't set custom loader, world is not loaded yet.");
		}

		this.#gameMechanics = structuredClone({
			...DEFAULT_GAME_MECHANICS,
			...data,
		});
		this.#gameMechanics.maxPlayers = data.maxPlayers ?? DEFAULT_GAME_MECHANICS.maxPlayers;

		this.#state.markDirty();

		return this.pk;
	}

	getDefault() {
		const id = generateId();

		return {
			...DEFAULT_GAME_MECHANICS,
			pk: `${AssetType.GAME_MECHANICS}#${id}`,
			type: AssetType.GAME_MECHANICS,
		} satisfies IGameMechanics;
	}
}
