import { create } from "zustand";

type IJacyRerenderState = {
	reRenderUploadStatus: object;
	reRenderWorldData: object;
	reRenderSettings: object;
	reRenderCustomLoader: object;
	reRenderThumbnail: object;
	reRenderGameMechanics: object;
	reRenderSkybox: object;
	reRenderEnvironmentPreset: object;
	reRenderBlockTextures: object;
	reRenderAvatars: object;
	reRenderAvatarCanvas: object;
	reRenderScripts: object;
	reRenderInventory: object;
	reRenderBlockStructures: object;
	reRenderProps: object;
	reRenderCharacters: object;

	forceRerenderUploadStatus: () => void;
	forceRerenderWorldData: () => void;
	forceRerenderSettings: () => void;
	forceRerenderCustomLoader: () => void;
	forceRerenderThumbnail: () => void;
	forceRerenderGameMechanics: () => void;
	forceRerenderSkybox: () => void;
	forceRerenderEnvironmentPreset: () => void;
	forceRerenderBlockTextures: () => void;
	forceRerenderAvatars: () => void;
	forceRerenderAvatarCanvas: () => void;
	forceRerenderScripts: () => void;
	forceRerenderInventory: () => void;
	forceRerenderBlockStructures: () => void;
	forceRerenderProps: () => void;
	forceRerenderCharacters: () => void;

	forceRerenderAll: () => void;
};

// Triggers re-render when assets are updated.
export const useJacyRerenderStore = create<IJacyRerenderState>((set) => ({
	reRenderUploadStatus: {},
	reRenderChangeset: {},
	reRenderWorldData: {},
	reRenderSettings: {},
	reRenderCustomLoader: {},
	reRenderThumbnail: {},
	reRenderGameMechanics: {},
	reRenderSkybox: {},
	reRenderEnvironmentPreset: {},
	reRenderBlockTextures: {},
	reRenderAvatars: {},
	reRenderAvatarCanvas: {},
	reRenderScripts: {},
	reRenderCreateAssetsPackage: {},
	reRenderInventory: {},
	reRenderBlockStructures: {},
	reRenderProps: {},
	reRenderCharacters: {},

	forceRerenderUploadStatus: () => set({ reRenderUploadStatus: {} }),
	forceRerenderWorldData: () => set({ reRenderWorldData: {} }),
	forceRerenderSettings: () => set({ reRenderSettings: {} }),
	forceRerenderCustomLoader: () => set({ reRenderCustomLoader: {} }),
	forceRerenderThumbnail: () => set({ reRenderThumbnail: {} }),
	forceRerenderGameMechanics: () => set({ reRenderGameMechanics: {} }),
	forceRerenderSkybox: () => set({ reRenderSkybox: {} }),
	forceRerenderEnvironmentPreset: () => set({ reRenderEnvironmentPreset: {} }),
	forceRerenderBlockTextures: () => set({ reRenderBlockTextures: {} }),
	forceRerenderAvatars: () => set({ reRenderAvatars: {} }),
	forceRerenderAvatarCanvas: () => set({ reRenderAvatarCanvas: {} }),
	forceRerenderScripts: () => set({ reRenderScripts: {} }),
	forceRerenderInventory: () => set({ reRenderInventory: {} }),
	forceRerenderBlockStructures: () => set({ reRenderBlockStructures: {} }),
	forceRerenderProps: () => set({ reRenderProps: {} }),
	forceRerenderCharacters: () => set({ reRenderCharacters: {} }),

	forceRerenderAll: () =>
		set({
			reRenderUploadStatus: {},
			reRenderWorldData: {},
			reRenderSettings: {},
			reRenderCustomLoader: {},
			reRenderThumbnail: {},
			reRenderGameMechanics: {},
			reRenderSkybox: {},
			reRenderEnvironmentPreset: {},
			reRenderBlockTextures: {},
			reRenderAvatars: {},
			reRenderAvatarCanvas: {},
			reRenderScripts: {},
			reRenderInventory: {},
			reRenderBlockStructures: {},
			reRenderProps: {},
			reRenderCharacters: {},
		}),
}));

export const forceRerenderInventory = () => useJacyRerenderStore.getState().forceRerenderInventory();
