import type { JacyClient } from "@jacy-client";

const DEFAULT_UPLOAD_TIMEOUT = 2 * 60 * 1000;

export const uploadFileToS3 = (
	jacy: JacyClient,
	{ uploadUrl, file, timeout }: { uploadUrl: string; file: File; timeout?: number },
) => {
	return new Promise<{ success: true }>((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.timeout = timeout ?? DEFAULT_UPLOAD_TIMEOUT;

		xhr.upload.addEventListener("progress", (event) => {
			jacy.events.emit("syncer:progress", {
				assetType: "uploading",
				total: event.total,
				current: event.loaded,
			});
		});

		xhr.upload.addEventListener("loadend", () => resolve({ success: true }));
		xhr.upload.addEventListener("error", reject);

		xhr.open("PUT", uploadUrl);
		xhr.send(file);
	});
};
