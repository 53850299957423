import { GameClient } from "@jamango/client";
import { useErrorStore } from "@stores/dialogs";
import { useAuthUserStore } from "@stores/auth-user";
import { useGameLoading } from "@lib/hooks/game-client/useGameLoading";
import { Loading } from "@stores/game-client/game-client";
import { Button } from "@components/ui/Button";
import { Dialog } from "./Dialog";
import { DISCORD_INVITE_LINK } from "@lib/constants/general";

export function ErrorDialog() {
	const open = useErrorStore((state) => state.open);
	const toggle = useErrorStore((state) => state.toggle);
	const errorObj = useErrorStore((state) => state.error);
	const loading = useGameLoading();
	const isCreator = useAuthUserStore((state) => state.isCreator);

	const handleOpenChange = () => {
		window.location.reload();
		toggle(false);
	};

	const reloadWorld = () => {
		const worldId = localStorage.getItem("worldId");
		if (worldId) {
			window.location.replace(`/play?world=${worldId}`);
		}
	};

	return (
		<Dialog.Root open={open} onOpenChange={handleOpenChange}>
			<Dialog.Content className="fixed z-[1000] h-full max-w-[690px] overflow-y-auto rounded-3xl bg-slate-50 p-8 shadow-md outline-none">
				<Dialog.Title className="sr-only">Error Dialog</Dialog.Title>
				<div className="flex flex-col items-center justify-between md:items-start">
					<h1 className="mb-2 w-full text-3xl font-medium">Oops! Something went wrong</h1>
					<p className="mb-4 text-2xl">
						Emmet Brown and his crew will take a look under the hood 💪
					</p>
					<div className="flex w-full flex-col justify-start gap-2 py-1 text-center xs:flex-row">
						{isCreator && (
							<Button
								size="xl"
								className="text-xl"
								onClick={() => GameClient.saveWorld()}
								disabled={!!loading}
							>
								{loading === Loading.SAVE_WORLD ? "Saving world..." : "Attempt to save world"}
							</Button>
						)}
						<Button size="xl" className="text-xl" onClick={reloadWorld}>
							Reload game
						</Button>
					</div>
					<div className="text-md mt-4 w-full">
						If this problem persists, please let us know in the{" "}
						<span className="font-medium">#support</span> channel on{" "}
						<a
							href={DISCORD_INVITE_LINK}
							target="_blank"
							rel="noopener noreferrer"
							className="text-link"
						>
							Discord
						</a>
						.
					</div>

					<div className="my-4 h-[1px] w-full bg-slate-300" />

					{errorObj && (
						<>
							<div className="flex w-full cursor-pointer items-center text-sm">Crash Log</div>
							<p className="w-full mt-2 overflow-auto overflow-y-auto whitespace-pre-wrap rounded-lg bg-gray-200 p-4 font-mono text-xs">
								{(errorObj as any)?.error}
								{errorObj?.stack}
							</p>
						</>
					)}
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
}
