import { Jacy } from "@jacy-client";
import type {
	BlockScriptAttachment,
	IAssetKey,
	IBlockOptions,
	IDateString,
	IParsedBlock,
	IParsedBlockTexture,
} from "@jamango/content-client";
import {
	AssetType,
	BlockCollision,
	BlockMaterialSide,
	BlockTransparency,
	BlockTransparencyBehaviour,
	DEFAULT_BLOCK_OPTIONS,
	DEFAULT_COLOR,
} from "@jamango/content-client";
import { isNullish, isColor } from "@jamango/helpers";
import { create } from "zustand";

type IJacyBlockEditorState = {
	open: boolean;
	isCreate?: boolean;

	pk: IAssetKey;
	displayName: string;
	description: string;
	isTexture: boolean;
	isDefaultFace: boolean;
	texture?: IParsedBlockTexture | null;
	color?: string | null;
	material: IParsedBlock["material"];
	options: IBlockOptions;
	createdAt: IDateString;
	updatedAt: IDateString;
	scripts: BlockScriptAttachment[];
	soundsPackId?: IParsedBlock["soundsPackId"];
	packageId?: IParsedBlock["packageId"];

	// Handlers
	setDisplayName: (displayName: string) => void;
	setDescription: (description: string) => void;
	toggleMaterial: () => void;
	toggleDefaultFace: () => void;
	setTransparency: (transparency: BlockTransparency) => void;
	setTransparencyBehaviour: (transparencyBehaviour: BlockTransparencyBehaviour) => void;
	toggleCollision: (enabled: boolean) => void;
	setFriction: (friction: number) => void;
	setColor: (color: string) => void;
	setTextureSide: (side: BlockMaterialSide, textureId: IAssetKey) => void;
	addScript: (scriptId: string) => void;
	removeScript: (scriptId: string) => void;
	setScriptData: (scriptId: string, data: Record<string, any>) => void;
	setSoundsPackId: (soundsPackId: string | null) => void;

	// Actions
	close: () => void;
	getBlock: () => { block: IParsedBlock };
	newBlock: (name?: string) => void;
	editBlock: (block: IParsedBlock) => void;
};

const DEFAULT_STATE = {
	pk: "",
	displayName: "",
	description: "",
	isTexture: false,
	isDefaultFace: true,
	color: DEFAULT_COLOR,
	material: {
		default: null,
		nx: null,
		ny: null,
		nz: null,
		px: null,
		py: null,
		pz: null,
	},
	options: structuredClone(DEFAULT_BLOCK_OPTIONS),
	createdAt: new Date().toISOString(),
	updatedAt: new Date().toISOString(),
	scripts: [],
};

export const useJacyBlockEditorStore = create<IJacyBlockEditorState>((set, get) => ({
	open: false,
	...DEFAULT_STATE,

	// Handlers
	setDisplayName: (displayName) => set({ displayName }),
	setDescription: (description) => set({ description }),
	toggleMaterial: () => {
		const isTexture = !get().isTexture;

		const material = { ...get().material };
		material.default = isTexture ? get().texture : get().color;

		set({ isTexture, material });
	},
	toggleDefaultFace: () => set({ isDefaultFace: !get().isDefaultFace }),
	setTransparency: (transparency) => {
		const options = structuredClone(get().options);

		if (transparency === BlockTransparency.OPAQUE) {
			options.transparency = transparency;
			options.transparencyBehaviour = undefined;
		} else {
			options.transparency = transparency;
			options.transparencyBehaviour = options.transparencyBehaviour ?? BlockTransparencyBehaviour.JOIN;
		}

		set({ options });
	},
	setTransparencyBehaviour: (transparencyBehaviour) => {
		const options = structuredClone(get().options);
		options.transparencyBehaviour = transparencyBehaviour;
		set({ options });
	},
	toggleCollision: (enabled) => {
		const options = structuredClone(get().options);
		options.collision = enabled ? BlockCollision.ENABLED : BlockCollision.DISABLED;

		if (!enabled) {
			options.friction = 1;
		}

		set({ options });
	},
	setFriction: (friction) => {
		const options = structuredClone(get().options);
		options.friction = friction;
		set({ options });
	},
	setColor: (color) => {
		set({
			color,
			material: {
				...get().material,
				default: color,
			},
		});
	},
	setTextureSide: (side, textureId) => {
		const blockTexture = Jacy.state.blockTextures.get(textureId);
		if (!blockTexture) {
			return;
		}

		set({
			texture: side === BlockMaterialSide.DEFAULT ? blockTexture : get().texture,
			material: {
				...get().material,
				[side]: blockTexture,
			},
		});
	},
	addScript: (scriptId) => {
		const data = Jacy.actions.scripts.getDefaultScriptControlsData(scriptId);

		const scripts = [...get().scripts, { script: scriptId, data }];

		set({ scripts });
	},
	removeScript: (scriptId) => {
		const scripts = get().scripts.filter((script) => script.script !== scriptId);
		set({ scripts });
	},
	setScriptData: (scriptId, data) => {
		const scripts = get().scripts.map((script) =>
			script.script === scriptId ? { script: script.script, data } : script,
		);
		set({ scripts });
	},
	setSoundsPackId: (soundsPackId) => set({ soundsPackId: soundsPackId ?? undefined }),

	// Actions
	close: () => set({ open: false }),
	getBlock: () => {
		const pk = get().pk;

		if (!get().isCreate && !pk) {
			throw new Error("Block identifier not found");
		}

		const colorMaterial = get().isTexture ? undefined : get().color;

		const block: IParsedBlock = {
			pk: pk!,
			type: AssetType.BLOCK,
			displayName: get().displayName,
			description: get().description,
			material: {
				default: colorMaterial ?? get().material.default,
				px: colorMaterial ?? get().material.px,
				nx: colorMaterial ?? get().material.nx,
				py: colorMaterial ?? get().material.py,
				ny: colorMaterial ?? get().material.ny,
				pz: colorMaterial ?? get().material.pz,
				nz: colorMaterial ?? get().material.nz,
			},
			options: get().options,
			createdAt: get().createdAt,
			updatedAt: get().updatedAt,
			scripts: get().scripts ?? [],
			soundsPackId: get().soundsPackId,
			packageId: get().packageId,
		};

		return { block };
	},
	newBlock: (name) => {
		const texture = Jacy.state.blockTextures.query("dirt");
		const today = new Date().toISOString();

		set({
			...structuredClone(DEFAULT_STATE),
			displayName: name ?? "",
			open: true,
			isCreate: true,
			texture,
			createdAt: today,
			updatedAt: today,
			scripts: [],
			soundsPackId: undefined,
			packageId: undefined,
			pk: Jacy.state.blocks.createPK(Jacy.state.blocks.createId()),
		});
	},
	editBlock: (block) => {
		let isTexture = false;
		let color = DEFAULT_COLOR;
		let texture;

		if (typeof block.material.default === "object" && !isNullish(block.material.default)) {
			isTexture = true;
			texture = block.material.default;
		} else if (
			isNullish(block.material.default) &&
			!(
				isNullish(block.material.px) ||
				isNullish(block.material.nx) ||
				isNullish(block.material.py) ||
				isNullish(block.material.ny) ||
				isNullish(block.material.pz) ||
				isNullish(block.material.nz)
			)
		) {
			isTexture = true;
		} else {
			isTexture = false;

			if (!isNullish(block.material.default) && isColor(block.material.default)) {
				color = block.material.default;
			}
		}

		set({
			open: true,
			isCreate: false,
			pk: block.pk,
			displayName: block.displayName,
			description: block.description ?? "",
			isTexture,
			color,
			texture,
			isDefaultFace: true,
			material: block.material,
			options: {
				...DEFAULT_BLOCK_OPTIONS,
				...(block.options || {}),
			},
			createdAt: block.createdAt,
			updatedAt: block.updatedAt,
			scripts: block.scripts ?? [],
			soundsPackId: block.soundsPackId,
			packageId: block.packageId,
		});
	},
}));
