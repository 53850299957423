import { create } from "zustand";

import { useEngineStore } from "./bb";

export const useDebugStore = create((set, get) => ({
	open: false,

	metrics: [],

	// Handlers
	setOpen: (open) => {
		set({ open });

		const { BB } = useEngineStore.getState().engine;
		BB.client.debug.setDebugEnabled(open);
	},

	toggleOpen: () => get().setOpen(!get().open),

	// Events
	setDebug: (settings) => {
		set(settings);
	},
	toggleOcclusionCulling: () => {
		const { BB } = useEngineStore.getState().engine;
		if (Number(BB.client.settings.occlusionCulling) === 0) BB.client.settings.occlusionCulling = 1;
		else BB.client.settings.occlusionCulling = 0;
	},
	toggleOcclusionCullingDebug: () => {
		const { BB } = useEngineStore.getState().engine;
		BB.client.settings.occlusionCullingDebug = !BB.client.settings.occlusionCullingDebug;
	},
	toggleLocomotionLogs: () => {
		const { BB } = useEngineStore.getState().engine;
		BB.client.settings.locomotionLogsEnabled = !BB.client.settings.locomotionLogsEnabled;
	},
	toggleDebugRendererMode: () => {
		const { BB } = useEngineStore.getState().engine;
		BB.client.debug.debugRendererMode =
			(BB.client.debug.debugRendererMode + 1) % BB.client.debug.numDebugRendererModes;
	},
	toggleZPrepass: () => {
		const { BB } = useEngineStore.getState().engine;
		BB.client.settings.zPrepassEnabled = !BB.client.settings.zPrepassEnabled;
	},
	toggleAASetting: () => {
		const { BB } = useEngineStore.getState().engine;
		switch (BB.client.settings.aaSetting) {
			case "no":
				BB.client.settings.aaSetting = "msaa";
				break;
			case "msaa":
				BB.client.settings.aaSetting = "fxaa";
				break;
			case "fxaa":
				BB.client.settings.aaSetting = "no";
				break;
		}
	},
}));

export default {
	useDebug: useDebugStore.getState,
};
