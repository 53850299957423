import type {
	AnyControlDef,
	AnyInputDef,
	AnyNodeDef,
	AnyOutputDef,
	IDataStructure,
	IDataType,
	IScript,
} from "@jamango/content-client";
import { generateUUID, type Events } from "@jamango/content-client";
import { NODE } from "@jamango/engine/Events.ts";
import { useEngineStore } from "@stores/bb";
import type * as RF from "@xyflow/react";
import { createContext, useContext } from "react";
import { useStore } from "zustand";
import type { StoreApi } from "zustand/vanilla";
import { createStore } from "zustand/vanilla";
import { useEditor } from "../editor-store";
import type { IEditorStoreState } from "../editor-types";
import type { UndoManager } from "../undo";
import { createUndoManager } from "../undo";
import {
	addComment,
	addDynamicDefNodeSocket,
	addFrame,
	addNode,
	changeDynamicDefAllSocketsDataType,
	changeUtilityNodeSocketDataStructure,
	changeUtilityNodeSocketDataType,
	connectNodes,
	disableAllOptionalNodeInputs,
	enableAllOptionalNodeInputs,
	exportSelection,
	findConnectionByNodesPair,
	getNode,
	getNodeChildrenIds,
	getNodeDef,
	getNodeInputDefaultValue,
	handleNodeTransformFinalized,
	importEvents,
	nodeCanHaveChildren,
	removeConnectionById,
	removeDynamicDefNodeSocket,
	renameUtilityNodeSocket,
	setCommentText,
	setDynamicDefNodeName,
	setDynamicDefNodeSockets,
	setNodeDefaultValues,
	setNodeInputValue,
	tryRemoveNode,
	validateNodeInputValue,
} from "./actions";
import {
	applyRfEdgeChanges,
	applyRfNodeChanges,
	commentToRfNode,
	connectionToRfEdge,
	eventsToRfNodesAndEdges,
	frameToRfNode,
	nodeToRfNode,
} from "./rf-utils";

export type EditorNodeData = {
	defId: string;
	def: AnyNodeDef;
	collapsible?: boolean;
};

export type RfNodeGeneral = RF.Node & { data: EditorNodeData };
export type RfNodeComment = RF.Node;
export type RfNodeFrame = RF.Node;
export type RfNode = RfNodeGeneral | RfNodeComment | RfNodeFrame;
export type RfEdge = RF.Edge;

export type Notification = { type: "error" | "info"; message: string };

type ContextMenu =
	| {
			position: { x: number; y: number };
			hit?: undefined;
			clipboardHasEvents: boolean;
	  }
	| {
			position: { x: number; y: number };
			hit: "node";
			node: {
				def: AnyNodeDef;
				data: RF.Node;
			};
	  }
	| {
			position: { x: number; y: number };
			hit: "frame";
			node: {
				data: RF.Node;
			};
	  }
	| {
			position: { x: number; y: number };
			hit: "edge";
			id: string;
	  }
	| {
			position: { x: number; y: number };
			hit: "selection";
	  };

export type EditorSelectionState = {
	selectedNodes: string[];
	selectedEdges: string[];
};

const throttledAction = <T extends Array<unknown>>(fn: (...args: [...T]) => void, delay: number) => {
	let lastCall = 0;
	let timeoutId: NodeJS.Timer | null = null;

	return (...args: [...T]) => {
		const now = Date.now();

		if (lastCall === 0 || now - lastCall >= delay) {
			lastCall = now;
			fn(...args);
		} else {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			timeoutId = setTimeout(
				() => {
					lastCall = Date.now();
					fn(...args);
					timeoutId = null;
				},
				delay - (now - lastCall),
			);
		}
	};
};

export type ScriptEditorState = IEditorStoreState & {
	id: string;

	viewportChanged: boolean;
	viewport: RF.Viewport;
	reactFlowInstance: RF.ReactFlowInstance;

	undoManager: UndoManager<Events>;

	selection: EditorSelectionState;

	script: IScript;
	events: Events;

	nodes: RfNode[];
	edges: RfEdge[];

	setScript: (script: IScript) => void;
	reload: () => void;

	setScriptName: (name: string) => void;
	setScriptDescription: (description: string) => void;
	setScriptIcon: (icon: IScript["icon"]) => void;
	setScriptStarred: (starred: boolean) => void;
	setScriptType: (type: IScript["scriptType"]) => void;

	getMenuDefs: () => AnyNodeDef[];

	addNode: (defId: string, position: [number, number], parentId?: string) => void;
	addComment: (position: [number, number]) => void;
	addFrame: (position: [number, number]) => void;
	tryRemoveNode: (nodeId: string, removeChildren?: boolean) => void;
	tryRemoveNodes: (nodeIds: string[]) => void;
	removeConnection: (id: string) => void;

	getNodeDef: (nodeId: string) => AnyNodeDef | undefined;

	createChangeCheckpoint: () => void;
	undo: () => void;
	redo: () => void;

	selectAll: () => void;
	_copy: () => Promise<void>;
	cut: () => void;
	copy: () => void;
	checkClipboard: () => Promise<Events | null>;
	paste: (position?: [number, number]) => void;

	duplicate: (nodeIds: string[]) => void;

	setNodeCollapsed: (nodeId: string, collapsed: boolean) => void;
	setNodeInputToDefault: (nodeId: string, inputId: string) => void;
	setNodeInputValue: (nodeId: string, inputId: string, value: unknown) => void;
	enableAllOptionalNodeInputs: (nodeId: string) => void;
	disableAllOptionalNodeInputs: (nodeId: string) => void;
	validateNodeInputValue: (nodeId: string, inputId: string, value: unknown) => null | string;
	setCommentText: (commentId: string, text: string) => void;
	setDynamicDefNodeName: (nodeId: string, name?: string) => void;
	setDynamicDefNodeSockets: (
		nodeId: string,
		sockets: {
			controls?: Record<string, AnyControlDef>;
			inputs?: Record<string, AnyInputDef>;
			outputs?: Record<string, AnyOutputDef>;
		},
	) => void;
	addDynamicDefNodeSocket: (nodeId: string) => void;
	removeDynamicDefNodeSocket: (nodeId: string, socketId: string) => void;
	changeDynamicDefSocketsDataType: (
		nodeId: string,
		inputs: string[],
		outputs: string[],
		type: IDataType,
	) => void;

	renameUtilityNodeSocket: (nodeId: string, socketId: string, name: string) => void;
	changeUtilityNodeSocketDataType: (nodeId: string, socketId: string, type: IDataType) => void;
	changeUtilityNodeSocketDataStructure: (
		nodeId: string,
		socketId: string,
		structure: IDataStructure,
	) => void;

	markNodesDirty: (...nodeIds: string[]) => void;

	setSelection: (selection: EditorSelectionState) => void;
	onSelectionChange: RF.OnSelectionChangeFunc;
	onEdgesChange: RF.OnEdgesChange<RfEdge>;
	onNodesChange: RF.OnNodesChange<RfNode>;
	onNodeTransformEnd: (nodeId: string) => void;
	onConnect: RF.OnConnect;
	onReconnect: RF.OnReconnect;
	onReconnectDropped: (id: string) => void;

	contextMenu?: ContextMenu;
	setContextMenu: (contextMenu?: ContextMenu) => void;

	notification?: Notification;
	setNotification: (notification: Notification) => void;
	clearNotification: () => void;
};

export type ScriptEditorStore = StoreApi<ScriptEditorState>;

export const createScriptEditorStore = (): ScriptEditorStore => {
	return createStore<ScriptEditorState>((set, get) => ({
		id: generateUUID(),
		viewportChanged: false,
		viewport: {
			x: 0,
			y: 0,
			zoom: 1,
		},
		reactFlowInstance: null!,
		undoManager: null!,
		selection: {
			selectedNodes: [],
			selectedEdges: [],
			dragSelectionInProgress: false,
		},
		script: null!,
		events: null!,
		modified: false,
		nodes: [],
		edges: [],
		setScript: (script: IScript) => {
			const events = script.events;

			const undoManager = createUndoManager<Events>();
			undoManager.init(script.events);

			set({ script, events, undoManager });
		},
		reload: () => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, selection } = get();

			if (!editorClient) {
				return;
			}

			const { nodes, edges } = eventsToRfNodesAndEdges(editorClient, events, selection);

			set({ nodes, edges });
		},

		setScriptName: (name) => {
			const { script, createChangeCheckpoint } = get();

			script.name = name;

			set({ script });

			createChangeCheckpoint();
		},
		setScriptDescription: (description) => {
			const { script, createChangeCheckpoint } = get();

			script.description = description.trim();

			set({ script });

			createChangeCheckpoint();
		},
		setScriptIcon: (icon) => {
			const { script, createChangeCheckpoint } = get();

			script.icon = icon;

			set({ script });

			createChangeCheckpoint();
		},
		setScriptStarred: (starred) => {
			const { script, createChangeCheckpoint } = get();

			script.starred = starred;

			set({ script });

			createChangeCheckpoint();
		},
		setScriptType: (type) => {
			const { script, createChangeCheckpoint } = get();

			script.scriptType = type;

			set({ script });

			createChangeCheckpoint();
		},

		getMenuDefs: () => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events } = get();

			const startUtilityExists = Object.values(events.nodes).some((node) => node.defId === NODE.Start);

			return editorClient.getMenuDefs().filter((def) => {
				const isStart = def.id === NODE.Start;
				if (isStart && startUtilityExists) return false;

				return true;
			});
		},

		/* actions */
		addNode: (defId, position, parentId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint } = get();

			const node = addNode(editorClient, events, defId, position, parentId);

			if (!node) return;

			const def = getNodeDef(editorClient, defId, events, node.id);
			const world = useEngineStore.getState().engine.BB.world;
			setNodeDefaultValues(node, def, world);

			createChangeCheckpoint();

			const rfNode = nodeToRfNode(editorClient, events, node);

			if (!rfNode) return;

			set({ nodes: [...get().nodes, rfNode] });
		},
		addComment: (position) => {
			const { events, createChangeCheckpoint } = get();

			const comment = addComment(events, position);

			createChangeCheckpoint();

			const rfNode = commentToRfNode(comment);
			set({
				nodes: [...get().nodes, rfNode],
			});
		},
		addFrame: (position) => {
			const { events, createChangeCheckpoint } = get();

			const frame = addFrame(events, position);
			createChangeCheckpoint();

			const rfNode = frameToRfNode(frame);
			set({
				nodes: [...get().nodes, rfNode],
			});
		},
		tryRemoveNode: (nodeId, removeChildren) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			const { removed } = tryRemoveNode(editorClient, events, nodeId, removeChildren);

			if (!removed) return;

			createChangeCheckpoint();

			reload();
		},
		tryRemoveNodes: (nodeIds) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			const removedNodes: string[] = [];

			for (const nodeId of nodeIds) {
				const { removed } = tryRemoveNode(editorClient, events, nodeId);

				if (removed) {
					removedNodes.push(nodeId);
				}
			}

			if (removedNodes.length > 0) {
				createChangeCheckpoint();
			}

			reload();
		},
		removeConnection: (id) => {
			const { events, createChangeCheckpoint, markNodesDirty } = get();

			const connection = events.connections.find((c) => c.id === id);

			if (connection) {
				markNodesDirty(connection.source, connection.target);
			}

			removeConnectionById(events, id);

			createChangeCheckpoint();

			set({
				edges: get().edges.filter((edge) => edge.id !== id),
			});
		},

		getNodeDef: (nodeId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events } = get();

			const [node] = getNode(events, nodeId);

			if (node && "defId" in node) {
				return getNodeDef(editorClient, node.defId, events, nodeId);
			}
		},

		undoHistory: [],
		redoHistory: [],
		lastCheckpointEvents: null,
		createChangeCheckpoint: () => {
			const { events, undoManager } = get();

			undoManager.set(events);

			set({ modified: true });
		},
		undo: () => {
			const { undoManager, setNotification, reload } = get();

			const undo = undoManager.undo();

			if (!undo) {
				setNotification({ type: "error", message: "No changes to undo" });
				return;
			}

			set({ events: undoManager.get() });

			reload();

			setNotification({ type: "info", message: "Undid changes" });
		},
		redo: () => {
			const { undoManager, setNotification, reload } = get();

			const redo = undoManager.redo();

			if (!redo) {
				setNotification({ type: "error", message: "No changes to redo" });
				return;
			}

			set({ events: undoManager.get() });

			reload();

			setNotification({ type: "info", message: "Redid changes" });
		},
		selectAll: () => {
			const { nodes, onSelectionChange } = get();

			onSelectionChange({ nodes, edges: [] });
		},
		_copy: async () => {
			const { events, selection: editorState } = get();
			const exportedSelection = exportSelection(events, editorState.selectedNodes);

			await navigator.clipboard.writeText(JSON.stringify({ __events_editor_json: exportedSelection }));
		},
		cut: async () => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { _copy, selection, events, setNotification } = get();

			await _copy();

			const removedNodes: string[] = [];

			for (const nodeId of selection.selectedNodes) {
				const { removed } = tryRemoveNode(editorClient, events, nodeId);

				if (removed) {
					removedNodes.push(nodeId);
				}
			}

			if (removedNodes.length > 0) {
				set({
					nodes: get().nodes.filter((node) => !removedNodes.includes(node.id)),
				});
			}

			setNotification({ type: "info", message: `Cut ${removedNodes.length} nodes` });
		},
		copy: async () => {
			const { _copy, selection, setNotification } = get();

			await _copy();

			setNotification({ type: "info", message: `Copied ${selection.selectedNodes.length} nodes` });
		},
		checkClipboard: async () => {
			let clipboardText = null;

			try {
				clipboardText = await navigator.clipboard.readText();
			} catch (_e) {}

			if (!clipboardText) return null;

			let clipboardData;

			try {
				clipboardData = JSON.parse(clipboardText);
			} catch (_e) {}

			if (!clipboardData || !clipboardData.__events_editor_json) {
				return null;
			}

			const clipboardEvents = clipboardData.__events_editor_json;

			return clipboardEvents;
		},
		paste: async (position) => {
			const {
				checkClipboard,
				reactFlowInstance,
				events,
				reload,
				createChangeCheckpoint,
				setNotification,
			} = get();

			const clipboardEvents = await checkClipboard();

			if (!clipboardEvents) {
				return;
			}

			let pastePosition: [number, number];

			if (position) {
				pastePosition = position;
			} else {
				const pos = reactFlowInstance.screenToFlowPosition({
					x: window.innerWidth / 2,
					y: window.innerHeight / 2,
				});
				pastePosition = [pos.x, pos.y];
			}

			const { importedNodeIds } = importEvents(events, clipboardEvents, pastePosition);

			createChangeCheckpoint();

			set({
				selection: {
					selectedNodes: importedNodeIds,
					selectedEdges: [],
				},
			});

			reload();

			setNotification({ type: "info", message: `Pasted ${importedNodeIds.length} nodes` });
		},
		duplicate: (nodeIds) => {
			const { events, reload, createChangeCheckpoint } = get();

			const position = [0, 0] as [number, number];
			for (const nodeId of nodeIds) {
				const [x, y] = events.nodes[nodeId].position;
				position[0] += x;
				position[1] += y;
			}
			position[0] /= nodeIds.length;
			position[1] /= nodeIds.length;

			position[0] += 100;
			position[1] += 100;

			const exportedNodes = exportSelection(events, nodeIds);

			const { importedNodeIds } = importEvents(events, exportedNodes, position);

			createChangeCheckpoint();

			set({
				selection: {
					selectedNodes: importedNodeIds,
					selectedEdges: [],
				},
			});

			reload();
		},

		/* component actions and utils */
		markNodesDirty: (...nodeIds: string[]) => {
			const { nodes } = get();

			set({
				nodes: nodes.map((node) => {
					if (nodeIds.includes(node.id)) {
						return { ...node };
					}

					return node;
				}),
			});
		},
		setNodeCollapsed: (nodeId, collapsed) => {
			const { events, markNodesDirty, createChangeCheckpoint } = get();

			events.nodes[nodeId] = { ...events.nodes[nodeId], collapsed };

			createChangeCheckpoint();

			markNodesDirty(nodeId);
		},
		setNodeInputValue: (nodeId, inputId, value) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, markNodesDirty, createChangeCheckpoint } = get();

			const world = useEngineStore.getState().engine.BB.world;
			const changed = setNodeInputValue(get(), editorClient, world, events, nodeId, inputId, value);

			if (!changed) return;

			createChangeCheckpoint();

			markNodesDirty(nodeId);
		},
		setNodeInputToDefault: (nodeId, inputId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, setNodeInputValue } = get();

			const world = useEngineStore.getState().engine.BB.world;

			const [node, nodeType] = getNode(events, nodeId);
			if (!node || nodeType !== "node") return;

			const def = getNodeDef(editorClient, node.defId, events, node.id);
			const inputOrControl = def.inputs?.[inputId] ?? def.controls?.[inputId];
			if (!inputOrControl) return;

			const defaultValue = getNodeInputDefaultValue(inputOrControl, world);

			setNodeInputValue(nodeId, inputId, defaultValue);
		},

		enableAllOptionalNodeInputs: (nodeId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, markNodesDirty, createChangeCheckpoint } = get();

			enableAllOptionalNodeInputs(editorClient, events, nodeId);

			createChangeCheckpoint();

			markNodesDirty(nodeId);
		},
		disableAllOptionalNodeInputs: (nodeId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, markNodesDirty, createChangeCheckpoint } = get();

			disableAllOptionalNodeInputs(editorClient, events, nodeId);

			createChangeCheckpoint();

			markNodesDirty(nodeId);
		},

		validateNodeInputValue: (nodeId, inputId, value) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events } = get();

			const defId = events.nodes[nodeId]?.defId;
			if (!defId) return "Node not found";

			const def = getNodeDef(editorClient, defId, events, nodeId);
			if (!def) return "Node definition not found";

			const inputOrControl = def.inputs?.[inputId] ?? def.controls?.[inputId];
			if (!inputOrControl) return "Input not found";

			return validateNodeInputValue(inputOrControl, value);
		},
		setCommentText: (commentId, text) => {
			const { events, markNodesDirty, createChangeCheckpoint } = get();

			setCommentText(events, commentId, text);

			createChangeCheckpoint();

			markNodesDirty(commentId);
		},
		setDynamicDefNodeName: (nodeId, name) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			setDynamicDefNodeName(editorClient, events, nodeId, name);

			createChangeCheckpoint();

			reload();
		},
		setDynamicDefNodeSockets: (nodeId, sockets) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			setDynamicDefNodeSockets(editorClient, events, nodeId, sockets);

			createChangeCheckpoint();

			reload();
		},
		addDynamicDefNodeSocket: (nodeId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			addDynamicDefNodeSocket(editorClient, events, nodeId);

			createChangeCheckpoint();

			reload();
		},
		removeDynamicDefNodeSocket: (nodeId, socketId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			removeDynamicDefNodeSocket(editorClient, events, nodeId, socketId);

			createChangeCheckpoint();

			reload();
		},
		changeDynamicDefSocketsDataType: (nodeId, inputs, outputs, type) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			changeDynamicDefAllSocketsDataType(editorClient, events, nodeId, inputs, outputs, type);

			createChangeCheckpoint();

			reload();
		},

		renameUtilityNodeSocket: (nodeId, socketId, name) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			renameUtilityNodeSocket(editorClient, events, nodeId, socketId, name);

			createChangeCheckpoint();

			reload();
		},
		changeUtilityNodeSocketDataType: (nodeId, socketId, type) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			changeUtilityNodeSocketDataType(editorClient, events, nodeId, socketId, type);

			createChangeCheckpoint();

			reload();
		},
		changeUtilityNodeSocketDataStructure: (nodeId, socketId, structure) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, createChangeCheckpoint, reload } = get();

			changeUtilityNodeSocketDataStructure(editorClient, events, nodeId, socketId, structure);

			createChangeCheckpoint();

			reload();
		},

		setSelection: (selection) => {
			const { nodes, edges } = get();

			const newSelection = structuredClone(selection);

			// Check if there is a frame in selected node ids
			selection.selectedNodes.forEach((nodeId) => {
				const currentNode = nodes.find((node) => node.id === nodeId);

				if (!currentNode) return;

				if (currentNode.type === "frame") {
					nodes.forEach((node) => {
						if (!node.parentId) return;
						if (node.parentId === currentNode.id && !node.selected) {
							newSelection.selectedNodes.push(node.id);
						}
					});
				}
			});

			const newNodes = nodes.map((node) => {
				const selected = newSelection.selectedNodes.includes(node.id);
				const change = node.selected !== selected;

				if (change) {
					return { ...node, selected };
				}

				return node;
			});

			const newEdges = edges.map((edge) => {
				const selected = newSelection.selectedEdges.includes(edge.id);
				const change = edge.selected !== selected;

				if (change) {
					return { ...edge, selected };
				}

				return edge;
			});

			set({ selection: newSelection, nodes: newNodes, edges: newEdges });
		},

		/* react flow callbacks */
		// debounce selection changes, react flow is aggressive with this callback,
		// updating state on every call isn't necessary
		onSelectionChange: throttledAction(({ nodes: selectedNodes, edges: selectedEdges }) => {
			const { setSelection } = get();

			const selectedNodeIds = selectedNodes.map((node) => node.id);
			const selectedEdgeIds = selectedEdges.map((edge) => edge.id);

			setSelection({
				selectedNodes: selectedNodeIds,
				selectedEdges: selectedEdgeIds,
			});
		}, 100),
		onNodesChange: (changes) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const {
				events,
				selection: editorState,
				nodes,
				setNotification,
				reload,
				createChangeCheckpoint,
			} = get();

			const {
				error,
				regenerationRequired,
				commitChanges,
				nodes: newNodes,
			} = applyRfNodeChanges(editorClient, events, editorState, changes, nodes);

			if (error) {
				setNotification({ type: "error", message: error });
			}

			if (regenerationRequired) {
				reload();
			} else if (newNodes) {
				set({
					nodes: newNodes,
				});
			}

			if (commitChanges) {
				createChangeCheckpoint();
			}
		},
		onNodeTransformEnd: (nodeId) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { reactFlowInstance, events, markNodesDirty, reload, createChangeCheckpoint } = get();

			const rfNode = reactFlowInstance.getNode(nodeId);
			if (!rfNode) return;

			const intersectingNodeIds = reactFlowInstance.getIntersectingNodes(rfNode).map((n) => n.id);

			const handleParentChangesResult = handleNodeTransformFinalized(
				editorClient,
				events,
				nodeId,
				intersectingNodeIds,
			);

			let shouldReload = false;

			if (
				handleParentChangesResult.clearedParent ||
				handleParentChangesResult.setParent ||
				handleParentChangesResult.connectionsRemoved
			) {
				shouldReload = true;
			}

			const canHaveChildren = nodeCanHaveChildren(events, nodeId);

			if (canHaveChildren) {
				const potentiallyAffectedNodeIds = new Set([
					...getNodeChildrenIds(events, nodeId),
					...intersectingNodeIds,
				]);

				for (const otherNodeId of potentiallyAffectedNodeIds) {
					const otherRfNode = reactFlowInstance.getNode(otherNodeId);

					if (!otherRfNode) continue;

					const childIntersectingNodeIds = reactFlowInstance
						.getIntersectingNodes(otherRfNode)
						.map((n) => n.id);

					const childHandleParentChangesResult = handleNodeTransformFinalized(
						editorClient,
						events,
						otherNodeId,
						childIntersectingNodeIds,
					);

					if (
						childHandleParentChangesResult.clearedParent ||
						childHandleParentChangesResult.setParent ||
						childHandleParentChangesResult.connectionsRemoved
					) {
						shouldReload = true;
					}
				}
			}

			createChangeCheckpoint();

			if (shouldReload) {
				reload();
			} else {
				markNodesDirty(nodeId);
			}
		},
		onEdgesChange: (changes) => {
			const { events, selection: editorState, edges, nodes, createChangeCheckpoint } = get();

			const {
				edges: newEdges,
				nodes: newNodes,
				commitChanges,
			} = applyRfEdgeChanges(events, editorState, changes, edges, nodes);

			if (!commitChanges) {
				return;
			}

			createChangeCheckpoint();

			set({
				edges: newEdges,
				nodes: newNodes,
			});
		},
		onConnect: ({ source, sourceHandle, target, targetHandle }) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, setNotification, createChangeCheckpoint } = get();

			const result = connectNodes(editorClient, events, source, sourceHandle!, target, targetHandle!);

			if (typeof result.error === "string") {
				setNotification({ type: "error", message: result.error });
				return;
			}

			const { removedConnection, newConnection } = result;

			const newEdge = connectionToRfEdge(newConnection);

			let newEdges = [...get().edges];

			if (removedConnection) {
				newEdges = newEdges.filter((edge) => edge.id !== removedConnection.id);
			}

			newEdges.push(newEdge);

			createChangeCheckpoint();

			set({ edges: newEdges });
		},
		onReconnect: (oldEdge, newConnection) => {
			const editorClient = useEditor.getState().scriptEditorClient;
			const { events, setNotification, createChangeCheckpoint } = get();

			const originalConnection = findConnectionByNodesPair(
				events,
				oldEdge.source,
				oldEdge.sourceHandle!,
				oldEdge.target,
				oldEdge.targetHandle!,
			);

			if (!originalConnection) return;

			// noop
			if (
				originalConnection.source === newConnection.source &&
				originalConnection.target === newConnection.target &&
				originalConnection.sourceOutput === newConnection.sourceHandle &&
				originalConnection.targetInput === newConnection.targetHandle
			) {
				return;
			}

			const result = connectNodes(
				editorClient,
				events,
				newConnection.source,
				newConnection.sourceHandle!,
				newConnection.target,
				newConnection.targetHandle!,
			);

			if (typeof result.error === "string") {
				setNotification({
					type: "error",
					message: typeof result.error === "string" ? result.error : "Cannot reconnect nodes",
				});
				return;
			}

			removeConnectionById(events, originalConnection!.id);

			const newEdge = connectionToRfEdge(result.newConnection);

			let newEdges = [...get().edges];

			newEdges = newEdges.filter((edge) => edge.id !== originalConnection.id);

			if (result.removedConnection) {
				newEdges = newEdges.filter((edge) => edge.id !== result.removedConnection!.id);
			}

			newEdges.push(newEdge);

			createChangeCheckpoint();

			set({
				edges: newEdges,
			});
		},
		onReconnectDropped: (id) => {
			const { events, createChangeCheckpoint } = get();

			removeConnectionById(events, id);

			createChangeCheckpoint();

			set({
				edges: get().edges.filter((edge) => edge.id !== id),
			});
		},

		/* ui */
		contextMenu: undefined,
		setContextMenu: (contextMenu?: ContextMenu) => {
			set({ contextMenu });
		},
		notification: undefined,
		setNotification: (notification: Notification) => set({ notification }),
		clearNotification: () => set({ notification: undefined }),
	}));
};

export const ScriptEditorContext = createContext<ScriptEditorStore>(null!);

export const useScriptEditorStore = () => {
	return useContext(ScriptEditorContext);
};

export const useScriptEditorContext = <T>(selector: (state: ScriptEditorState) => T) => {
	return useStore(useContext(ScriptEditorContext), selector);
};
