import type React from "react";
import { useEffect, useRef, useState } from "react";
import { Input } from "@components/ui/Input";
import { useCustomUIStore, type QuadValueSides } from "@stores/custom-ui";
import type { QuadInputType, ValueType } from "@jamango/content-client";
import { Button } from "@components/ui/Button";
import { CornersOut } from "@components/icons";
import { useClickOutside } from "@lib/hooks/useClickOutside";
import { InputIcon } from "./InputIcon";

interface NumberInputProps {
	className: string;
	name: string;
	value: number;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberInput = ({ className, ...props }: NumberInputProps) => {
	return (
		<label htmlFor="" className="inline-flex flex-col gap-1">
			<Input variant="editor" type="number" className={className} placeholder="0" {...props} />
		</label>
	);
};

interface QuadInputProps {
	styleValue: QuadInputType | ValueType;
	styleName: string;
}

export function QuadInput({ styleValue, styleName }: QuadInputProps) {
	const className = "text-base p-2 block";
	const [mixedInput, setMixedInput] = useState(false);
	const [showAllSides, setShowAllSides] = useState(false);
	const setComponentStyle = useCustomUIStore((state) => state.setComponentStyle);
	const [quadValue, setQuadValue] = useState<QuadInputType>(styleValue as QuadInputType);
	const wrapperRef = useRef(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const prevValue = { ...quadValue };
		prevValue[e.target.dataset.side as QuadValueSides] = Number.parseInt(e.target.value);
		setQuadValue(prevValue);
		setComponentStyle(styleName, prevValue);
	};

	const handleSingleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(e.target.value);
		const allValue = {
			top: value,
			bottom: value,
			left: value,
			right: value,
		};
		setQuadValue(allValue);
		setComponentStyle(styleName, allValue);
	};

	useClickOutside(wrapperRef, () => {
		setShowAllSides(false);
	});

	useEffect(() => {
		if (quadValue.top === quadValue.bottom && quadValue.right === quadValue.left) {
			setMixedInput(false);
		} else {
			setMixedInput(true);
		}
	}, [quadValue]);

	return (
		<div className="relative flex w-full items-center  gap-2" ref={wrapperRef}>
			{showAllSides && (
				<div className="absolute top-[110%] z-10 grid grid-cols-2 gap-2 rounded-md bg-white p-2 shadow-md">
					<div className="flex items-center gap-2">
						<InputIcon direction="top" type={styleName} />
						<NumberInput
							className={className}
							value={quadValue?.top ?? 0}
							data-side="top"
							name="Top"
							onChange={handleChange}
						/>
					</div>
					<div className="flex items-center gap-2">
						<InputIcon direction="bottom" type={styleName} />
						<NumberInput
							className={className}
							value={quadValue?.bottom ?? 0}
							data-side="bottom"
							name="Bottom"
							onChange={handleChange}
						/>
					</div>
					<div className="flex items-center gap-2">
						<InputIcon direction="right" type={styleName} />
						<NumberInput
							className={className}
							value={quadValue?.right ?? 0}
							data-side="right"
							name="Right"
							onChange={handleChange}
						/>
					</div>
					<div className="flex items-center gap-2">
						<InputIcon direction="left" type={styleName} />
						<NumberInput
							className={className}
							value={quadValue?.left ?? 0}
							data-side="left"
							name="Left"
							onChange={handleChange}
						/>
					</div>
				</div>
			)}
			{mixedInput ? (
				<label htmlFor="" className="inline-flex flex-col gap-1">
					<Input readOnly value="MIXED" variant="editor" />
				</label>
			) : (
				<NumberInput
					className={className}
					value={quadValue?.left ?? 0}
					data-side="all"
					name="all"
					onChange={handleSingleInputChange}
				/>
			)}

			<Button
				color={showAllSides ? "primary" : "white"}
				square
				onClick={() => {
					setShowAllSides(true);
				}}
			>
				<CornersOut className="size-5" />
			</Button>
		</div>
	);
}
