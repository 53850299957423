import { MISSING_AVATAR } from "@lib/constants/images";
import { cn } from "@lib/helpers/cn";

type IProps = {
	username?: string | null;
	displayPhoto?: string | null;
};

export function UserAvatar({ username, displayPhoto }: IProps) {
	return (
		<div className="rounded-full bg-blue-500">
			<img
				src={displayPhoto ?? MISSING_AVATAR}
				className={cn("block size-8 rounded-full", displayPhoto && "object-cover object-left-top")}
				alt={username ? `${username}'s avatar` : "default user avatar"}
				loading="lazy"
			/>
		</div>
	);
}
