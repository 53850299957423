import type { IAssetKey, IJacyContent } from "@jamango/content-client";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useInventorySelectorStore } from "@stores/hud/inventory-selector";
import { forceRerenderInventory } from "@stores/jacy/rerender";
import { useJacyItemEditorStore } from "../../../stores/jacy/item-editor";
import type { JacyActions } from "../JacyActions";

export class JacyItemActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	editNewItem() {
		try {
			const newItem = this.#state.items.makeEmptyItem();
			useJacyItemEditorStore.getState().editItem(newItem);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editItem(pk: IAssetKey) {
		try {
			const item = this.#state.items.get(pk);

			if (!item) {
				this.#actions.setError("Item not found");
				return;
			}

			useJacyItemEditorStore.getState().editItem(item);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	saveItem() {
		try {
			const item = useJacyItemEditorStore.getState().getItem();

			this.#state.items.set(item);

			forceRerenderInventory();
			useInventoryStore.getState().setSelectedAsset(item.pk);
			useJacyItemEditorStore.getState().close();

			this.#actions.setSuccess(`Item "${item.name}" created successfully.`);

			return item.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteItem() {
		try {
			const item = useJacyItemEditorStore.getState().getItem();

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Item",
				description: `Are you sure you want to the "${item.name}" item? This action cannot be undone. You will lose all the data associated with this item.`,
				confirmText: "Yes, delete this item",
			});

			if (!confirmed) return;

			this.#state.items.delete(item.pk);

			useInventorySelectorStore.getState().refresh();
			forceRerenderInventory();
			useJacyItemEditorStore.getState().close();

			this.#actions.setSuccess(`Item "${item.name}" deleted successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
