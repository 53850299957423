import { useCustomUIModalStore } from "@stores/dialogs";
import { UIEditor } from "./UIEditor";
import type { ReactNode } from "react";
import { useEffect, useRef } from "react";
import { useCustomUIStore } from "@stores/custom-ui";
import { cn } from "@lib/helpers/cn";
import { Panel } from "@components/ui/Panel";
import { useClickOutside } from "@lib/hooks/useClickOutside";
import { useInventoryStore } from "@stores/dialogs/inventory";

interface IProps {
	children: ReactNode;
}

export function CustomUIEditorWrapper({ children }: IProps) {
	const open = useCustomUIModalStore((state) => state.open);
	const setOpen = useCustomUIModalStore((state) => state.setOpen);
	const toggleModal = useCustomUIModalStore((state) => state.toggle);
	const nodeRef = useRef<HTMLDivElement>(null);
	const editorPosition = useCustomUIStore((state) => state.editorPosition);
	const closeCustomUIModal = useCustomUIModalStore((state) => state.close);
	const toggleInventory = useInventoryStore((state) => state.toggle);

	useClickOutside(nodeRef, () => {
		closeCustomUIModal();
		toggleInventory();
	});

	useEffect(() => {
		if (!open) return;

		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				toggleModal();
				e.preventDefault();
			}
		};

		if (open) {
			document.addEventListener("keydown", handleKeyDown);
		}

		return () => {
			if (open) {
				document.removeEventListener("keydown", handleKeyDown);
			}
		};
	}, [open]);

	if (!open) return null;

	return (
		<Panel open={open} setOpen={setOpen} variant="base" ref={nodeRef}>
			<div
				className={cn(
					"editor-handle h-screen max-w-lg cursor-move rounded-lg bg-white/60 p-3 shadow-md backdrop-blur-0 lg:h-[90vh]",
					editorPosition === "right" && "right-4 top-4",
					editorPosition === "left" && "left-8 top-4",
				)}
				ref={nodeRef}
			>
				{children}
			</div>
		</Panel>
	);
}

export function CustomUIEditor() {
	return (
		<CustomUIEditorWrapper>
			<UIEditor />
		</CustomUIEditorWrapper>
	);
}
