import type { IAuthUser } from "@jamango/content-client";
import type { User } from "@jamango/content-service/types/index.ts";
import { apiClient } from "./index";

function convertServerUserToClientUser(user: User) {
	return {
		id: user.id,
		username: (user as any).name,
		email: user.email,
		featureFlags: user.featureFlags ?? [],
		acceptedAlphaTOS: true,
		hasPlayedTutorial: true,
		globalAvatarId: user.globalAvatarId ?? undefined,
		role: user.role as IAuthUser["role"],
		photoUrl: user.photoUrl,
	} satisfies IAuthUser;
}

export async function register({
	accessCode,
	username,
	email,
	password,
}: {
	accessCode?: string;
	username: string;
	email: string;
	password: string;
}) {
	const { data, error } = await apiClient.auth.signUp.email({
		email,
		password,
		name: username,
		accessCode,
	});

	if (error || !data) return undefined;

	const user = data.user as any as User;

	return {
		user: convertServerUserToClientUser(user),
	};
}

export async function updateEmail(newEmail: string) {
	await apiClient.auth.changeEmail({ newEmail }); // TODO email verification page!
}

export async function updatePassword(currentPassword: string, newPassword: string) {
	await apiClient.auth.changePassword({ newPassword, currentPassword });
}

export function updateForgottenPassword(token: string, newPassword: string) {
	return apiClient.auth.resetPassword({ token, newPassword });
}

export function resetPassword(email: string) {
	return apiClient.auth.forgetPassword({ email });
}

export async function login(email: string, password: string) {
	const { data, error } = await apiClient.auth.signIn.email({ email, password, rememberMe: true });

	if (error) {
		throw error;
	}

	return {
		user: convertServerUserToClientUser(data.user as any as User),
	};
}

export async function signInWithDiscord() {
	const { data, error } = await apiClient.auth.signIn.social({
		provider: "discord",
		callbackURL: (globalThis as any).globalEnv?.FRONTEND_URL ?? "http://localhost:5173",
	});

	console.log(data, error);

	if (error) throw error;

	return {
		user: convertServerUserToClientUser((data as any).user as any as User),
	};
}

export async function auth() {
	const { data, error } = await apiClient.auth.getSession({
		query: {
			disableCookieCache: true,
		},
	});
	console.log(data, error);
	if (!data) return;

	const user = data.user as any as User;

	return convertServerUserToClientUser(user);
}

export async function logout() {
	await apiClient.auth.signOut();
}

export async function verifyEmail(token: string) {
	const { error } = await apiClient.auth.verifyEmail({
		query: {
			token,
		},
	});

	if (error) throw error;

	// After successful verification, get the user session
	// This works with autoSignInAfterVerification: true in the backend
	return auth();
}

export async function acceptTOS() {
	await console.error(`api.acceptTOS Not implemented`);
}

export async function updateHasPlayedTutorial(_hasPlayedTutorial: boolean) {
	await console.error(`api.updateHasPlayedTutorial Not implemented`);
}
