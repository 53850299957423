import { getStaticFile } from "@jamango/content-client";

export default `
    @font-face {
        font-family: 'Anek Devanagari';
        src: url(${getStaticFile("assets/frontend/fonts/Anek_Devanagari/AnekDevanagari-Medium.woff2")}) format('woff2'); 
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
`;
