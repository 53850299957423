import type { IAssetKey, IBundle, ICharacter, IParsedCharacter } from "../../lib/types";
import { AssetType } from "../../lib/types";
import { generateRandomCharacterName, TOTAL_VARIATIONS } from "../../lib/helpers/characters";
import type { JacyContentState } from "../JacyContentState";
import { generateId, getIdentifier } from "../../lib/helpers/general";
import { validations } from "../../lib/validations";
import { isNullish } from "@jamango/helpers";

export class JacyCharactersState {
	#state: JacyContentState;
	characters: Record<IAssetKey, ICharacter>;
	#builtInCharacters: Set<IAssetKey>;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.characters = {};
		this.#builtInCharacters = new Set();
	}

	import(data?: IBundle["assets"]["characters"]) {
		if (!data) return;

		this.characters = Object.assign(this.characters, data);
		this.#state.markDirty();
	}

	export() {
		return Object.fromEntries(
			Object.entries(this.characters).filter(([pk]) => !this.#builtInCharacters.has(pk)),
		);
	}

	get(pk?: IAssetKey | null) {
		if (!pk) return null;
		const character = this.characters[pk];
		if (!character) return null;
		return this.#parseCharacter(character);
	}

	getByName(name: string) {
		return Object.values(this.characters).find((character) => character.name === name);
	}

	getUnparsed(pk?: IAssetKey | null) {
		if (!pk) return null;
		return this.characters[pk];
	}

	set(data: ICharacter) {
		this.characters[data.pk] = data;
		this.#state.markDirty();
	}

	getAll() {
		return Object.values(this.characters)
			.map((character) => this.#parseCharacter(character))
			.filter((item) => !isNullish(item)) as IParsedCharacter[];
	}

	getRandomName() {
		const characterNames = this.getAll().map((character) => character.name);
		let name = generateRandomCharacterName();
		let counter = characterNames.length + 1;

		while (characterNames.includes(name)) {
			name =
				characterNames.length - 40 >= TOTAL_VARIATIONS
					? generateRandomCharacterName() + " " + counter++
					: generateRandomCharacterName();
		}

		return name;
	}

	isBuiltIn(name: string) {
		const character = this.getByName(name);
		if (!character) return true;

		return this.#builtInCharacters.has(name);
	}

	setBuiltIn(character: ICharacter) {
		this.set(character);
		this.#builtInCharacters.add(character.pk);
	}

	createPK(id = generateId()) {
		return `${AssetType.CHARACTER}#${id}`;
	}

	create(character: Omit<ICharacter, "id" | "type">) {
		const today = new Date().toISOString();

		const isNameUnique = !this.getAll().some((item) => item.name === character.name);

		if (!isNameUnique) {
			throw new Error(`Character name "${character.name}" already exists.`);
		}

		validations.character.create(character);

		const createdCharacter: ICharacter = {
			pk: character.pk,
			id: getIdentifier(character.pk),
			type: AssetType.CHARACTER,
			name: character.name,
			scripts: character.scripts,
			aiAvatar: character.aiAvatar,
			aiType: character.aiType,
			showNametag: character.showNametag,
			dropItem: character.dropItem,
			canKillNPCs: character.canKillNPCs,
			needsReload: true,
			createdAt: today,
			updatedAt: today,
		};

		this.set(createdCharacter);

		return createdCharacter;
	}

	update(character: ICharacter) {
		const pk = character.pk;

		const existingCharacter = this.characters[pk];

		if (!existingCharacter) {
			throw new Error(`Character not found: ${pk}`);
		}

		const isNameUnique = !this.getAll().some((item) => item.name === character.name && item.pk !== pk);

		if (!isNameUnique) {
			throw new Error(`Character name "${character.name}" already exists.`);
		}

		validations.character.all(character);

		const updatedCharacter = {
			pk: existingCharacter.pk,
			id: existingCharacter.id,
			type: existingCharacter.type,
			name: character.name,
			scripts: character.scripts,
			aiType: character.aiType,
			canKillNPCs: character.canKillNPCs,
			dropItem: character.dropItem,
			showNametag: character.showNametag,
			aiAvatar: character.aiAvatar,
			needsReload: true,
			createdAt: existingCharacter.createdAt,
			updatedAt: new Date().toISOString(),
		} satisfies ICharacter;

		this.set(updatedCharacter);

		return updatedCharacter;
	}

	delete(pk: IAssetKey) {
		const character = this.characters[pk];
		if (!character) return;

		delete this.characters[pk];

		this.#state.markDirty();

		// Check if avatar is used by other characters, if not, remove it
	}

	onWorldReload() {
		for (const character of Object.values(this.characters)) {
			if (character.needsReload) {
				this.set({
					...character,
					needsReload: false,
				});
			}
		}
	}

	onSave() {
		for (const character of Object.values(this.characters)) {
			character.updatedAt = new Date().toISOString();
			this.set(character);
		}
	}

	#parseCharacter(character: ICharacter) {
		const aiAvatar = this.#state.avatars.get(character.aiAvatar);

		if (!aiAvatar) {
			console.error(`Character ${character.name} has missing AI avatar.`);
			return null;
		}

		return {
			...character,
			aiAvatar,
		} satisfies IParsedCharacter;
	}
}
