import { getStaticFile } from "@jamango/content-client";

// The original version of this font is vertically offset too high.
// To fix the font, go to https://www.fontsquirrel.com/tools/webfont-generator
// Keep Optimal mode

export default `
@font-face {
  font-family: 'Midfield Rounded';
  src: url(${getStaticFile("assets/frontend/fonts/midfield-rounded.woff2")}) format('woff2'),
  font-weight: normal;
  font-style: normal;
}
`;
