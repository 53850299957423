import { isNullish } from "@jamango/helpers";
import { generateId } from "../../lib/helpers/general";
import type { IAudio, IBundle, IParsedAudio } from "../../lib/types";
import { AssetType, type IAssetKey } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";

export class JacyAudioState {
	#state: JacyContentState;
	#audios: NonNullable<IBundle["assets"]["audios"]>;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#audios = {};
	}

	import(data?: IBundle["assets"]["audios"]) {
		if (!data) return;

		this.#audios = Object.assign(this.#audios, data);
		this.#state.markDirty();
	}

	export() {
		return this.#audios;
	}

	set(data: IAudio) {
		this.#audios[data.pk] = data;
		this.#state.markDirty();
	}

	get(pk: IAssetKey) {
		const audio = this.#audios[pk];
		if (!audio) return;
		return this.#parsedAudio(audio);
	}

	delete(pk: IAssetKey) {
		delete this.#audios[pk];
		this.#state.markDirty();
	}

	useAudioResource(resourcePk: string, name: string) {
		const id = generateId();
		const pk = `${AssetType.AUDIO}#${id}`;

		const audio = {
			pk,
			type: AssetType.AUDIO,
			resourcePk,
			name,
		} as const;

		this.set(audio);

		return audio;
	}

	getAll(): IParsedAudio[] {
		return Object.values(this.#audios)
			.map((audio) => this.#parsedAudio(audio))
			.filter((item) => !isNullish(item)) as IParsedAudio[];
	}

	setAudio(audio: Omit<IAudio, "type">) {
		this.set({
			...audio,
			type: AssetType.AUDIO,
		});
	}

	#parsedAudio(data: IAudio): IParsedAudio | null {
		const resource = this.#state.resources.getAudio(data.resourcePk);

		if (!resource) {
			return null;
		}

		return {
			...data,
			resource,
			name: (data.name = data.name
				.replace("snd-", "")
				// Remove UUID suffix if present (it is not always present)
				.replace(/-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/, "")),
		};
	}
}
