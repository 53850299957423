import React from "react";
import { cn } from "@lib/helpers/cn";

type IProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onChange"> & {
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	setChecked?: (newState: boolean | ((prevState: boolean) => boolean)) => void;
};

function SwitchBase(
	{ checked = false, onChange, setChecked, className, ...props }: IProps,
	ref: React.Ref<HTMLButtonElement>,
) {
	const handleClick = () => {
		if (setChecked) {
			setChecked((state) => !state);
		}

		if (onChange) {
			onChange(!checked);
		}
	};

	return (
		<button
			ref={ref}
			type="button"
			role="switch"
			className={cn(
				"relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center gap-2 rounded-lg p-0.5 text-base font-medium ring-blue-300 transition duration-200 ease-in-out focus:text-slate-900 focus:outline-none focus-visible:ring-4 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm-h:h-8 sm-h:w-14",
				checked
					? " bg-blue-500 text-white enabled:hover:bg-blue-600"
					: " bg-slate-300 text-slate-900 enabled:hover:bg-slate-400",
				className,
			)}
			aria-checked={checked}
			onClick={handleClick}
			{...props}
		>
			{props["aria-label"] && <span className="sr-only">{props["aria-label"]}</span>}
			<span
				aria-hidden="true"
				className={cn(
					"pointer-events-none inline-block size-4 transform rounded-md bg-white shadow-[inset_0_-3px_#e4e4e4] ring-0 transition duration-200 ease-in-out sm-h:size-7",
					checked ? "translate-x-5 sm-h:translate-x-6" : "translate-x-0",
				)}
			/>
		</button>
	);
}

export const Switch = React.forwardRef(SwitchBase);
Switch.displayName = "Switch";
