import { createContext, useContext } from "react";
import { useStore } from "zustand";
import type { StoreApi } from "zustand/vanilla";
import { createStore } from "zustand/vanilla";
import type { UndoManager } from "../undo";
import { createUndoManager } from "../undo";
import { Jacy } from "../../jacy/JacyClient";
import type { IEditorStoreState } from "../editor-types";

export type BlockGroupEditorState = IEditorStoreState & {
	state: {
		id: string;
		name: string | null;
		scripts: Array<{
			id: string;
			data?: Record<string, any>;
		}>;
	};

	undoManager: UndoManager<BlockGroupEditorState["state"]>;

	setBlockGroup: (state: BlockGroupEditorState["state"]) => void;

	setName: (name: string | null) => void;

	addScript: (script: string) => void;
	addNewScript: () => string;
	removeScript: (script: string) => void;

	setScriptData: (script: string, data: Record<string, any>) => void;

	undo: () => void;
	redo: () => void;
};

export type BlockGroupEditorStore = StoreApi<BlockGroupEditorState>;

export const createBlockGroupEditorStore = (): BlockGroupEditorStore => {
	return createStore<BlockGroupEditorState>((set, get) => ({
		state: {
			id: "",
			name: "",
			scripts: [],
		},
		modified: false,
		undoManager: null!,
		undo: () => {
			const { undoManager } = get();

			undoManager.undo();

			set({
				state: undoManager.get(),
				modified: true,
			});
		},
		redo: () => {
			const { undoManager } = get();

			undoManager.redo();

			set({
				state: undoManager.get(),
				modified: true,
			});
		},
		setBlockGroup: (state) => {
			const undoManager = createUndoManager<BlockGroupEditorState["state"]>();
			undoManager.init(state);

			set({
				state: {
					id: state.id,
					name: state.name,
					scripts: state.scripts,
				},
				undoManager,
				modified: false,
			});
		},
		setName: (name) => {
			const { state, undoManager } = get();

			state.name = name;

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		addScript: (scriptPk) => {
			const { state, undoManager } = get();

			const defaultScriptData = Jacy.actions.scripts.getDefaultScriptControlsData(scriptPk);
			state.scripts = [...state.scripts, { id: scriptPk, data: defaultScriptData }];

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		addNewScript: () => {
			const { state, undoManager } = get();

			const scriptName = state.name ? `${state.name} script` : "New script";

			const newScript = Jacy.actions.scripts.create(scriptName);

			state.scripts = [...state.scripts, { id: newScript }];

			undoManager.set(state);

			set({
				state,
				modified: true,
			});

			return newScript;
		},
		removeScript: (script) => {
			const { state, undoManager } = get();

			state.scripts = state.scripts.filter((s) => s.id !== script);

			undoManager.set(state);

			set({
				state,
				modified: true,
			});
		},
		setScriptData: (id, data) => {
			const { state } = get();

			set({
				state: {
					...state,
					scripts: state.scripts.map((s) => (s.id === id ? { ...s, data } : s)),
				},
				modified: true,
			});
		},
	}));
};

export const BlockGroupEditorContext = createContext<BlockGroupEditorStore>(null!);

export const useBlockGroupEditorStore = <T>(
	store: BlockGroupEditorStore,
	selector: (state: BlockGroupEditorState) => T,
) => {
	return useStore(store, selector);
};

export const useBlockGroupEditorContext = <T>(selector: (state: BlockGroupEditorState) => T) => {
	return useStore(useContext(BlockGroupEditorContext), selector);
};
