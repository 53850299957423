// @ts-ignore
if (!window.globalEnv) {
	// @ts-ignore
	window.globalEnv = {};

	console.error(`You need to populate globalEnv in setup_envs.js`);
}

import React from "react";
import { createRoot } from "react-dom/client";

import { RouterProvider } from "react-router";
import { GameClient } from "@jamango/client";
import { router } from "./Router";

import midfieldRounded from "@styles/fonts/midfield-rounded";
import clashGrotesk from "@styles/fonts/clash-grotesk";
import interTight from "@styles/fonts/inter-tight";
import orbitron from "@styles/fonts/orbitron";
import spaceMono from "@styles/fonts/space-mono";
import electrolize from "@styles/fonts/electrolize";
import archivo from "@styles/fonts/archivo";
import anekDevanagari from "@styles/fonts/anek-devanagari";
import anton from "@styles/fonts/anton";
import blackOpsOne from "@styles/fonts/black-ops-one";
import forum from "@styles/fonts/forum";
import jersey10 from "@styles/fonts/jersey-10";
import luckiestGuy from "@styles/fonts/luckiest-guy";
import newAmasterdam from "@styles/fonts/new-amsterdam";
import righteous from "@styles/fonts/righteous";
import urbanist from "@styles/fonts/urbanist";

import "./hmr";

import "./polyfill";

import "@xyflow/react/dist/base.css";
import "@styles/index.css";

GameClient.init();

createRoot(document.getElementById("root")!).render(
	<React.Suspense>
		<style>{`
			${midfieldRounded} 
			${clashGrotesk} 
			${interTight} 
			${orbitron} 
			${spaceMono} 
			${electrolize} 
			${archivo} 
			${anekDevanagari} 
			${anton} 
			${blackOpsOne} 
			${forum} 
			${jersey10} 
			${luckiestGuy} 
			${newAmasterdam} 
			${righteous} 
			${urbanist}
		`}</style>
		<RouterProvider router={router} />
	</React.Suspense>,
);
