import type { IJacyContent, IJacyEventsEmitter } from "@jamango/content-client";
import { JacyCore, WorldUserRole } from "@jamango/content-client";
import { createLogger, createRequestPromise, type IPromise } from "@jamango/helpers";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { initializeAnalytics } from "@lib/helpers/analytics/analyzeMe";
import { GameClient } from "@jamango/client";
import { JacyActions } from "./content/JacyActions";
import { auth } from "rest-client";
import { useAuthUserStore } from "@stores/auth-user";

const logger = createLogger("JacyClient");

export class JacyClient {
	readonly initializePromise: IPromise<void>;
	role: WorldUserRole;
	readonly tutorialWorldId = `4W3AC`;
	dom: {
		avatarEditor: HTMLCanvasElement | null;
	};

	ongoingUploads: Set<File>;

	content: IJacyContent;
	state: IJacyContent["state"];
	events: IJacyEventsEmitter;
	actions: JacyActions;

	getFilePath: IJacyContent["state"]["getFilePath"];

	constructor() {
		this.initializePromise = createRequestPromise<void>();
		this.role = WorldUserRole.OWNER;
		this.dom = {
			avatarEditor: null,
		};

		this.events = new JacyCore.EventEmitter();
		this.content = new JacyCore.Content(this.events as any);
		this.state = this.content.state;
		this.actions = new JacyActions(this);

		this.ongoingUploads = new Set();

		this.getFilePath = this.state.getFilePath.bind(this.content.state);

		this.events.on("jacy_sync_patches", this.#handleJacySyncPatches);
	}

	async init() {
		initializeAnalytics();

		const authUser = await auth().catch(() => null);

		useAuthUserStore.getState().setUser(authUser ?? null);

		this.initializePromise.resolve();

		return this.initializePromise;
	}

	#handleJacySyncPatches = (patches: any[]) => {
		if (patches.length > 0) {
			this.refreshContentState();
		}
	};

	setIsServer(isServer: boolean) {
		this.content.state.isServer = isServer;
	}

	setIsPublicServer(isPublicServer: boolean) {
		this.content.state.isPublicServer = isPublicServer;
	}

	setAvatarEditorCanvas(canvas: HTMLCanvasElement) {
		this.dom.avatarEditor = canvas;
		useJacyRerenderStore.getState().forceRerenderAvatarCanvas();
	}

	async import() {
		await GameClient.importWorld();

		logger.info("World has been imported!");
	}

	async export() {
		await GameClient.exportWorld();

		logger.info("World has been exported!");
	}

	refreshContentState() {
		useJacyRerenderStore.getState().forceRerenderAll();
	}
}

export const Jacy = new JacyClient();
