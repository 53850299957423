import type { z } from "zod";
import type { AssetFileSchema } from "../validations/general";

export enum S3FilePath {
	SAVE_WORLD = "save-world",
	UPLOADING_ASSET = "uploading-asset",
	UPLOADING_PACKAGE = "uploading-package",
	UPLOADING_JAMFILE = "uploading-jamfile",
}

export enum S3FileName {
	SAVE_WORLD = "changeset.zip",
	BUNDLE = "bundle.zip",
}

export type AssetFile = z.infer<typeof AssetFileSchema>;
