import { DEFAULT_CUSTOM_LOADER } from "../../lib/constants/custom-loader";
import { generateId } from "../../lib/helpers/general";
import type { BannerImageResource, IParsedCustomLoader } from "../../lib/types";
import { AssetType, type ICustomLoader } from "../../lib/types";
import { validations } from "../../lib/validations";
import type { JacyContentState } from "../JacyContentState";

export class JacyCustomLoaderState {
	readonly #state: JacyContentState;
	#loader: ICustomLoader;

	get pk() {
		return this.#loader.pk;
	}

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#loader = this.getDefault();
	}

	import(data: ICustomLoader = this.getDefault()) {
		this.set(data);
	}

	export() {
		return { ...this.#loader };
	}

	get show() {
		return this.#loader.enabled;
	}

	get title() {
		return this.#loader.title;
	}

	get description() {
		return this.#loader.description;
	}

	get banner() {
		return this.get().banner;
	}

	get bannerUrl() {
		const banner = this.get().banner;

		return banner?.resource.file.url;
	}

	get(): IParsedCustomLoader {
		let parsedBanner: IParsedCustomLoader["banner"] | undefined = undefined;

		if (this.#loader.banner) {
			const bannerResource = this.#state.resources.get(
				this.#loader.banner.resourcePk,
			) as BannerImageResource;

			if (bannerResource) {
				parsedBanner = {
					resource: bannerResource,
				};
			}
		}

		return {
			...this.#loader,
			banner: parsedBanner,
		};
	}

	setShow(show: boolean) {
		validations.customLoader.show(show);
		this.update({ enabled: show });
	}

	setTitle(title: string) {
		validations.customLoader.title(title);
		this.update({ title });
	}

	setDescription(description: string) {
		validations.customLoader.description(description);
		this.update({ description });
	}

	useBannerResource(pk: string) {
		this.#clearBanner();

		const customLoader: Pick<ICustomLoader, "banner"> = {
			banner: {
				resourcePk: pk,
			},
		};

		this.update(customLoader);
	}

	#clearBanner() {
		if (!this.#loader.banner) {
			return;
		}

		const resource = this.#state.resources.get(this.#loader.banner.resourcePk);

		if (resource) {
			this.#state.resources.remove(resource.pk);
		}
	}

	update(data: Partial<ICustomLoader>) {
		this.set({
			...this.#loader,
			...data,
		});
	}

	set(data: ICustomLoader) {
		this.#loader = structuredClone(data);

		if (!this.#loader.title) {
			const worldName = this.#state.worldData.name;
			this.#loader.title = worldName;
		}

		this.#state.markDirty();
	}

	getDefault() {
		const id = generateId();

		return {
			...DEFAULT_CUSTOM_LOADER,
			pk: `${AssetType.CUSTOM_LOADER}#${id}`,
			enabled: false,
		} satisfies ICustomLoader;
	}
}
