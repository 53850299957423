import { ValidationError } from "@jamango/content-client";
import type { IJacyEventsEmitter, IJacyContent } from "@jamango/content-client";
import type { JacyClient } from "@jacy-client";
import { createLogger } from "@jamango/helpers";

import { JacyThumbnailActions } from "./JacyActions/JacyThumbnailActions";
import { JacyCustomLoaderActions } from "./JacyActions/JacyCustomLoaderActions";
import { JacyGameMechanicsActions } from "./JacyActions/JacyGameMechanicsActions";
import { JacySettingsActions } from "./JacyActions/JacySettingsActions";
import { JacySkyboxActions } from "./JacyActions/JacySkyboxActions";
import { JacyEnvironmentPresetActions } from "./JacyActions/JacyEnvironmentPresetActions";
import { JacyBlockTextureActions } from "./JacyActions/JacyBlockTextureActions";
import { JacyBlockActions } from "./JacyActions/JacyBlockActions";
import { JacyCharacterActions } from "./JacyActions/JacyCharacterActions";
import { JacyAvatarActions } from "./JacyActions/JacyAvatarActions";
import { JacyAudioActions } from "./JacyActions/JacyAudioActions";
import { useGameClientStore } from "@stores/game-client/game-client";
import { JacyWorldDataActions } from "./JacyActions/JacyWorldDataActions";
import { useGeneralNotificationStore } from "@stores/dialogs/general-notification";
import { JacyCostumeActions } from "./JacyActions/JacyCostumeActions";
import { formatErrorMessage } from "@lib/helpers/formatErrorMessage";
import { JacyScriptActions } from "./JacyActions/JacyScriptActions";
import { JacyPropActions } from "./JacyActions/JacyPropActions";
import { JacyItemActions } from "./JacyActions/JacyItemActions";

const logger = createLogger("JacyActions");

export class JacyActions {
	client: JacyClient;
	content: IJacyContent;
	state: IJacyContent["state"];
	events: IJacyEventsEmitter;

	thumbnail: JacyThumbnailActions;
	customLoader: JacyCustomLoaderActions;
	gameMechanics: JacyGameMechanicsActions;
	settings: JacySettingsActions;
	skybox: JacySkyboxActions;
	environmentPresets: JacyEnvironmentPresetActions;
	blockTexture: JacyBlockTextureActions;
	block: JacyBlockActions;
	character: JacyCharacterActions;
	prop: JacyPropActions;
	item: JacyItemActions;
	avatar: JacyAvatarActions;
	audio: JacyAudioActions;
	worldData: JacyWorldDataActions;
	costumes: JacyCostumeActions;
	scripts: JacyScriptActions;

	constructor(client: JacyClient) {
		this.client = client;
		this.content = client.content;
		this.events = client.events;
		this.state = client.state;

		this.worldData = new JacyWorldDataActions(this);
		this.thumbnail = new JacyThumbnailActions(this);
		this.customLoader = new JacyCustomLoaderActions(this);
		this.gameMechanics = new JacyGameMechanicsActions(this);
		this.settings = new JacySettingsActions(this);
		this.skybox = new JacySkyboxActions(this);
		this.environmentPresets = new JacyEnvironmentPresetActions(this);
		this.blockTexture = new JacyBlockTextureActions(this);
		this.block = new JacyBlockActions(this);
		this.character = new JacyCharacterActions(this);
		this.prop = new JacyPropActions(this);
		this.item = new JacyItemActions(this);
		this.avatar = new JacyAvatarActions(this);
		this.audio = new JacyAudioActions(this);
		this.costumes = new JacyCostumeActions(this);
		this.scripts = new JacyScriptActions(this);
	}

	setInfo(message: string) {
		useGameClientStore.getState().setNotification({ type: "info", message });
	}

	setSuccess(message: string) {
		useGameClientStore.getState().setNotification({ type: "success", message });
	}

	setError(error: unknown) {
		if (error instanceof Error) {
			useGameClientStore.getState().setNotification({
				type: "error",
				message:
					error instanceof ValidationError
						? error.issues.map((issue) => issue.message)
						: formatErrorMessage(error),
			});
		} else {
			useGameClientStore.getState().setNotification({
				type: "error",
				message: formatErrorMessage(error),
			});
		}
	}

	setGeneralInfo(message: string) {
		useGeneralNotificationStore.getState().setNotification({ type: "info", message });
	}

	setGeneralSuccess(message: string) {
		useGeneralNotificationStore.getState().setNotification({ type: "success", message });
	}

	setGeneralError(error: unknown) {
		if (error instanceof Error) {
			useGeneralNotificationStore.getState().setNotification({
				type: "error",
				message:
					error instanceof ValidationError
						? error.issues.map((issue) => issue.message)
						: error.message,
			});
		} else {
			logger.error(error);
		}
	}

	setValidationsErrors(error: unknown) {
		if (error instanceof Error) {
			useGameClientStore.getState().setNotification({
				type: "error",
				message:
					error instanceof ValidationError
						? error.issues.map((issue) => issue.message)
						: error.message,
			});
		} else {
			logger.error(error);
		}
	}
}
