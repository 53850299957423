import { Jacy } from "@jacy-client";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { useMemo } from "react";

export function useJacyWorldOwnerState() {
	useJacyRerenderStore((state) => state.reRenderWorldData);
	return Jacy.state.worldData.owner;
}

export function useJacyWorldCollaboratorsState() {
	useJacyRerenderStore((state) => state.reRenderWorldData);
	return Jacy.state.worldData.collaborators;
}

export function useJacyWorldDataState() {
	useJacyRerenderStore((state) => state.reRenderWorldData);
	return Jacy.state.worldData;
}

export function useJacyWorldPublishState() {
	useJacyRerenderStore((state) => state.reRenderWorldData);
	return Jacy.state.worldData.publish;
}

export function useJacyWorldVersionState() {
	useJacyRerenderStore((state) => state.reRenderWorldData);
	return Jacy.state.worldData.version;
}

export function useJacyScriptsState() {
	useJacyRerenderStore((state) => state.reRenderScripts);
	return Jacy.state.scripts;
}

export function useJacyScripts() {
	const reRenderScripts = useJacyRerenderStore((state) => state.reRenderScripts);
	return useMemo(() => {
		return [...Jacy.state.scripts.getAll()];
	}, [reRenderScripts]);
}

export function useJacyScript(pk: string) {
	const reRenderScripts = useJacyRerenderStore((state) => state.reRenderScripts);

	return useMemo(() => {
		return Jacy.state.scripts.get(pk);
	}, [pk, reRenderScripts]);
}

export function useJacyBlockStructures() {
	const reRenderBlockStructures = useJacyRerenderStore((state) => state.reRenderBlockStructures);
	return useMemo(() => {
		return { ...Jacy.state.blockStructures.blockStructures };
	}, [reRenderBlockStructures]);
}

export function useJacyProps() {
	const reRenderProps = useJacyRerenderStore((state) => state.reRenderProps);
	return useMemo(() => {
		return { ...Jacy.state.props.props };
	}, [reRenderProps]);
}

export function useJacyCharacters() {
	const reRenderProps = useJacyRerenderStore((state) => state.reRenderCharacters);
	return useMemo(() => {
		return { ...Jacy.state.characters.characters };
	}, [reRenderProps]);
}
