import { useJacyRerenderStore } from "@stores/jacy/rerender";
import type { IGameMechanics, IJacyContent } from "@jamango/content-client";
import { validations } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";

export class JacyGameMechanicsActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setMaxPlayers(value: number) {
		validations.gameMechanics.maxPlayers(value);
		this.#updateGameMechanics({ maxPlayers: value });
	}

	setCharacterCollisions(characterCollisions: boolean) {
		this.#updateGameMechanics({ characterCollisions });
	}

	#updateGameMechanics(data: Partial<IGameMechanics>) {
		this.#state.gameMechanics.update(data);
		useJacyRerenderStore.getState().forceRerenderGameMechanics();
	}
}
