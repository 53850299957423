import { Outlet } from "react-router";
import { GlobalComponents } from "./components/GlobalComponents";

export function Root() {
	return (
		<main className="h-full">
			<GlobalComponents />
			<Outlet />
		</main>
	);
}
