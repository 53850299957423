import { Jacy } from "@jacy-client";
import type { IAssetKey, ICharacter, IDateString, IParsedCharacter } from "@jamango/content-client";
import { AIType, AssetType, getIdentifier } from "@jamango/content-client";
import { create } from "zustand";
import { Cube, PersonSimple } from "@components/icons";

export const TABS = [
	{ label: "Basic", icon: Cube },
	{ label: "Avatar", icon: PersonSimple },
];

type IJacyCharacterEditorState = {
	open: boolean;
	selectedTab: string;
	isCreate?: boolean;

	pk: IAssetKey;
	name: string;
	aiType: AIType;
	showNametag: boolean;
	dropItem: boolean;
	canKillNPCs: boolean;
	avatar?: IAssetKey | null;
	createdAt: IDateString;
	updatedAt: IDateString;
	scripts: ICharacter["scripts"];

	// Handlers
	setSelectedTab: (selectedTab: string) => void;
	setName: (name: string) => void;
	setAIType: (aiType: AIType) => void;
	setShowNametag: (showNametag: boolean) => void;
	setDropItem: (dropItem: boolean) => void;
	setCanKillNPCs: (canKillNPCs: boolean) => void;
	setAvatar: (pk: IAssetKey | null) => void;
	addScript: (scriptId: string) => void;
	removeScript: (scriptId: string) => void;
	setScriptData: (scriptId: string, data: Record<string, any>) => void;

	// Actions
	close: () => void;
	randomizeName: () => void;
	getCharacter: () => ICharacter;
	newCharacter: () => void;
	editCharacter: (character: IParsedCharacter) => void;
};

const DEFAULT_STATE = {
	name: "",
	aiType: AIType.NEUTRAL,
	showNametag: true,
	dropItem: true,
	canKillNPCs: false,
	avatar: null,
	createdAt: new Date().toISOString(),
	updatedAt: new Date().toISOString(),
	scripts: [] as ICharacter["scripts"],
};

export const useJacyCharacterEditorStore = create<IJacyCharacterEditorState>((set, get) => ({
	...DEFAULT_STATE,
	pk: "",

	open: false,
	selectedTab: TABS[0].label,

	// Handlers
	setSelectedTab: (selectedTab) => {
		set({ selectedTab });
	},
	setName: (name) => set({ name }),
	setAIType: (aiType) => set({ aiType }),
	setShowNametag: (showNametag) => set({ showNametag }),
	setDropItem: (dropItem) => set({ dropItem }),
	setCanKillNPCs: (canKillNPCs) => set({ canKillNPCs }),
	setAvatar: (pk) => set({ avatar: pk }),
	addScript: (scriptId) => {
		const data = Jacy.actions.scripts.getDefaultScriptControlsData(scriptId);

		const scripts = [...get().scripts, { script: scriptId, data }];

		set({ scripts });
	},
	removeScript: (scriptId) => {
		const scripts = get().scripts.filter((script) => script.script !== scriptId);
		set({ scripts });
	},
	setScriptData: (scriptId, data) => {
		const scripts = get().scripts.map((script) =>
			script.script === scriptId ? { script: script.script, data } : script,
		);
		set({ scripts });
	},

	// Actions
	close: () => set({ open: false }),
	randomizeName: () => set({ name: Jacy.state.characters.getRandomName() }),
	getCharacter: () => {
		const pk = get().pk;

		return {
			pk: pk,
			id: getIdentifier(pk),
			type: AssetType.CHARACTER,
			name: get().name,
			scripts: get().scripts,
			aiType: get().aiType,
			showNametag: get().showNametag,
			dropItem: get().dropItem,
			canKillNPCs: get().canKillNPCs,
			aiAvatar: get().avatar,
			createdAt: get().createdAt,
			updatedAt: get().updatedAt,
		} satisfies ICharacter;
	},
	newCharacter: () => {
		const name = Jacy.state.characters.getRandomName();
		const today = new Date().toISOString();

		const avatar = Jacy.state.avatars.getAvatarForNewCharacter();

		set({
			...structuredClone(DEFAULT_STATE),
			open: true,
			selectedTab: TABS[0].label,
			isCreate: true,
			name,
			avatar: avatar?.pk,
			createdAt: today,
			updatedAt: today,
			pk: Jacy.state.characters.createPK(),
		});
	},
	editCharacter: (character) => {
		set({
			...structuredClone(DEFAULT_STATE),
			open: true,
			isCreate: false,
			selectedTab: TABS[0].label,
			pk: character.pk,
			name: character.name,
			scripts: character.scripts,
			aiType: character.aiType,
			showNametag: character.showNametag,
			avatar: character.aiAvatar.pk,
			dropItem: character.dropItem,
			canKillNPCs: character.canKillNPCs,
			createdAt: character.createdAt,
			updatedAt: character.updatedAt,
		});
	},
}));
