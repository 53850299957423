import type { EditorClient } from "@jamango/engine/Events.ts";
import type * as RF from "@xyflow/react";
import type { Comment, Events, Frame, Node, NodeConnection } from "@jamango/content-client";
import {
	getNode,
	getNodeDef,
	removeConnectionById,
	setNodeDimensions,
	setNodePosition,
	tryRemoveNode,
} from "./actions";
import type { EditorNodeData, EditorSelectionState, RfEdge, RfNode, RfNodeComment } from "./store";

export const nodeToRfNode = (editorClient: EditorClient, events: Events, node: Node): RfNode | null => {
	const def = getNodeDef(editorClient, node.defId, events, node.id);

	const collapsible = def
		? Object.values(def.inputs ?? {}).some((input) => input.optional) ||
			Object.values(def.outputs ?? {}).some((output) => output.config?.collapsible)
		: false;

	const nodeData: EditorNodeData = {
		defId: node.defId,
		def,
		collapsible,
	};

	return {
		id: node.id,
		type: "general",
		position: { x: node.position[0], y: node.position[1] },
		data: nodeData,
		parentId: node.parentId,
	};
};

export const commentToRfNode = (comment: Comment): RfNodeComment => {
	return {
		id: comment.id,
		type: "comment",
		position: { x: comment.position[0], y: comment.position[1] },
		width: comment.width,
		height: comment.height,
		data: {},
		parentId: comment.parentId,
	};
};

export const frameToRfNode = (frame: Frame): RfNode => {
	return {
		id: frame.id,
		type: "frame",
		position: { x: frame.position[0], y: frame.position[1] },
		width: frame.width,
		height: frame.height,
		data: {},
	};
};

export const connectionToRfEdge = (connection: NodeConnection): RfEdge => {
	return {
		id: connection.id,
		source: connection.source,
		sourceHandle: connection.sourceOutput,
		target: connection.target,
		targetHandle: connection.targetInput,
	};
};

export const eventsToRfNodesAndEdges = (
	editorClient: EditorClient,
	events: Events,
	selection: EditorSelectionState,
) => {
	const rfNodes: (RfNode | RfNodeComment)[] = [];
	const edges: RfEdge[] = [];

	for (const [, node] of Object.entries(events.nodes ?? {})) {
		const rfNode = nodeToRfNode(editorClient, events, node);

		if (!rfNode) {
			continue;
		}

		rfNodes.push(rfNode);
	}

	for (const connection of Object.values(events.connections)) {
		const rfEdge: RF.Edge = connectionToRfEdge(connection);

		if (selection.selectedEdges.includes(rfEdge.id)) {
			rfEdge.selected = true;
		}

		edges.push(rfEdge);
	}

	for (const comment of Object.values(events.comments ?? {})) {
		const rfNode = commentToRfNode(comment);

		rfNodes.push(rfNode);
	}

	for (const frame of Object.values(events.frames ?? {})) {
		const rfNode = frameToRfNode(frame);

		rfNodes.push(rfNode);
	}

	for (const rfNode of rfNodes) {
		if (selection.selectedNodes.includes(rfNode.id)) {
			rfNode.selected = true;
		}
	}

	rfNodes.sort((a, b) => {
		// nodes with parents should be last in the list
		if (a.parentId && !b.parentId) {
			return 1;
		}

		if (!a.parentId && b.parentId) {
			return -1;
		}

		return 0;
	});

	return {
		nodes: rfNodes,
		edges,
	};
};

export function applyRfNodeChanges(
	editorClient: EditorClient,
	events: Events,
	selection: EditorSelectionState,
	changes: RF.NodeChange[],
	nodes: RfNode[],
) {
	let regenerationRequired = false;
	let commitChanges = false;
	let selectionChange = false;

	const nodePatchChanges: Record<string, RF.NodeChange[]> = {};
	const nodeRemovals: Record<string, boolean> = {};

	for (const change of changes) {
		if (change.type === "remove") {
			nodeRemovals[change.id] = true;
		} else if (change.type === "position" || change.type === "dimensions" || change.type === "select") {
			let patchChanges = nodePatchChanges[change.id];

			if (!patchChanges) {
				patchChanges = nodePatchChanges[change.id] = [];
			}

			patchChanges.push(change);
		}
	}

	const dirtyNodes = new Set<string>();

	for (const node of nodes) {
		const remove = nodeRemovals[node.id];

		if (remove) {
			const { removed } = tryRemoveNode(editorClient, events, node.id);

			if (removed) {
				commitChanges = true;
				continue;
			}

			delete nodeRemovals[node.id];
		}

		const patchChanges = nodePatchChanges[node.id];

		if (patchChanges) {
			for (const change of Object.values(patchChanges)) {
				if (change.type === "position") {
					if (typeof change.position !== "undefined") {
						const [eventsNode] = getNode(events, change.id);

						const oldParentId =
							eventsNode && "parentId" in eventsNode ? eventsNode.parentId : undefined;

						setNodePosition(events, change.id, [change.position.x, change.position.y]);

						const newParentId =
							eventsNode && "parentId" in eventsNode ? node.parentId : undefined;

						if (oldParentId !== newParentId) {
							regenerationRequired = true;

							if (oldParentId) {
								dirtyNodes.add(oldParentId);
							}
							if (newParentId) {
								dirtyNodes.add(newParentId);
							}
						}

						node.position = change.position;
					}

					if (typeof change.dragging !== "undefined") {
						node.dragging = change.dragging;
					}

					dirtyNodes.add(node.id);
				} else if (change.type === "dimensions") {
					if (typeof change.dimensions !== "undefined") {
						node.measured ??= {};
						node.measured.width = change.dimensions.width;
						node.measured.height = change.dimensions.height;

						if (change.setAttributes) {
							setNodeDimensions(
								events,
								change.id,
								change.dimensions.width,
								change.dimensions.height,
							);

							node.width = change.dimensions.width;
							node.height = change.dimensions.height;
						}
					}

					if (typeof change.resizing === "boolean") {
						node.resizing = change.resizing;
					}

					dirtyNodes.add(node.id);
				} else if (change.type === "select") {
					node.selected = change.selected;
					selection.selectedNodes.push(change.id);
					selectionChange = true;
					dirtyNodes.add(node.id);
				}
			}
		}
	}

	const newNodes: RfNode[] = nodes
		.map((node) => {
			if (nodeRemovals[node.id]) {
				return null;
			}

			if (dirtyNodes.has(node.id)) {
				return { ...node } as RfNode;
			}

			return node;
		})
		.filter((node): node is RfNode => !!node);

	if (selectionChange) {
		selection.selectedNodes = Array.from(new Set(selection.selectedNodes));
	}

	return {
		error: null,
		regenerationRequired,
		commitChanges,
		nodes: newNodes,
	};
}

export function applyRfEdgeChanges(
	events: Events,
	selection: EditorSelectionState,
	changes: RF.EdgeChange[],
	edges: RfEdge[],
	nodes: RfNode[],
) {
	const dirtyNodes = new Set<string>();

	let commitChanges = false;
	let selectionChange = false;

	for (const change of changes) {
		if (change.type === "remove") {
			const connection = events.connections.find((connection) => connection.id === change.id);

			if (!connection) {
				continue;
			}

			dirtyNodes.add(connection.source);
			dirtyNodes.add(connection.target);

			removeConnectionById(events, change.id);

			edges = edges.filter((edge) => edge.id !== change.id);

			commitChanges = true;
		} else if (change.type === "select") {
			selection.selectedEdges.push(change.id);

			const edge = edges.find((edge) => edge.id === change.id);

			if (edge) {
				edge.selected = change.selected;
			}

			selectionChange = true;
		}
	}

	if (selectionChange) {
		selection.selectedEdges = Array.from(new Set(selection.selectedEdges));
	}

	const newNodes = nodes.map((node) => {
		if (dirtyNodes.has(node.id)) {
			return { ...node } as RfNode;
		}

		return node;
	});

	return { edges, nodes: newNodes, commitChanges };
}
