import { X } from "@components/icons";

import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";
import { Dialog } from "./Dialog";
import { Button } from "@components/ui/Button";

export function OtherAlertDialog() {
	const open = useAlertDialogStore((state) => state.open);
	const toggle = useAlertDialogStore((state) => state.toggle);
	const close = useAlertDialogStore((state) => state.close);
	const alertObj = useAlertDialogStore((state) => state.alert);

	return (
		<Dialog.Root open={open} onOpenChange={toggle}>
			<Dialog.Content
				className="grid h-[80vh] w-9/12 max-w-2xl place-items-center rounded-md bg-slate-900 p-8 text-white shadow-md outline-none"
				data-qaid="alert-dialog"
			>
				<button className="absolute right-4 top-4" onClick={() => close()}>
					<X className="size-6" />
				</button>
				<div className="flex flex-col items-center gap-4">
					<Dialog.Title className="mb-4 text-ellipsis text-center text-3xl font-semibold text-white">
						{alertObj?.title ?? "No title"}
					</Dialog.Title>

					{alertObj?.message && (
						<p className="mb-4 line-clamp-3 text-justify md:text-left">
							{alertObj?.message.includes("\n")
								? alertObj.message.split("\n").map((line, index) => (
										<span key={index} className="mt-2 block">
											{line}
										</span>
									))
								: alertObj?.message}
						</p>
					)}

					<Button fullWidth onClick={close} data-qaid="confirm-dialog-confirm-btn">
						Confirm
					</Button>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
}
