import {
	useHubStore,
	useWorldPreviewStore,
	useConfirmPromptStore,
	useGeneralPromptStore,
	usePromptStore,
	useErrorStore,
	useHubWorldModal,
	useCreateWorldModal,
	useImportBlockPackModal,
	useHubWorldVersionsModal,
} from "@stores/dialogs";
import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useChatStore } from "@stores/chat";
import { useControlsStore } from "@stores/controls";
import { useWrenchStore } from "@stores/wrench";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";
import { useJacyEnvironmentPresetEditorStore } from "@stores/jacy/environment-preset-editor";
import { useJacyBlockEditorStore } from "@stores/jacy/block-editor";
import { useJacyCharacterEditorStore } from "@stores/jacy/character-editor";
import { useJacyBlockStructureEditorStore } from "@stores/jacy/block-structure-editor";
import { useJacyPropEditorStore } from "@stores/jacy/prop-editor";
import { useJacyItemEditorStore } from "@stores/jacy/item-editor";

// Note: Prevent adding close method that has side effects like updating the pointer lock.
export function closeAllModal() {
	useHubStore.getState().close();
	useWorldPreviewStore.getState().close();
	useConfirmPromptStore.getState().closeDialog();
	useGeneralPromptStore.getState().cancel();
	usePromptStore.getState().close();
	useErrorStore.getState().close();
	useAlertDialogStore.getState().close(false);
	useHubWorldModal.getState().close();
	useCreateWorldModal.getState().close();
	useInventoryStore.getState().close();
	useChatStore.getState().close();
	useControlsStore.getState().close();
	useWrenchStore.getState().close(false);
	useJacyEnvironmentPresetEditorStore.getState().close();
	useJacyAvatarEditorStore.getState().close(false);
	useJacyBlockEditorStore.getState().close();
	useJacyBlockStructureEditorStore.getState().close();
	useJacyCharacterEditorStore.getState().close();
	useJacyPropEditorStore.getState().close();
	useJacyItemEditorStore.getState().close();
	useImportBlockPackModal.getState().close();
	useHubWorldVersionsModal.getState().close();
}
