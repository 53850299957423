import type { IJacyContent } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { Jacy } from "@jacy-client";
import { uploadAndCreateResource } from "../../upload/upload-resource";

export class JacyBlockTextureActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	async uploadTexture(initialFile: File) {
		try {
			const { name, resourceType, file } = await this.#state.resources.processBlockTexture(initialFile);

			const { pk, resource } = await uploadAndCreateResource(Jacy, {
				name,
				resourceType,
				file,
			});

			const newTexture = this.#state.blockTextures.useBlockTextureResource(pk, resource.name);

			useJacyRerenderStore.getState().forceRerenderBlockTextures();

			return {
				blockTexturePk: newTexture.pk,
			};
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
