import type { IBundle } from "../../lib/types";
import { AssetType, type DeepPartial, type IAssetKey, type IEnvironmentPreset } from "../../lib/types";
import { mergeObject } from "@jamango/helpers";
import { DEFAULT_ENVIRONMENT_PRESET } from "../../lib/constants/environment-presets";
import type { JacyContentState } from "../JacyContentState";
import { generateId } from "../../lib/helpers/general";

export class JacyEnvironmentPresetState {
	#envPresets: Record<string, IEnvironmentPreset>;
	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
		// environment presets that the user has access but isn't used in the world yet.
		this.#envPresets = {};
	}

	import(data?: IBundle["assets"]["environmentPresets"]) {
		if (!data) return;

		for (const envPreset of Object.values(data)) {
			this.set(envPreset);
		}
	}

	export() {
		return this.#envPresets;
	}

	get current() {
		return this.getAll().find((e) => e.isUsed);
	}

	get(pk: IAssetKey) {
		return this.#envPresets[pk];
	}

	getDefault() {
		return this.getAll().find((preset) => preset.isDefault);
	}

	getAll() {
		return Object.values(this.#envPresets);
	}

	reset(pk: IAssetKey) {
		const envPreset = this.get(pk);
		if (!envPreset) return;

		envPreset.preset = structuredClone(envPreset.originalPreset);
		this.#state.markDirty();
	}

	set(data: IEnvironmentPreset) {
		const envPreset = structuredClone({
			...DEFAULT_ENVIRONMENT_PRESET,
			...data,
			preset: mergeObject(data.preset, DEFAULT_ENVIRONMENT_PRESET.preset),
			originalPreset: mergeObject(data.originalPreset, DEFAULT_ENVIRONMENT_PRESET.preset),
		});

		this.#envPresets[envPreset.pk] = envPreset;
		this.#state.markDirty();
	}

	createId() {
		return generateId();
	}

	create(id: string) {
		const envPreset = {
			pk: `${AssetType.ENVIRONMENT_PRESET}#${id}`,
			type: AssetType.ENVIRONMENT_PRESET,
			id,
			name: "New Preset",
			preset: structuredClone(DEFAULT_ENVIRONMENT_PRESET.preset),
			originalPreset: structuredClone(DEFAULT_ENVIRONMENT_PRESET.preset),
			isDefault: false,
			isUsed: false,
		} satisfies IEnvironmentPreset;

		this.set(envPreset);

		return envPreset;
	}

	update(pk: IAssetKey, name: string, preset: DeepPartial<IEnvironmentPreset["preset"]>) {
		const envPreset = this.get(pk);
		if (!envPreset) {
			throw new Error(`Environment preset with pk ${pk} does not exist`);
		}

		envPreset.name = name;
		envPreset.preset = mergeObject(preset, envPreset.preset) as any;
		envPreset.originalPreset = structuredClone(envPreset.preset);

		this.set(envPreset);

		return envPreset.pk;
	}

	delete(pk: IAssetKey) {
		const envPreset = this.get(pk);

		if (!envPreset) {
			throw new Error(`Environment preset with pk ${pk} does not exist`);
		}

		delete this.#envPresets[pk];

		this.#state.markDirty();
	}

	use(pk: IAssetKey) {
		this.getAll().forEach((e) => {
			e.isUsed = e.pk === pk;
		});
		this.#state.markDirty();
	}

	changePreset(pk: IAssetKey) {
		const currentPreset = this.current;

		if (!currentPreset) {
			throw new Error("Failed to find current environment preset.");
		}

		const envPreset = this.get(pk);

		if (!envPreset) {
			throw new Error(`Failed to find environment preset ${pk}`);
		}

		this.reset(currentPreset.pk);

		this.use(pk);
	}
}
