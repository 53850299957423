import { Jacy } from "@jacy-client";
import type { IAssetKey, ISelectorSlot, ISelectorSlots } from "@jamango/content-client";
import { AssetType, getAssetType } from "@jamango/content-client";
import { create } from "zustand";
import type { WorldTemplateOptions } from "@components/world/templates";
import { InputCommandType, type ManagerClientState } from "@jamango/engine/Input.ts";
import { ItemBuildTool } from "@jamango/frontend/ItemBuildTool.ts";
import { isAnyModalOpen } from "@lib/helpers/isAnyModalOpen";
import { useEngineStore } from "@stores/bb";
import { useHelpersStore } from "@stores/helpers";
import { useItemStore } from "@stores/hud/item";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";
import { ItemSpawner } from "mods/defs/ItemSpawner";
import { ItemWrench } from "mods/defs/ItemWrench";
import { ItemPencil } from "mods/defs/ItemPencil";

export const SLOT_SIZE = 9;
const SUPPORTED_ASSET_TYPES = [
	AssetType.BLOCK,
	AssetType.BLOCK_STRUCTURE,
	AssetType.CHARACTER,
	AssetType.PROP,
	AssetType.ITEM,
	AssetType.AUDIO,
	AssetType.PARTICLE_SYSTEM,
	AssetType.SCRIPT,
];

type ISelectorState = {
	renderSelector: boolean;
	selector: ISelectorSlots;
	selectedSlot: number;
	selectedBlock: ISelectorSlot | null;
	selectedCharacter: ISelectorSlot | null;

	// Handlers
	setSelectorSlot: (pk: IAssetKey, index?: number, active?: boolean) => void;
	setSelector: (selector: ISelectorSlots) => void;
	setSelectedSlot: (slot?: number, isMouseScroll?: boolean) => void;
	activateBuildModeSlot: () => void;

	// Actions
	init: (inventory?: ISelectorSlots) => void;
	clear: () => void;
	refresh: () => void;
	pickBlock: () => void;

	// Helpers
	convertDefaultInventoryToIventorySlots: (
		slot: Required<WorldTemplateOptions>["defaultInventory"],
	) => ISelectorSlots;
	recomputeRenderSelector: () => void;
};

export const useInventorySelectorStore = create<ISelectorState>((set, get) => ({
	renderSelector: false,
	selector: [],
	selectedSlot: 0,
	selectedBlock: null,
	selectedCharacter: null,

	// Handlers
	setSelectorSlot: (pk, index, active = false) => {
		const selector = [...get().selector];
		const currentIndex = index ?? get().selectedSlot;
		let item: ISelectorSlot | null = null;

		const asset = Jacy.state.findAsset(pk);

		if (asset && SUPPORTED_ASSET_TYPES.includes(asset.type as any)) {
			item = { pk };
		}

		if (!item) return;

		selector[currentIndex] = item;

		set({ selector });

		const isCurrentSlot = active || currentIndex === get().selectedSlot;

		if (isCurrentSlot) {
			get().setSelectedSlot(currentIndex);
		}
	},
	setSelector: (selector) => {
		set({ selector });
		get().refresh();

		get().setSelectedSlot(0);
	},
	// In the setSelectedSlot method, replace everything with this simplified version
	setSelectedSlot: (slot, isMouseScroll) => {
		if (slot === undefined) return;

		const isAvatarEditorOpen = useJacyAvatarEditorStore.getState().open;
		if (isMouseScroll && isAvatarEditorOpen) return;

		set({ selectedSlot: slot });

		useHelpersStore.getState().markChangeSlotKnown();

		const { BB } = useEngineStore.getState().engine;

		const item = get().selector[get().selectedSlot];
		if (BB.world.client!.camera?.target.type.def.isPlayer) {
			BB.world.client.camera.target.selector!.state.selectedSlot = item;
		}

		const equippedItem = BB.world.client?.getEquippedItem();

		if (!equippedItem) {
			get().activateBuildModeSlot();
			return;
		}

		// Only handle tool switching for wrench
		const isWrench = equippedItem.def === ItemWrench.name;
		const isPencil = ItemPencil.isPencil(equippedItem.def);

		if (isWrench) {
			// Switch to build tool when user selects a slot while holding wrench
			const input = (BB.world.input as ManagerClientState).local;
			input.cmd.push([InputCommandType.EQUIP, ItemBuildTool.name]);
			get().activateBuildModeSlot();
			return;
		}

		// If using pencil, don't change the tool
		if (item && isPencil) {
			// Just activate the selection without switching tools
			const itemAssetType = getAssetType(item.pk);
			const input = (BB.world.input as ManagerClientState).local;

			if (itemAssetType === AssetType.BLOCK_STRUCTURE) {
				// For blueprints with pencil
				input.cmd.push([InputCommandType.EQUIP, ItemPencil.blockStructurePrefix + item.pk]);
			} else {
				// For blocks and other types with pencil
				// We just re-equip the pencil to maintain it as the active tool
				input.cmd.push([InputCommandType.EQUIP, ItemPencil.name]);
			}
			return;
		}

		// For other tools, use the standard behavior
		get().activateBuildModeSlot();
	},
	activateBuildModeSlot: () => {
		const item = get().selector[get().selectedSlot];
		const { BB } = useEngineStore.getState().engine;
		if (!BB.world.client!.camera?.target.type.def.isPlayer) return;

		const selector = BB.world.client!.camera.target.selector;
		if (!selector) return;

		selector.state.selectedSlot = item;

		const input = (BB.world.input as ManagerClientState).local;

		// todo: PlayerContext for tools that use blocks

		const itemType = getAssetType(item?.pk ?? "") as AssetType;

		if (!item || !itemType) {
			input.cmd.push([InputCommandType.EQUIP, null]);
		} else if (itemType === AssetType.BLOCK) {
			input.cmd.push([InputCommandType.EQUIP, ItemBuildTool.blockPrefix + item.pk]);
		} else if (itemType === AssetType.BLOCK_STRUCTURE) {
			input.cmd.push([InputCommandType.EQUIP, ItemPencil.blockStructurePrefix + item.pk]);
		} else {
			input.cmd.push([InputCommandType.EQUIP, ItemSpawner.name]);
		}
	},
	// Actions
	init: (inventory) => {
		if (inventory && inventory.length !== 0) {
			get().setSelector(inventory);
		} else {
			const blocks = Jacy.state.blocks.getAll();

			const selector: ISelectorSlots = blocks.slice(0, SLOT_SIZE).map((block) => ({ pk: block.pk }));

			get().setSelector(selector);
		}
	},
	clear: () => {
		set({ selector: Array(SLOT_SIZE).fill(null) });
	},
	refresh: () => {
		const selector = get().selector.map((slot) => {
			if (!slot) return null;

			const asset = Jacy.state.findAsset(slot.pk);

			if (!asset) return null;

			return { pk: slot.pk };
		}) satisfies ISelectorSlots;

		set({ selector });
	},
	pickBlock: () => {
		if (isAnyModalOpen()) return;

		const { BB } = useEngineStore.getState().engine;

		const cameraTarget = BB.world.client!.camera.target;
		const viewRaycast = cameraTarget?.viewRaycast;
		const selector = cameraTarget?.selector;
		if (!selector || !viewRaycast || !viewRaycast.state.block) return;

		const blockType = BB.world.scene.getType(viewRaycast.state.block);
		const foundIndex = get().selector.findIndex((slot) => {
			if (!slot) return false;
			return slot.pk === blockType;
		});

		if (foundIndex !== -1) {
			get().setSelectedSlot(foundIndex);
		} else {
			get().setSelectorSlot(blockType);
		}
	},

	// Helpers

	convertDefaultInventoryToIventorySlots: (defaultInventory) => {
		const state = Jacy.state;

		const defaultSelectorSlots: ISelectorSlots = [];

		for (const item of defaultInventory) {
			const itemPK = item.id;
			const asset = state.findAsset(itemPK);
			if (asset) {
				defaultSelectorSlots.push({ pk: itemPK });
			}
		}

		return defaultSelectorSlots;
	},
	recomputeRenderSelector: () => {
		const { toolCycle, toolWheel } = useItemStore.getState();
		set({ renderSelector: toolCycle.length > 0 || toolWheel.length > 0 });
	},
}));

export default {
	useInventorySelector: useInventorySelectorStore.getState,
};
