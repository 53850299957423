import type { IAssetKey, IJacyContent } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";
import { forceRerenderInventory } from "@stores/jacy/rerender";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useInventorySelectorStore } from "@stores/hud/inventory-selector";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useJacyPropEditorStore } from "@stores/jacy/prop-editor";

export class JacyPropActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	editNewProp() {
		try {
			const newProp = this.#state.props.makeEmptyProp();
			useJacyPropEditorStore.getState().editProp(newProp);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editProp(pk: IAssetKey) {
		try {
			const prop = this.#state.props.get(pk);

			if (!prop) {
				this.#actions.setError("Prop not found");
				return;
			}

			useJacyPropEditorStore.getState().editProp(prop);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	saveProp() {
		try {
			const prop = useJacyPropEditorStore.getState().getProp();

			this.#state.props.set(prop);

			forceRerenderInventory();
			useInventoryStore.getState().setSelectedAsset(prop.pk);
			useJacyPropEditorStore.getState().close();

			this.#actions.setSuccess(`Prop "${prop.name}" created successfully.`);

			return prop.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteProp() {
		try {
			const prop = useJacyPropEditorStore.getState().getProp();

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Prop",
				description: `Are you sure you want to "${prop.name}" prop? This action cannot be undone. You will lose all the data associated with this prop.`,
				confirmText: "Yes, delete this prop",
			});

			if (!confirmed) return;

			this.#state.props.delete(prop.pk);

			useInventorySelectorStore.getState().refresh();
			forceRerenderInventory();
			useJacyPropEditorStore.getState().close();

			this.#actions.setSuccess(`Prop "${prop.name}" deleted successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
