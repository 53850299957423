import type { JacyClient } from "@jacy-client";
import { S3FileName, serializeJamfileSaveZip } from "@jamango/content-client";
import { getMapBuffer } from "@jamango/engine/Runtime";
import { createLogger } from "@jamango/helpers";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { mutate } from "swr";
import { uploadFileToS3 } from "./upload-file-to-s3";
import { requestSaveWorld, ws } from "rest-client";
import type { PubEventByName } from "@jamango/content-service/types/index.ts";

const logger = createLogger("SaveWorldHandler");

export const VERSION = 1;

export const SAVE_ERROR = {
	UNAUTHORIZED: "unauthorized",
};

export const uploadWorldSave = async (jacy: JacyClient, autosave: boolean) => {
	jacy.content.state.worldData.assert();

	const { worldId, zipContent } = await createSaveZip(jacy);

	const versionId = jacy.content.state.worldData.version?.id;
	let requestId = "";

	const signedRequestResult = await requestSaveWorld({ worldId, versionId, autosave });

	if (!signedRequestResult.data) throw new Error(`Can't process request`);

	const signedRequest = signedRequestResult.data;

	requestId = signedRequest.requestId;

	const file = new File([zipContent], S3FileName.SAVE_WORLD);
	await uploadFileToS3(jacy, { uploadUrl: signedRequest.uploadUrl, file });

	const sub = ws.client.subscribe("world:save", { requestId });

	let lastMessage: PubEventByName<"world:save"> | undefined;

	sub.listen((msg) => {
		lastMessage = msg;
		logger.debug(msg);
	});

	const response = await sub.disposedPromise;

	if (response instanceof Error) {
		throw response;
	}

	if (!lastMessage?.result || lastMessage.error) {
		throw new Error(`Connection was lost! Try again!`);
	}

	const { shortCode, version } = lastMessage.result;

	jacy.content.state.onSave(shortCode);
	jacy.content.state.worldData.setVersion(version);
	useJacyRerenderStore.getState().forceRerenderWorldData();

	mutate(["/worlds", worldId]);
	mutate(["/worlds/versions", worldId]);

	return shortCode;
};

export const createSaveZip = async (jacy: JacyClient) => {
	const state = jacy.content.state;

	const mapBuffer = getMapBuffer();

	await state.map.update(mapBuffer);

	const zipContent = await serializeJamfileSaveZip({ state, bundle: state.export() });

	return {
		worldId: state.worldData.id,
		identifier: state.worldData.shortCode,
		zipContent,
	};
};
