import { DEBUG_ENABLED } from "@jamango/generated";
import { createLogger } from "@jamango/helpers";
import AVATAR_COMPONENT_ASSETS from "./avatar-components.json";
import type { IAvatarComponent, IPackageBundle } from "../../lib/types";
import { AvatarComponentSchema } from "../../lib/validations/avatar";
import { LATEST_BUNDLE_VERSION } from "../../lib/validations/bundle";

if (DEBUG_ENABLED) {
	const logger = createLogger("AvatarComponentsPackageValidation");

	for (const asset of Object.values(AVATAR_COMPONENT_ASSETS)) {
		const result = AvatarComponentSchema.safeParse(asset);

		if (result.error) {
			logger.error("Avatar component does not match schema", result.error);
		}
	}
}

export const AVATAR_COMPONENTS_PACKAGE: IPackageBundle = {
	version: LATEST_BUNDLE_VERSION,
	type: "package",
	id: "AVATAR_COMPONENTS",
	assets: {
		avatarComponents: AVATAR_COMPONENT_ASSETS as Record<string, IAvatarComponent>,
	},
};
