import type React from "react";
import { useCustomUIStore } from "@stores/custom-ui";
import { InventoryFormSection } from "@components/inventory/Inventory/InventoryFormSection";
import { Input } from "@components/ui/Input";
import { Switch } from "@components/ui/Switch";
import type { TableState, InputType as TypeInputType } from "@jamango/content-client";

interface InputTypeProps {
	name: string;
	type: string;
	hidden?: boolean;
	input: TypeInputType;
	setInputs: (input: string, value: unknown) => void;
	value: string | number | boolean | null | undefined | TableState;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
}

const InputType = ({ name, type, hidden, input, setInputs, value, ...props }: InputTypeProps) => {
	if (hidden) return null;

	switch (type) {
		case "boolean":
			return (
				<InventoryFormSection
					title={<label className="">{name}</label>}
					description=""
					descriptionClassName="hidden md:block"
					main={
						<Switch
							{...props}
							id="world-custom-loader"
							checked={input.value as boolean}
							onChange={(value) => setInputs(input.inputKey, value)}
							setChecked={() => {}}
						/>
					}
				/>
			);
		default:
			return (
				<div className="">
					<label htmlFor="" className="mb-2 inline-block text-sm text-gray-400">
						{name}
					</label>
					<Input
						variant="editor"
						type="text"
						className="w-40"
						value={value?.toString()}
						{...props}
					/>
				</div>
			);
	}
};

export function InputConfig() {
	const currentComponent = useCustomUIStore((state) => state.currentComponent);
	const setInputs = useCustomUIStore((state) => state.setInputs);

	if (!currentComponent) return;

	const inputSettings = Object.values(currentComponent.inputs);

	if (!inputSettings) return;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, inputKey: string) => {
		const value = e.target.value;
		setInputs(inputKey, value);
	};

	return (
		<div className="mb-4">
			<h2 className="mb-4 text-sm font-medium uppercase">Inputs</h2>
			<div className="grid grid-cols-2 gap-4">
				{inputSettings.map((input) => {
					return (
						<InputType
							key={input.name}
							name={input.name}
							type={input.type}
							hidden={input.hidden}
							onChange={(e) => handleChange(e, input.inputKey)}
							value={input.value}
							input={input}
							setInputs={setInputs}
							placeholder={input.name}
						/>
					);
				})}
			</div>
		</div>
	);
}
