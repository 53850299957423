import {
	type IAssetKey,
	TONE_MAPPING,
	type DeepPartial,
	type IEnvironmentPreset,
	type TerrainGenerationOptions,
} from "@jamango/content-client";
import {
	CANOPY_TREE,
	FERN_PLANT,
	SHORT_PALM_TREE,
	SNOWY_TUNDRA_CRACK_IN_ICE,
	SNOWY_TUNDRA_ICE,
	SNOWY_TUNDRA_ICE_SPIKE1,
	SNOWY_TUNDRA_ICE_SPIKE2,
	SNOWY_TUNDRA_ICE_SPIKE3,
	SNOWY_TUNDRA_PINE_TREE,
	TALL_PALM_TREE,
	WILD_WEST_DESERT_CACTUS1,
	WILD_WEST_DESERT_CACTUS2,
	WILD_WEST_DESERT_CACTUS3,
	WILD_WEST_DESERT_ROCK1,
	WILD_WEST_DESERT_ROCK2,
} from "./objects";
import { IMAGES } from "@lib/constants/images";

type InventorySlot = { id: IAssetKey };

export type WorldTemplateOptions = {
	terrainGeneration: TerrainGenerationOptions;
	defaultInventory?: InventorySlot[];
	envPresetSettings?: DeepPartial<IEnvironmentPreset["preset"]>;
	packagesIds?: string[];
};

// const BARREN_DESERT_TERRAIN: TerrainGenerationOptions = {
// 	type: "custom",
// 	version: 1,
// 	ground: {
// 		amplitude: 2,
// 		vrTransition: 15,
// 		noiseOptions: { scale: 500, octaves: 3 },
// 		foundationLevel: -10,
// 		topBlock: "block#374B61FB60D607C88332FA4FBE1F5D12",
// 		midBlock: "block#374B61FB60D607C88332FA4FBE1F5D12",
// 		foundationBlock: "block#374B61FB60D607C88332FA4FBE1F5D12",
// 	},
// };

const TROPICAL_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	ground: {
		amplitude: 13,
		vrTransition: 5,
		noiseOptions: { scale: 400, octaves: 5 },
		foundationLevel: -3,
		topBlock: "block#041BF939E4344551A0D8017C16DADBC5",
		midBlock: "block#B29F5546FF2242A5956FFD9B613F890A",
		foundationBlock: "block#B29F5546FF2242A5956FFD9B613F890A",
	},
	water: {
		level: 4,
		sandBlock: "block#374B61FB60D607C88332FA4FBE1F5D12",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 5,
			maxHeight: 13,
			forestProbability: 0.5,
			probabilityForest: 0.009,
			probabilityNonForest: 0.009,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: CANOPY_TREE,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: TALL_PALM_TREE,
					},
					probability: 0.25,
				},
				{
					factory: {
						type: "static",
						template: SHORT_PALM_TREE,
					},
					probability: 0.5,
				},
				{
					factory: {
						type: "static",
						template: FERN_PLANT,
					},
					probability: 2,
				},
			],
		},
	],
};

const SNOWY_TUNDRA_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	ground: {
		amplitude: 7,
		vrTransition: 15,
		noiseOptions: { scale: 250, octaves: 5 },
		foundationLevel: -10,
		topBlock: "block#7ECD008E6EE6468DB3FA5784B290B0E9",
		midBlock: "block#1243423C9831127C92653DD89AE610EF",
		foundationBlock: "block#48561BFA726F47B170ED10E76EACFBB9",
	},
	mountains: {
		amplitude: 200,
		noiseOptions: { scale: 250, octaves: 4 },
		probability: 0.35,
		mountainBlock: "block#7F5554819711994B04E484913CA6A8C3",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 2,
			maxHeight: 7,
			forestProbability: 0.5,
			probabilityForest: 0.009,
			probabilityNonForest: 0.009,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_PINE_TREE,
					},
					probability: 1,
				},
			],
		},
		{
			type: "perlinNoise",
			minHeight: -1,
			maxHeight: 0,
			forestProbability: 0.5,
			probabilityForest: 0.999,
			probabilityNonForest: 0.999,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_CRACK_IN_ICE,
					},
					probability: 0.01,
				},
			],
		},
		{
			type: "perlinNoise",
			minHeight: -1,
			maxHeight: 0,
			forestProbability: 1,
			probabilityForest: 0.005,
			probabilityNonForest: 0,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE_SPIKE1,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE_SPIKE2,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE_SPIKE3,
					},
					probability: 1,
				},
			],
		},
	],
};

// const DEEP_SPACE_TERRAIN: TerrainGenerationOptions = {
// 	type: "custom",
// 	version: 1,
// 	objectsPlacers: [
// 		{
// 			type: "static",
// 			objects: [
// 				{
// 					factory: {
// 						type: "static",
// 						template: createPlatform(0, 0, 0, 10, 1, 10, "block#bb.block.spaceStation.metal-grate-1"),
// 					},
// 					positions: [[-5, -1, -5]],
// 				},
// 			],
// 		},
// 	],
// };

const FANTASY_GRASSLANDS_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	ground: {
		amplitude: 13,
		vrTransition: 15,
		noiseOptions: { scale: 250, octaves: 5 },
		foundationLevel: -10,
		topBlock: "block#53C6A0C465BD40A8AFA0B2019A5B9E7A",
		midBlock: "block#E09564CACE524BCD9D20A5B58C2EBDCD",
		foundationBlock: "block#1F0623A215344E9A8C76780F513C9BB5",
	},
	mountains: {
		amplitude: 200,
		noiseOptions: { scale: 250, octaves: 4 },
		probability: 0.35,
		mountainBlock: "block#1F0623A215344E9A8C76780F513C9BB5",
	},
	snow: {
		min: 50,
		max: 80,
		snowBlock: "block#7B712D1C11DF4243806FDCA6AEB8F5BB",
		noiseOptions: { scale: 50, octaves: 3 },
	},
	water: {
		level: 4,
		sandBlock: "block#DD40A08298D340E49A418FF076CD3526",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 5,
			maxHeight: 13,
			forestProbability: 0.42,
			probabilityForest: 0.006,
			probabilityNonForest: 0.0004,
			perlinOptions: {
				scale: 150,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: [
							[0, 0, 0, 15, "block#0CD5DDAA0D834CBB9E76EE69CF6AE0B6"],
							[0, 1, 0, 15, "block#0CD5DDAA0D834CBB9E76EE69CF6AE0B6"],
							[0, 2, 0, 15, "block#0CD5DDAA0D834CBB9E76EE69CF6AE0B6"],
							[0, 3, 0, 15, "block#0CD5DDAA0D834CBB9E76EE69CF6AE0B6"],
							[0, 4, 0, 15, "block#0CD5DDAA0D834CBB9E76EE69CF6AE0B6"],
							[0, 5, 0, 15, "block#0CD5DDAA0D834CBB9E76EE69CF6AE0B6"],
							[0, 6, 0, 15, "block#0CD5DDAA0D834CBB9E76EE69CF6AE0B6"],
							[0, 7, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 8, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 3, 0, 83, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 3, 0, 92, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 4, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 4, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 5, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 5, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 6, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 6, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 7, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 7, 0, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 8, 0, 44, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 8, 0, 35, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 3, -1, 90, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 3, 1, 85, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 4, -1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 4, 1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 5, -1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 5, 1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 6, -1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 6, 1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 7, -1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 7, 1, 15, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 8, -1, 42, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 8, 1, 37, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 4, 1, 164, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 5, 1, 59, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 6, 1, 30, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 7, 1, 59, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 4, 1, 161, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 5, 1, 27, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 6, 1, 78, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 7, 1, 78, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 4, -1, 162, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 5, -1, 75, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 6, -1, 75, "block#8F541D112A954DEEB11A874AFC534343"],
							[1, 7, -1, 75, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 4, -2, 85, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 4, 2, 90, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 5, -2, 101, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 5, 2, 26, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 6, -2, 101, "block#8F541D112A954DEEB11A874AFC534343"],
							[0, 6, 2, 26, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 4, -1, 168, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 5, -1, 62, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 6, -1, 109, "block#8F541D112A954DEEB11A874AFC534343"],
							[-1, 7, -1, 109, "block#8F541D112A954DEEB11A874AFC534343"],
							[-2, 4, 0, 92, "block#8F541D112A954DEEB11A874AFC534343"],
							[2, 4, 0, 83, "block#8F541D112A954DEEB11A874AFC534343"],
							[-2, 5, 0, 58, "block#8F541D112A954DEEB11A874AFC534343"],
							[2, 5, 0, 74, "block#8F541D112A954DEEB11A874AFC534343"],
							[-2, 6, 0, 58, "block#8F541D112A954DEEB11A874AFC534343"],
							[2, 6, 0, 74, "block#8F541D112A954DEEB11A874AFC534343"],
						],
					},
					probability: 1,
				},
			],
		},
	],
};

const WILD_WEST_DESERT_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	ground: {
		amplitude: 5,
		vrTransition: 15,
		noiseOptions: { scale: 250, octaves: 5 },
		foundationLevel: -3,
		topBlock: "block#0DFCCA06BDCB4E3C89B93700A3BAEE40",
		midBlock: "block#14C1EF0E20794D90AC69E8003C628D8E",
		foundationBlock: "block#DB49545197144DF493699D1A586941E0",
	},
	mountains: {
		amplitude: 100,
		noiseOptions: { scale: 250, octaves: 4 },
		probability: 0.3,
		mountainBlock: "block#DB49545197144DF493699D1A586941E0",
	},
	water: {
		level: 1,
		sandBlock: "block#0DFCCA06BDCB4E3C89B93700A3BAEE40",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 2,
			maxHeight: 5,
			forestProbability: 0.5,
			probabilityForest: 0.005,
			probabilityNonForest: 0.005,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_CACTUS1,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_CACTUS2,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_CACTUS3,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_ROCK1,
					},
					probability: 0.25,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_ROCK2,
					},
					probability: 0.25,
				},
			],
		},
	],
};

export type WorldTemplate = {
	codename?: string;
	title: string;
	description: string;
	thumbnailSrc: string;
	options: WorldTemplateOptions;
	isStarterWorld?: boolean;
};

export const BlankWorldTemplate: WorldTemplate = {
	codename: "blank",
	title: `Blank`,
	description: "An empty flat world",
	thumbnailSrc: IMAGES.EMPTY_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: { type: "blank" },
		defaultInventory: [
			{ id: "block#32436E2D66564448BBE74153B5276A8A" },
			{ id: "block#1C0FC4456B3D4EAD98B1149F0CFCB1FC" },
			{ id: "block#F53585CF978E4D0EA9BB48AD06012ECB" },
			{ id: "block#1F6EEDCAA2CE48DDB47BE88841CDCF3F" },
			{ id: "block#3890C805501A4354921697350B199367" },
			{ id: "block#46DB01E1B540407CB5D7A3D09470B12F" },
			{ id: "block#CE403A94C1D64F0EB319EAF07E15E574" },
			{ id: "block#2E474032C3064E3481BCD25864EBD563" },
			{ id: "block#A328B19C45994EA28FC1D2617DD857BD" },
		],
	},
};

const MVRWorldTemplate: WorldTemplate = {
	codename: "mvr",
	title: `Grass and Mountains`,
	description: "A world with rivers, grass and mountains",
	thumbnailSrc: IMAGES.DEFAULT_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: { type: "mvr" },
		defaultInventory: [
			{ id: "block#CC96F3CD5C5EA294CD581B7F69E5609B" },
			{ id: "block#1243423C9831127C92653DD89AE610EF" },
			{ id: "block#374B61FB60D607C88332FA4FBE1F5D12" },
			{ id: "block#8E2D2ECAD648EBECF9EC8D3DC1526172" },
			{ id: "block#48561BFA726F47B170ED10E76EACFBB9" },
			{ id: "block#FA212C66C52073EE8DE37970108E1764" },
			{ id: "block#625478D387114C476997337AEF54402D" },
			{ id: "block#03602E99DA07FBA89E03154EEB0D0A74" },
			{ id: "block#7F5554819711994B04E484913CA6A8C3" },
		],
	},
};

// const _BarrenDesertWorldTemplate: WorldTemplate = {
// 	title: "Barren Desert",
// 	description: "...?",
// 	thumbnailSrc: IMAGES.BARREN_DESERT_WORLD_THUMBNAIL,
// 	options: {
// 		terrainGeneration: BARREN_DESERT_TERRAIN,
// 		defaultInventory: [...(MVRWorldTemplate.options.defaultInventory ?? [])],
// 		envPresetSettings: {
// 			sky: {
// 				cloudColor: "#f6e9d5",
// 				fogColor: "#fff4ad",
// 				skyColor: "#5ac8f6",
// 			},
// 			fog: {
// 				fogNear: 1,
// 				fogFar: 150,
// 			},
// 		},
// 	},
// };

const TropicalWorldTemplate: WorldTemplate = {
	codename: "tropical",
	title: `Tropical Islands`,
	description: "A vast ocean world filled with jungle-covered islands",
	thumbnailSrc: IMAGES.TROPICAL_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: TROPICAL_TERRAIN,
		defaultInventory: [
			{ id: "block#041BF939E4344551A0D8017C16DADBC5" },
			{ id: "block#FB5D3C1C3DA348D898B467B37112A3B4" },
			{ id: "block#2266F6D3F82C4A26AC33AAD9701768E1" },
			{ id: "block#307C38E0FF1D4C15A7382318ED93BCB0" },
			{ id: "block#6ECDEBCC13E741D0B569FBA488197960" },
			{ id: "block#EA8955EC226A4C4189665F3F824A6A30" },
			{ id: "block#7642149B7FC3460F8E808678A6B55D06" },
			{ id: "block#42B958190C91459EBC125654166CE41C" },
			{ id: "block#C1C5D3FF7CBE4A2D8E1A5F2A75045F21" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 200,
				fogNear: 159,
			},
			sky: {
				fogy: 0,
				haze: 0.1,
				step: 16,
				sample: 128,
				fogColor: "#8bc4f8",
				skyColor: "#03578c",
				cloudSize: 0.29,
				cloudColor: "#fdf7e2",
				saturation: 2,
				groundColor: "#bb7831",
				toneMapping: TONE_MAPPING.ACES,
				cloudDensity: 1,
				cloudCoverage: 0.56,
				cloudDistance: 0.64,
				toneMappingExposure: 2.1,
			},
			light: {
				sunColor: "#ecbd89",
				sunIntensity: 1.1,
				nightLuminosity: 0,
			},
			general: {
				hour: 7.9,
				azimuth: 329,
			},
		},
		packagesIds: ["343140B9392047ACAFC0A4C60028E982"],
	},
};

const SnowyTundraWorldTemplate: WorldTemplate = {
	codename: "snowy-tundra",
	title: `Snowy Tundra`,
	description: "An arctic, snowy environment with pine trees and frozen rivers",
	thumbnailSrc: IMAGES.SNOWY_TUNDRA_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: SNOWY_TUNDRA_TERRAIN,
		defaultInventory: [
			{ id: "block#7ECD008E6EE6468DB3FA5784B290B0E9" },
			{ id: "block#1243423C9831127C92653DD89AE610EF" },
			{ id: "block#7F5554819711994B04E484913CA6A8C3" },
			{ id: "block#48561BFA726F47B170ED10E76EACFBB9" },
			{ id: "block#FA212C66C52073EE8DE37970108E1764" },
			{ id: "block#625478D387114C476997337AEF54402D" },
			{ id: "block#ED0678ADC7BC47DDA0710AE8910B54CC" },
			{ id: "block#6A3E04C90FE34E5382374D8D18A11DF0" },
			{ id: "block#8E2D2ECAD648EBECF9EC8D3DC1526172" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 129,
				fogNear: 1,
			},
			sky: {
				fogy: 0.2,
				haze: 0.1,
				step: 16,
				sample: 128,
				fogColor: "#d1e9ff",
				skyColor: "#327e9f",
				cloudSize: 0,
				cloudColor: "#bdddff",
				saturation: 1.3,
				groundColor: "#bb7831",
				toneMapping: TONE_MAPPING.ACES,
				cloudDensity: 0.2,
				cloudCoverage: 0.56,
				cloudDistance: 0.64,
				toneMappingExposure: 1.26,
			},
			light: {
				sunColor: "#ffffff",
				sunIntensity: 1.26,
				nightLuminosity: 0.81,
			},
			general: {
				hour: 8.4,
				azimuth: 21,
			},
		},
		packagesIds: ["343140B9392047ACAFC0A4C60028E982"],
	},
};

// const _DeepSpaceWorldTemplate: WorldTemplate = {
// 	title: `Deep Space`,
// 	description: "Where is taxi?",
// 	thumbnailSrc: IMAGES.DEEP_SPACE_WORLD_THUMBNAIL,
// 	options: {
// 		terrainGeneration: DEEP_SPACE_TERRAIN,
// 		envPresetSettings: {
// 			sky: {
// 				skyColor: "#000000",
// 				cloudCoverage: 0,
// 				fogColor: "#000000",
// 			},
// 			general: {
// 				hour: 0,
// 				azimuth: 0,
// 			},
// 			light: {
// 				nightLuminosity: 0,
// 			},
// 			fog: {
// 				fogNear: 200,
// 				fogFar: 200,
// 			},
// 		},
// 	},
// };

const FantasyGrasslandsWorldTemplate: WorldTemplate = {
	codename: "fantasy-grassland",
	title: `Fantasy Grasslands`,
	description: "A fantasy world with autumnal trees and grand mountains",
	thumbnailSrc: IMAGES.FANTASY_GRASSLANDS_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: FANTASY_GRASSLANDS_TERRAIN,
		defaultInventory: [
			{ id: "block#9D2A38ECA4EB445BBCC6577549680B58" },
			{ id: "block#5B587CEF68014FB493BACF38253227F8" },
			{ id: "block#441BDC3043504555AD5DC4D407DF0EC4" },
			{ id: "block#BD8BB4D42B1A4C629CE9CF8DAADE9834" },
			{ id: "block#F830A38C4EB3442AB6B8BAF9A01516E5" },
			{ id: "block#5EE436B7F57144FD8C7CC09E2C09051B" },
			{ id: "block#6D60E3D1BA7442BF864CDDBC508764A7" },
			{ id: "block#2FC2BF3A241F4B96B75D545E6C7108A3" },
			{ id: "block#6E98B26FBAF44BDFBACE9200BBBBBE64" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 200,
				fogNear: 200,
			},
			sky: {
				fogy: 0,
				haze: 0.1,
				step: 16,
				sample: 128,
				fogColor: "#81b8d9",
				skyColor: "#305464",
				cloudSize: 0.29,
				cloudColor: "#e0fffb",
				saturation: 1.3,
				groundColor: "#bb7831",
				toneMapping: TONE_MAPPING.ACES,
				cloudDensity: 1,
				cloudCoverage: 0.56,
				cloudDistance: 0.64,
				toneMappingExposure: 3.1,
			},
			light: {
				sunColor: "#f5d6b2",
				sunIntensity: 0.4,
				nightLuminosity: 0.81,
			},
			general: {
				hour: 9.6,
				azimuth: 26,
			},
		},
		packagesIds: ["7DE1A667116B4EC8880CEF1D39948DA1"],
	},
};

const WildWestDesertWorldTemplate: WorldTemplate = {
	codename: "wild-west-desert",
	title: `Wild West Desert`,
	description: "Vast desert landscape with the hot sun and cacti scattered across the sand",
	thumbnailSrc: IMAGES.WILD_WEST_DESERT_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: WILD_WEST_DESERT_TERRAIN,
		defaultInventory: [
			{ id: "block#0DFCCA06BDCB4E3C89B93700A3BAEE40" },
			{ id: "block#DB49545197144DF493699D1A586941E0" },
			{ id: "block#57473602BFC84B2386DA4CB95FE949E8" },
			{ id: "block#751B48EB17BC4CF7BB8EDBD4A2E3C739" },
			{ id: "block#8172A656DEE549FF9F56EE8E2A8137C4" },
			{ id: "block#09702931C2B64AD4BC81994830B1EFD6" },
			{ id: "block#2749332881164C47B26435C3CF9260E3" },
			{ id: "block#9471C23A72714EFCB95AD25B12D7026F" },
			{ id: "block#7016A51D572845BCA409A343080CA576" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 200,
				fogNear: 187,
			},
			sky: {
				fogy: 0,
				haze: 0,
				step: 16,
				sample: 128,
				fogColor: "#d9a27d",
				skyColor: "#0970f6",
				cloudSize: 0.29,
				cloudColor: "#fddfae",
				saturation: 1.3,
				groundColor: "#bb7831",
				toneMapping: TONE_MAPPING.ACES,
				cloudDensity: 1,
				cloudCoverage: 0.5,
				cloudDistance: 0.64,
				toneMappingExposure: 2.7,
			},
			light: {
				sunColor: "#ffffff",
				sunIntensity: 0.9,
				nightLuminosity: 0.81,
			},
			general: {
				hour: 16.5,
				azimuth: 21,
			},
		},
		packagesIds: ["A08AAD7B8A3349C8A03D0D37B2768BA3"],
	},
};

export const Templates: WorldTemplate[] = [
	FantasyGrasslandsWorldTemplate,
	TropicalWorldTemplate,
	WildWestDesertWorldTemplate,
	SnowyTundraWorldTemplate,
	MVRWorldTemplate,
	BlankWorldTemplate,
];
