export const MOVEMENT = {
	horizontal: 0,
	stop: 1,
	up: 2,
	down: 3,
	fly: 4,
};

export const MODE = {
	build: "build",
	break: "break",
};

/**
 * Default key controls
 */
export const CONTROLS = {
	hub: ["h"],
	inventory: ["e"],
	editor: ["j"],
	chat: ["t"],
	emote: ["g"],
	camera: ["c"],
	fullscreen: ["f11"],
	reload: ["r"],
	tool: ["q"],
	sculpt: ["z"],
	invert: ["i"],
	interact: ["f"],
	forward: ["w"],
	backward: ["s"],
	right: ["d"],
	left: ["a"],
	up: [" "],
	down: ["shift"],
	sprint: ["capslock"],
	pickBlock: ["p"],
	forceRespawn: ["control", "k"],
	screenshotCanvas: ["f2"],
	zenMode: ["control", "m"],
	context: ["`"],

	/* world editor */
	worldEditorStartFromSpawn: ["control", "o"],
	worldEditorToggleStartStop: ["tab"],
	worldEditorTogglePauseResume: ["p"],
	worldEditorRestart: ["l"],

	//unlisted
	debug: ["control", "'"],
	toggleOcclusionCulling: ["control", "shift", "z"],
	toggleOcclusionCullingDebug: ["control", "shift", "v"],
	toggleDebugRendererMode: ["control", "l"],
	toggleZPrepass: ["control", "i"],
	toggleAASetting: ["control", "o"],

	/* animation system */
	toggleLocomotionLogs: ["control", "b"],

	/* selector controls */
	selectorToggleSelectionMode: ["x"],
	selectorResetBulkSelection: ["r"],
	selectorNudgeLeft: ["arrowleft"],
	selectorNudgeRight: ["arrowright"],
	selectorNudgeForward: ["arrowup"],
	selectorNudgeBack: ["arrowdown"],
	selectorNudgeUp: ["]"],
	selectorNudgeDown: ["["],
	/* end - selector controls */

	/* pencil controls */
	// todo: items/defs should be able to define their own controls - https://www.notion.so/novablocksgame/Contextual-Controls-383396fc59c94f55a3214d894434ab0f
	cut: ["control", "x"],
	copy: ["control", "c"],
	paste: ["control", "v"],
	pasteImmediately: ["control", "shift", "v"],
	undo: ["control", "z"],
	redo: ["control", "y"],
	pencilSolidFill: ["f"],
	pencilHollowFill: ["b"],
	pencilRemoveAllBlocks: ["v"],
	pencilRotateClipboard: ["r"],
	pencilFlipClipboard: ["v"],
	pencilCreateBlockStructure: ["control", "p"],
	/* end - pencil controls */

	/* wrench controls */
	wrenchRemoveBlockGroups: ["backspace"],
	selectorCycleScriptTargets: ["tab"],
	/* end - wrench controls */

	/* rete controls */
	reteUndo: ["control", "z"],
	reteRedo: ["control", "y"],
	/* end - rete controls */
};
