import { S3FileName } from "@jamango/content-client";
import { createImportWorldRequest, ws } from "rest-client";
import { uploadFileToS3 } from "./upload-file-to-s3";
import { Jacy } from "@jacy-client";
import type { PubEventByName } from "@jamango/content-service/types/index.ts";

export async function importWorldBundle(zipContent: File) {
	const signedRequestResult = await createImportWorldRequest();

	if (!signedRequestResult.data) throw new Error(`Can't process request`);

	const signedRequest = signedRequestResult.data;

	const requestId = signedRequest.requestId;

	const file = new File([zipContent], S3FileName.BUNDLE);
	await uploadFileToS3(Jacy, { uploadUrl: signedRequest.uploadUrl, file });

	const sub = ws.client.subscribe("world:imported", { requestId });

	let lastMessage: PubEventByName<"world:imported"> | undefined;

	sub.listen((msg) => {
		lastMessage = msg;
	});

	const error = await Promise.any([
		sub.disposedPromise,
		new Promise<Error>((resolve) =>
			setTimeout(
				() => resolve(new Error("We couldn't import the world. Please try again.")),
				5 * 60 * 1000, //5mins
			),
		),
	]);

	sub.unsubscribe();

	if (error) {
		throw error;
	}

	if (!lastMessage?.result) {
		throw new Error("We couldn't import the world. Please try again.");
	}

	return {
		shortCode: lastMessage.result.shortCode,
		versionId: lastMessage.result.versionId,
	};
}
