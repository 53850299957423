import { DEFAULT_SETTINGS } from "../../lib/constants/settings";
import { generateId } from "../../lib/helpers/general";
import type { ISettings } from "../../lib/types";
import { AssetType } from "../../lib/types";
import { validations } from "../../lib/validations";
import type { JacyContentState } from "../JacyContentState";

export class JacySettingsState {
	#settings: ISettings;
	#state: JacyContentState;

	get pk() {
		return this.#settings.pk;
	}

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#settings = this.getDefault();
	}

	import(data: ISettings = this.getDefault()) {
		this.set(data);
	}

	export() {
		return this.#settings;
	}

	get dayCycle() {
		return this.#settings.dayCycle;
	}

	get() {
		return this.#settings;
	}

	update(data: Partial<ISettings>) {
		if (!this.pk) {
			throw new Error("Can't update settings, world is not loaded yet.");
		}

		this.set({
			...this.get(),
			...data,
		} satisfies ISettings);
	}

	setDayCycle(enabled: boolean) {
		validations.settings.dayCycle(enabled);
		this.update({ dayCycle: enabled });
	}

	set(data: ISettings) {
		if (!this.pk) {
			throw new Error("Can't set settings, world is not loaded yet.");
		}

		this.#settings = structuredClone({
			...DEFAULT_SETTINGS,
			...data,
		});

		this.#state.markDirty();
	}

	getDefault() {
		const id = generateId();
		return {
			...DEFAULT_SETTINGS,
			pk: `${AssetType.SETTINGS}#${id}`,
			type: AssetType.SETTINGS,
		} satisfies ISettings;
	}
}
