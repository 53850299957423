import { create } from "zustand";
import { Loading } from "./game-client";
import type { PubEventByName } from "@jamango/content-service/types/index.ts";
import type { PeerMetadata } from "@jamango/engine/PeerMetadata.js";
import { Jacy } from "@jacy-client";
import { useAuthUserStore } from "@stores/auth-user";

type Events = NonNullable<PubEventByName<"startup">["result"]>;

type Event<T extends Events["type"]> = Extract<Events, { type: T }>;

type IServer = Event<"server-list">["servers"][number];

type IMultiplayerStore = {
	loading: null | Loading;
	isHosting: boolean;
	isServer: boolean;
	isPublicServer: boolean;
	serverID: string | null;
	peerID: string | null;
	hostname: string | null;
	peers: PeerMetadata[];
	status: null | string;
	servers: IServer[];

	setStatus: (status: null | string) => void;
	getCollaboratorServers: () => IServer[];
	setPublicServer: (isPublicServer: boolean) => void;
	setServer: (isServer: boolean) => void;
};

export const useMultiplayerStore = create<IMultiplayerStore>()((set, get) => ({
	status: null,
	loading: null,
	isHosting: false,
	isPublicServer: false,
	isServer: true,
	serverID: null,
	peerID: null,
	hostname: null,
	peers: [],
	servers: [],

	setStatus: (status) => set({ status }),
	getCollaboratorServers: () => {
		const { servers, serverID } = get();
		const world = Jacy.state.worldData;
		const userId = useAuthUserStore.getState().userId;

		const owner = world.owner;
		const collaborators = world.collaborators.map((collaborator) => collaborator.id);

		if (!userId || !owner) return [];
		if (!collaborators) return [];

		return servers.filter(
			(server) =>
				server.worldID === world.shortCode &&
				server.serverID !== serverID &&
				server.userId !== userId &&
				(owner.id === server.userId || collaborators.includes(server.userId ?? "")),
		);
	},
	setPublicServer: (isPublicServer) => {
		set({ isPublicServer });

		if (isPublicServer !== undefined) {
			Jacy.setIsPublicServer(isPublicServer);
		}
	},
	setServer: (isServer) => {
		set({ isServer });

		if (isServer !== undefined) {
			Jacy.setIsServer(isServer);
		}
	},
}));

export default {
	useMultiplayer: useMultiplayerStore.getState,
};
