import { generateId } from "../../lib/helpers/general";
import type { IBlockStructure, IBundle } from "../../lib/types";
import { AssetType, type IAssetKey } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";

export class JacyBlockStructuresState {
	blockStructures: NonNullable<IBundle["assets"]["blockStructures"]>;
	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.blockStructures = {};
	}

	import(data?: IBundle["assets"]["blockStructures"]) {
		if (!data) return;

		this.blockStructures = Object.assign(this.blockStructures, data);
		this.#state.markDirty();
	}

	export() {
		return this.blockStructures;
	}

	createNewBlockStructure(
		name: string,
		blocksData: [number, number, number, number, string][],
		blockGroups?: IBlockStructure["data"]["blockGroups"],
		blockGroupDefs?: IBlockStructure["data"]["blockGroupDefs"],
	): IBlockStructure {
		const id = generateId();
		const pk = `${AssetType.BLOCK_STRUCTURE}#${id}`;

		const blockTypesSet = new Set<string>();
		blocksData.forEach((block) => {
			blockTypesSet.add(block[4]);
		});

		const blockTypes = [...blockTypesSet];

		const blocks: Array<IBlockStructure["data"]["blocks"][number]> = [];

		for (const block of blocksData) {
			const [x, y, z, shape, type] = block;
			const blockTypeIndex = blockTypes.indexOf(type);

			blocks.push([x, y, z, shape, blockTypeIndex]);
		}

		const blockStructure: IBlockStructure = {
			pk,
			type: AssetType.BLOCK_STRUCTURE,
			name,
			data: {
				blocks,
				blockTypes,
				blockGroups,
				blockGroupDefs,
			},
		};

		if (blockStructure.data.blockGroups?.length === 0) {
			delete blockStructure.data.blockGroups;
		}

		if (
			blockStructure.data.blockGroupDefs &&
			Object.values(blockStructure.data.blockGroupDefs)?.length === 0
		) {
			delete blockStructure.data.blockGroupDefs;
		}

		return blockStructure;
	}

	generateNewName() {
		const names = new Set(this.getAll().map((i) => i.name));

		let newName = "";
		let counter = names.size;

		do {
			newName = `New Blueprint${counter}`;
			counter++;
		} while (names.has(newName));

		return newName;
	}

	set(data: IBlockStructure) {
		this.blockStructures[data.pk] = data;
		this.#state.markDirty();
	}

	get(pk: IAssetKey) {
		return this.blockStructures[pk];
	}

	delete(pk: IAssetKey) {
		delete this.blockStructures[pk];
		this.#state.markDirty();
	}

	getAll(): IBlockStructure[] {
		return Object.values(this.blockStructures);
	}
}
