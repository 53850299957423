import type React from "react";
import { forwardRef } from "react";

import { cn } from "@lib/helpers/cn";

export type IButtonSize = "xl" | "lg" | "md" | "sm" | "xs";
export type IButtonColor =
	| "primary"
	| "primary-outline"
	| "primary-ghost"
	| "warning"
	| "orange-gradient"
	| "black-alpha"
	| "red"
	| "red-outline"
	| "red-ghost"
	| "white"
	| "white-shadow"
	| "white-ghost";

export type IButtonProps = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> & {
	size?: IButtonSize;
	color?: IButtonColor;
	fullWidth?: boolean;
	pushEffect?: boolean;
	square?: boolean;
	isSubmit?: boolean;
	justifyLeft?: boolean;
	squashable?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
	(
		{
			color = "primary",
			size = "lg",
			type = "button",
			fullWidth = false,
			pushEffect = true,
			square = false,
			isSubmit = false,
			justifyLeft = false,
			squashable = false, // Makes the button smaller in height on short screens
			className = "",
			...props
		},
		ref,
	) => (
		<button
			ref={ref}
			type={isSubmit ? "submit" : type}
			className={cn(
				"font-medium transition-all focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-blue-300 disabled:opacity-50 has-[svg,img]:inline-flex has-[svg,img]:items-center has-[svg,img]:justify-center",

				color === "primary" &&
					"border-b-2 border-b-blue-700 bg-blue-500 text-white focus-visible:ring-offset-2 enabled:hover:border-b-blue-800 enabled:hover:bg-blue-600",

				color === "primary" &&
					pushEffect &&
					"enabled:active:translate-y-[1px] enabled:active:border-b-transparent",

				color === "primary" && !pushEffect && "enabled:active:bg-blue-700",

				color === "primary-outline" &&
					"border-2 border-blue-400 bg-white text-blue-500 hover:border-blue-500 hover:bg-blue-50 focus-visible:border-transparent active:border-blue-500 active:bg-blue-100",
				color === "primary-ghost" &&
					"border-2 border-none bg-white text-blue-500 hover:bg-blue-50 active:bg-gray-100",

				color === "orange-gradient" &&
					"orange-btn-readability-shadow border-b-2 border-b-[#D33102] bg-gradient-to-r from-[#FFAD0F] to-[#FF7C02] bg-clip-padding text-white hover:brightness-105 focus-visible:ring-offset-2 enabled:hover:border-b-[#c2401f] enabled:hover:bg-orange-600",

				color === "orange-gradient" &&
					pushEffect &&
					"enabled:active:translate-y-[2px] enabled:active:border-b-transparent",

				color === "orange-gradient" && !pushEffect && "enabled:active:saturate-200",

				color === "warning" &&
					"border-b-2 border-yellow-200 bg-yellow-400 text-black hover:bg-yellow-600 focus-visible:ring-offset-2",

				color === "black-alpha" &&
					"bg-gray-900/40 text-white hover:bg-gray-900/60 focus:bg-gray-900/60",

				color === "red" &&
					"border-b-2 border-b-red-700 bg-red-500 text-white enabled:hover:border-b-red-800 enabled:hover:bg-red-600",

				color === "red" &&
					pushEffect &&
					"enabled:active:translate-y-[1px] enabled:active:border-b-transparent",

				color === "red" && !pushEffect && "enabled:active:bg-red-700",

				color === "red-ghost" &&
					"text-red-700 enabled:hover:bg-red-50 enabled:focus:bg-red-50 enabled:active:bg-red-50",

				color === "white" &&
					"border border-slate-300 bg-white text-slate-700 hover:bg-slate-50 focus-visible:border-transparent active:border-slate-400 active:bg-slate-100",

				color === "white-shadow" &&
					"bg-white text-slate-700 shadow hover:bg-slate-50 active:bg-slate-100",

				color === "white-ghost" &&
					"text-slate-700 enabled:hover:bg-slate-100 enabled:focus:bg-slate-100 enabled:active:bg-slate-200",

				size === "xl" && "rounded-lg px-5 py-2 text-2xl has-[svg,img]:gap-2.5",
				size === "xl" && squashable && "py-1 sm-h:py-2",

				// 17px font-size
				size === "lg" &&
					"rounded-lg px-4 py-[0.4375rem] text-[1.0625rem] leading-[1.625rem] has-[svg,img]:gap-2",
				size === "lg" && squashable && "py-0.5 sm-h:py-[0.4375rem]",

				size === "md" && "rounded-md px-3.5 py-1.5 has-[svg,img]:gap-1.5",
				size === "md" && squashable && "py-0.5 sm-h:py-1.5",
				size === "sm" && "rounded-md px-3 py-1 has-[svg,img]:gap-1",
				size === "sm" && squashable && "py-0.5 sm-h:py-1",
				size === "xs" && "rounded-md p-0.5 has-[svg,img]:gap-1",

				square && "aspect-square px-0 py-0",
				square && size === "lg" && "size-10",
				square && size === "md" && "size-9",
				square && size === "sm" && "size-8",
				square && size === "xs" && "size-6",

				square && size === "lg" && squashable && "size-9 sm-h:size-10",
				square && size === "md" && squashable && "size-8 sm-h:size-9",
				square && size === "sm" && squashable && "size-7 sm-h:size-8",

				fullWidth && "w-full",
				justifyLeft && "justify-start text-left has-[svg,img]:justify-start",
				className,
			)}
			{...props}
		/>
	),
);

Button.displayName = "Button";
