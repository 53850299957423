import { CaretLeft, X as CloseIcon } from "@components/icons";
import { Dialog } from "@components/ui/Dialog";
import { Button } from "@components/ui/Button";
import { useCreateWorldModal, useImportBlockPackModal } from "@stores/dialogs";
import { ImgButton } from "./ImgButton";
import { BLOCK_PACKS_LIST } from "@lib/constants/block-packs";

export function ImportBlockPackModal({ onClose }: { onClose?: () => void }) {
	const isOpen = useImportBlockPackModal((state) => state.open);
	const setOpen = useImportBlockPackModal((state) => state.setOpen);
	const setCreateWorldModal = useCreateWorldModal((state) => state.setOpen);
	const packagesIds = useCreateWorldModal((state) => state.packagesIds);
	const togglePackage = useCreateWorldModal((state) => state.togglePackage);
	const startNewWorld = useCreateWorldModal((state) => state.startNewWorld);
	const customWorldName = useCreateWorldModal((state) => state.customWorldName);

	const onStartWorldClicked = () => {
		setOpen(false);
		startNewWorld(false, customWorldName);
	};

	const totalBlocks = BLOCK_PACKS_LIST.reduce((acc, blockPack) => {
		if (packagesIds.has(blockPack.packageId)) {
			acc += blockPack.blockCount;
		}
		return acc;
	}, 0);

	return (
		<Dialog.Root
			open={isOpen}
			onOpenChange={(open) => {
				setOpen(false);
				!open && onClose?.();
			}}
		>
			<Dialog.Content className="z-10 max-w-6xl overflow-y-auto rounded-lg bg-white p-5 sm-h:shadow-lg">
				<Button
					className="fixed left-6 top-4 z-10"
					color="white"
					square
					onClick={() => {
						setOpen(false);
						setCreateWorldModal(true);
					}}
				>
					<CaretLeft className="size-6" />
				</Button>

				<Button
					className="fixed right-6 top-4 z-10"
					onClick={() => {
						setOpen(false);
						onClose?.();
					}}
					square
					color="white"
				>
					<CloseIcon className="size-6" />
				</Button>
				<div className="h-full overflow-y-auto pb-4">
					<Dialog.Title
						className="mx-auto mb-4 mt-2 text-center text-2xl font-semibold xs:text-3xl"
						data-qaid="import-blocks-title"
					>
						Choose block packs
					</Dialog.Title>
					<div className="mx-auto grid grid-cols-2 justify-center gap-2 py-6 sm:grid sm:max-w-3xl md:grid-cols-3 lg:max-w-5xl lg:gap-4">
						{BLOCK_PACKS_LIST.map((blockPack) => (
							<ImgButton
								blockPack={blockPack}
								key={blockPack.packageId}
								toggleBlockPack={togglePackage}
								selected={packagesIds.has(blockPack.packageId)}
							/>
						))}
					</div>

					<p className="text-md my-4 text-center">
						{totalBlocks === 0 ? (
							<>No additional blocks</>
						) : (
							<span className="font-semibold">{totalBlocks} blocks</span>
						)}{" "}
						will be added to your world
					</p>

					<div className="mx-auto max-w-sm">
						<Button
							className="w-full py-1.5 font-display text-xl"
							color="orange-gradient"
							onClick={onStartWorldClicked}
							data-qaid="start-world-on-import-blocks-btn"
						>
							Start World
						</Button>
					</div>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
}
