import { useState } from "react";
import { regions, type DedicatedServerRegion } from "@jamango/frontend/DedicatedServerRegions.ts";
import * as Popover from "@radix-ui/react-popover";

interface RegionSelectorProps {
	selectedRegion: DedicatedServerRegion;
	onRegionChange: (region: DedicatedServerRegion) => void;
	className?: string;
}

export const RegionSelector = ({ selectedRegion, onRegionChange, className = "" }: RegionSelectorProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleRegionSelect = (region: DedicatedServerRegion) => {
		onRegionChange(region);
		setIsOpen(false); // Close the popover
	};

	return (
		<Popover.Root open={isOpen} onOpenChange={setIsOpen}>
			<Popover.Trigger
				className={`flex h-10 items-center justify-center gap-1 rounded-lg border-2 border-gray-300 bg-white px-3 py-2 hover:border-gray-400 ${className}`}
				aria-haspopup="true"
			>
				<span className="text-xl">{selectedRegion.flag}</span>
			</Popover.Trigger>
			<Popover.Portal>
				<Popover.Content
					className="z-10 w-44 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
					sideOffset={8}
				>
					<div className="py-1" role="menu" aria-orientation="vertical">
						{regions.map((region) => (
							<button
								key={region.id}
								className={`flex w-full items-center gap-2 px-4 py-2 text-left text-sm ${
									selectedRegion.id === region.id ? "bg-slate-100" : "hover:bg-slate-50"
								}`}
								role="menuitem"
								onClick={() => handleRegionSelect(region)}
							>
								<span className="text-xl">{region.flag}</span>
								<span>{region.name}</span>
							</button>
						))}
					</div>
					<Popover.Arrow className="fill-white" />
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
};
