import { Jacy } from "@jacy-client";
import { SHORT_HASH } from "@jamango/generated";
import type { IUser, IWorldBundle } from "@jamango/content-client";
import { WorldUserRole } from "@jamango/content-client";
import { Analytics, type AnalyticsEvent } from "@jamango/analytics";
import * as Device from "./Device";
import * as Router from "./Router";
import * as GameLifeCycle from "./GameLifeCycle";
import * as GameMultiplayer from "./GameMultiplayer";
import * as GameClientState from "./GameClientState";

import { useConfirmPromptStore, useErrorStore, useGeneralPromptStore, useHubStore } from "@stores/dialogs";
import { useLoadingScreenStore } from "@stores/loading-screen";
import { useAuthDialogStore, useAuthUserStore } from "@stores/auth-user";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useEngineStore } from "@stores/bb";
import { Loading, useGameClientStore } from "@stores/game-client/game-client";
import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";
import { trackEvent } from "@lib/helpers/analytics/analyzeMe";
import * as Engine from "@jamango/engine/Runtime";
import { formatErrorMessage } from "@lib/helpers/formatErrorMessage";
import { useInventorySelectorStore } from "@stores/hud/inventory-selector";
import { BlankWorldTemplate, type WorldTemplateOptions } from "@components/world/templates";
import { SAVE_ERROR, uploadWorldSave } from "../jacy/upload/upload-world-save";
import { openJamFile, saveJamFile } from "@lib/helpers/files-io";
import { getDefaultWorld, getUserAvatars, getWorld, setBaseURL } from "rest-client";
import { importWorldBundle } from "../jacy/upload/import-world-bundle";
import { useMultiplayerStore } from "@stores/game-client/multiplayer";

export const IS_PRODUCTION = process.env.NODE_ENV === "production";

type GameClientOptions = { isStandalone?: boolean; worldBundle?: IWorldBundle; onWorldLoaded?: () => void };

let GameOptions: GameClientOptions = {};

export function getGameOptions() {
	return GameOptions;
}

export async function init({ isStandalone, worldBundle, onWorldLoaded }: GameClientOptions = {}) {
	GameOptions = { isStandalone, worldBundle, onWorldLoaded };

	Device.init();
	Router.init({ isStandalone });

	const analyticsClientTrackingImpl = (events: AnalyticsEvent[]) => {
		for (const [, name, value, meta] of events) {
			trackEvent("event", name, { value, ...meta });
		}
	};

	Analytics.init({
		url: globalEnv.PUBLIC_API_URL,
		buildVersion: SHORT_HASH,
		clientTrackingImpl: analyticsClientTrackingImpl,
	});

	const bypassAuthToken = localStorage.getItem("x-jamango-bypass-basic-auth") || undefined;
	setBaseURL(globalEnv.PUBLIC_API_URL, bypassAuthToken);
	await Jacy.init();
}

type NewWorldOptions = {
	worldTemplateOptions?: WorldTemplateOptions;
	force?: boolean;
	customWorldName?: null | string;
	customWorldBundle?: IWorldBundle;
	packagesIds?: string[];
};

export async function startNewWorld({
	worldTemplateOptions,
	force,
	customWorldName = null,
	customWorldBundle,
	packagesIds = [],
}: NewWorldOptions) {
	if (!force && Router.isPlayPage()) {
		const confirmPrompt = useConfirmPromptStore.getState().prompt;
		const confirmed = await confirmPrompt({
			title: "Are you sure?",
			description: useAuthUserStore.getState().isCreator
				? "This will generate a new world. Don't forget to save any unsaved progress of your current world."
				: "Are you sure you want to leave this world to start your own world?",
			confirmText: "Yes, start new world",
		});

		if (!confirmed) return;
	}

	if (!localStorage.getItem("serverId") && !localStorage.getItem("worldId")) {
		trackEvent("event", "visit_world");
		trackEvent("event", "start_world_from_homepage");
	}

	const loading = useGameClientStore.getState().loading;
	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to start a new world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();
	useGameClientStore.getState().setLoading(Loading.LOAD_WORLD);
	useLoadingScreenStore.getState().show();
	await GameLifeCycle.onDisposeWorld();
	await Jacy.initializePromise;

	try {
		useMultiplayerStore.getState().setPublicServer(false);

		const [initWorldBundle, userAvatars] = await Promise.all([
			customWorldBundle
				? Promise.resolve(customWorldBundle)
				: getDefaultWorld({ packagesIds }).then((response) => response.ok && response.data?.bundle),
			!useAuthUserStore.getState().userId
				? Promise.resolve([])
				: getUserAvatars().then((response) => (response.ok && response.data?.avatars) || []),
		]);

		if (!initWorldBundle) {
			throw new Error("Failed to get initial world bundle.");
		}

		const bundle = Jacy.state.worldData.generateNewWorld(initWorldBundle);
		Jacy.content.state.worldData.setAsNew();
		Jacy.content.import(bundle, { isDefaultWorld: true });

		worldTemplateOptions?.terrainGeneration &&
			Jacy.content.state.map.setOptions({
				terrainGenerationOptions: worldTemplateOptions.terrainGeneration,
			});

		for (const avatar of userAvatars) {
			Jacy.content.importPackage(avatar);
		}

		const { userId, username, photoUrl } = useAuthUserStore.getState();
		let owner: IUser | null = null;
		if (userId && username) {
			owner = {
				id: userId,
				username,
				photoUrl: photoUrl ?? undefined,
			};
		}

		Jacy.content.setMetadata({ owner });

		if (worldTemplateOptions?.envPresetSettings) {
			const envPresetState = Jacy.content.state.environmentPreset;
			const current = envPresetState.current;
			if (current) {
				envPresetState.update(current.pk, current.name, worldTemplateOptions?.envPresetSettings);
			}
		}

		GameLifeCycle.onBeforeLoadWorld();

		await Engine.startNewWorld(Jacy.content, WorldUserRole.OWNER);

		if (customWorldName) {
			Jacy.actions.worldData.setName(customWorldName);
		}

		const inventory = worldTemplateOptions?.defaultInventory
			? useInventorySelectorStore
					.getState()
					.convertDefaultInventoryToIventorySlots(worldTemplateOptions.defaultInventory)
			: undefined;

		GameLifeCycle.onAfterLoadWorld(inventory);
		GameOptions.onWorldLoaded?.();
	} catch (error) {
		if (error instanceof Error) {
			useErrorStore.getState().setError(error);
		}
	}

	useGameClientStore.getState().setLoading(null);
}

export async function loadWorld(
	shortCode: string,
	options: { isPrivateServer?: boolean; versionId?: string | null } = {},
) {
	const isPrivateServer = options.isPrivateServer ?? true;

	const loading = useGameClientStore.getState().loading;
	const isGuest = !useAuthUserStore.getState().userId;

	if (isGuest) {
		Router.navigate("/signin");
		return;
	}

	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to load a world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	// TODO: Revisit this logic
	// if (
	// 	Router.isPlayPage() &&
	// 	!(
	// 		Jacy.state.worldData.shortCode && identifier === Jacy.state.worldData.shortCode
	// 	)
	// ) {
	// 	const confirmPrompt = useConfirmPromptStore.getState().prompt;
	// 	const confirmed = await confirmPrompt({
	// 		title: "Are you sure?",
	// 		description:
	// 			"Are you sure you want to leave this world to load another one? Any unsaved progress will be lost.",
	// 		confirmText: "Yes, load the other world",
	// 	});

	// 	if (!confirmed) return;
	// }

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();
	useGameClientStore.getState().setLoading(Loading.LOAD_WORLD);
	useLoadingScreenStore.getState().show();
	await GameLifeCycle.onDisposeWorld();
	await Jacy.initializePromise;

	try {
		useLoadingScreenStore.getState().setText("Loading world");
		useLoadingScreenStore.getState().show();

		const [worldResponse, userAvatars] = await Promise.all([
			getWorld({
				shortCode,
				versionId: options.versionId ?? undefined,
			}).then((response) => (response.data ? response.data : null)),
			isGuest
				? Promise.resolve([])
				: getUserAvatars().then((response) => (response.ok && response.data?.avatars) || []),
		]);

		if (!worldResponse) {
			throw new Error("Failed to load the world. Missing bundle");
		}

		const { bundle, world, authData } = worldResponse;

		useMultiplayerStore.getState().setPublicServer(isPrivateServer === false);
		Jacy.content.import(bundle);
		Jacy.content.setMetadata(world);

		for (const avatar of userAvatars) {
			Jacy.content.importPackage(avatar);
		}

		GameLifeCycle.onBeforeLoadWorld();

		trackEvent("event", "load_world", { world_id: shortCode });

		await Engine.startExistingWorld(Jacy.content, isPrivateServer ? authData.role : WorldUserRole.NONE);
		GameLifeCycle.onAfterLoadWorld(authData.inventory.selector);
		GameOptions.onWorldLoaded?.();

		useGameClientStore.getState().setLoading(null);
		trackEvent("event", "visit_world", { world_id: shortCode });

		if (isPrivateServer) {
			const servers = useMultiplayerStore.getState().servers;
			const otherHost = servers.find(
				(host) =>
					host.worldID === shortCode &&
					host.userId !== useAuthUserStore.getState().userId &&
					!host.dedicated,
			);

			if (!otherHost) return;

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: `Another collaborator is hosting this world`,
				description: `${otherHost.username} is hosting this world. Do you want to join them? If you continue, you will be hosting your own version of the world which may cause conflicts with the other host.`,
				confirmText: `Yes, join ${otherHost.username}`,
				cancelText: "Continue Anyway",
			});

			if (!confirmed) return;
			await GameMultiplayer.joinWorld(otherHost.serverID);
		}
	} catch (error: any) {
		if (error.isAxiosError) {
			if ([401, 404].includes(error.response?.status)) {
				Router.navigate("/404");
				return;
			}
		}

		useErrorStore.getState().setError(formatErrorMessage(error));
		useGameClientStore.getState().setLoading(null);
	}
}

export async function importWorld() {
	const loading = useGameClientStore.getState().loading;
	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to load a world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();

	useGameClientStore.getState().setLoading(Loading.LOAD_WORLD);

	await GameLifeCycle.onDisposeWorld();

	useLoadingScreenStore.getState().show();
	await Jacy.initializePromise;

	const zipFile = await openJamFile();

	useLoadingScreenStore.getState().setText("Importing world");

	const { shortCode, versionId } = await importWorldBundle(zipFile);

	useGameClientStore.getState().setLoading(null);

	await loadWorld(shortCode, { versionId });
}

export async function exportWorld() {
	if (!Router.isPlayPage()) {
		throw new Error("Exporting is only available in the play page.");
	}

	const mapBuffer = Engine.getMapBuffer();

	await Jacy.state.map.update(mapBuffer);

	const worldBundle = Jacy.content.export();
	const bytes = await Jacy.content.serializer.export(worldBundle);

	if (!bytes) {
		throw new Error("Failed to export the world.");
	}

	saveJamFile(new Blob([bytes]), `world-${worldBundle.id}`);
}

export async function saveWorld(force = false, autosave = false) {
	const loading = useGameClientStore.getState().loading;

	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to save the world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	if (!useAuthUserStore.getState().isCreator) {
		useGameClientStore
			.getState()
			.setNotification({ type: "error", message: "User is not allowed to save the world" });
		useInventoryStore.getState().toggle(true);
		return;
	}

	if (Jacy.ongoingUploads.size !== 0) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to save the world",
			message: "Please wait for the current uploads to complete.",
		});
		return;
	}

	const isNew = Jacy.state.worldData.isNew;

	if (!autosave && Jacy.state.worldData.name === "Unknown World") {
		const newWorldName = await useGeneralPromptStore
			.getState()
			.prompt("What would you like to call your world?", {
				confirmText: "Confirm Name",
				cancelText: `Cancel`,
			});
		if (newWorldName) {
			Jacy.actions.worldData.setName(newWorldName);
		}
	}

	const collaboratorHosts = useMultiplayerStore.getState().getCollaboratorServers();
	const confirmPrompt = useConfirmPromptStore.getState().prompt;

	if (collaboratorHosts.length && !force) {
		const names = collaboratorHosts.map((host: { username: string }) => host.username).join(", ");
		const verb = names.length ? "are" : "is";

		const confirmed = await confirmPrompt({
			title: "Are you sure?",
			description: `${names} ${verb} currently hosting the game, saving this world will overwrite the current version and might cause conflicts if ${names} ${verb} also hosting and saving their version.`,
			confirmText: "Yes, save world",
		});

		if (!confirmed) return;
	} else if (!force) {
		const confirmed = await confirmPrompt({
			title: "Saving the world",
			description:
				"Are you sure you want to save this world? While saving the world, do not close the tab, refresh the page, navigate to another page or load / join another world until the save is complete.",
			confirmText: "Yes, save world",
		});

		if (!confirmed) return;
	}

	useHubStore.getState().close();
	useInventoryStore.getState().close();
	useGameClientStore.getState().setLoading(Loading.SAVE_WORLD);

	try {
		const worldShortCode = await uploadWorldSave(Jacy, autosave);

		if (isNew) {
			trackEvent("event", "save_new_world", { world_id: worldShortCode });

			const { gbi } = useEngineStore.getState().engine;

			gbi.base.Multiplayer.updateWorldShortcode(worldShortCode);
		} else {
			trackEvent("event", "save_world", { world_id: worldShortCode });
		}

		localStorage.setItem("worldId", worldShortCode);

		Jacy.refreshContentState();
	} catch (error: any) {
		if (error?.message === SAVE_ERROR.UNAUTHORIZED) {
			useAuthDialogStore.getState().openLogin(false);
		} else {
			useAlertDialogStore.getState().setAlert({
				title: "Failed to save the world",
				message: `There was an error saving the world.\n${formatErrorMessage(error)}`,
			});
		}
	}

	useGameClientStore.getState().setLoading(null);
}

export async function saveAndReloadWorld() {
	const loading = useGameClientStore.getState().loading;
	if (loading && [Loading.LOAD_WORLD, Loading.RELOAD_WORLD].includes(loading)) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to save and reload the world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	if (useMultiplayerStore.getState().isHosting) {
		const confirmPrompt = useConfirmPromptStore.getState().prompt;
		const confirmed = await confirmPrompt({
			title: "Save and reload the world",
			description:
				"Are you sure you want to save this world? While saving the world, do not close the tab, refresh the page, navigate to another page or load / join another world until the save is complete.\nReloading the world will also stop hosting and kick out any players that are currently in this server.",
			confirmText: "Yes, save and reload the world",
		});

		if (!confirmed) return;
	}

	try {
		const hasReloaded = await reloadWorld(true);
		if (!hasReloaded) return;
	} catch (error) {
		if (error instanceof Error) {
			useErrorStore.getState().setError(error);
		}
	}

	saveWorld(true);
}

export async function leaveWorld() {
	const confirmed = await useConfirmPromptStore.getState().prompt({
		title: "Are you sure?",
		description:
			"You will lose any unsaved progress if you leave this server. This action cannot be undone.",
		confirmText: "Yes, leave world",
	});

	if (!confirmed) return false;

	loadDefaultWorld();
	return true;
}

export async function leaveGame(options: { force: boolean } = { force: false }) {
	const loading = useGameClientStore.getState().loading;
	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to leave the game",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	if (!options.force) {
		const confirmPrompt = useConfirmPromptStore.getState().prompt;
		const confirmed = await confirmPrompt({
			title: "Leave game?",
			description: "Leave game and return to the home page. Any unsaved progress will be lost.",
		});

		if (!confirmed) return;
	}

	Router.navigate("/");

	const worldID = Jacy.state.worldData.shortCode;
	const serverID = useMultiplayerStore.getState().serverID;

	trackEvent("event", "leave_world", { world_id: worldID, server_id: serverID, is_dedicated: false });

	try {
		await GameLifeCycle.onDisposeWorld();
	} catch (error) {
		if (error instanceof Error) {
			useErrorStore.getState().setError(error);
		}
	}
}

export async function reloadWorld(force = false) {
	if (useMultiplayerStore.getState().isHosting && !force) {
		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Are you sure?",
			description:
				"You are currently hosting. This will stop hosting and kick out any players that are currently in this server.",
			confirmText: "Yes, reload the world",
		});

		if (!confirmed) return false;
	}

	const bundle = Jacy.content.export();

	if (bundle) {
		const validationResult = Jacy.content.validate(bundle);

		if (validationResult !== true) {
			Jacy.actions.setValidationsErrors(validationResult);
			return false;
		}
	}

	const isSaving = useGameClientStore.getState().loading === Loading.SAVE_WORLD;

	try {
		useInventoryStore.getState().close();
		useHubStore.getState().close();

		useLoadingScreenStore.getState().setText("Reloading world");
		useLoadingScreenStore.getState().show();
		if (!isSaving) {
			useGameClientStore.getState().setLoading(Loading.RELOAD_WORLD);
		}
		try {
			await GameMultiplayer.disconnect();

			const state = GameClientState.getCurrentState();

			await Engine.reloadWorld(Jacy.content, Jacy.role);

			GameLifeCycle.onAfterLoadWorld();
			GameOptions.onWorldLoaded?.();
			GameClientState.setCurrentState(state);
			if (!isSaving) {
				useGameClientStore.getState().setLoading(null);
			}
			useLoadingScreenStore.getState().hide();

			return true;
		} catch (error) {
			if (error instanceof Error) {
				useErrorStore.getState().setError(error);
			}
			useGameClientStore.getState().setLoading(null);
			useLoadingScreenStore.getState().hide();

			return false;
		}
	} catch (error) {
		Jacy.actions.setError(error);
		return false;
	}
}

export async function loadDefaultWorld() {
	await startNewWorld({
		worldTemplateOptions: BlankWorldTemplate.options,
		force: true,
		customWorldBundle: GameOptions.worldBundle,
	});
}
