import * as avatar from "./avatar";
import * as blockTexture from "./block-texture";
import * as block from "./block";
import * as character from "./character";
import * as world from "./world";
import * as environmentPreset from "./environment-preset";
import * as customLoader from "./custom-loader";
import * as general from "./general";
import * as gameMechanics from "./game-mechanics";
import * as settings from "./setttings";
import * as skybox from "./skybox";
import * as thumbnail from "./thumbnail";
import * as audio from "./audio";
import * as bundle from "./bundle";
import * as terrainGeneration from "./terrainGeneration";
import * as resources from "./resources";
import * as particleSystem from "./particle-system";
import * as blockSoundsPack from "./block-sounds-pack";
import * as blockStructure from "./block-structure";

// NOTE: this causes import coupling, and is a pattern that should not be extended.
// export validations as above instead, and opportunistically remove usages of
// the `validations` exported object.
export const validations = {
	avatar,
	blockTexture,
	block,
	bundle,
	character,
	customLoader,
	environmentPreset,
	gameMechanics,
	general,
	settings,
	skybox,
	thumbnail,
	world,
	audio,
	terrainGeneration,
	resources,
	particleSystem,
	blockSoundsPack,
	blockStructure,
};

export type IValidations = typeof validations;
