import { EDIT_WORLD_MODE } from "@jamango/content-client";
import { WorldEditorAction, WorldEditorStatus } from "@jamango/engine/Runtime/base/world/WorldEditor.ts";
import * as WorldEditorRouter from "@jamango/engine/Runtime/router/world/WorldEditor.ts";
import { create } from "zustand";
import { Jacy } from "../jacy/JacyClient";
import { useEngineStore } from "./bb";
import { useControlsStore } from "./controls";
import { useInventoryStore } from "./dialogs/inventory";

type WorldEditorState = {
	status: WorldEditorStatus;

	// derived from status
	testing: boolean;
	previewing: boolean;
	playing: boolean;

	updateStatus: (status: WorldEditorStatus) => void;

	toggleStartStop: () => void;
	togglePauseResume: () => void;

	startGame: () => void;
	startTesting: () => void;
	startTestingFromSpawn: () => void;
	play: () => void;
	pause: () => void;
	stop: () => void;
	restart: () => void;

	clear: () => void;
};

export const useWorldEditorStore = create<WorldEditorState>((set, get) => ({
	status: WorldEditorStatus.PLAYING,
	testing: false,
	previewing: false,
	playing: true,

	updateStatus: (status) => {
		const prevStatus = get().status;

		const prevTesting =
			prevStatus === WorldEditorStatus.TESTING_ACTIVE ||
			prevStatus === WorldEditorStatus.TESTING_PAUSED;

		const testing =
			status === WorldEditorStatus.TESTING_ACTIVE || status === WorldEditorStatus.TESTING_PAUSED;

		const previewing = status === WorldEditorStatus.PREVIEWING;

		const playing = status === WorldEditorStatus.PLAYING;

		if (testing !== prevTesting) {
			const world = useEngineStore.getState().world;

			if (world) {
				world.hudPopup.client!.clear();
			}
		}

		set({ status, testing, previewing, playing });
	},

	toggleStartStop: () => {
		if (Jacy.state.worldData.mode !== EDIT_WORLD_MODE) return;

		const status = get().status;

		if (status === WorldEditorStatus.EDITING) {
			get().startTesting();
		} else {
			get().stop();
		}
	},

	togglePauseResume: () => {
		if (Jacy.state.worldData.mode !== EDIT_WORLD_MODE) return;

		const status = get().status;

		if (status === WorldEditorStatus.TESTING_ACTIVE) {
			get().pause();
		} else {
			get().play();
		}
	},

	startGame: () => {
		WorldEditorRouter.update(WorldEditorAction.START_GAME);

		useControlsStore.getState().exitPointerLock(false);
		useInventoryStore.getState().toggle(false);
	},

	startTesting: () => {
		if (Jacy.state.worldData.mode !== EDIT_WORLD_MODE) return;

		WorldEditorRouter.update(WorldEditorAction.START_TESTING_FROM_POSITION);
	},

	startTestingFromSpawn: () => {
		if (Jacy.state.worldData.mode !== EDIT_WORLD_MODE) return;

		WorldEditorRouter.update(WorldEditorAction.START_TESTING_FROM_SPAWN);
	},

	play: () => {
		WorldEditorRouter.update(WorldEditorAction.RESUME);
	},

	pause: () => {
		WorldEditorRouter.update(WorldEditorAction.PAUSE);
	},

	stop: () => {
		WorldEditorRouter.update(WorldEditorAction.STOP);
	},

	restart: () => {
		WorldEditorRouter.update(WorldEditorAction.RESTART);
	},

	clear: () => {
		get().updateStatus(WorldEditorStatus.PLAYING);
	},
}));

export default {
	useWorldEditor: useWorldEditorStore.getState,
};
