import type { IAssetKey, IBundle, ICostume, IParsedCostume } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";
import { getAvatarConfig as helperGetAvatarConfig } from "../../lib/helpers/avatars";

export class JacyCostumeState {
	#state: JacyContentState;
	#costumes: Record<IAssetKey, ICostume>;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#costumes = {};
	}

	import(data?: IBundle["assets"]["costumes"]) {
		if (!data) return;

		this.#costumes = Object.assign(this.#costumes, data);

		this.#state.markDirty();
	}

	export() {
		return this.#costumes;
	}

	set(data: ICostume) {
		this.#costumes[data.pk] = data;

		this.#state.markDirty();
	}

	get(pk?: IAssetKey | null) {
		const raw = this.getUnparsed(pk);
		if (!raw) return null;
		return this.#parsedCostume(raw);
	}

	getUnparsed(pk?: IAssetKey | null) {
		if (!pk) return null;
		return this.#costumes[pk];
	}

	getAvatarSetConfig(avatar: ICostume) {
		return helperGetAvatarConfig(avatar, this.#state.avatarComponents.getAll());
	}

	getAll() {
		return Object.values(this.#costumes)
			.filter((avatar) => !avatar.isDeleted)
			.map((c) => this.#parsedCostume(c)!)
			.filter(Boolean);
	}

	#parsedCostume(data: ICostume): IParsedCostume | null {
		const thumbnailResource = this.#state.resources.getAvatarThumbnail(data.thumbnailResourcePk);

		if (!thumbnailResource) {
			return null;
		}

		const displayPhotoResource = data.displayPhotoResourcePk
			? this.#state.resources.getAvatarPhoto(data.displayPhotoResourcePk)
			: null;

		return {
			...data,
			thumbnailResource,
			displayPhotoResource,
		};
	}
}
