import { assertNever } from "@jamango/helpers";
import { MAP_RESOURCE_MIME_TYPE, TEXTURE_RESOURCE_TYPES } from "../../lib/constants/resources";
import {
	createUrlFromFile,
	formatFileName,
	getExtension,
	renameFile,
	resizeTexture,
} from "../../lib/helpers/files";
import { generateId, generateShortId } from "../../lib/helpers/general";
import type { IBundle } from "../../lib/types";
import { AssetType, type IAssetKey } from "../../lib/types";
import type { MapResource, Resource } from "../../lib/types/resources";
import { validations } from "../../lib/validations";
import type { JacyContentState } from "../JacyContentState";

type BetterOmit<Type, TFields extends keyof Type> = {
	[Property in keyof Type as Exclude<Property, TFields>]: Type[Property];
};

export class JacyResourcesState {
	assets: Record<IAssetKey, Resource>;
	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.assets = {};
	}

	import(data?: IBundle["assets"]["resources"]) {
		if (!data) return;

		this.assets = Object.assign(this.assets, data);
		this.#state.markDirty();
	}

	export() {
		return Object.fromEntries(
			Object.values(this.assets)
				.filter((resource) => !resource.isBuiltIn)
				.map((resource) => {
					// remove 'buffer' from the file object
					const { buffer: _, ...fileDetails } = resource.file;
					return { ...resource, file: fileDetails };
				})
				.map((resource) => [resource.pk, resource]),
		);
	}

	async #preprocessFile<TResourceType extends Resource["resourceType"]>(
		initialFile: File,
		resourceType: TResourceType,
	) {
		let fileNamePrefix = "unknown";
		let fileNamePostfix = `-${generateShortId()}`;

		switch (resourceType) {
			case "thumbnail":
			case "banner":
			case "avatarPhoto":
			case "avatarThumbnail":
				fileNamePrefix = "image-";
				break;
			case "blockTexture":
			case "skyboxSide":
				fileNamePrefix = "texture-";
				break;
			case "map":
				fileNamePrefix = "map-";
				break;
			case "audio":
				fileNamePrefix = "";
				fileNamePostfix = "";
				break;
			default:
				assertNever(resourceType);
		}

		const name = `${fileNamePrefix}${formatFileName(initialFile.name)}${fileNamePostfix}`;
		const fileExtension = getExtension(initialFile.name);
		const fileName = `${name}.${fileExtension}`;

		let file = renameFile(initialFile, fileName);

		if (TEXTURE_RESOURCE_TYPES.includes(resourceType)) {
			const resizedFile = await resizeTexture(file);

			if (resizedFile) {
				file = resizedFile;
			}
		}

		return {
			name,
			resourceType: resourceType as TResourceType,
			file,
		} satisfies {
			name: string;
			resourceType: Resource["resourceType"];
			file: File;
		};
	}

	processThumbnailImage(initialFile: File) {
		validations.thumbnail.thumbnailFile(initialFile);

		return this.#preprocessFile(initialFile, "thumbnail");
	}

	processBannerImage(initialFile: File) {
		validations.customLoader.banner(initialFile);

		return this.#preprocessFile(initialFile, "banner");
	}

	processBlockTexture(initialFile: File) {
		validations.resources.textureFile(initialFile);

		return this.#preprocessFile(initialFile, "blockTexture");
	}

	processSkyboxSideTexture(initialFile: File) {
		validations.skybox.skyboxSide(initialFile);

		return this.#preprocessFile(initialFile, "skyboxSide");
	}

	processAudio(initialFile: File) {
		validations.resources.audioFile(initialFile);

		return this.#preprocessFile(initialFile, "audio");
	}

	processAvatarThumbnail(initialFile: File) {
		validations.avatar.file(initialFile);

		return this.#preprocessFile(initialFile, "avatarThumbnail");
	}

	processAvatarPhoto(initialFile: File) {
		validations.avatar.file(initialFile);

		return this.#preprocessFile(initialFile, "avatarPhoto");
	}

	async uploadMap(initialFile: File) {
		const { file, name } = await this.#preprocessFile(initialFile, "map");

		const map: Omit<MapResource, "pk"> = {
			type: AssetType.RESOURCE,
			resourceType: "map",
			name,
			file: {
				buffer: file,
				name: file.name,
				size: file.size,
				mimeType: MAP_RESOURCE_MIME_TYPE,
				url: createUrlFromFile(file),
			},
		};

		const resource = this.createResource(map);

		return this.addResource(resource);
	}

	remove(pk: string) {
		const resource = this.get(pk);

		if (!resource) return;

		if (resource.file.buffer) {
			URL.revokeObjectURL(resource.file.url);
		}

		this.#remove(pk);
	}

	addBuiltIn(data: BetterOmit<Resource, "pk" | "type">) {
		const pk = `${AssetType.RESOURCE}#${generateId()}`;

		const resource: Resource = {
			...data,
			pk,
			isBuiltIn: true,
			type: AssetType.RESOURCE,
		};

		return this.addResource(resource);
	}

	createResource(data: BetterOmit<Resource, "pk">, id = generateId()) {
		const pk = `${AssetType.RESOURCE}#${id}`;
		const resource = { ...data, pk };

		return resource;
	}

	addResource<T extends Resource>(resource: T): T {
		this.assets[resource.pk] = resource;

		this.#state.markDirty();

		return resource;
	}

	#remove(pk: IAssetKey) {
		delete this.assets[pk];

		this.#state.markDirty();
	}

	has(pk: string) {
		return pk in this.assets;
	}

	get(pk?: string | null) {
		if (!pk) return undefined;
		return this.assets[pk];
	}

	getBlockTexture(pk: string) {
		const resource = this.get(pk);
		if (!resource) return null;
		if (resource.resourceType !== "blockTexture") return null;
		return resource;
	}

	getAudio(pk: string) {
		const resource = this.get(pk);
		if (!resource) return null;
		if (resource.resourceType !== "audio") return null;
		return resource;
	}

	getAvatarThumbnail(pk: string) {
		const resource = this.get(pk);
		if (!resource) return null;
		if (resource.resourceType !== "avatarThumbnail") return null;
		return resource;
	}

	getAvatarPhoto(pk: string) {
		const resource = this.get(pk);
		if (!resource) return null;
		if (resource.resourceType !== "avatarPhoto") return null;
		return resource;
	}

	getAll() {
		return Object.values(this.assets);
	}
}
