import { AssetType, type IBundle, type IWorldBundle } from "../lib/types";
import { JacyBlocksState } from "./state/JacyBlocksState";
import { JacySettingsState } from "./state/JacySettingsState";
import { JacyCustomLoaderState } from "./state/JacyCustomLoaderState";
import { JacyThumbnailState } from "./state/JacyThumbnailState";
import { JacyGameMechanicsState } from "./state/JacyGameMechanicsState";
import { JacyBlockTextureState } from "./state/JacyBlockTextureState";
import { JacyAudioState } from "./state/JacyAudioState";
import { JacyEnvironmentPresetState } from "./state/JacyEnvironmentPresetState";
import { JacySkyboxState } from "./state/JacySkyboxState";
import { JacyMapState } from "./state/JacyMapState";
import { JacyCharactersState } from "./state/JacyCharactersState";
import { JacyAvatarsState } from "./state/JacyAvatarsState";
import { JacyAvatarComponentsState } from "./state/JacyAvatarComponentsState";
import { JacyWorldDataState } from "./state/JacyWorldDataState";
import { JacyCostumeState } from "./state/JacyCostumeState";
import { JacyResourcesState } from "./state/JacyResourcesState";
import { LATEST_BUNDLE_VERSION } from "../lib/validations/bundle";
import { JacyCustomUIState } from "./state/JacyCustomUIState";
import { JacyScriptsState } from "./state/JacyScriptsState";
import { JacyParticleSystemsState } from "./state/JacyParticleSystemsState";
import { JacyBlockSoundsPackState } from "./state/JacyBlockSoundsPackState";
import { exportAsPackage } from "./export-as-package";
import { JacyBlockStructuresState } from "./state/JacyBlockStructuresState";
import { getStaticFile } from "../lib/helpers/cdn";
import { getAssetType } from "../lib/helpers/general";
import { JacyPropsState } from "./state/JacyPropState";
import { JacyItemsState } from "./state/JacyItemState";

export class JacyContentState {
	isPublicServer: boolean;
	isServer: boolean;

	map: JacyMapState;
	settings: JacySettingsState;
	customLoader: JacyCustomLoaderState;
	gameMechanics: JacyGameMechanicsState;
	environmentPreset: JacyEnvironmentPresetState;
	thumbnail: JacyThumbnailState;
	skybox: JacySkyboxState;
	blocks: JacyBlocksState;
	blockTextures: JacyBlockTextureState;
	audios: JacyAudioState;
	characters: JacyCharactersState;
	avatars: JacyAvatarsState;
	avatarComponents: JacyAvatarComponentsState;
	worldData: JacyWorldDataState;
	costumes: JacyCostumeState;
	scripts: JacyScriptsState;
	resources: JacyResourcesState;
	customUI: JacyCustomUIState;
	particleSystems: JacyParticleSystemsState;
	blockSoundsPacks: JacyBlockSoundsPackState;
	blockStructures: JacyBlockStructuresState;
	props: JacyPropsState;
	items: JacyItemsState;

	#dirty = false;

	get isDirty() {
		return this.#dirty;
	}

	constructor() {
		this.isPublicServer = false;
		this.isServer = true;

		this.worldData = new JacyWorldDataState(this);

		this.map = new JacyMapState(this);
		this.settings = new JacySettingsState(this);
		this.customLoader = new JacyCustomLoaderState(this);
		this.gameMechanics = new JacyGameMechanicsState(this);
		this.environmentPreset = new JacyEnvironmentPresetState(this);
		this.thumbnail = new JacyThumbnailState(this);
		this.skybox = new JacySkyboxState(this);
		this.blocks = new JacyBlocksState(this);
		this.blockTextures = new JacyBlockTextureState(this);
		this.audios = new JacyAudioState(this);
		this.characters = new JacyCharactersState(this);
		this.avatars = new JacyAvatarsState(this);
		this.avatarComponents = new JacyAvatarComponentsState();
		this.costumes = new JacyCostumeState(this);
		this.scripts = new JacyScriptsState(this);
		this.resources = new JacyResourcesState(this);
		this.customUI = new JacyCustomUIState(this);
		this.particleSystems = new JacyParticleSystemsState();
		this.blockSoundsPacks = new JacyBlockSoundsPackState(this);
		this.blockStructures = new JacyBlockStructuresState(this);
		this.props = new JacyPropsState(this);
		this.items = new JacyItemsState(this);
	}

	get isPrivateServer() {
		return !this.isPublicServer;
	}

	import(worldBundle: IWorldBundle) {
		const { assets } = worldBundle;

		this.worldData.import(assets.worldData);
		this.map.import(assets.map);
		this.settings.import(assets.settings);
		this.gameMechanics.import(assets.gameMechanics);
		this.customLoader.import(assets.customLoader);
		this.thumbnail.import(assets.thumbnail);

		this.resources.import(assets.resources);
		this.environmentPreset.import(assets.environmentPresets);
		this.skybox.import(assets.skyboxes);
		this.blockTextures.import(assets.blockTextures);
		this.blocks.import(assets.blocks);
		this.avatars.import(assets.avatars);
		this.characters.import(assets.characters);
		this.audios.import(assets.audios);
		this.costumes.import(assets.costumes);
		this.customUI.import(assets.customUI);
		this.scripts.import(assets.scripts);
		this.blockStructures.import(assets.blockStructures);
		this.props.import(assets.props);
		this.items.import(assets.items);

		this.markDirty();
	}

	importPackage(packageBundle: IBundle) {
		const { assets } = packageBundle;

		if ("packageData" in assets && assets.packageData) {
			this.worldData.addPackage({
				id: assets.packageData.id,
				version: assets.packageData.version,
			});
		}

		this.resources.import(assets.resources);
		this.environmentPreset.import(assets.environmentPresets);
		this.skybox.import(assets.skyboxes);
		this.blockTextures.import(assets.blockTextures);
		this.blocks.import(assets.blocks);
		this.avatars.import(assets.avatars);
		this.characters.import(assets.characters);
		this.audios.import(assets.audios);
		this.costumes.import(assets.costumes);
		this.customUI.import(assets.customUI);
		this.scripts.import(assets.scripts);
		this.blockStructures.import(assets.blockStructures);
		this.props.import(assets.props);
		this.items.import(assets.items);

		this.markDirty();
	}

	export(): IWorldBundle {
		const bundle: IWorldBundle = {
			version: LATEST_BUNDLE_VERSION,
			type: "world",
			id: this.worldData.id,
			assets: {
				map: this.map.export(),
				settings: this.settings.export(),
				environmentPresets: this.environmentPreset.export(),
				gameMechanics: this.gameMechanics.export(),
				customLoader: this.customLoader.export(),
				thumbnail: this.thumbnail.export() ?? undefined,
				skyboxes: this.skybox.export(),
				blockTextures: this.blockTextures.export(),
				blocks: this.blocks.export(),
				avatars: this.avatars.export(),
				characters: this.characters.export(),
				audios: this.audios.export(),
				worldData: this.worldData.export(),
				costumes: this.costumes.export(),
				scripts: this.scripts.export(),
				customUI: this.customUI.export(),
				resources: this.resources.export(),
				blockStructures: this.blockStructures.export(),
				props: this.props.export(),
				items: this.items.export(),
			},
		};

		// remove falsy keys from assets
		bundle.assets = Object.fromEntries(
			Object.entries(bundle.assets).filter(([_, value]) => Boolean(value)),
		) as IWorldBundle["assets"];

		return bundle;
	}

	exportAsPackage(pks: string[]) {
		const bundle = structuredClone(this.export());

		return exportAsPackage(bundle, pks, this);
	}

	findAsset(pk?: string | null) {
		if (!pk) return null;

		const assetType = getAssetType(pk) as AssetType;

		switch (assetType) {
			case AssetType.BLOCK:
				return this.blocks.getParsedBlock(pk);
			case AssetType.CHARACTER:
				return this.characters.get(pk);
			case AssetType.AUDIO:
				return this.audios.get(pk);
			case AssetType.AVATAR:
				return this.avatars.get(pk);
			case AssetType.BLOCK_STRUCTURE:
				return this.blockStructures.get(pk);
			case AssetType.SCRIPT:
				return this.scripts.get(pk);
			case AssetType.CUSTOM_UI:
				return this.customUI.get(pk);
			case AssetType.ENVIRONMENT_PRESET:
				return this.environmentPreset.get(pk);
			case AssetType.SKYBOX:
				return this.skybox.get(pk);
			case AssetType.PARTICLE_SYSTEM:
				return this.particleSystems.get(pk);
			case AssetType.PROP:
				return this.props.get(pk);
			case AssetType.ITEM:
				return this.items.get(pk);
			default:
				return null;
		}
	}

	getAssetsOfType(assetType: AssetType) {
		switch (assetType) {
			case AssetType.BLOCK:
				return this.blocks.getAll();
			case AssetType.CHARACTER:
				return this.characters.getAll();
			case AssetType.AVATAR:
				return this.avatars.getAll();
			case AssetType.BLOCK_STRUCTURE:
				return this.blockStructures.getAll();
			case AssetType.AUDIO:
				return this.audios.getAll();
			case AssetType.SCRIPT:
				return this.scripts.getAll();
			case AssetType.PARTICLE_SYSTEM:
				return this.particleSystems.getAll();
			case AssetType.ENVIRONMENT_PRESET:
				return this.environmentPreset.getAll();
			case AssetType.SKYBOX:
				return this.skybox.getAll();
			case AssetType.CUSTOM_UI:
				return this.customUI.getAll();
			case AssetType.PROP:
				return this.props.getAll();
			case AssetType.ITEM:
				return this.items.getAll();
			default:
				return [];
		}
	}

	getFilePath(path: string) {
		return getStaticFile(path);
	}

	reset() {
		// User is intentionally not reset on world reset.
		this.isServer = true;
		this.isPublicServer = false;
		this.map = new JacyMapState(this);
		this.settings = new JacySettingsState(this);
		this.environmentPreset = new JacyEnvironmentPresetState(this);
		this.gameMechanics = new JacyGameMechanicsState(this);
		this.customLoader = new JacyCustomLoaderState(this);
		this.thumbnail = new JacyThumbnailState(this);
		this.skybox = new JacySkyboxState(this);
		this.blocks = new JacyBlocksState(this);
		this.blockTextures = new JacyBlockTextureState(this);
		this.characters = new JacyCharactersState(this);
		this.avatars = new JacyAvatarsState(this);
		this.avatarComponents = new JacyAvatarComponentsState();
		this.audios = new JacyAudioState(this);
		this.costumes = new JacyCostumeState(this);
		this.scripts = new JacyScriptsState(this);
		this.customUI = new JacyCustomUIState(this);
		this.particleSystems = new JacyParticleSystemsState();
		this.resources = new JacyResourcesState(this);
		this.blockSoundsPacks = new JacyBlockSoundsPackState(this);
		this.blockStructures = new JacyBlockStructuresState(this);
		this.props = new JacyPropsState(this);
		this.items = new JacyItemsState(this);

		this.#dirty = false;
	}

	onSave(shortCode: string) {
		this.worldData.onSave(shortCode);
		this.characters.onSave();
		this.avatars.onSave();
	}

	markDirty() {
		this.#dirty = true;
	}

	markClean() {
		this.#dirty = false;
	}
}
