import type { IAssetKey, IBlockStructure } from "@jamango/content-client";
import { AssetType } from "@jamango/content-client";
import { create } from "zustand";

type IJacyBlockStructureEditorState = {
	open: boolean;
	pk: IAssetKey | null;
	name: string;
	data: IBlockStructure["data"] | null;
	packageId: IBlockStructure["packageId"] | null;

	// Handlers
	setName: (name: string) => void;

	// Actions
	close: () => void;
	getBlockStructure: () => { blockStructure: IBlockStructure };
	editBlockStructure: (blockStructure: IBlockStructure) => void;
};

const DEFAULT_STATE = {
	pk: null,
	data: null,
	name: "",
	packageId: null,
};

export const useJacyBlockStructureEditorStore = create<IJacyBlockStructureEditorState>((set, get) => ({
	open: false,
	...DEFAULT_STATE,

	setName: (name) => set({ name }),
	close: () => set({ open: false }),
	getBlockStructure: () => {
		const pk = get().pk;

		if (!pk) {
			throw new Error("Block identifier not found");
		}

		const blockStructure = {
			pk: pk!,
			type: AssetType.BLOCK_STRUCTURE,
			name: get().name,
			data: get().data!,
			packageId: get().packageId!,
		} satisfies IBlockStructure;

		return { blockStructure };
	},
	editBlockStructure: (blockStructure) => {
		set({
			open: true,
			pk: blockStructure.pk,
			name: blockStructure.name,
			data: blockStructure.data,
			packageId: blockStructure.packageId,
		});
	},
}));
