import { useCustomUIStore } from "@stores/custom-ui";
import { BasicIndicator } from "@components/custom-ui/prebuilt/BasicIndicator";
import { useGameHudStore } from "@stores/game-hud";
import { HealthBar } from "@components/custom-ui/prebuilt/Healthbar";
import { Leaderboard } from "@components/custom-ui/prebuilt/Leaderboard";
import { ProgressBar } from "@components/custom-ui/prebuilt/ProgressBar";
import type { CustomUIComponent, UIPosition } from "@jamango/content-client";
import { type PrebuiltUIComponentIndexes } from "@jamango/content-client";
import { assertNever } from "@jamango/helpers";

const getPrebuiltCustomUI = (type: PrebuiltUIComponentIndexes) => {
	switch (type) {
		case "basic-indicator":
			return BasicIndicator;
		case "health-bar":
			return HealthBar;
		case "leaderboard-local":
			return Leaderboard;
		case "progress-bar":
			return ProgressBar;
		default:
			assertNever(type);
	}
};

const displayCustomUI = (customUIList: CustomUIComponent[], position: UIPosition) => {
	if (!Array.isArray(customUIList)) return;

	return customUIList
		.filter((ui) => ui.position.value === position && ui.show)
		.map((ui) => {
			const UI = getPrebuiltCustomUI(ui.type);

			return <UI key={ui.id} styles={ui.styles} config={ui.inputs} />;
		});
};

export function CustomUIHUD() {
	const customUIList = useCustomUIStore((state) => state.components);
	const zenMode = useGameHudStore((state) => state.zenMode);

	if (zenMode) return null;

	const topLeftUI = displayCustomUI(customUIList, "top-left");
	const topRightUI = displayCustomUI(customUIList, "top-right");
	const topUI = displayCustomUI(customUIList, "top");
	const bottomLeftUI = displayCustomUI(customUIList, "bottom-left");
	const bottomRightUI = displayCustomUI(customUIList, "bottom-right");
	const bottomUI = displayCustomUI(customUIList, "bottom");
	const leftUI = displayCustomUI(customUIList, "left");
	const rightUI = displayCustomUI(customUIList, "right");
	const centerUI = displayCustomUI(customUIList, "center");

	return (
		<>
			{/* TOP */}
			<div className="absolute left-1/2 w-max -translate-x-1/2">
				<div className="flex flex-col items-center gap-2" id="custom-ui-top">
					{topUI}
				</div>
			</div>
			{/* TOP LEFT */}
			<div className="absolute left-0 top-0 w-max max-w-md">
				<div className="flex flex-col justify-start gap-2" id="custom-ui-top-left">
					{topLeftUI}
				</div>
			</div>
			{/* TOP RIGHT */}
			<div className="absolute right-0 top-20 w-max max-w-md">
				<div className="grid gap-2" id="custom-ui-top-right">
					{topRightUI}
				</div>
			</div>
			{/* LEFT */}
			<div className="absolute left-0 top-1/2 w-max max-w-md -translate-y-1/2">
				<div className="flex flex-col justify-start gap-2" id="custom-ui-left">
					{leftUI}
				</div>
			</div>
			{/* MIDDLE */}
			<div className="absolute left-1/2 top-1/2 w-max max-w-2xl -translate-x-1/2 -translate-y-1/2">
				<div className="flex flex-col items-center justify-center gap-2" id="custom-ui-middle">
					{centerUI}
				</div>
			</div>
			{/* RIGHT */}
			<div className="absolute right-0 top-1/2 w-max max-w-md -translate-y-1/2">
				<div className="grid gap-2" id="custom-ui-right">
					{rightUI}
				</div>
			</div>

			{/* BOTTOM LEFT */}
			<div className="absolute bottom-20 left-0 w-max max-w-md">
				<div className="flex flex-col justify-start gap-2" id="custom-ui-bottom-left">
					{bottomLeftUI}
				</div>
			</div>
			{/* BOTTOM RIGHT */}
			<div className="absolute bottom-20 right-0 w-max max-w-md">
				<div className="flex flex-col justify-end gap-2" id="custom-ui-bottom-right">
					{bottomRightUI}
				</div>
			</div>
			{/* BOTTOM  */}
			<div className="absolute bottom-24 left-1/2 w-max max-w-md -translate-x-1/2">
				<div className="flex flex-col justify-center gap-2" id="custom-ui-bottom">
					{bottomUI}
				</div>
			</div>
		</>
	);
}
