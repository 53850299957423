import type { IAssetKey, IJacyContent } from "@jamango/content-client";
import type { JacyActions } from "../JacyActions";
import { useJacyCharacterEditorStore } from "@stores/jacy/character-editor";
import { forceRerenderInventory } from "@stores/jacy/rerender";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useInventorySelectorStore } from "@stores/hud/inventory-selector";
import { useInventoryStore } from "@stores/dialogs/inventory";

export class JacyCharacterActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	newCharacter() {
		try {
			useJacyCharacterEditorStore.getState().newCharacter();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	createCharacter() {
		try {
			const newCharacter = useJacyCharacterEditorStore.getState().getCharacter();

			const character = this.#state.characters.create(newCharacter);

			forceRerenderInventory();
			useInventoryStore.getState().setSelectedAsset(character.pk);
			useJacyCharacterEditorStore.getState().close();

			this.#actions.setSuccess(`Character "${character.name}" created successfully.`);

			return character.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editCharacter(pk: IAssetKey) {
		try {
			const character = this.#state.characters.get(pk);

			if (!character) {
				this.#actions.setError("Character not found");
				return;
			}

			useJacyCharacterEditorStore.getState().editCharacter(character);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	updateCharacter() {
		try {
			const updatedCharacter = useJacyCharacterEditorStore.getState().getCharacter();

			const character = this.#state.characters.update(updatedCharacter);

			useInventorySelectorStore.getState().refresh();
			forceRerenderInventory();
			useInventoryStore.getState().setSelectedAsset(character.pk);
			useJacyCharacterEditorStore.getState().close();

			this.#actions.setSuccess(`Character "${character.name}" updated successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteCharacter() {
		try {
			const character = useJacyCharacterEditorStore.getState().getCharacter();
			const isCreate = useJacyCharacterEditorStore.getState().isCreate;

			if (isCreate) {
				useJacyCharacterEditorStore.getState().close();
				return;
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Character",
				description: `Are you sure you want to "${character.name}" character? This action cannot be undone. You will lose all the data associated with this character.`,
				confirmText: "Yes, delete this character",
			});

			if (!confirmed) return;

			this.#state.characters.delete(character.pk);

			useInventorySelectorStore.getState().refresh();
			forceRerenderInventory();
			useJacyCharacterEditorStore.getState().close();

			this.#actions.setSuccess(`Character "${character.name}" deleted successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
