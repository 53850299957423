import { create } from "zustand";
import { GameClient } from "@jamango/client";
import { closeAllModal } from "@lib/helpers/closeAllModal";
import { useControlsStore } from "./controls";
import { useCustomUIStore } from "./custom-ui";
import { BlankWorldTemplate } from "@components/world/templates";
import type { VALIDATOR_TYPE } from "@lib/helpers/validators";
import type { WorldTemplateOptions } from "@components/world/templates";
import type { GetSuccessData, getWorldsFeed } from "rest-client";
import { createRequestPromise, type IPromise } from "@jamango/helpers";
import { useEngineStore } from "./bb";
import { useEditor } from "../editor/editor-store";
import { useWrenchStore } from "./wrench";
import { isAnyModalOpen } from "../lib/helpers/isAnyModalOpen";
import { usePermissionStore } from "./player";
import { useInventoryStore } from "./dialogs/inventory";

type WorldItem = GetSuccessData<typeof getWorldsFeed>["feed"][number]["items"][number];

type BaseModalState = {
	open: boolean;
	close: () => void;
	toggle: () => void;
	setOpen: (open: boolean) => void;
};

type HubWorldModalState = BaseModalState & {
	worldID: string;
	setWorldID: (worldID: string) => void;
};

export const useHubStore = create<
	Omit<BaseModalState, "toggle"> & {
		toggle: (open: boolean, togglePointerLock?: boolean) => void;
	}
>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	setOpen: (open) => set({ open }),
	toggle: (open, togglePointerLock = true) => {
		const isWrenchOpen = useWrenchStore.getState().open;
		if (isWrenchOpen) return;

		set((state) => {
			const newState = open ?? !state.open;

			useInventoryStore.getState().playSound(newState ? "snd-ui-menu-open" : "snd-ui-menu-close");

			if (newState) {
				closeAllModal();
			}

			if (togglePointerLock) {
				useControlsStore.getState().exitPointerLock(newState);
			}

			return { open: newState };
		});
	},
}));

export const useWorldPreviewStore = create<
	Pick<BaseModalState, "open" | "close"> & {
		currentWorld: WorldItem | null;
		setCurrentWorld: (world: WorldItem) => void;
		toggle: (open: boolean) => void;
	}
>()((set) => ({
	currentWorld: null,
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: (open) => {
		set((state) => {
			const newState = open ?? !state.open;

			return { open: newState };
		});
	},
	setCurrentWorld: (world) => set({ currentWorld: world }),
}));

export const useWorldPortalStore = create<
	Pick<BaseModalState, "open" | "close"> & {
		open: boolean;
		worldId: string | null;
		currentWorld: WorldItem | null;
		show: (worldId: string) => void;
		close: () => void;
		setCurrentWorld: (world: WorldItem) => void;
	}
>()((set) => ({
	open: false,
	worldId: null,
	currentWorld: null,
	close: () => {
		set({ open: false, worldId: null, currentWorld: null });
	},
	show: (worldId) => {
		set({ open: true, worldId });
		useControlsStore.getState().exitPointerLock(true);
	},
	setCurrentWorld: (world) => set({ currentWorld: world }),
}));

export const useConfirmPromptStore = create<{
	promise: null | IPromise<any>;
	open: boolean;
	title: string;
	description: string;
	confirmText: string;
	cancelText: string;
	promptText: null | string;
	exitPointerLock: boolean;
	setOpen: (open: boolean) => void;
	setExitPointerLock: (exitPointerLock: boolean) => void;
	confirm: () => void;
	cancel: () => void;
	closeDialog: () => void;
	prompt: (opts: {
		title?: string;
		description?: string;
		confirmText?: string;
		cancelText?: string;
		prompt?: null | string;
	}) => Promise<unknown>;
	confirmAsync: (description: string) => Promise<unknown>;
}>()((set, get) => ({
	promise: null,

	open: false,
	title: "Are you sure?",
	description: "This action cannot be undone.",
	confirmText: "Yes, I am sure",
	cancelText: "Cancel",
	promptText: null,
	exitPointerLock: true,

	setOpen: (open) => {
		set({ open });
		if (!open) get().promise?.resolve(false);

		useControlsStore.getState().exitPointerLock(open);
	},
	setExitPointerLock: (exitPointerLock) => set({ exitPointerLock }),
	confirm: () => {
		get().promise?.resolve(true);
		set({ open: false });
	},
	cancel: () => {
		get().promise?.resolve(false);
		set({ open: false });
	},
	closeDialog: () => {
		set({ open: false });
	},
	prompt: async ({
		title = "Are you sure?",
		description = "This action cannot be undone.",
		confirmText = "Yes, I am sure",
		cancelText = "Cancel",
		prompt = null,
	}) => {
		const promise = createRequestPromise();

		set({
			open: true,
			title,
			description,
			promptText: prompt,
			confirmText,
			cancelText,
			promise,
		});

		useControlsStore.getState().exitPointerLock(true);
		return await promise;
	},
	confirmAsync: async (description) => {
		return await get().prompt({
			title: document.title,
			description,
			confirmText: "Yes",
			cancelText: "No",
		});
	},
}));

const DEFAULT_GENERAL_PROMPT_STATE = {
	confirmText: "Submit",
	cancelText: "Cancel",
};

export const useGeneralPromptStore = create<{
	promise?: null | IPromise<string | false>;
	confirmText: string;
	cancelText: string;
	open: boolean;
	promptText: null | string;
	validator?: null | keyof typeof VALIDATOR_TYPE;
	setOpen: (open: boolean) => void;
	confirm: (answer: string) => void;
	cancel: () => void;
	prompt: (
		promptText: string,
		opts: typeof DEFAULT_GENERAL_PROMPT_STATE & {
			validator?: keyof typeof VALIDATOR_TYPE;
		},
	) => Promise<string | false>;
}>()((set, get) => ({
	...DEFAULT_GENERAL_PROMPT_STATE,
	promise: null,
	open: false,
	validator: null,
	promptText: null,

	setOpen: (open) => {
		set({ open });
		if (!open) get().promise?.resolve(false);
	},
	confirm: (answer) => {
		get().promise?.resolve(answer);
		set({ open: false });
	},
	cancel: () => {
		get().promise?.resolve(false);
		set({ open: false });
	},
	prompt: async (
		promptText,
		{ confirmText = "Submit", cancelText = "Cancel", validator } = DEFAULT_GENERAL_PROMPT_STATE,
	) => {
		const promise = createRequestPromise<string | false>();

		set({ open: true, promptText, confirmText, cancelText, validator, promise });

		return await promise;
	},
}));

const DEFAULT_ERROR_STATE: {
	open: boolean;
	error: null | Error;
	onClose: null | (() => void);
} = {
	open: false,
	error: null,
	onClose: null,
};

export const usePromptStore = create<
	typeof DEFAULT_ERROR_STATE & {
		reason?: "create-world" | null;
		close: () => void;
		toggle: (open: boolean, onClose?: null | (() => void)) => void;
		setError: (error: any) => void;
		openCreateWorld: (onClose?: null | (() => void)) => void;
	}
>()((set, get) => ({
	...DEFAULT_ERROR_STATE,
	reason: null,
	close: () => {
		const onClose = get().onClose;
		if (typeof onClose === "function") onClose();
		set({ open: false, onClose: null, error: null, reason: null });
	},
	toggle: (open, onClose = null) => {
		set({ open, onClose, error: null });
	},
	setError: (error) => {
		set({ error });
	},
	openCreateWorld: (onClose) => {
		set({
			open: true,
			onClose,
			error: null,
			reason: "create-world",
		});
	},
}));

export const useErrorStore = create<
	typeof DEFAULT_ERROR_STATE & {
		close: () => void;
		toggle: (open: boolean, onClose?: null | (() => void)) => void;
		setError: (error: Error | string) => void;
	}
>()((set, get) => ({
	...DEFAULT_ERROR_STATE,
	close: () => {
		set({ ...DEFAULT_ERROR_STATE });
	},
	toggle: (open, onClose = null) => {
		set({ open, onClose, error: null });
	},
	setError: (error) => {
		if (!(error instanceof Error)) {
			error = Error(error);
		}

		localStorage.setItem("serverId", "");
		window.history.pushState({}, document.title, "/play");

		console.error(error);
		if (!get().open) {
			set({ error, open: true });
		}
	},
}));

export const useHubWorldModal = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open: boolean) => {
		set({ open });
	},
}));

export const useEditorModal = create<BaseModalState>()((set, get) => ({
	open: false,
	close: () => {
		get().setOpen(false);
	},
	toggle: () => {
		get().setOpen(!get().open);
	},
	setOpen: (open: boolean) => {
		if (!usePermissionStore.getState().permissions.canUseWrench) open = false;
		if (get().open === open) return;

		const { BB } = useEngineStore.getState().engine;

		BB.world.sfxManager.play({
			asset: open ? "snd-ui-wrench-editor-open" : "snd-ui-wrench-editor-close",
			loop: false,
			volume: 0.5,
		});

		if (open) {
			useEditor.getState().onOpenEditor();
		}

		set({ open });

		if (!open && !isAnyModalOpen()) {
			useControlsStore.getState().exitPointerLock(false);
		} else {
			useControlsStore.getState().exitPointerLock(true);
		}
	},
}));

export const useHubWorldVersionsModal = create<HubWorldModalState>()((set) => ({
	open: false,
	worldID: "",
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open: boolean) => {
		set({ open });
	},
	setWorldID: (worldID: string) => set({ worldID }),
}));

export const useCreateWorldModal = create<
	BaseModalState & {
		worldTemplate: WorldTemplateOptions | null;
		packagesIds: Set<string>;
		customWorldName: string;
		setCustomWorldName: (name: string) => void;
		setWorldTemplate: (worldTemplate: WorldTemplateOptions | null) => void;
		togglePackage: (id: string) => void;
		startNewWorld: (force: boolean, customWorldName?: string | null) => Promise<any>;
		resetPackages: () => void;
	}
>()((set, get) => ({
	open: false,
	worldTemplate: BlankWorldTemplate.options,
	packagesIds: new Set<string>(),
	customWorldName: "Unknown World",
	setCustomWorldName: (name: string) => set({ customWorldName: name }),
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open) => set({ open }),
	setWorldTemplate: (worldTemplate) => set({ worldTemplate }),
	togglePackage: (id) =>
		set((state) => {
			if (state.packagesIds.has(id)) {
				state.packagesIds.delete(id);
			} else {
				state.packagesIds.add(id);
			}
			return { packagesIds: new Set(state.packagesIds) };
		}),
	startNewWorld: (force, customWorldName) => {
		const state = get();

		const uniquePackagesIds = new Set([
			...state.packagesIds,
			...(state.worldTemplate?.packagesIds ?? []),
		]);

		return GameClient.startNewWorld({
			worldTemplateOptions: state.worldTemplate
				? {
						...state.worldTemplate,
						terrainGeneration: {
							...state.worldTemplate.terrainGeneration,
						},
					}
				: BlankWorldTemplate.options,
			force,
			customWorldName,
			packagesIds: [...uniquePackagesIds],
		});
	},
	resetPackages: () => set({ packagesIds: new Set<string>() }),
}));

export const useImportBlockPackModal = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open) => set({ open }),
}));

export const useEditorTutorialModal = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open: boolean) => set({ open }),
}));

export const useCustomUIModalStore = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		useCustomUIStore.getState().setCurrentComponent(null);
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open) => set({ open }),
}));

export default {
	useHub: useHubStore.getState,
	useEditorModal: useEditorModal.getState,
	useConfirmPrompt: useConfirmPromptStore.getState,
	usePrompt: usePromptStore.getState,
	useGeneralPrompt: useGeneralPromptStore.getState,
	useError: useErrorStore.getState,
	useHubWorldModal: useHubWorldModal.getState,
	useEditorTutorialModal: useEditorTutorialModal.getState,
	useWorldPortal: useWorldPortalStore.getState,
};
