import type { IWorldBundle, IPackageBundle } from "../lib/types";
import { JacyEventsEmitter } from "../events/JacyEventsEmitter";
import { JacyContentState } from "./JacyContentState";
import { getFileType } from "../lib/helpers/files";
import { JacyContentSerializer } from "./JacyContentSerializer";
import { validations } from "../lib/validations";
import { getExtension, getFileNameWithoutExtension } from "../lib/helpers/files";
import type { ContentMetadata } from "../lib/types/content-metadata";

export type IContentImportOptions = {
	isDefaultWorld?: boolean;
};

export class JacyContent {
	state: JacyContentState;
	events: JacyEventsEmitter;
	serializer: JacyContentSerializer;
	options: IContentImportOptions;

	constructor(events = new JacyEventsEmitter()) {
		this.events = events;
		this.state = new JacyContentState();
		this.serializer = new JacyContentSerializer(this);
		this.options = {};
	}

	createFromBundle(worldBundle: IWorldBundle) {
		this.import(worldBundle, { isDefaultWorld: true });
	}

	importPackage(packageBundle: IPackageBundle) {
		// TODO add converter

		this.state.importPackage(packageBundle);
	}

	import(bundle: IWorldBundle, options: IContentImportOptions = {}) {
		this.options = options;

		// TODO add converter

		this.state.import(bundle);
	}

	setMetadata(data: Partial<ContentMetadata>) {
		this.state.worldData.setMetadata(data);
	}

	export() {
		return this.state.export();
	}

	reset() {
		this.state.reset();
	}

	async getFile(url: string) {
		const formattedURL = this.state.getFilePath(url);
		const filename = getFileNameWithoutExtension(url);
		const extension = getExtension(url);
		const blob = await fetch(formattedURL).then((response) => response.blob());

		if (!filename || !extension) {
			console.error(`Faied to fetch file for url ${url}, missing filename and/or extension.`);
			return;
		}

		return new File([blob], `${filename}.${extension}`, {
			type: getFileType(extension),
		});
	}

	validate(bundle: IWorldBundle): true | unknown {
		try {
			const validatedBundle = validations.bundle.all(bundle);
			if (validatedBundle.success) return true;

			const errorCompiler = {};
			const formattedError = validatedBundle.error.format();
			const assets = formattedError.assets;
			validations.bundle.formatBundleErrors(assets, errorCompiler);
			const errorMessage: { label: string; message: any }[] = [];
			for (const key in errorCompiler) {
				errorMessage.push({
					label: key,
					message: (errorCompiler as any)[key], // any here to avoid TS lint error
				});
			}
			const CustomError = new Error();
			CustomError.message = errorMessage as any; // any here to avoid TS lint error
			throw CustomError;
		} catch (error) {
			return error;
		}
	}
}
