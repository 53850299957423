import { GameClient } from "@jamango/client";
import {
	EDIT_WORLD_MODE,
	PLAY_WORLD_MODE,
	type IIdentifier,
	type IJacyContent,
	type WorldMode,
} from "@jamango/content-client";
import { mutate } from "swr";
import { useConfirmPromptStore, useHubStore } from "@stores/dialogs";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import type { JacyActions } from "../JacyActions";
import * as WorldEditorRouter from "@jamango/engine/Runtime/router/world/WorldEditor.ts";
import { WorldEditorAction } from "@jamango/engine/Runtime/base/world/WorldEditor.ts";
import {
	addWorldCollaborator,
	archiveWorld,
	removeWorldCollaborator,
	requestPublishWorld,
	setWoldVersionAsLive,
	unpublishWorld,
} from "rest-client";

export class JacyWorldDataActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setName(name: string) {
		this.#state.worldData.updateName(name);
		useJacyRerenderStore.getState().forceRerenderWorldData();
	}

	async setWorldVersionAsLive(worldId: IIdentifier, versionId: string) {
		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Set this version as live",
			description:
				"Are you sure you want to set this world version as live? This will make this version of the world publicly available.",
			confirmText: "Yes, set as live",
			cancelText: "Cancel",
		});

		if (!confirmed) return;

		try {
			await setWoldVersionAsLive(worldId, versionId);
			this.#state.worldData.liveVersionId = versionId;
			useJacyRerenderStore.getState().forceRerenderWorldData();
			mutate("/live_worlds");
			mutate(["/worlds/versions", worldId]);

			if (useHubStore.getState().open) {
				this.#actions.setSuccess("World has been set as live.");
			} else {
				this.#actions.setGeneralSuccess("World has been set as live.");
			}
		} catch (error) {
			if (useHubStore.getState().open) {
				this.#actions.setError(error);
			} else {
				this.#actions.setGeneralError(error);
			}
		}
	}

	async unpublishWorld(worldId: IIdentifier) {
		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Unpublish world",
			description:
				"Are you sure you want to unpublish this world? This will make your world publicly unavailable.",
			confirmText: "Yes, unpublish world",
			cancelText: "Cancel",
		});

		if (!confirmed) return;

		try {
			await unpublishWorld(worldId);
			this.#state.worldData.setPublishStatus(null);
			this.#state.worldData.liveVersionId = null;
			if (this.#state.worldData.version) {
				this.#state.worldData.version.isLive = false;
			}
			useJacyRerenderStore.getState().forceRerenderWorldData();
			mutate("/live_worlds");
			mutate(["/worlds/versions", worldId]);

			if (useHubStore.getState().open) {
				this.#actions.setSuccess("World has been unpublished.");
			} else {
				this.#actions.setGeneralSuccess("World has been unpublished.");
			}
		} catch (error) {
			if (useHubStore.getState().open) {
				this.#actions.setError(error);
			} else {
				this.#actions.setGeneralError(error);
			}
		}
	}

	async requestPublishWorld() {
		try {
			const publishStatus = this.#state.worldData.publish.status;
			let versionId = this.#state.worldData.version?.id;

			if (publishStatus === "under-review") {
				throw new Error(
					"The world is already under reviewed. Please wait for the publishing status.",
				);
			}

			if (!this.#state.thumbnail.url) {
				await useConfirmPromptStore.getState().prompt({
					title: "Warning",
					description:
						"You currently do not have a thumbnail for this world. Thumbnail is required when publishing a world.",
					confirmText: "Okay",
					cancelText: "Cancel",
				});

				return;
			}

			// TODO: revisit this logic

			let warningDescription = "";

			if (this.#state.worldData.isExperimental) {
				if (warningDescription.length) {
					warningDescription += "\n";
				}

				warningDescription +=
					"You currently have experimental features turned on in this world, therefore this world will be not be appearing in the homepage due to its experimental features.";
			}

			if (warningDescription.length) {
				const confirmed = await useConfirmPromptStore.getState().prompt({
					title: "Warning",
					description: warningDescription,
					confirmText: "Save & Publish",
					cancelText: "Cancel",
				});

				if (!confirmed) return;

				await GameClient.saveWorld();
				versionId = this.#state.worldData.version?.id;
			}

			if (!versionId) {
				throw new Error("There was an error reading the version of the world.");
			}

			this.#actions.setInfo("Requesting to publish the world...");

			const worldId = this.#state.worldData.id;

			await requestPublishWorld({
				worldId,
				versionId,
			});
			this.#state.worldData.setPublishStatus("under-review");
			useJacyRerenderStore.getState().forceRerenderWorldData();
			mutate(["/worlds/versions", worldId]);

			if (useInventoryStore.getState().open) {
				this.#actions.setSuccess("World has been under review.");
			} else {
				this.#actions.setGeneralSuccess("World has been under review.");
			}
		} catch (error) {
			if (useInventoryStore.getState().open) {
				this.#actions.setError(error);
			} else {
				this.#actions.setGeneralError(error);
			}
		}
	}

	async addCollaborator(username: string) {
		try {
			const world = this.#state.worldData;
			if (world.isNew) {
				this.#actions.setError("Could not add a collaborator to an unknown world.");
				return;
			}

			const response = await addWorldCollaborator({
				username,
				worldId: world.id,
			});

			if (response.error) throw new Error("Failed to add collaborator.", { cause: response.error });

			this.#state.worldData.setCollaborators(response.data);
			useJacyRerenderStore.getState().forceRerenderWorldData();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async removeCollaborator(accountId: IIdentifier) {
		try {
			const world = this.#state.worldData;
			const response = await removeWorldCollaborator({
				worldId: world.id,
				accountId,
			});

			if (response.error) throw new Error("Failed to remove collaborator", { cause: response.error });

			this.#state.worldData.setCollaborators(response.data);
			useJacyRerenderStore.getState().forceRerenderWorldData();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	toggleAllowRemix(allowRemix: boolean) {
		try {
			const world = this.#state.worldData;
			world.setAllowRemix(allowRemix);
			useJacyRerenderStore.getState().forceRerenderWorldData();
		} catch (error) {
			this.#actions.setGeneralError(error);
		}
	}

	toggleMode(mode: WorldMode) {
		try {
			if (mode === PLAY_WORLD_MODE) {
				WorldEditorRouter.update(WorldEditorAction.ENTER_PLAY_MODE);
			} else if (mode === EDIT_WORLD_MODE) {
				WorldEditorRouter.update(WorldEditorAction.ENTER_EDIT_MODE);
			}

			this.#state.worldData.updateMode(mode);

			useJacyRerenderStore.getState().forceRerenderWorldData();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	toggleExperiments(experiment: string, enabled: boolean) {
		try {
			this.#state.worldData.updateExperiment(experiment, enabled);

			useJacyRerenderStore.getState().forceRerenderWorldData();
			this.#actions.setSuccess(
				`Successfully ${enabled ? "added" : "removed"} experiment: ${experiment}`,
			);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteWorld(worldId: IIdentifier, name: string) {
		const currentWorldId = this.#state.worldData.id;

		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Are you sure?",
			description:
				"Deleting this world is irreversible. It will delete the associated blocks, skybox, and texture pack(s) that are not used in other worlds. You will be forced to leave this world and start a new one.",
			confirmText: "Yes, delete the world",
			prompt: `delete ${name}`,
		});

		if (!confirmed) return;

		try {
			await archiveWorld({ worldId });

			if (currentWorldId === worldId) {
				GameClient.leaveGame();
			}
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	addTag(tag: string) {
		try {
			const worldData = this.#state.worldData;
			worldData.addTag(tag);

			useJacyRerenderStore.getState().forceRerenderWorldData();

			this.#actions.setSuccess(`Tag "${tag}" added successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	deleteTag(tag: string) {
		try {
			const worldData = this.#state.worldData;
			worldData.deleteTag(tag);

			useJacyRerenderStore.getState().forceRerenderWorldData();

			this.#actions.setSuccess(`Tag "${tag}" deleted successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
