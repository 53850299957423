import { create } from "zustand";

// Store for game mechanics
export const useGameStore = create((set) => ({
	objectives: new Map(),

	setObjectives: (objectives) => set({ objectives }),

	onLoadWorld: () => {
		set({ objectives: new Map() });
	},
}));

export default {
	useGame: useGameStore.getState,
};
