import { Check } from "@components/icons";
import { cn } from "@lib/helpers/cn";
import * as BaseCheckbox from "@radix-ui/react-checkbox";
import type { ForwardedRef } from "react";
import React from "react";

export const Checkbox = React.forwardRef(function CheckboxComponent(
	{
		id,
		children,
		className,
		labelClassName,
		...props
	}: BaseCheckbox.CheckboxProps & { labelClassName?: string },
	ref: ForwardedRef<HTMLButtonElement>,
) {
	return (
		<div className="flex items-center">
			<BaseCheckbox.Root
				ref={ref}
				id={id}
				className={
					"flex size-5 items-center justify-center rounded border-2 border-gray-300 text-white transition-all focus:ring-blue-300 focus-visible:ring-4 focus-visible:ring-offset-2 data-[state=checked]:border-blue-500 data-[state=checked]:bg-blue-500 " +
					className
				}
				{...props}
			>
				<BaseCheckbox.Indicator>
					<Check className="size-4.5" aria-hidden="true" weight="bold" />
				</BaseCheckbox.Indicator>
			</BaseCheckbox.Root>
			<label htmlFor={id} className={cn("ml-2 text-sm font-medium text-gray-700", labelClassName)}>
				{children}
			</label>
		</div>
	);
});
