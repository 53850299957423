import type { IAssetKey } from "@jamango/content-client";
import { create } from "zustand";
import {
	GlobeHemisphereWest,
	CubeTransparent,
	Play,
	SquaresFour,
	CloudSun,
	Sliders,
	LockKey,
	Flask,
	FileArchive,
} from "@components/icons";

import { useEngineStore } from "../bb";
import { useControlsStore } from "../controls";
import { useHubStore } from "../dialogs";
import { useHelpersStore } from "../helpers";
import { useInventorySelectorStore } from "../hud/inventory-selector";

export enum MainTab {
	WORLD = "World",
	CREATE = "Create",
	PLAY = "Play",
}

export enum CreateTab {
	ALL = "World Inventory",
	ASSETS_LIBRARY = "Assets Library",
	ASSET_STORE = "Asset Store",
}

export enum WorldTab {
	BASIC = "Basic",
	ENVIRONMENT = "Environment",
	EXPERIMENTS = "Experiments",
	EXPORTED_ASSETS = "Exported Assets",
}

export enum PlayTab {
	PRIVATE = "Private",
	PUBLIC = "Public",
}

export const TABS = [
	{ label: MainTab.WORLD, icon: GlobeHemisphereWest },
	{ label: MainTab.CREATE, icon: CubeTransparent },
	{ label: MainTab.PLAY, icon: Play },
];

export const CREATE_TABS = [
	{ label: CreateTab.ALL, icon: SquaresFour },
	{ label: CreateTab.ASSETS_LIBRARY, icon: SquaresFour },
	{ label: CreateTab.ASSET_STORE, icon: SquaresFour },
];

export const WORLD_TABS = [
	{ label: WorldTab.BASIC, icon: Sliders },
	{ label: WorldTab.ENVIRONMENT, icon: CloudSun },
	{ label: WorldTab.EXPERIMENTS, icon: Flask },
	{ label: WorldTab.EXPORTED_ASSETS, icon: FileArchive },
];

export const PLAY_TABS = [
	{ label: PlayTab.PRIVATE, icon: LockKey },
	{ label: PlayTab.PUBLIC, icon: GlobeHemisphereWest },
];

const DEFAULT_STATE = {
	open: false,
	selectedTab: MainTab.CREATE,
	createTab: CreateTab.ALL,
	worldTab: WorldTab.BASIC,
	playTab: PlayTab.PRIVATE,
	selectedAsset: null,
};

type IInventoryState = {
	open: boolean;
	selectedTab: MainTab;
	createTab: CreateTab;
	worldTab: WorldTab;
	playTab: PlayTab;
	selectedAsset?: IAssetKey | null;

	// Handlers
	toggle: (state?: boolean, togglePointerLock?: boolean) => void;
	close: () => void;
	setSelectedTab: (selectedTab: MainTab) => void;
	setCreateTab: (createTab: CreateTab) => void;
	setWorldTab: (worldTab: WorldTab) => void;
	setPlayTab: (playTab: PlayTab) => void;
	setSelectedAsset: (pk: IAssetKey | null) => void;

	// Actions
	clear: () => void;
	playSound: (sound: string, volume?: number) => void;
};

export const useInventoryStore = create<IInventoryState>((set, get) => ({
	...DEFAULT_STATE,

	// Handlers
	close: () => set({ open: false, selectedTab: MainTab.CREATE }),
	toggle: (state, togglePointerLock = true) => {
		const open = state ?? !get().open;
		if (get().open === open) return;

		set({ open });

		useHelpersStore.getState().markInventoryKnown();

		const { BB } = useEngineStore.getState().engine;

		if (BB.world?.sfxManager) {
			BB.world.sfxManager.play({
				asset: open ? "snd-ui-menu-open" : "snd-ui-menu-close",
				loop: false,
				volume: 5,
			});

			if (togglePointerLock) {
				useControlsStore.getState().exitPointerLock(open);
			}
		}

		if (open) {
			useHubStore.getState().close();
		}
	},
	setSelectedTab: (selectedTab) => {
		get().playSound("snd-ui-menu-tab");
		set({ selectedTab });
	},
	setCreateTab: (createTab) => {
		get().playSound("snd-ui-menu-tab");
		set({ createTab });
	},
	setWorldTab: (worldTab) => {
		get().playSound("snd-ui-menu-tab");
		set({ worldTab });
	},
	setPlayTab: (playTab) => {
		get().playSound("snd-ui-menu-tab");
		set({ playTab });
	},
	setSelectedAsset: (pk) => {
		set({ selectedAsset: pk });
	},

	// Actions
	clear: () => {
		set({ selectedAsset: null });
		useInventorySelectorStore.getState().clear();
	},
	playSound: (sound, volume = 3) => {
		const { BB } = useEngineStore.getState().engine;

		if (BB.world?.sfxManager) {
			BB.world.sfxManager.play({
				asset: sound,
				loop: false,
				volume,
			});
		}
	},
}));

export default {
	useInventory: useInventoryStore.getState,
};
