import React from "react";
import { useConfirmPromptStore } from "@stores/dialogs";
import { AlertDialog } from "@components/ui/AlertDialog";
import { Input } from "@components/ui/Input";
import { Button } from "@components/ui/Button";
import { isNullish } from "@jamango/helpers";

export function ConfirmDialog() {
	const open = useConfirmPromptStore((state) => state.open);
	const setOpen = useConfirmPromptStore((state) => state.setOpen);
	const title = useConfirmPromptStore((state) => state.title);
	const description = useConfirmPromptStore((state) => state.description);
	const confirmText = useConfirmPromptStore((state) => state.confirmText);
	const cancelText = useConfirmPromptStore((state) => state.cancelText);
	const promptText = useConfirmPromptStore((state) => state.promptText);
	const confirm = useConfirmPromptStore((state) => state.confirm);
	const cancel = useConfirmPromptStore((state) => state.cancel);

	const [prompt, setPrompt] = React.useState("");

	const disabled = promptText && promptText !== prompt;
	const paragraphs = description?.split("\n") ?? [];

	React.useEffect(() => {
		setPrompt("");
	}, [open]);

	const toggleOpen = (open) => {
		setOpen(open);
		setPrompt("");
		if (!open) cancel();
	};

	const confirmResponse = () => {
		if (disabled) return;
		confirm();
	};

	return (
		<AlertDialog.Root open={open} onOpenChange={(value) => toggleOpen(value)}>
			<AlertDialog.Content>
				<div className="overflow-y-auto rounded border-b border-gray-400 bg-white p-6 shadow-xl pointer-events-auto">
					<div className="flex justify-between gap-8">
						<AlertDialog.Title className="text-2xl font-medium">
							{title || "Are you sure?"}
						</AlertDialog.Title>
					</div>
					<AlertDialog.Description className="my-2 text-base text-gray-700">
						{paragraphs.length === 0 && "This action cannot be undone."}
						{paragraphs.map((paragraph) => (
							<React.Fragment key={paragraph}>
								{paragraph}
								{paragraphs.length > 1 && <br />}
							</React.Fragment>
						))}
					</AlertDialog.Description>
					{!isNullish(promptText) && (
						<p className="my-2 text-base text-gray-700">
							To continue please type{" "}
							<span className="font-semibold" data-qaid="confirm-dialog-prompt-text">
								{promptText}
							</span>
							.
						</p>
					)}

					{!isNullish(promptText) && (
						<Input
							type="text"
							id="prompt"
							name="prompt"
							className="my-2 w-full"
							value={prompt}
							onChange={(evt) => setPrompt(evt.target.value)}
							required
							autoFocus
							data-qaid="confirm-dialog-input"
						/>
					)}

					<div className="mb-2 mt-4 flex items-center justify-end gap-2">
						<Button data-qaid="cancel-btn" color="white" onClick={cancel}>
							{cancelText || "Cancel"}
						</Button>
						<Button data-qaid="confirm-btn" onClick={confirmResponse} disabled={disabled}>
							{confirmText || "Yes, I am sure"}
						</Button>
					</div>
				</div>
			</AlertDialog.Content>
		</AlertDialog.Root>
	);
}
