import { create } from "zustand";
import {
	FEATURE_FLAGS,
	IAvatarConfig,
	IAvatarObject,
	IParsedAvatar,
	type JacyContentState,
	type IAuthUser,
} from "@jamango/content-client";
import { GameMultiplayer, Router } from "@jamango/client";
import { Gear } from "@components/icons";
import { validateFilled } from "@lib/helpers/validateFilled";
import { formatErrorMessage } from "@lib/helpers/formatErrorMessage";
import { useHubStore, useConfirmPromptStore } from "./dialogs";
import { useEngineStore } from "./bb";
import { trackEvent, identifyUser } from "@lib/helpers/analytics/analyzeMe"; // Adjust the import path as necessary
import { Jacy } from "@jacy-client";
import { useAlertDialogStore } from "./dialogs/alert-dialog";
import { useJacyAvatarEditorStore } from "./jacy/avatar-editor";
import { createRequestPromise, type IPromise } from "@jamango/helpers";
import { useEditor } from "../editor/editor-store";
import {
	login,
	logout,
	register,
	updateEmail,
	updateForgottenPassword,
	updatePassword,
	ws,
} from "rest-client";
import { Analytics } from "@jamango/analytics";
import { usePlayerStore } from "@stores/player";
import { useSettingsStore } from "@stores/settings";
import { EventSourceType } from "@jamango/content-service/types/index.ts";

export const LOADING_STATE = {
	idle: false,
	auth: "auth",
	login: "login",
	register: "register",
	updateEmail: "update-email",
	updatePassword: "update-password",
};

type AuthDialogState = {
	open: false | "login" | "register" | "open-alpha";
	error: null;
	isAlert: boolean;
	didLogout: boolean;
	resetWorld: boolean;
	promise: IPromise<IAuthUser>;
	close: () => void;
	auth: () => Promise<any>;
	openLogin: (resetWorld?: boolean) => void;
	openRegister: () => void;
	openAlphaModal: () => void;
};

export const useAuthDialogStore = create<AuthDialogState>()((set, get) => ({
	open: false,
	promise: createRequestPromise(),
	isAlert: false,
	resetWorld: true,
	error: null,
	didLogout: false,

	close: () => {
		set({ open: false, error: null, isAlert: false });
	},
	auth: () => {
		set({ open: "login", isAlert: true });
		return get().promise;
	},
	openLogin: (resetWorld = true) => {
		set({ open: "login", resetWorld });
	},
	openRegister: () => {
		set({ open: "register" });
	},
	openAlphaModal: () => {
		set({ open: "open-alpha" });
	},
	setError: (error: any) => {
		set({ error });
	},
}));

const syncFeatureFlags = (featureFlags: string[]) => {
	const windowGlobal = window as any;

	if (globalEnv.NODE_ENV !== "production" || featureFlags.includes(FEATURE_FLAGS.CONSOLE_DEBUG)) {
		const { engine } = useEngineStore.getState();

		if (engine) {
			windowGlobal.DEBUG = engine.DEBUG;
			windowGlobal.EXPOSE = engine;
		}

		windowGlobal.JACY = Jacy;
		windowGlobal.EDITOR = useEditor;
		windowGlobal.AVATAR_EDITOR = useJacyAvatarEditorStore;
	}
};

const checkIfOwner = (state: JacyContentState, userId: string | null) => {
	const world = state.worldData;
	if (world.isNew) return true;

	if (!userId) return false;
	if (!world.owner) return false;

	return world.owner.id === userId;
};

const checkIfCollaborator = (state: JacyContentState, userId: string | null) => {
	const world = state.worldData;

	if (!userId) return false;

	return world.collaborators.some((collaborator) => collaborator.id === userId);
};

const checkIfCreator = (state: JacyContentState, userId: string | null) => {
	if (state.isPublicServer) return false;
	return checkIfOwner(state, userId) || checkIfCollaborator(state, userId);
};

type AuthUserState = {
	userId: string | null;
	role: "user" | "admin" | null;
	globalAvatarId: string | null;
	username: string | null;
	photoUrl: string | null;
	email: string | null;
	hasPlayedTutorial: boolean;
	featureFlags: string[];

	error: null | string;
	loading: (typeof LOADING_STATE)[keyof typeof LOADING_STATE];
	didLogout: boolean;

	isOwner: boolean;
	isCollaborator: boolean;
	isCreator: boolean;
};

type AuthUserAction = {
	setHasPlayedTutorial: (played: boolean) => void;

	login: (email: string, password: string) => Promise<true | undefined>;

	logout: () => Promise<any>;

	register: (data: { username: string; email: string; password: string }) => Promise<IAuthUser | undefined>;

	updateEmail: (email: string) => Promise<void>;

	updatePassword: (
		currentPassword: string,
		newPassword: string,
		newPasswordConfirmation: string,
	) => Promise<void>;

	updateForgottenPassword: (token: string, password: string) => Promise<true | undefined>;

	getAvatar: () => IParsedAvatar | null;
	getAvatarConfig: () => IAvatarConfig | null;
	getAvatarObject: () => IAvatarObject | null;

	setUser: (user: IAuthUser | null) => void;

	updateAccess: (state: JacyContentState) => void;
	hasAccess: (access?: "save" | "edit" | "delete") => boolean;

	syncFeatureFlags: () => void;
};

// const DEFAULT_STATE = {
// 	id: undefined,
// 	username: undefined,
// 	photoUrl: undefined,
// 	email: undefined,
// 	featureFlags: [],

// 	isGuest: true,
// 	isOwner: true,
// 	isCollaborator: false,
// 	isCreator: true, // is owner or collaborator

// 	canEdit: false,
// 	canDelete: false,
// 	canSave: false,
// 	canSaveWorld: false,
// } satisfies Omit<IJacyUserState, "clear">;

export const useAuthUserStore = create<AuthUserState & AuthUserAction>()((set, get) => ({
	userId: null,
	role: null,
	globalAvatarId: null,
	hasPlayedTutorial: false,
	error: null,
	loading: LOADING_STATE.idle,
	didLogout: false,
	username: null,
	photoUrl: null,
	email: null,
	featureFlags: [],

	isOwner: true,
	isCollaborator: false,
	isCreator: true, // is owner or collaborator

	// Events
	login: async (email, password) => {
		set({ error: null, loading: LOADING_STATE.login });

		if (!validateFilled([email, password])) {
			set({
				error: "Email and password are required.",
				loading: LOADING_STATE.idle,
			});
			return;
		}

		try {
			const data = await login(email, password);

			if (!data) throw new Error(`Error!`);

			get().setUser(data.user);

			set({
				didLogout: false,
			});

			identifyUser(data.user.id, {
				username: data.user.username,
				email: data.user.email,
			});
			trackEvent("event", "login");

			const { resetWorld, close, promise } = useAuthDialogStore.getState();

			if (resetWorld) {
				await GameMultiplayer.disconnect();
			}

			close();

			promise.resolve(data.user);

			return true;
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		} finally {
			set({ loading: LOADING_STATE.idle });
		}
	},
	logout: async () => {
		if (Router.isPlayPage()) {
			const confirmPrompt = await useConfirmPromptStore.getState().prompt;
			const description = Jacy.state.worldData.shortCode
				? "You will be logged out of the game. Don't forget to save any unsaved progress of your current world."
				: "Any unsaved progress will be lost";

			const confirmed = await confirmPrompt({
				title: "Are you sure?",
				description,
				confirmText: "Yes, log out",
			});

			if (!confirmed) return confirmed;
		}

		localStorage.setItem("worldId", "");
		localStorage.setItem("serverId", "");

		useHubStore.getState().close();

		await GameMultiplayer.disconnect();
		Router.navigate("/");
		try {
			get().setUser(null);
			await logout();
			set({ didLogout: true });
		} catch (ex) {
			console.error(ex);
		}
	},
	register: async ({ username, email, password }) => {
		if (!validateFilled([username, email, password])) {
			set({
				error: "Username, email and password are required.",
				loading: LOADING_STATE.idle,
			});
			return;
		}

		set({ error: null, loading: LOADING_STATE.register });

		try {
			const response = await register({
				username,
				email,
				password,
			});

			if (!response) throw new Error(`Error!`);

			const newUser = response.user;

			get().setUser(newUser);

			identifyUser(response.user.id, {
				username: username,
				email: email,
			});

			trackEvent("event", "sign_up");

			const { resetWorld, close, promise } = useAuthDialogStore.getState();

			if (resetWorld) {
				await GameMultiplayer.disconnect();
			}

			close();
			promise.resolve(newUser);

			return newUser;
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		} finally {
			set({ loading: LOADING_STATE.idle });
		}
	},
	updateEmail: async (email) => {
		if (!validateFilled([email])) {
			set({ error: "Email is required.", loading: LOADING_STATE.idle });
			return;
		}

		set({ error: null, loading: LOADING_STATE.updateEmail });

		try {
			await updateEmail(email);

			set({
				email,
			});

			useAlertDialogStore.getState().setAlert({ title: "Email updated!", message: "" });
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		} finally {
			set({ loading: LOADING_STATE.idle });
		}
	},
	updatePassword: async (currentPassword, newPassword, passwordConfirmation) => {
		if (!validateFilled([newPassword, passwordConfirmation])) {
			set({ error: "Password is required.", loading: LOADING_STATE.idle });
			return;
		}

		set({ error: null, loading: LOADING_STATE.updatePassword });

		try {
			await updatePassword(currentPassword, newPassword);
			useAlertDialogStore.getState().setAlert({ title: "Password updated!", message: "" });
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
	},
	updateForgottenPassword: async (token, password) => {
		set({ error: null, loading: LOADING_STATE.updatePassword });

		try {
			const result = await updateForgottenPassword(token, password);

			if (!result || "status" in result) {
				throw new Error(`Can't update password`);
			}

			useAlertDialogStore.getState().setAlert({
				title: "Password changed!",
				message: "Redirecting you to the login page",
			});

			return true;
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
	},

	setHasPlayedTutorial(played: boolean) {
		set({ hasPlayedTutorial: played });
		localStorage.setItem("hasPlayedTutorial", String(played));
		if (played && !get().hasPlayedTutorial) {
			trackEvent("event", "tutorial_launched", { first_time: true });
		}
	},

	getAvatar() {
		const avatarId = get().globalAvatarId;
		if (!avatarId) return null;

		return Jacy.content.state.avatars.get(Jacy.content.state.avatars.generatePK(avatarId));
	},

	getAvatarConfig() {
		const avatar = get().getAvatar();
		if (!avatar) return null;
		return Jacy.content.state.avatars.convertAvatarToAvatarConfig(avatar);
	},

	getAvatarObject() {
		const avatar = get().getAvatar();
		if (!avatar) return null;
		return Jacy.content.state.avatars.convertConfigToAvatarObject(avatar);
	},

	setUser: (user) => {
		if (user) {
			Analytics.identity({
				userId: user.id,
				sourceType: EventSourceType.USER,
			});

			set({
				userId: user.id,
				email: user.email,
				username: user.username,
				photoUrl: user.photoUrl,
				featureFlags: user.featureFlags,
				globalAvatarId: user.globalAvatarId,
				role: user.role,
				hasPlayedTutorial: localStorage.getItem("hasPlayedTutorial") === "true", //Todo: add server logic to save whether user has played tutorial across devices
			});
		} else {
			set({
				userId: null,
				role: null,
				email: null,
				username: null,
				photoUrl: null,
				featureFlags: [],
				globalAvatarId: null,
				hasPlayedTutorial: false
			});
		}

		ws.reconnect();

		usePlayerStore.getState().setUsername(user ? user.username : null);

		if (user) {
			useSettingsStore.getState().setSettings({ acceptedAlphaTOS: user.acceptedAlphaTOS });
		}

		get().syncFeatureFlags();
	},

	updateAccess: (state) => {
		const userId = get().userId;

		set({
			isCreator: checkIfCreator(state, userId),
			isCollaborator: checkIfCollaborator(state, userId),
			isOwner: checkIfOwner(state, userId),
		});
	},

	hasAccess: (access: "save" | "edit" | "delete" = "edit") => {
		if (Jacy.content.state.isPublicServer) {
			return false;
		}

		if (!Jacy.content.state.isServer) {
			return false;
		}

		const { userId, isCreator } = get();

		if (!userId) {
			return false;
		}

		if (access === "edit") {
			return isCreator;
		}

		if (access === "save") {
			return isCreator;
		}

		if (access === "delete") {
			return isCreator;
		}

		return true;
	},

	syncFeatureFlags: () => {
		syncFeatureFlags(get().featureFlags);
	},
}));

export const PROFILE_TABS = [{ label: "General", icon: Gear }];

export const useProfileStore = create<{
	selectedTab: string;
	setSelectedTab: (selectedTab: string) => void;
}>()((set) => ({
	selectedTab: PROFILE_TABS[0].label,

	setSelectedTab: (selectedTab: string) => set({ selectedTab }),
}));
