import { useRef, useEffect } from "react";

export function useUnmounted(): { current: boolean } {
	const unmounted = useRef(false);

	useEffect(() => {
		return () => {
			unmounted.current = true;
		};
	}, []);

	return unmounted;
}
