import type React from "react";
import { cn } from "@lib/helpers/cn";
import type { CustomCSSProps } from "@stores/custom-ui";
import {
	formatCustomUIStyles,
	type GeneralInputs,
	type GeneralStyles,
	type TableState,
} from "@jamango/content-client";

interface IProps {
	styles: GeneralStyles;
	config: GeneralInputs;
	isPreview?: boolean;
}

export function Leaderboard({ styles, config }: IProps) {
	const stateData = (config?.stateData?.value ?? config?.stateData?.defaultValue) as TableState;
	const styleConfiguration = formatCustomUIStyles(styles, "leaderboard-local") as CustomCSSProps;

	const textShadowConfig = {
		"--text-shadow-color": styleConfiguration["--leaderboard-local-textShadowColor"],
		"--text-stroke-color": styleConfiguration["--leaderboard-local-textStrokeColor"],
	} as React.CSSProperties;

	stateData.columns[0] = (config.rank?.value || config.rank?.defaultValue) as string;
	stateData.columns[1] = (config.player?.value || config.player?.defaultValue) as string;

	return (
		<div
			className={cn(
				"relative w-full max-w-xl",
				"m-[var(--leaderboard-local-margin)]",
				"lg:m-[var(--leaderboard-local-margin-lg)]",
				// position
				"!top-[var(--leaderboard-local-position-top)]",
				"!left-[var(--leaderboard-local-position-left)]",
				"!bottom-[var(--leaderboard-local-position-bottom)]",
				"!right-[var(--leaderboard-local-position-right)]",
			)}
			style={styleConfiguration}
			hidden={!open}
		>
			<div
				className={cn(
					"flex w-full max-w-2xl flex-col rounded-md bg-gray-900/75 p-4 opacity-75",
					"!font-[var(--leaderboard-local-fontWeight)]",
					"lg:!font-[var(--leaderboard-local-fontWeight-lg)]",
					"rounded-[var(--leaderboard-local-borderRadius)]",
					"lg:rounded-[var(--leaderboard-local-borderRadius-lg)]",
					"bg-[var(--leaderboard-local-backgroundColor)]",
					"lg:bg-[var(--leaderboard-local-backgroundColor-lg)]",
					"p-[var(--leaderboard-local-padding)]",
					"lg:p-[var(--leaderboard-local-padding-lg)]",
					"font-[family-name:var(--leaderboard-local-fontFamily)]",
					"lg:font-[family-name:var(--leaderboard-local-fontFamily-lg)]",
					"text-[length:var(--leaderboard-local-fontSize)]",
					"lg:text-[length:var(--leaderboard-local-fontSize-lg)]",
					"text-[var(--leaderboard-local-color)]",
					"lg:text-[var(--leaderboard-local-color-lg)]",
				)}
				style={styleConfiguration}
			>
				<div className="flex justify-center">
					<h2
						className={cn(
							"text-center text-2xl font-medium",
							"!font-stroke-[var(--leaderboard-local-textStroke)]",
							"!text-shadow-[var(--leaderboard-local-textShadow)]",
						)}
						style={textShadowConfig}
					>
						{(config.title?.value || config.title?.defaultValue) as string}
					</h2>
				</div>

				<div className="flex flex-col gap-2">
					{stateData && stateData.columns && stateData.rows && (
						<table
							className={cn(
								"w-full",
								"!font-stroke-[var(--leaderboard-local-textStroke)]",
								"!text-shadow-[var(--leaderboard-local-textShadow)]",
							)}
							style={textShadowConfig}
						>
							<thead className="text-center text-sm">
								<tr>
									{stateData.columns.map((column, index) => (
										<th
											key={`${column}-${index}`}
											scope="col"
											className={cn(
												"capitalize",
												"whitespace-nowrap px-3 py-3.5 text-center",
												"!font-stroke-[var(--leaderboard-local-textStroke)]",
												"!text-shadow-[var(--leaderboard-local-textShadow)]",
											)}
										>
											{column}
										</th>
									))}
								</tr>
							</thead>
							<tbody className="text-center">
								{stateData.rows.map((row, rowIndex) => (
									<tr key={`row-${rowIndex}`}>
										{row.map((cell, cellIndex) => (
											<td
												key={`cell-${rowIndex}-${cellIndex}`}
												className="whitespace-nowrap text-center"
											>
												{cell}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</div>
	);
}
