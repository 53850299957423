import { Button } from "@components/ui/Button";
import { UserAvatar } from "@components/avatar/UserAvatar";
import { Carousel } from "@components/landing/Carousel/Carousel";
import { RegionSelector } from "@components/ui/RegionSelector";
import { X as CloseIcon, Star, ThumbsUp } from "@components/icons";
import { truncateString } from "@lib/helpers/truncateString";
import { formatNumber } from "@lib/helpers/formatNumber";
import { distanceToNow } from "@lib/helpers/date";
import { DEFAULT_THUMBNAIL } from "@lib/constants/images";
import { FeedWorldData } from "@jamango/content-service/types/worlds.js";
import { type DedicatedServerRegion } from "@jamango/frontend/DedicatedServerRegions.ts";

export type WorldPreviewProps = {
	currentWorld: FeedWorldData | null;
	hasLiked: boolean;
	hasFavorited: boolean;
	likes: number;
	selectedRegion: DedicatedServerRegion;
	onRegionChange: (region: DedicatedServerRegion) => void;
	onClose: () => void;
	onToggleLike: () => void;
	onToggleFavorite: () => void;
	onPlay: () => void;
	onStayHere?: () => void;
};

export const WorldPreview = ({
	currentWorld,
	hasLiked,
	hasFavorited,
	likes,
	selectedRegion,
	onRegionChange,
	onClose,
	onToggleLike,
	onToggleFavorite,
	onPlay,
	onStayHere,
}: WorldPreviewProps) => {
	const items = [
		{
			img: currentWorld?.thumbnailUrl ?? DEFAULT_THUMBNAIL,
		},
	];

	const Skeleton = () => (
		<div className="animate-pulse">
			<div className="h-8 w-3/4 bg-gray-300 rounded mb-4"></div>
			<div className="h-64 w-full bg-gray-300 rounded-2xl mb-4"></div>
			<div className="flex flex-col gap-4">
				<div className="h-6 w-1/2 bg-gray-300 rounded"></div>
				<div className="h-6 w-1/3 bg-gray-300 rounded"></div>
				<div className="h-6 w-1/4 bg-gray-300 rounded"></div>
			</div>
		</div>
	);

	return (
		<div className="relative grid content-start gap-8 overflow-auto bg-slate-100 px-8 pb-8 pt-6 lg:max-h-[90vh] lg:grid-cols-1 lg:rounded-xl">
			<Button square color="white-ghost" className="absolute right-6 top-6" onClick={onClose}>
				<CloseIcon className="size-7" />
			</Button>
			<div className="grid gap-4">
				{currentWorld ? (
					<>
						<h1 className="text-xl font-semibold lg:text-3xl">
							{truncateString(currentWorld.name, 25)}
						</h1>

						<Carousel items={items}>
							{items.slice(0, 6).map((item, i) => (
								<div
									className="inline-flex h-64 w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-2xl"
									key={i}
								>
									<div className="h-64 w-full">
										<img
											src={item.img}
											alt=""
											className="block h-full w-full object-cover"
										/>
									</div>
								</div>
							))}
						</Carousel>

						<div className="flex flex-col justify-between gap-2 rounded-xl bg-white px-6 py-3 2xs:gap-1">
							<div className="flex flex-wrap items-center gap-2">
								<h6 className="mr-auto text-gray-500">Creator</h6>
								<div className="flex items-center gap-2">
									<UserAvatar
										username={currentWorld.owner.username}
										displayPhoto={currentWorld.owner.photoUrl}
									/>
									<p className="truncate text-lg">{currentWorld.owner.username}</p>
								</div>
							</div>
							<div className="flex flex-wrap items-center justify-center gap-2">
								<h6 className="text-md mr-auto text-gray-500">Game visits</h6>
								<p className="text-bold text-lg">{formatNumber(currentWorld.visits)}</p>
							</div>
							<div className="hidden flex-col xs:flex xs:flex-row xs:items-center">
								<h6 className="mr-auto text-gray-500">Created</h6>
								<p className="text-lg">{distanceToNow(new Date(currentWorld.createdAt))}</p>
							</div>
							<div className="flex-col text-center xs:flex xs:flex-row xs:items-center">
								<h6 className="mr-auto text-sm text-gray-500 xs:text-base">Last updated</h6>
								<p className="text-lg">{distanceToNow(new Date(currentWorld.updatedAt))}</p>
							</div>
						</div>

						<div className="flex items-center gap-2">
							<RegionSelector selectedRegion={selectedRegion} onRegionChange={onRegionChange} />
							<Button
								className={`h-10 min-w-[4rem] border-2 ${hasLiked ? "border-orange-500" : "border-gray-300"} inline-flex items-center justify-center gap-1 rounded-lg px-3 py-2`}
								color="white"
								onClick={onToggleLike}
							>
								<ThumbsUp
									className="size-5 flex-shrink-0"
									aria-hidden="true"
									weight="bold"
									color={hasLiked ? "#F97316" : "currentColor"}
								/>
								<span
									className={`${hasLiked ? "text-orange-500" : "text-current"} min-w-[1ch] text-center`}
								>
									{likes}
								</span>
							</Button>
							<Button
								className={`h-10 border-2 ${hasFavorited ? "border-orange-500" : "border-gray-300"} inline-flex items-center rounded-lg px-3 py-2`}
								color="white"
								onClick={onToggleFavorite}
							>
								<Star
									className="size-5 flex-shrink-0"
									aria-hidden="true"
									weight="bold"
									color={hasFavorited ? "#F97316" : "currentColor"}
								/>
							</Button>
							<Button
								color="orange-gradient"
								className="h-10 flex-grow rounded-lg font-display text-xl flex items-center justify-center"
								onClick={onPlay}
							>
								Play
							</Button>
							{onStayHere && (
								<Button
									color="white"
									className="h-10 flex-grow rounded-lg font-display text-xl flex items-center justify-center"
									onClick={onStayHere}
								>
									Stay here
								</Button>
							)}
						</div>
					</>
				) : (
					<Skeleton />
				)}
			</div>
		</div>
	);
};
