import { getStaticFile } from "@jamango/content-client";

export default `
    @font-face {
        font-family: 'Space Mono';
        src: url(${getStaticFile("assets/frontend/fonts/Space_Mono/SpaceMono-Regular.woff2")}) format('woff2'); 
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;
