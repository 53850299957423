export async function openJamFile() {
	const inputEl = document.createElement("input");
	inputEl.type = "file";
	inputEl.accept = ".jam";
	inputEl.click();
	await new Promise((resolve) => (inputEl.onchange = resolve));

	const zipFile = inputEl.files?.[0];
	inputEl.value = "";

	if (!zipFile) {
		throw new Error("No file selected");
	}

	return zipFile;
}

export function saveJamFile(data: Blob, name: string) {
	const linkEl = document.createElement("a");
	linkEl.download = `${name}.jam`;
	linkEl.href = URL.createObjectURL(data);
	linkEl.click();
	URL.revokeObjectURL(linkEl.href);
}
