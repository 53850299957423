import { cn } from "@lib/helpers/cn";
import { getStaticFile } from "@jamango/content-client";

type IProps = {
	blockPack: {
		packageId: string;
		url: string;
		displayName: string;
		description?: string;
		size?: string;
		blockCount?: number;
		packageName: string;
	};
	toggleBlockPack: (name: string) => void;
	selected: boolean;
};

export function ImgButton({ blockPack, toggleBlockPack, selected }: IProps) {
	return (
		<div
			className={cn(
				"group relative flex w-full basis-1/3 cursor-pointer flex-col gap-4 overflow-hidden rounded-lg border-2 bg-gray-200 shadow-md",
				selected ? "border-blue-500" : "border-transparent",
			)}
			onClick={() => toggleBlockPack(blockPack.packageId)}
			data-qaid={`block-pack-${blockPack.packageName}`}
		>
			{selected && (
				<span className="absolute left-2 top-2 z-30 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-blue-500 ring-1 ring-inset ring-green-600/20">
					Selected
				</span>
			)}

			<img
				src={getStaticFile(blockPack.url)}
				className={cn("block h-40 flex-shrink-0 object-cover group-hover:opacity-90")}
				alt={blockPack.displayName}
				loading="lazy"
			/>

			<div className="p-4 pt-0 text-center">
				<p className="font-display text-lg text-gray-900">{blockPack.displayName}</p>
				<span className="mb-4 block text-sm font-normal leading-4 text-gray-400">
					{blockPack.description}
				</span>
				<span className="inline-flex items-center justify-center gap-2 text-sm font-normal leading-4 text-gray-400">
					{blockPack.size}
					<span className="inline-block size-1 rounded-full bg-gray-500"></span>
					{blockPack.blockCount} blocks
				</span>
			</div>
		</div>
	);
}
