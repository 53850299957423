import { ZodError } from "zod";
import BaseJSZip from "jszip";
import { JacyContent } from "./content/JacyContent";
import { JacyEventsEmitter } from "./events/JacyEventsEmitter";

export { deserializeJamfileSaveZip, serializeJamfileSaveZip } from "./content/jamfile-save-serdes";

export * from "./lib/validations";
export * from "./lib/types/index";

export const JSZip = BaseJSZip;

export const JacyCore = {
	Content: JacyContent,
	EventEmitter: JacyEventsEmitter,
};

export const ValidationError = ZodError;
export type IValidationError = ZodError;
export type IError = ZodError | Error;
export type IJacyContent = InstanceType<typeof JacyContent>;
export type IJacyEventsEmitter<
	T extends Record<string, (...args: any[]) => void> = Record<string, (...args: any[]) => void>,
> = InstanceType<typeof JacyEventsEmitter<T>>;

export * from "./content/JacyContent";
export * from "./content/JacyContentState";
export * from "./events/JacyEventsEmitter";
export * from "./content/state/JacyWorldBundleDiff";
export * from "./content/JacyContentSyncer";

export * from "./data/avatar-components/avatar-components-package";
export * from "./data/particle-systems/particle-systems-package";

export * from "./lib/constants/audio";
export * from "./lib/constants/animation";
export * from "./lib/constants/avatar-components";
export * from "./lib/constants/avatars";
export * from "./lib/constants/blocks";
export * from "./lib/constants/bundle";
export * from "./lib/constants/characters";
export * from "./lib/constants/custom-loader";
export * from "./lib/constants/environment-presets";
export * from "./lib/constants/files";
export * from "./lib/constants/game-mechanics";
export * from "./lib/constants/settings";
export * from "./lib/constants/world";
export * from "./lib/constants/resources";
export * from "./lib/constants/block-sounds-pack-package";
export * from "./lib/constants/experiments";
export * from "./lib/constants/custom-ui";
export * from "./lib/constants/inventory";

export * from "./lib/validations/world";
export * from "./lib/validations/prop";
export * from "./lib/validations/item";

export * from "./lib/helpers/assets";
export * from "./lib/helpers/avatars";
export * from "./lib/helpers/blocks";
export * from "./lib/helpers/characters";
export * from "./lib/helpers/files";
export * from "./lib/helpers/general";
export * from "./lib/helpers/maps";
export * from "./lib/helpers/cdn";

export * from "./content/dependency-graph";
