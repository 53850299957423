import { useCustomUIStore } from "@stores/custom-ui";
import { InputField } from "./InputField";
import { Listbox } from "@components/ui/Listbox";
import type { UIPosition } from "@jamango/content-client";
import { UI_POSITIONS } from "@jamango/content-client";
import type { JSX } from "react";

export function ThemeEditor() {
	const currentComponent = useCustomUIStore((state) => state.currentComponent);
	const setPosition = useCustomUIStore((state) => state.setPosition);

	if (!currentComponent) return;

	const styleSettings = Object.entries(currentComponent.styles);

	if (!styleSettings) return;

	const boxStyleConfig: JSX.Element[] = [];
	const typographyConfig: JSX.Element[] = [];

	for (const [key, style] of styleSettings) {
		if (style.group === "typography") {
			typographyConfig.push(<InputField key={key} style={style} />);
		} else if (style.group === "box-style") {
			boxStyleConfig.push(<InputField key={key} style={style} />);
		}
	}

	return (
		<>
			<div className="mb-4">
				<h2 className="mb-4 text-sm  font-medium uppercase">Typography</h2>
				<div className="grid grid-cols-2 gap-4">
					<div className="">
						<label htmlFor="" className="mb-2 inline-block text-sm text-gray-400">
							Position
						</label>
						<Listbox
							id="custom-ui-position"
							selected={UI_POSITIONS.find(
								(position) => position.value === currentComponent?.position.value,
							)}
							options={UI_POSITIONS.map((option) => ({
								id: option.value,
								name: option.name,
							}))}
							variant="editor"
							onSelect={(value) => setPosition(value.id as UIPosition)}
							squashable
							noSelectionText=""
							disabled={false}
						/>
					</div>
					{typographyConfig}
				</div>
			</div>
			<div className="">
				<h2 className="mb-4 text-sm  font-medium uppercase">Box Style</h2>
				<div className="grid grid-cols-2 gap-4">{boxStyleConfig}</div>
			</div>
		</>
	);
}
