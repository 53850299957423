import {
	SelectorTargetType,
	type SelectorTarget,
} from "@jamango/engine/Runtime/base/world/entity/component/CharacterSelector.ts";
import { create } from "zustand";
import * as SelectorClient from "@jamango/engine/Runtime/client/world/tools/Selector.ts";
import { useEngineStore } from "../bb";

type ISelectorState = {
	selectorTargets: null | SelectorTarget[];
	existingSelectorTargets: null | SelectorTarget[];
	currentSelectorTarget: null | SelectorTarget;
	chosenSelectorTarget: null | SelectorTarget;
	bulkSelectionSize: number;
	hasHitBlock: boolean;

	hitInteractable: boolean;
	hitInteractLabel: string | null;

	updateSelector: (
		selectorTargets: SelectorTarget[] | null,
		currentSelectorTarget: SelectorTarget | null,
		chosenSelectorTarget: SelectorTarget | null,
		bulkSelectionSize: number,
		hasHitBlock: boolean,
	) => void;

	updateHitInteractable: (hitInteractable: boolean, hitInteractLabel: string) => void;

	nudgeSelection: (direction: [number, number, number]) => void;
	resetSelection: () => void;
};

export const useSelectorStore = create<ISelectorState>((set, get) => ({
	selectorTargets: null,
	existingSelectorTargets: null,
	currentSelectorTarget: null,
	chosenSelectorTarget: null,
	bulkSelectionSize: 0,
	hasHitBlock: false,
	hitInteractable: false,
	hitInteractLabel: "",

	updateSelector: (
		selectorTargets,
		currentSelectorTarget,
		chosenSelectorTarget,
		bulkSelectionSize,
		hasHitBlock,
	) => {
		// don't set state and re-render if nothing has changed
		if (
			JSON.stringify(currentSelectorTarget) !== JSON.stringify(get().currentSelectorTarget) ||
			JSON.stringify(chosenSelectorTarget) !== JSON.stringify(get().chosenSelectorTarget) ||
			JSON.stringify(selectorTargets) !== JSON.stringify(get().selectorTargets)
		) {
			set({ selectorTargets, currentSelectorTarget, chosenSelectorTarget, bulkSelectionSize });

			const existingSelectorTargets = selectorTargets?.filter(
				(target) => target.type !== SelectorTargetType.NEW_GROUP,
			);

			if (existingSelectorTargets) {
				set({ existingSelectorTargets });
			}
		}

		if (get().bulkSelectionSize !== bulkSelectionSize) {
			set({ bulkSelectionSize });
		}

		if (get().hasHitBlock !== hasHitBlock) {
			set({ hasHitBlock });
		}
	},

	updateHitInteractable: (hitInteractable, hitInteractLabel) => {
		if (get().hitInteractable !== hitInteractable) {
			set({ hitInteractable });
		}

		if (get().hitInteractLabel !== hitInteractLabel) {
			set({ hitInteractLabel });
		}
	},

	nudgeSelection: (direction) => {
		const world = useEngineStore.getState().world;
		if (!world) return;

		SelectorClient.nudgeSelection(world, direction);
	},

	resetSelection: () => {
		const world = useEngineStore.getState().world;
		if (!world) return;

		SelectorClient.resetSelection(world);
	},
}));

export default {
	useSelector: useSelectorStore.getState,
};
