import { PLAY_WORLD_MODE } from "@jamango/content-client";
import { create } from "zustand";

type State = {
	version: number;
	mode: number;
	error?: string;
	worldTags?: unknown;
	experimental?: unknown;
};

type Actions = {
	setVersion: (version: State["version"]) => void;
	setWorldTags: (tags: State["worldTags"]) => void;
};

export const useWorldStore = create<State & Actions>()((set) => ({
	version: 0,
	mode: PLAY_WORLD_MODE,

	// Handlers
	setVersion: (version) => set({ version }),
	setWorldTags: (worldTags) => set({ worldTags }),
}));

export default {
	useWorld: useWorldStore.getState,
};
