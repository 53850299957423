import { useSub } from "@lib/hooks/useSub";
import { useMultiplayerStore } from "@stores/game-client/multiplayer";
import React, { useEffect } from "react";
import { ws } from "rest-client";

export const NetworkEvents = React.memo(() => {
	useSub("startup", {
		onEvent: (ev) => {
			const msg = ev.result;
			if (!msg) return;

			switch (msg.type) {
				case "server-list": {
					useMultiplayerStore.setState({ servers: msg.servers });
					return;
				}
			}
		},
	});

	useEffect(() => {
		ws.client.onVersionMismatch.add(() => {
			// TODO Show Update Dialog
			console.error("Version mismatch");
		});
	}, []);

	return null;
});
