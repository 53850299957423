import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import { format } from "date-fns/format";

export function distanceToNow(date: Date) {
	return formatDistanceToNow(date, { addSuffix: true });
}

export function formatDate(date: Date) {
	return format(date, "MMMM dd, yyyy hh:mm a");
}
