import { generatePk } from "../../lib/helpers/general";
import type { IBundle, IProp } from "../../lib/types";
import { AssetType, type IAssetKey } from "../../lib/types";
import { PropColliderType, PropMeshType, PropMotionType } from "../../lib/validations/prop";
import type { JacyContentState } from "../JacyContentState";

export class JacyPropsState {
	props: NonNullable<IBundle["assets"]["props"]>;
	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.props = {};
	}

	import(data?: IBundle["assets"]["props"]) {
		if (!data) return;

		this.props = Object.assign(this.props, data);
		this.#state.markDirty();
	}

	export() {
		return this.props;
	}

	makeEmptyProp(): IProp {
		return {
			pk: generatePk(AssetType.PROP),
			type: AssetType.PROP,
			name: "New Prop",
			scripts: [],
			mesh: {
				type: PropMeshType.NONE,
			},
			physics: {
				collider: {
					type: PropColliderType.NONE,
				},
				mass: 1,
				friction: 0.5,
				restitution: 0.2,
				motionType: PropMotionType.DYNAMIC,
			},
			scale: 1,
		};
	}

	set(data: IProp) {
		this.props[data.pk] = data;
		this.#state.markDirty();
	}

	get(pk: IAssetKey) {
		return this.props[pk];
	}

	delete(pk: IAssetKey) {
		delete this.props[pk];
		this.#state.markDirty();
	}

	getAll(): IProp[] {
		return Object.values(this.props);
	}
}
