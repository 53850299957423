import { LOADING_TEXT } from "@stores/game-client/game-client";
import { useGameLoading } from "@lib/hooks/game-client/useGameLoading";
import { GameClient, GameMultiplayer } from "@jamango/client";
import { distanceToNow } from "@lib/helpers/date";
import { useAuthUserStore } from "@stores/auth-user";
import { useMultiplayerStore } from "@stores/game-client/multiplayer";

import { ArrowRight, GlobeHemisphereWest, PlayCircle } from "@components/icons";
import { Button } from "@components/ui/Button";
import { Tooltip } from "@components/ui/Tooltip";
import { WorldOption } from "./HubDialog/screens/WorldOption";
import { Skeleton } from "@components/ui/Skeleton";
import type { IPublicWorld } from "@jamango/content-service/types/index.ts";

type IProps = {
	world: IPublicWorld;
};

export function HubWorldCard({ world }: IProps) {
	const loading = useGameLoading();
	const accountId = useAuthUserStore((state) => state.userId);
	const servers = useMultiplayerStore((state) => state.servers);

	const server = servers.find((server) => server.worldID === world.shortCode);

	const thumbnailUrl = world.thumbnailFile?.name;

	const isCollaborator = !!world.collaborators?.some((user) => user.id === accountId);

	return (
		<li key={world.id} className="flex flex-col justify-between gap-4 p-4 xs:flex-row sm:items-center">
			{thumbnailUrl ? (
				<div className="size-16 rounded-md">
					<img src={thumbnailUrl} alt="thumbnail" className="size-full rounded-md object-cover" />
				</div>
			) : (
				<div
					aria-hidden="true"
					className="hidden rounded-md border-b border-gray-300 bg-gray-100 p-4 text-gray-900 sm:block"
				>
					<GlobeHemisphereWest className="size-8" />
				</div>
			)}
			<div className="flex-1">
				<div className="text-xl font-medium text-gray-900">
					<span data-qaid="world-card-name">{world.name}</span>

					{(world.isFeatured || world.liveVersionId) && (
						<span className="ml-2 rounded bg-emerald-200 px-1 py-0.5 font-sans text-xs font-semibold text-emerald-900">
							{world.isFeatured ? "Featured" : "Live"}
						</span>
					)}
					{isCollaborator && (
						<span className="ml-2 rounded bg-rose-200 px-1 py-0.5 font-sans text-xs font-semibold text-rose-900">
							Collaborator
						</span>
					)}
					{server && <ServerTag server={server} />}
				</div>
				<p className="text-sm text-gray-600">
					{world.shortCode}&nbsp;&middot; Updated {distanceToNow(new Date(world.version.updatedAt))}
				</p>
			</div>

			{world.analytics && (
				<p className="text-sm text-gray-600">
					Total Visits: {world.analytics.visits} &nbsp;&middot; Unique Visitors:{" "}
					{world.analytics.uniqueVisits}
				</p>
			)}
			<div className="flex flex-col gap-2 sm:flex-row sm:items-center">
				{server?.dedicated && (
					<Button size="md" onClick={() => GameMultiplayer.joinWorld(server.serverID)}>
						<ArrowRight className="hidden size-4 xs:block" aria-hidden="true" />
						Join world
					</Button>
				)}
				<div className="flex gap-2">
					<Tooltip content={loading ? LOADING_TEXT[loading] : null}>
						<Button
							size="md"
							onClick={() =>
								GameClient.loadWorld(world.shortCode, {
									versionId: world.version.id,
								})
							}
							className="grow"
							disabled={!!loading}
							data-qaid="load-current-world-btn"
							title={world.shortCode}
						>
							<PlayCircle className="hidden size-5 xs:block" aria-hidden="true" />
							Load and play
						</Button>
					</Tooltip>
					<WorldOption id={world.id} name={world.name} />
				</div>
			</div>
		</li>
	);
}

export function SkeletonHubWorldCard() {
	return (
		<div
			aria-hidden="true"
			className="flex flex-col items-center justify-between gap-4 rounded border-b-2 border-gray-400 p-4 shadow md:flex-row"
		>
			<div
				aria-hidden="true"
				className="rounded border-b-2 border-gray-400 bg-gray-200 p-4 text-gray-900"
			>
				<GlobeHemisphereWest className="size-6" />
			</div>
			<div className="flex-1">
				<Skeleton className="w-2/5" />
				<Skeleton size="sm" className="w-4/5" />
			</div>
		</div>
	);
}

type IServerTagProps = {
	server: { dedicated: boolean; username: string };
};

function ServerTag({ server }: IServerTagProps) {
	const username = useAuthUserStore((state) => state.username);
	const isAuthUserHosting = !!(server && server.username === username);

	if (server.dedicated) {
		return (
			<span className="ml-2 rounded bg-indigo-200 px-1 py-0.5 font-sans text-xs font-semibold text-indigo-900">
				Dedicated Server Hosting
			</span>
		);
	}

	if (isAuthUserHosting) {
		return (
			<span className="ml-2 rounded bg-indigo-200 px-1 py-0.5 font-sans text-xs font-semibold text-indigo-900">
				Currently Hosting
			</span>
		);
	}

	return (
		<span className="ml-2 rounded bg-indigo-200 px-1 py-0.5 font-sans text-xs font-semibold text-indigo-900">
			Hosting by {server.username}
		</span>
	);
}
