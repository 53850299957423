import { type IProp } from "@jamango/content-client";
import { create } from "zustand";
import { Jacy } from "../../jacy/JacyClient";

type JacyPropEditorState = {
	open: boolean;
	prop: IProp;

	// Handlers
	setName: (name: string) => void;
	setMesh: (mesh: IProp["mesh"]) => void;
	setCollider: (collider: IProp["physics"]["collider"]) => void;
	setPhysics: (physics: IProp["physics"]) => void;
	setScale: (scale: number) => void;
	addScript: (scriptId: string) => void;
	removeScript: (scriptId: string) => void;
	setScriptData: (scriptId: string, data: Record<string, any>) => void;

	// Actions
	close: () => void;
	editProp: (prop: IProp) => void;
	getProp: () => IProp;
};

export const useJacyPropEditorStore = create<JacyPropEditorState>((set, get) => ({
	open: false,
	prop: null!,

	setName: (name) => {
		const prop = get().prop;

		prop.name = name;
		set({ prop });
	},
	setMesh: (mesh) => {
		const prop = get().prop;
		prop.mesh = mesh;
		set({ prop });
	},
	setCollider: (collider) => {
		const prop = get().prop;
		prop.physics.collider = collider;
		set({ prop });
	},
	setPhysics: (physics) => {
		const prop = get().prop;
		prop.physics = physics;
		set({ prop });
	},
	setScale: (scale) => {
		const prop = get().prop;
		prop.scale = scale;
		set({ prop });
	},
	addScript: (scriptId) => {
		const data = Jacy.actions.scripts.getDefaultScriptControlsData(scriptId);

		const prop = get().prop;

		const scripts = [...get().prop.scripts, { script: scriptId, data }];
		prop.scripts = scripts;

		set({ prop });
	},
	removeScript: (scriptId) => {
		const prop = get().prop;
		const scripts = prop.scripts.filter((script) => script.script !== scriptId);
		prop.scripts = scripts;

		set({ prop });
	},
	setScriptData: (scriptId, data) => {
		const prop = get().prop;
		const scripts = prop.scripts.map((script) =>
			script.script === scriptId ? { script: script.script, data } : script,
		);
		prop.scripts = scripts;
		set({ prop });
	},
	close: () => set({ open: false }),
	editProp: (prop) => {
		set({
			open: true,
			prop,
		});
	},
	getProp: () => structuredClone(get().prop),
}));
