export class JacyEventsEmitter<
	TEvents extends Record<string, (...args: any[]) => void> = Record<string, (...args: any[]) => void>,
> {
	events: Map<keyof TEvents, Set<TEvents[keyof TEvents]>>;

	constructor() {
		this.events = new Map();
	}

	on<E extends keyof TEvents>(event: E, callback: TEvents[E]) {
		let callbacks = this.events.get(event);
		if (!callbacks) {
			callbacks = new Set();
			this.events.set(event, callbacks);
		}

		callbacks.add(callback);

		return () => this.off(event, callback);
	}

	off<E extends keyof TEvents>(event: E, callback: TEvents[E]) {
		const callbacks = this.events.get(event);
		if (!callbacks) {
			return;
		}

		callbacks.delete(callback);
	}

	emit<E extends keyof TEvents>(event: E, ...args: Parameters<TEvents[E]>) {
		const callbacks = this.events.get(event);
		if (!callbacks) {
			return;
		}

		for (const callback of callbacks) {
			callback(...args);
		}
	}
}
