import {
	AlignBottom,
	AlignLeft,
	AlignRight,
	AlignTop,
	ArrowDown,
	ArrowLeft,
	ArrowRight,
	ArrowUp,
} from "@components/icons";
import { Jacy } from "@jacy-client";

export function InputIcon({
	direction,
	type,
	className = "size-5 shrink-0",
}: {
	type: "padding" | "margin" | "borderRadius" | "position" | string;
	direction: "top" | "bottom" | "left" | "right";
	className?: string;
}) {
	if (type === "borderRadius") {
		if (direction === "top") {
			return (
				<img
					src={Jacy.getFilePath(`assets/frontend/icons/icon-border-radius-top-left.svg`)}
					alt=""
					className="size-6"
				/>
			);
		}
		if (direction === "right") {
			return (
				<img
					src={Jacy.getFilePath(`assets/frontend/icons/icon-border-radius-top-right.svg`)}
					alt=""
					className="size-6"
				/>
			);
		}
		if (direction === "bottom") {
			return (
				<img
					src={Jacy.getFilePath(`assets/frontend/icons/icon-border-radius-bottom-right.svg`)}
					alt=""
					className="size-6"
				/>
			);
		}
		if (direction === "left") {
			return (
				<img
					src={Jacy.getFilePath(`assets/frontend/icons/icon-border-radius-bottom-left.svg`)}
					alt=""
					className="size-6"
				/>
			);
		}
	}

	if (type === "padding" || type === "position") {
		if (direction === "left") {
			return <AlignLeft className={className} />;
		}
		if (direction === "right") {
			return <AlignRight className={className} />;
		}
		if (direction === "top") {
			return <AlignTop className={className} />;
		}
		if (direction === "bottom") {
			return <AlignBottom className={className} />;
		}
	}

	if (type === "margin") {
		if (direction === "left") {
			return <ArrowLeft className={className} />;
		}
		if (direction === "right") {
			return <ArrowRight className={className} />;
		}
		if (direction === "top") {
			return <ArrowUp className={className} />;
		}
		if (direction === "bottom") {
			return <ArrowDown className={className} />;
		}
	}

	return <div></div>;
}
