export type DedicatedServerRegionId = string;

export type DedicatedServerRegion = {
	id: DedicatedServerRegionId;
	name: string;
	flag: string;
};

export const regions: DedicatedServerRegion[] = [
	{ id: "atl", name: "USA (Atlanta)", flag: "🇺🇸" },
	{ id: "bom", name: "India", flag: "🇮🇳" },
	{ id: "fra", name: "Germany", flag: "🇩🇪" },
	{ id: "gru", name: "Brazil", flag: "🇧🇷" },
	{ id: "lax", name: "USA (Los Angeles)", flag: "🇺🇸" },
	{ id: "osa", name: "Japan", flag: "🇯🇵" },
	{ id: "sin", name: "Singapore", flag: "🇸🇬" },
	{ id: "syd", name: "Australia", flag: "🇦🇺" },
];

export const allowedRegionIds: DedicatedServerRegionId[] = regions.map((r) => r.id);
