import type { World } from "base/world/World";
import * as PencilClient from "client/world/tools/Pencil";
import * as WrenchClient from "client/world/tools/Wrench";
import { ItemPencil } from "mods/defs/ItemPencil";
import { ItemWrench } from "mods/defs/ItemWrench";
import type { Vector3Tuple } from "three";
import { Vector3 } from "three";

const _direction = new Vector3();

export const nudgeSelection = (world: World, directionTuple: Vector3Tuple) => {
	const equippedItem = world.client?.getEquippedItem();
	if (!equippedItem) return;

	const direction = _direction.fromArray(directionTuple);

	if (ItemPencil.isPencil(equippedItem.def) || ItemPencil.isBlockStructure(equippedItem.def)) {
		PencilClient.nudge(world.client.pencil, world, direction);
	} else if (ItemWrench.name === equippedItem.def) {
		WrenchClient.nudge(world.client.wrench, world, direction);
	}
};

export const resetSelection = (world: World) => {
	const equippedItem = world.client?.getEquippedItem();
	if (!equippedItem) return;

	if (ItemPencil.isPencil(equippedItem.def) || ItemPencil.isBlockStructure(equippedItem.def)) {
		PencilClient.resetPencil(world.client.pencil, world);
	} else if (ItemWrench.name === equippedItem.def) {
		WrenchClient.resetWrench(world.client.wrench, world);
	}
};
