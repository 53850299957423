import { getStaticFile } from "@jamango/content-client";

export const IMAGES = {
	DEFAULT_WORLD_THUMBNAIL: getStaticFile("assets/frontend/placeholders/world-template.webp"),
	EMPTY_WORLD_THUMBNAIL: getStaticFile("assets/frontend/placeholders/world-blank.webp"),
	MAIN_BG: getStaticFile("assets/frontend/hero-emmet-offset.webp"),
	DEEP_SPACE_WORLD_THUMBNAIL: getStaticFile("assets/frontend/placeholders/world-deep-space.webp"),
	BARREN_DESERT_WORLD_THUMBNAIL: getStaticFile("assets/frontend/placeholders/world-barren-desert.webp"),
	SNOWY_TUNDRA_WORLD_THUMBNAIL: getStaticFile("assets/frontend/placeholders/world-snowy-tundra.webp"),
	TROPICAL_WORLD_THUMBNAIL: getStaticFile("assets/frontend/placeholders/world-tropical.webp"),
	FANTASY_GRASSLANDS_WORLD_THUMBNAIL: getStaticFile(
		"assets/frontend/placeholders/world-fantasy-grasslands.webp",
	),
	WILD_WEST_DESERT_WORLD_THUMBNAIL: getStaticFile(
		"assets/frontend/placeholders/world-wild-west-desert.webp",
	),
};

export const JAMANGO_ICON_192 = getStaticFile("assets/frontend/jamango-icon-192.png");
export const DEFAULT_COVER = getStaticFile("assets/frontend/novablocks.jpeg");
export const DEFAULT_THUMBNAIL = getStaticFile("assets/frontend/placeholders/world-thumbnail.webp");

export const MISSING_AVATAR = getStaticFile("assets/frontend/avatars/missing.png");

export const DEFAULT_CHARACTER_AVATAR = getStaticFile("./assets/frontend/placeholders/npc-avatar.png");
