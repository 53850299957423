import useSWR from "swr";
import { useLocation } from "react-router";
import { useAuthUserStore } from "@stores/auth-user";
import { swrFetcher } from "@lib/helpers/swr";
import { HubWorldCard, SkeletonHubWorldCard } from "@components/hub/HubWorldCard";
import { HubReturnButton } from "../HubReturnButton";
import { isNullish } from "@jamango/helpers";
import { loadUserWorlds } from "rest-client";

type IProps = {
	selected?: boolean;
	onBackClicked: () => void;
};

function HubContainer({ selected, onBackClicked, children }: IProps & { children: React.ReactNode }) {
	const location = useLocation();

	return (
		<div
			id="tabpanel-world"
			role="tabpanel"
			tabIndex={0}
			aria-labelledby="tab-world"
			className={
				"flex h-full-dialog flex-col justify-between overflow-y-hidden sm-h:h-m-dialog sm-h:sm:h-dialog " +
				(!selected ? "!hidden" : "")
			}
		>
			<div className="flex-1 overflow-y-auto">
				<div className="relative flex h-[40px] items-center justify-center">
					{location.pathname !== "/" && (
						<HubReturnButton onClick={onBackClicked} className="absolute left-0" />
					)}

					<h2 className="text-center text-2xl font-medium text-gray-900">Load a world</h2>
				</div>
				{children}
			</div>
		</div>
	);
}

export function HubUserWorlds(props: IProps) {
	const username = useAuthUserStore((state) => state.username);

	const { data: worldsResult } = useSWR(
		!isNullish(username) ? ["/worlds", username] : null,
		swrFetcher(loadUserWorlds),
	);

	if (isNullish(worldsResult?.data)) {
		return (
			<HubContainer {...props}>
				<div className="flex flex-col gap-2">
					<span className="sr-only">Loading...</span>
					<SkeletonHubWorldCard />
					<SkeletonHubWorldCard />
					<SkeletonHubWorldCard />
				</div>
			</HubContainer>
		);
	}

	const worlds = worldsResult.data;

	if (worlds.length === 0) {
		return (
			<HubContainer {...props}>
				<div className="my-6 rounded border-b-2 border-gray-400 px-4 py-8 shadow">
					<p className="text-center text-base font-semibold text-gray-600">No saved world.</p>
				</div>
			</HubContainer>
		);
	}

	return (
		<HubContainer {...props}>
			<ul className="flex flex-col gap-1 divide-y divide-slate-200">
				{worlds.map((world) => (
					<HubWorldCard key={world.id} world={world} />
				))}
			</ul>
		</HubContainer>
	);
}
