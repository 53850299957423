import { create } from "zustand";
import { Desktop, DeviceMobile } from "@components/icons";
import type { CustomUIComponent, UIPosition, ValueType } from "@jamango/content-client";

export const VIEW_MODE_OPTIONS = [
	{ name: "Mobile View", value: "sm", icon: DeviceMobile },
	{ name: "Desktop View", value: "lg", icon: Desktop },
];

export const getViewMode = (mode: ViewModeTypes) => {
	return VIEW_MODE_OPTIONS.find((viewMode) => viewMode.value === mode)?.name;
};

const CSS_STYLES = {
	fontFamily: "",
	fontSize: "",
	color: "",
	backgroundColor: "",
	padding: "",
	borderRadius: "",
	margin: "",
	width: "",
	height: "",
};

const INPUT_DATA = {
	value: "",
	text: "",
	stateData: "",
};

const CSS_SIDES = {
	top: "",
	bottom: "",
	left: "",
	right: "",
};

export type InputIndexes = keyof typeof INPUT_DATA;
export type CssStylesIndexes = keyof typeof CSS_STYLES;
export type QuadValueSides = keyof typeof CSS_SIDES;

export type ViewModeTypes = "lg" | "sm";

export type DefaultValueType = {
	value: string | number | boolean | null | undefined;
}[];

type EditorPositions = "left" | "right" | "bottom" | "free";

interface ICustomUIState {
	components: CustomUIComponent[];
	currentComponent: null | CustomUIComponent | undefined;
	selected: string;
	mode: "create" | "edit";
	viewMode: "lg" | "sm";
	editorPosition: EditorPositions;
	setEditorPosition: (position: EditorPositions) => void;
	clearCustomUI: () => void;
	setSelected: (selected: string) => void;
	setCurrentComponent: (currentComponent: CustomUIComponent | null) => void;
	setViewMode: (viewMode: ViewModeTypes) => void;
	setComponentStyle: (style: string, value: ValueType) => void;
	setInputs: (input: string, value: unknown) => void;
	setPosition: (position: UIPosition) => void;
	setComponentName: (name: string) => void;
	setMode: (mode: "create" | "edit") => void;
	upsertComponent: (component: CustomUIComponent) => void;
	deleteComponent: (component: CustomUIComponent) => void;
}

export type CustomCSSProps = React.CSSProperties & Record<`--${string}`, number | string>;

const modifyCurrentComponent = (state: ICustomUIState, mod: (component: CustomUIComponent) => void) => {
	if (!state.currentComponent) return state;
	const comp = { ...state.currentComponent! };
	mod(comp);
	const components = state.components.map((c) => (c.id === comp.id ? comp : c));
	return { components, currentComponent: comp };
};

export const useCustomUIStore = create<ICustomUIState>((set, get) => ({
	components: [],
	currentComponent: null,
	selected: "",
	mode: "create",
	viewMode: "lg",
	editorPosition: "right",
	setEditorPosition: (position) => set({ editorPosition: position }),
	setSelected: (selected) => set({ selected }),
	setCurrentComponent: (currentComponent) => {
		const component = structuredClone(currentComponent);
		if (component) {
			component.show = true;
			set({ currentComponent: component });
			// USED TO RERENDER THE COMPONENT LIST
			get().setPosition(component.position.value);
		}
	},
	setViewMode: (viewMode) => {
		set({ viewMode });
	},
	setComponentStyle: (style, value) => {
		const state = modifyCurrentComponent(get(), (component) => {
			const styleToUpdate = component.styles[style as CssStylesIndexes];
			if (!styleToUpdate) return state;
			styleToUpdate.value[get().viewMode] = value;
		});
		set(state);
	},
	setInputs: (input, value) => {
		const state = modifyCurrentComponent(get(), (component) => {
			const inputToUpdate = component.inputs[input as InputIndexes];
			if (!inputToUpdate) return state;
			inputToUpdate.value = value as any;
			inputToUpdate.defaultValue = value as any;
		});
		set(state);
	},
	setPosition: (position) => {
		const state = modifyCurrentComponent(get(), (component) => {
			component.position.value = position;
		});
		set(state);
	},
	setComponentName: (name) => {
		const state = modifyCurrentComponent(get(), (component) => {
			component.name = name;
		});
		set(state);
	},
	clearCustomUI: () => {
		set({ components: [] });
	},

	setMode: (mode) => set({ mode }),

	// EVENTS

	upsertComponent: (component) => {
		const { components } = get();
		const existingIndex = components.findIndex((c) => c.id === component.id);

		if (existingIndex === -1) {
			// Add new component if not found
			set({ components: [...components, component] });
		} else {
			// Update existing component
			const updated = components.map((c, index) => (index === existingIndex ? component : c));
			set({ components: updated });
		}
	},

	deleteComponent: (component) => {
		set({
			components: get().components.filter((currentComponent) => currentComponent.id !== component.id),
		});
	},
}));

export default {
	useCustomUI: useCustomUIStore.getState,
};
