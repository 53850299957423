import type { IAssetKey, IBundle } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";
import { BLOCK_SOUNDS_PACKS_PACKAGE } from "../../lib/constants/block-sounds-pack-package";

export class JacyBlockSoundsPackState {
	#blockSoundsPacks: NonNullable<IBundle["assets"]["blockSoundsPacks"]>;

	constructor(_state: JacyContentState) {
		this.#blockSoundsPacks = BLOCK_SOUNDS_PACKS_PACKAGE.assets.blockSoundsPacks;
	}

	get(pk?: IAssetKey | null) {
		if (!pk) return null;
		return this.#blockSoundsPacks[pk];
	}

	getByIdentifier(identifier?: string | null) {
		if (!identifier) return null;
		return this.get(`blockSoundsPack#${identifier}`);
	}

	getAll() {
		return Object.values(this.#blockSoundsPacks);
	}
}
