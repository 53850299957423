import { apiClient, apiResponseToResult } from "./index";

export async function addWorldCollaborator({ worldId, username }: { worldId: string; username: string }) {
	return apiResponseToResult(
		await apiClient.api.worlds({ worldId }).permissions["add-collaborator"].post({ username }),
	);
}

export async function removeWorldCollaborator({
	worldId,
	accountId,
}: {
	worldId: string;
	accountId: string;
}) {
	return apiResponseToResult(
		await apiClient.api
			.worlds({ worldId })
			.permissions["remove-collaborator"].post({ collaboratorId: accountId }),
	);
}
