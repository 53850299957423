import {
	getAvatarObjectNodeConfig,
	convertAvatarObjectToConfig as helperConvertAvatarObjectToConfig,
	getAvatarConfig as helperGetAvatarConfig,
} from "../../lib/helpers/avatars";
import { generateId } from "../../lib/helpers/general";
import type {
	BodyTypeValue,
	IAssetKey,
	IAvatar,
	IAvatarMonitoringConfig,
	IAvatarObject,
	IAvatarObjectBodyPart,
	IIdentifier,
	IBundle,
	IParsedAvatar,
} from "../../lib/types";
import { AssetType, AvatarType } from "../../lib/types";
import type { AVATAR_OBJECT } from "../../lib/constants/avatars";
import type { JacyContentState } from "../JacyContentState";
import { validations } from "../../lib/validations";
import { HAIR_COLORS, SKIN_COLORS } from "../../lib/constants/avatar-components";

export class JacyAvatarsState {
	#state: JacyContentState;
	#avatars: Record<IAssetKey, IAvatar>;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#avatars = {};
	}

	import(data?: IBundle["assets"]["avatars"]) {
		if (!data) return;

		this.#avatars = Object.assign(this.#avatars, data);
		this.#state.markDirty();
	}

	export() {
		return Object.fromEntries(
			Object.entries(this.#avatars).filter(
				([_, avatar]) => avatar.avatarType !== AvatarType.USER_AVATAR,
			),
		);
	}

	set(data: IAvatar) {
		this.#avatars[data.pk] = data;
		this.#state.markDirty();
	}

	get(pk?: IAssetKey | null) {
		const raw = this.getUnparsed(pk);
		if (!raw) return null;
		return this.#parsedAvatar(raw);
	}

	getUnparsed(pk?: IAssetKey | null) {
		if (!pk) return null;
		return this.#avatars[pk];
	}

	getAll() {
		return Object.values(this.#avatars)
			.filter((avatar) => !avatar.isDeleted)
			.map((a) => this.#parsedAvatar(a)!)
			.filter(Boolean);
	}

	getAvatarForNewCharacter() {
		return this.getAll().filter((a) => a.avatarType !== AvatarType.USER_AVATAR)[0];
	}

	convertAvatarToAvatarConfig(avatar: IAvatar) {
		return helperGetAvatarConfig(avatar, this.#state.avatarComponents.getAll());
	}

	convertAvatarObjectToConfig(avatarObject: IAvatarObject) {
		const monitoringConfig = helperConvertAvatarObjectToConfig(
			avatarObject,
			this.#state.avatarComponents.getAll(),
		);

		const config = helperGetAvatarConfig(
			{ config: monitoringConfig },
			this.#state.avatarComponents.getAll(),
		);

		return config;
	}

	convertConfigToAvatarObject(avatar: IAvatar) {
		const avatarObject = getAvatarObjectNodeConfig(avatar, this.#state.avatarComponents.getAll());

		return avatarObject;
	}

	getWorldCharacterAvatars() {
		return this.getAll().filter(
			(avatar) => avatar.avatarType === AvatarType.CHARACTER && !avatar.isDeleted,
		);
	}

	getNextAvatarName() {
		const avatarNames = this.getAll().map((avatar) => avatar.name);
		let counter = avatarNames.length;
		let name = `Avatar Costume ${counter}`;

		while (avatarNames.includes(name)) {
			counter++;
			name = `Avatar Costume ${counter}`;
		}

		return name;
	}

	generatePK(id: string) {
		return `${AssetType.AVATAR}#${id}`;
	}

	createId() {
		return generateId();
	}

	create(
		id: string,
		accountId: string,
		avatarType: AvatarType,
		config: IAvatarMonitoringConfig,
		thumbnailResourcePk: string,
		displayPhotoResourcePk: string,
	) {
		const name = this.getNextAvatarName();

		validations.avatar.create({
			name,
			avatarType,
			config,
		});

		const today = new Date().toISOString();

		const createdAvatar: IAvatar = {
			pk: `${AssetType.AVATAR}#${id}`,
			id,
			type: AssetType.AVATAR,
			name,
			config,
			displayPhotoResourcePk,
			thumbnailResourcePk,
			avatarType,
			owner: accountId,
			isDeleted: false,
			createdAt: today,
			updatedAt: today,
		};

		return createdAvatar;
	}

	update(
		pk: IAssetKey,
		config: IAvatarMonitoringConfig,
		thumbnailResourcePk: string,
		displayPhotoResourcePk: string,
		name: string,
	) {
		const avatar = this.getUnparsed(pk);

		if (!avatar) {
			throw new Error("Avatar not found");
		}

		validations.avatar.update({
			config,
		});

		validations.avatar.name(name);

		avatar.config = config;
		avatar.name = name;
		avatar.thumbnailResourcePk = thumbnailResourcePk;
		avatar.displayPhotoResourcePk = displayPhotoResourcePk;
		avatar.updatedAt = new Date().toISOString();

		this.set(avatar);

		return avatar;
	}

	setName(pk: IAssetKey, name: string) {
		const avatar = this.getUnparsed(pk);

		if (!avatar) {
			throw new Error("Avatar not found");
		}

		validations.avatar.name(name);

		avatar.name = name;
		avatar.updatedAt = new Date().toISOString();

		this.set(avatar);

		return avatar;
	}

	restore(pk: IAssetKey) {
		const avatar = this.getUnparsed(pk);

		if (!avatar) {
			throw new Error("Avatar not found");
		}

		avatar.isDeleted = false;
		avatar.updatedAt = new Date().toISOString();

		this.set(avatar);
	}

	delete(pk: IAssetKey) {
		const avatar = this.getUnparsed(pk);

		if (!avatar) {
			throw new Error("Avatar not found");
		}

		avatar.isDeleted = true;
		avatar.updatedAt = new Date().toISOString();

		this.set(avatar);
	}

	onWorldReload() {
		this.getAll().forEach((a) => {
			const avatar = this.getUnparsed(a.pk);
			if (!avatar) return;
			avatar.needsReload = false;
			this.set(avatar);
		});
	}

	onSave() {
		for (const avatar of Object.values(this.#avatars)) {
			avatar.updatedAt = new Date().toISOString();
			this.set(avatar);
		}
	}

	#parsedAvatar(data: IAvatar): IParsedAvatar | null {
		const thumbnailResource = this.#state.resources.getAvatarThumbnail(data.thumbnailResourcePk);

		if (!thumbnailResource) {
			console.error(`Thumbnail resource missing for avatar ${data.pk}`);
			return null;
		}

		const displayPhotoResource = this.#state.resources.getAvatarPhoto(data.displayPhotoResourcePk);

		if (!displayPhotoResource) {
			console.error(`Photo resource missing for avatar ${data.pk}`);
			return null;
		}

		return {
			...data,
			thumbnailResource,
			displayPhotoResource,
		};
	}

	getAvatarObjectBodyPartId(bodyPart: IAvatarObjectBodyPart | string | undefined | null) {
		if (!bodyPart) return null;
		if (typeof bodyPart === "string") return bodyPart;
		if (typeof bodyPart === "object") {
			return bodyPart.id;
		}
	}

	customizeConfig(
		avatarObject: IAvatarObject,
		bodyPart: keyof typeof AVATAR_OBJECT,
		identifier: IIdentifier,
	) {
		const currentBodyPart = bodyPart as keyof typeof AVATAR_OBJECT;
		const currentAvatarObject = avatarObject[currentBodyPart];
		if (identifier) {
			if (identifier === "EMPTY") {
				avatarObject[currentBodyPart] = "EMPTY" as any;
				return;
			}
			if (bodyPart === "hairColor") {
				const targetColor = HAIR_COLORS.find(
					(color) => color.id === Number(identifier) || color.color === identifier,
				);
				if (!targetColor) return;
				avatarObject.hairColor = targetColor.color;
				return;
			} else if (bodyPart === "skinColor") {
				const targetColor = SKIN_COLORS.find(
					(color) => color.id === Number(identifier) || color.color === identifier,
				);
				if (!targetColor) return;
				avatarObject.skinColor = targetColor.color;
				return;
			} else if (bodyPart === "bodyType") {
				avatarObject.bodyType = identifier as BodyTypeValue;
				return;
			} else {
				const newBodyPart = this.#state.avatarComponents.getByIdentifier(identifier);
				if (!newBodyPart) {
					console.error(`No body part found for ${currentBodyPart} identifier`);
					return;
				}
				if (identifier === "UNCHANGED") return;
				if (currentAvatarObject && identifier === (currentAvatarObject as IAvatarObjectBodyPart).id)
					return;

				avatarObject[currentBodyPart] = {
					name: newBodyPart.name,
					id: newBodyPart.id,
				} as any;
			}
		}
	}
}
