import { delay } from "@jamango/helpers";
import { create } from "zustand";

const getRandomTipIndex = () => Math.floor(Math.random() * 3 /* keep in sync with tip count */);

type IPatches = {
	show: (disablePointerLock: boolean) => void;
	hide: () => void;
	showProgress: (show: boolean) => void;
	setProgress: (text: string, percent: number) => string;
	setText: (text: string) => void;
};

type ILoadingScreenStore = {
	patches: IPatches;
	isInitialized: boolean;
	visible: boolean;
	text: string;
	progressVisible: boolean;
	progressText: string | null;
	progressPercent: string | null;
	tipIndex: number;

	init: (patches: IPatches) => void;
	show: (disablePointerLock?: boolean) => void;
	hide: () => void;
	setText: (text: string) => Promise<void>;
	setProgress: (text: string, percent: number) => void;
	showProgress: (show: boolean) => void;
};

export const useLoadingScreenStore = create<ILoadingScreenStore>((set, get) => ({
	patches: null!,
	isInitialized: false,
	visible: true,

	text: "",
	progressVisible: false,
	progressText: null,
	progressPercent: null,
	tipIndex: getRandomTipIndex(),

	// Events
	init: (patches) => {
		set({
			patches: {
				show: patches.show,
				hide: patches.hide,
				showProgress: patches.showProgress,
				setProgress: patches.setProgress,
				setText: patches.setText,
			},
		});
	},
	show: (disablePointerLock = true) => {
		set({ visible: true, isInitialized: true });
		get().patches.show(disablePointerLock);
	},
	hide: () => {
		get().patches.hide();
		set({ visible: false, tipIndex: getRandomTipIndex() });
	},
	setText: async (text) => {
		get().patches.setText(text);
		set({ text });
		get().showProgress(false);

		await new Promise(function (resolve) {
			(async function () {
				await new Promise(requestAnimationFrame);
				await new Promise(requestAnimationFrame);
				resolve(undefined);
			})();

			delay(50).then(resolve);
		});
	},
	setProgress: (text, percent) => {
		const formattedPercent = get().patches.setProgress(text, percent);
		set({ progressText: text, progressPercent: formattedPercent });
	},
	showProgress: (show) => {
		get().patches.showProgress(show);
		set({ progressVisible: show });

		if (show) get().setProgress("", 0);
	},
}));

export default {
	useLoadingScreen: useLoadingScreenStore.getState,
};
