import React from "react";
import { create } from "zustand";
import { getWorldsFeed, toggleWorldLike, toggleWorldFavorite, type GetSuccessData } from "rest-client";

type FeedResponse = GetSuccessData<typeof getWorldsFeed>;

type UserStatus = FeedResponse["feed"][number]["items"][number]["userStatus"];

type Props = {
	feed: FeedResponse["feed"];
	loading: boolean;
	error: string | null;
};

type Actions = {
	fetchFeed: () => Promise<void>;
	toggleLike: (worldId: string) => Promise<void>;
	toggleFavorite: (worldId: string) => Promise<void>;
	updateFeedItem: (worldId: string, updatedFields: Partial<UserStatus> & { likes?: number }) => void;
	reset: () => void; // Add this new action
};

type IState = Props & Actions;

export const useWorldFeedStore = create<IState>((set, get) => ({
	feed: [],
	loading: false,
	error: null,

	fetchFeed: async () => {
		set({ loading: true, error: null });
		try {
			const response = await getWorldsFeed();
			if (response.data && response.data.feed) {
				set({ feed: response.data.feed });
			} else {
				set({ error: "Failed to fetch the world feed." });
			}
		} catch {
			set({ error: "An error occurred while fetching the world feed." });
		} finally {
			set({ loading: false });
		}
	},

	toggleLike: async (worldId) => {
		try {
			const response = await toggleWorldLike(worldId);
			if (typeof response.data?.liked === "boolean") {
				// Calculate the new likes count based on the like status
				const currentWorld = get()
					.feed.flatMap((category) => category.items)
					.find((world) => world.id === worldId);
				const currentLikes = currentWorld?.likes || 0;
				const newLikes = response.data.liked ? currentLikes + 1 : currentLikes - 1;

				get().updateFeedItem(worldId, { hasLiked: response.data.liked, likes: newLikes });
			}
		} catch {
			// Optionally, handle error state here
		}
	},

	toggleFavorite: async (worldId) => {
		try {
			const response = await toggleWorldFavorite(worldId);
			const favorited = response.data?.favorited;
			if (typeof favorited === "boolean") {
				React.startTransition(() => {
					get().updateFeedItem(worldId, { hasFavorited: favorited });
				});
			}
		} catch {
			// Optionally, handle error state here
		}
	},

	updateFeedItem: (worldId, updatedFields) => {
		set((state) => {
			const updatedFeed = state.feed.map((category) => ({
				...category,
				items: category.items.map((world) =>
					world.id === worldId
						? {
								...world,
								userStatus: { ...world.userStatus, ...updatedFields },
								likes: updatedFields.likes !== undefined ? updatedFields.likes : world.likes,
							}
						: world,
				),
			}));

			if (updatedFields.hasFavorited === undefined) {
				return { feed: updatedFeed };
			}

			const favoritesCategoryIndex = updatedFeed.findIndex(
				(category) => category.title === "My Favorites",
			);

			//Handle removing from favorites
			if (updatedFields.hasFavorited === false) {
				if (favoritesCategoryIndex !== -1) {
					updatedFeed[favoritesCategoryIndex].items = updatedFeed[
						favoritesCategoryIndex
					].items.filter((world) => world.id !== worldId);
				}
				return { feed: updatedFeed };
			}

			//Handle adding to favorites
			const worldToAdd = state.feed
				.flatMap((category) => category.items)
				.find((world) => world.id === worldId);

			if (!worldToAdd) {
				return { feed: updatedFeed };
			}

			const updatedWorld = {
				...worldToAdd,
				userStatus: {
					...worldToAdd.userStatus,
					hasFavorited: true,
				},
			};

			// Add to existing favorites category or create new one
			if (favoritesCategoryIndex !== -1) {
				const existingInFavorites = updatedFeed[favoritesCategoryIndex].items.some(
					(world) => world.id === worldId,
				);

				if (!existingInFavorites) {
					updatedFeed[favoritesCategoryIndex].items.push(updatedWorld);
				}
			} else {
				updatedFeed.push({
					category: "all",
					type: "carousel",
					title: "My Favorites",
					items: [updatedWorld],
				});
			}

			return { feed: updatedFeed };
		});
	},

	reset: () => {
		set((state) => {
			// Reset all worlds' userStatus and remove My Favorites
			const resetFeed = state.feed
				.filter((category) => category.title !== "My Favorites")
				.map((category) => ({
					...category,
					items: category.items.map((world) => ({
						...world,
						userStatus: {
							...world.userStatus,
							hasLiked: false,
							hasFavorited: false,
						},
					})),
				}));

			return { feed: resetFeed };
		});
	},
}));
