import { create } from "zustand";

import { useEngineStore } from "../bb";
import { useInventorySelectorStore } from "./inventory-selector";
import { InputCommandType, type ManagerClientState } from "@jamango/engine/Input.ts";
import { usePermissionStore } from "@stores/player";
import { ItemPencil } from "@jamango/engine/Runtime/mods/defs/ItemPencil.js";
import { ItemBuildTool } from "@jamango/frontend/ItemBuildTool.ts";
import { ItemWrench } from "@jamango/engine/Runtime/mods/defs/ItemWrench.js";
import { AssetType, getAssetType } from "@jamango/content-client";

export enum InteractableType {
	PLAYER = "player",
	ITEM_PICKUP = "item_pickup",
}

export const CREATOR_SELECTOR_SLOT = {
	test: "Test Mode",
	play: "Play Mode",
};

export const CREATOR_SELECTOR_SLOTS = [CREATOR_SELECTOR_SLOT.test, CREATOR_SELECTOR_SLOT.play];

export type ItemUIDef = {
	name: string;
	display: string;
	icon?: string;
};

type IItemState = {
	equippedItem: string | null;
	equippedTool: ItemUIDef | null; //toolCycle or toolWheel expect to contain this as an element

	toolCycle: ItemUIDef[];
	toolWheel: ItemUIDef[];

	// Actions
	usePrimary: () => void;
	useSecondary: () => void;
	setEquippedItem: (newItem: string) => void;
	recomputeEquippables: () => void;
	cycleTool: () => void;
};

export const useItemStore = create<IItemState>((set, get) => ({
	equippedItem: null, // item def or null
	equippedTool: null,
	wrenchState: 0,

	toolCycle: [],
	toolWheel: [],

	// Handlers
	// Triggers the left-click action for the current object (used on mobile)
	usePrimary: () => {
		const { BB } = useEngineStore.getState().engine;
		(BB.world.input as ManagerClientState).local.cmd.push(InputCommandType.ITEM_PRIMARY);
	},
	// Triggers the right-click action for the current object (used on mobile)
	useSecondary: () => {
		const { BB } = useEngineStore.getState().engine;
		(BB.world.input as ManagerClientState).local.cmd.push(InputCommandType.ITEM_SECONDARY);
	},
	setEquippedItem: (newItem) => {
		let newTool = null;
		if (newItem !== null) {
			let defName;
			if (ItemBuildTool.isBuildTool(newItem))
				defName = ItemBuildTool.name; //special case: all of the 1-9 inventory slots count as "build tool" to the ui
			else defName = newItem;

			newTool =
				get().toolCycle.find((e) => defName.startsWith(e.name)) ??
				get().toolWheel.find((e) => defName.startsWith(e.name)) ??
				null;
		}

		set({
			equippedItem: newItem ?? null,
			equippedTool: newTool,
		});
	},
	recomputeEquippables() {
		const { canUseIndividualBlocks, canUseWrench, canUsePencil } =
			usePermissionStore.getState().permissions;
		const toolDefs: ItemUIDef[] = [];

		function copyEngineDef(original: ItemUIDef): ItemUIDef {
			return { name: original.name, display: original.display, icon: original.icon };
		}

		if (canUseIndividualBlocks || canUseWrench) toolDefs.push(copyEngineDef(ItemBuildTool)); // || canUseWrench to make npc slots available even when building is disabled (ItemSpawner)
		if (canUseWrench) toolDefs.push(copyEngineDef(ItemWrench));
		if (canUsePencil) toolDefs.push(copyEngineDef(ItemPencil));

		set({ toolCycle: toolDefs, toolWheel: toolDefs.slice() });

		const cur = get().equippedItem;
		if (cur) get().setEquippedItem(cur); //equippedTool may change with a permissions change
	},
	cycleTool: () => {
		const toolCycle = get().toolCycle;
		if (toolCycle.length === 0) return;

		const { canUseIndividualBlocks, canUseWrench } = usePermissionStore.getState().permissions;
		const { activateBuildModeSlot, selector, selectedSlot } = useInventorySelectorStore.getState();

		const curTool = get().equippedTool;
		let nextTool;

		nextTool = toolCycle[(toolCycle.indexOf(curTool!) + 1) % toolCycle.length].name;

		/*
		edge case due to build mode selector having both blocks+npcs:
		1. currently in build mode
		2. you selected a slot that you aren't allowed to equip
		3. curTool evaluates to nothing, so the next tool is still build tool
		4. trapped in build tool forever now
		solution: detect when this happens, jump to the next tool in the list
		*/
		const selectedSlotType = getAssetType(selector[selectedSlot]?.pk ?? "");

		if (
			nextTool === ItemBuildTool.name &&
			!curTool &&
			((!canUseWrench && selectedSlotType === AssetType.CHARACTER) ||
				(!canUseIndividualBlocks && selectedSlotType === AssetType.BLOCK))
		) {
			nextTool = toolCycle.find(function (_e, i) {
				//find the tool after build tool
				if (--i < 0) i += toolCycle.length;
				return toolCycle[i].name === ItemBuildTool.name;
			})!.name;
		}

		if (nextTool === ItemBuildTool.name) {
			activateBuildModeSlot();
		} else {
			const { BB } = useEngineStore.getState().engine;
			const input = (BB.world.input as ManagerClientState).local;
			input.cmd.push([InputCommandType.EQUIP, nextTool]);
		}
	},
}));

export default {
	useItem: useItemStore.getState,
};
