import { Jacy } from "@jacy-client";
import { type IJacyContent } from "@jamango/content-client";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { uploadAndCreateResource } from "../../upload/upload-resource";
import type { JacyActions } from "../JacyActions";

export class JacyThumbnailActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	async uploadThumbnail(initialFile: File) {
		try {
			const { name, resourceType, file } =
				await this.#state.resources.processThumbnailImage(initialFile);

			const uploadedResourceData = await uploadAndCreateResource(Jacy, {
				name,
				resourceType,
				file,
			});

			this.#state.thumbnail.useThumbnailResource(uploadedResourceData.pk);

			useJacyRerenderStore.getState().forceRerenderThumbnail();

			this.#actions.setSuccess("Thumbnail has been uploaded successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	clearThumbnail() {
		try {
			this.#state.thumbnail.clearThumbnail();

			useJacyRerenderStore.getState().forceRerenderThumbnail();

			this.#actions.setSuccess("Thumbnail has been cleared successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
