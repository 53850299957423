import React from "react";
import { useLocation } from "react-router";
import { GameLifeCycle, Router } from "@jamango/client";

import { useControlsStore } from "@stores/controls";
import { useMobileStore } from "@stores/game-client/mobile";
import { MobileNotSupported } from "@components/MobileNotSupported";

export const GameCanvas = React.memo(function MainCanvas({ children }) {
	useLocation();
	const isMobile = useMobileStore((state) => state.isMobile);
	const [canvasTouchStart, canvasTouchEnd] = useControlsStore((state) => [
		state.canvasTouchStart,
		state.canvasTouchEnd,
	]);

	const isPlayPage = Router.isPlayPage();

	React.useEffect(() => {
		GameLifeCycle.onCanvasLoaded();
	}, []);

	if (isPlayPage && isMobile && globalEnv.ALLOW_MOBILE === "false") {
		return <MobileNotSupported />;
	}

	return (
		<div id="gameContainer">
			<canvas
				id="gameCanvas"
				data-qaid="gameCanvas"
				className="top absolute left-0 top-0 z-0 h-full w-full select-none"
				hidden={!isPlayPage}
				onTouchStart={canvasTouchStart}
				onTouchEnd={canvasTouchEnd}
			/>
			<div id="gameHud" className="absolute left-1/2 top-3 flex -translate-x-1/2 flex-col items-center">
				<div
					id="gameTime"
					className="mb-2 rounded bg-gray-900/50 px-2 py-1 text-sm font-semibold tracking-wide text-white md:px-4 md:py-2 md:text-xl"
					hidden
				/>
			</div>
			{children}
		</div>
	);
});
