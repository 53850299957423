import type React from "react";
import { useState } from "react";
import { Input } from "@components/ui/Input";
import { useCustomUIStore } from "@stores/custom-ui";
import type { StyleValueType, QuadInputType, GeneralStyles } from "@jamango/content-client";
import { Listbox } from "@components/ui/Listbox";
import { ColorInput } from "@components/ui/ColorInput";
import { QuadInput } from "./QuadInput";

interface InputTypeProps {
	type: string;
	placeholder?: string;
	value: StyleValueType | string | number | QuadInputType;
	className?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleRemove?: () => void;
	removable?: boolean;
}

const InputType = ({ type, value, onChange, handleRemove, ...props }: InputTypeProps) => {
	switch (type) {
		case "string":
			return (
				<Input {...props} variant="editor" type="text" value={value as string} onChange={onChange} />
			);
		case "number":
			return (
				<Input
					{...props}
					variant="editor"
					type="number"
					value={value as number}
					onChange={onChange}
				/>
			);
		case "color":
			return (
				<ColorInput
					{...props}
					id=""
					name=""
					variant="editor"
					handleRemove={handleRemove}
					color={value as string}
					onColorChange={onChange as (evt: React.ChangeEvent<HTMLInputElement>) => void}
				/>
			);
		default:
			return null;
	}
};

export function InputField({ style }: { style: NonNullable<GeneralStyles[keyof GeneralStyles]> }) {
	const viewMode = useCustomUIStore((state) => state.viewMode);
	const [inputValue, setInputValue] = useState(style.value[viewMode] ?? style.defaultValue);
	const setComponentStyle = useCustomUIStore((state) => state.setComponentStyle);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setInputValue(value);
		setComponentStyle(style.styleName, value);
	};

	const handleRemove = () => {
		if (inputValue === "transparent") {
			setInputValue("#000");
			setComponentStyle(style.styleName, "#000");
		} else {
			setInputValue("transparent");
			setComponentStyle(style.styleName, "transparent");
		}
	};

	const DisplayInput = style.quadValue ? (
		<QuadInput styleValue={style.value[viewMode]} styleName={style.styleName} />
	) : (
		<InputType
			type={style.type}
			placeholder=""
			value={inputValue}
			className="text-base"
			onChange={handleChange}
			handleRemove={handleRemove}
			removable={style.removable}
		/>
	);

	return (
		<div className="relative">
			<label htmlFor="" className="mb-2 inline-block text-sm text-gray-400">
				{style.name}
			</label>
			{style.options ? (
				<Listbox
					id="world-block-transparency"
					selected={style.options.find((item) => item.value === style.value[viewMode]) as any}
					options={style.options.map((option) => ({
						id: option.value,
						name: option.name,
					}))}
					variant="editor"
					onSelect={(value) => setComponentStyle(style.styleName, value.id)}
					disabled={false}
					noSelectionText=""
					squashable
				/>
			) : (
				DisplayInput
			)}
		</div>
	);
}
