import type {
	IEnvironmentPreset,
	IEnvironmentPresetSettings,
	IAssetKey,
	IJacyContent,
} from "@jamango/content-client";
import { useJacyEnvironmentPresetEditorStore } from "@stores/jacy/environment-preset-editor";
import { useEngineStore } from "@stores/bb";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import type { JacyActions } from "../JacyActions";

export class JacyEnvironmentPresetActions {
	#state: IJacyContent["state"];
	#actions: JacyActions;

	constructor(actions: JacyActions) {
		this.#state = actions.content.state;
		this.#actions = actions;
	}

	async changePreset(pk: IAssetKey) {
		try {
			this.#state.environmentPreset.changePreset(pk);
		} catch (error) {
			return this.#actions.setError(error);
		}

		const currentPreset = this.#state.environmentPreset.current;

		if (!currentPreset) {
			return this.#actions.setError("Failed to find current environment preset.");
		}

		useJacyRerenderStore.getState().forceRerenderEnvironmentPreset();

		const settings = this.#formatEngineSettings(currentPreset.originalPreset);
		await this.changeEngineSettings(settings);
	}

	async createPreset() {
		const identifier = this.#state.environmentPreset.createId();
		const envPreset = this.#state.environmentPreset.create(identifier);

		await this.changePreset(envPreset.pk);
		this.editPreset();
	}

	async deletePreset() {
		if (!this.canDelete) {
			return this.#actions.setError("You are not authorized to delete this preset");
		}

		const envPreset = this.#state.environmentPreset.current;

		if (!envPreset) {
			return this.#actions.setError("Failed to find current environment preset");
		}

		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Delete Environment Preset",
			description: `Are you sure you want to delete ${envPreset.name}? This action cannot be undone. Any world using this preset will be reverted to the default preset.`,
			confirmText: "Yes, delete this preset",
			prompt: `delete ${envPreset.name}`,
		});
		if (!confirmed) return;

		this.cancelEditPreset();

		const defaultPreset = this.#state.environmentPreset.getDefault();

		if (!defaultPreset) {
			return this.#actions.setError("Failed to delete preset. There must be a default preset.");
		}

		try {
			await this.changePreset(defaultPreset.pk);
			this.#state.environmentPreset.delete(envPreset.pk);
		} catch (error) {
			return this.#actions.setError(error);
		}

		useJacyRerenderStore.getState().forceRerenderEnvironmentPreset();
	}

	async resetPreset() {
		const envPreset = this.#state.environmentPreset.current;

		if (!envPreset) {
			return this.#actions.setError("Failed to find current environment preset");
		}

		useJacyEnvironmentPresetEditorStore.getState().setPreset(envPreset.originalPreset);

		const settings = this.#formatEngineSettings(envPreset.originalPreset);
		await this.changeEngineSettings(settings);
	}

	async cancelEditPreset() {
		useJacyEnvironmentPresetEditorStore.getState().close();
		await this.resetPreset();
	}

	keepChanges() {
		const { pk, name, preset } = useJacyEnvironmentPresetEditorStore.getState();

		if (!pk || !name || !preset) {
			return this.#actions.setError("Failed to edit preset. Failed to find current environment preset");
		}

		useJacyEnvironmentPresetEditorStore.getState().close();

		try {
			if (!this.canEdit) {
				return this.#actions.setError("You are not authorized to edit this preset");
			}

			this.#state.environmentPreset.update(pk, name, preset);

			useJacyRerenderStore.getState().forceRerenderEnvironmentPreset();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editPreset() {
		const envPreset = this.#state.environmentPreset.current;

		if (!envPreset) {
			return this.#actions.setError("Failed to find current environment preset");
		}

		useJacyEnvironmentPresetEditorStore.getState().editPreset(envPreset);
	}

	#formatEngineSettings(preset: IEnvironmentPreset["preset"]) {
		let settings: Partial<IEnvironmentPresetSettings> = {};

		for (const key in preset) {
			settings = {
				...settings,
				...preset[key as keyof typeof preset],
			};
		}

		return settings;
	}

	async changeEngineSettings(settings: Partial<IEnvironmentPresetSettings>) {
		const { BB } = await useEngineStore.getState().promise;
		BB.world.environmentSettings.router.change(settings, true);
	}

	#canEditPreset(preset?: IEnvironmentPreset | null) {
		if (!this.#state.isServer) return false;
		if (this.#state.isPublicServer) return false;
		if (!preset) return false;
		return !preset.isDefault;
	}

	get canEdit() {
		return this.#canEditPreset(this.#state.environmentPreset.current);
	}

	get canDelete() {
		const envPreset = this.#state.environmentPreset.current;
		if (!envPreset) return false;
		if (!this.#state.isServer) return false;
		if (this.#state.isPublicServer) return false;
		return this.isOwner && !envPreset.isDefault;
	}

	get isOwner() {
		const envPreset = this.#state.environmentPreset.current;
		if (!envPreset) return false;
		return true;
	}
}
