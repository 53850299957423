import type React from "react";
import { cn } from "@lib/helpers/cn";
import type { CustomCSSProps } from "@stores/custom-ui";
import { formatCustomUIStyles, type GeneralInputs, type GeneralStyles } from "@jamango/content-client";

interface IProps {
	styles: GeneralStyles;
	config: GeneralInputs;
	isPreview?: boolean;
}

export function ProgressBar({ styles, config, isPreview = false }: IProps) {
	const stylesConfiguration = formatCustomUIStyles(styles, "progress-bar") as CustomCSSProps;

	const currentValue = config.value?.value as number;
	const maxValue = config?.maxValue?.value as number;
	const bgColorInStyle = {
		width: `${isPreview && !config?.value?.value ? 50 : (currentValue / maxValue) * 100}%`,
		"--progress-bar-backgroundColorIn": stylesConfiguration["--progress-bar-backgroundColorIn"],
		"--progress-bar-backgroundColorIn-lg": stylesConfiguration["--progress-bar-backgroundColorIn-lg"],
	} as React.CSSProperties;
	const bgColorOutStyle = {
		"--progress-bar-backgroundColorOut-lg": stylesConfiguration["--progress-bar-backgroundColorOut-lg"],
		"--progress-bar-backgroundColorOut": stylesConfiguration["--progress-bar-backgroundColorOut"],
	} as React.CSSProperties;
	const textShadowConfig = {
		"--text-shadow-color": stylesConfiguration["--progress-bar-textShadowColor"],
		"--text-stroke-color": stylesConfiguration["--progress-bar-textStrokeColor"],
	} as React.CSSProperties;

	return (
		<div
			className={cn(
				"relative flex select-none items-center gap-2",
				// font weight
				"!font-[var(--progress-bar-fontWeight)]",
				"lg:!font-[var(--progress-bar-fontWeight-lg)]",
				//margin
				"m-[var(--progress-bar-margin)]",
				"lg:m-[var(--progress-bar-margin-lg)]",
				// padding
				"p-[var(--progress-bar-padding)]",
				"lg:p-[var(--progress-bar-padding-lg)]",
				// font family
				"font-[family-name:var(--progress-bar-fontFamily)]",
				"lg:font-[family-name:var(--progress-bar-fontFamily-lg)]",
				// font size
				"text-[length:var(--progress-bar-fontSize)]",
				"lg:text-[length:var(--progress-bar-fontSize-lg)]",
				// color
				"text-[var(--progress-bar-color)]",
				"lg:text-[var(--progress-bar-color-lg)]",
				// position
				"!top-[var(--progress-bar-position-top)]",
				"!left-[var(--progress-bar-position-left)]",
				"!bottom-[var(--progress-bar-position-bottom)]",
				"!right-[var(--progress-bar-position-right)]",
				"gap-[var(--progress-bar-gap)]",
				"lg:gap-[var(--progress-bar-gap-lg)]",
			)}
			style={stylesConfiguration}
		>
			<p
				className={cn(
					"whitespace-pre font-semibold text-inherit",
					"!font-stroke-[var(--progress-bar-textStroke)]",
					"!text-shadow-[var(--progress-bar-textShadow)]",
				)}
				style={textShadowConfig}
			>
				{typeof config?.text?.value !== "object" && config?.text?.value}
				<span className="sr-only">
					{typeof config?.value?.value !== "object" ? config?.value?.value : 0}%
				</span>
			</p>

			<div
				className={cn(
					"overflow-hidden rounded",
					// Border radius
					"rounded-[var(--progress-bar-borderRadius)]",
					"lg:rounded-[var(--progress-bar-borderRadius-lg)]",
					// background color out
					"bg-[var(--progress-bar-backgroundColorOut)]",
					"lg:bg-[var(--progress-bar-backgroundColorOut-lg)]",
					// width
					"w-[var(--progress-bar-width)]",
					"lg:w-[var(--progress-bar-width-lg)]",
					// Height
					"h-[var(--progress-bar-height)]",
					"lg:h-[var(--progress-bar-height-lg)]",
				)}
				aria-hidden="true"
				style={bgColorOutStyle}
			>
				<div
					className={cn(
						"h-full",
						// Background Color In
						"bg-[var(--progress-bar-backgroundColorIn)]",
						"lg:bg-[var(--progress-bar-backgroundColorIn-lg)]",
					)}
					style={bgColorInStyle}
				/>
			</div>
		</div>
	);
}
