import { Jacy } from "@jacy-client";
import { cn } from "@lib/helpers/cn";

export function LetterLogo({ className, color = "orange", ...props }) {
	return (
		<div
			className={cn(
				"rounded-2xl bg-gradient-to-t p-3",
				color === "orange" && "from-[#FFB300] to-[#FF6F02]",
				color === "blue" && "from-[#61D2F5] to-[#296ABA]",
				className,
			)}
			{...props}
		>
			<img
				src={Jacy.getFilePath("assets/frontend/jamango-letters-no-shadow.svg")}
				alt="Jamango!"
				className="size-16 opacity-90"
				width="635"
				height="597"
			/>
		</div>
	);
}
