import { generateId } from "../../lib/helpers/general";
import type { IParsedThumbnail, IThumbnail, ThumbnailResource } from "../../lib/types";
import { AssetType } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";

export class JacyThumbnailState {
	#state: JacyContentState;
	#thumbnail: IThumbnail | null;

	get pk() {
		return this.#thumbnail?.pk;
	}

	constructor(state: JacyContentState) {
		this.#state = state;
		this.#thumbnail = null;
	}

	import(data?: IThumbnail) {
		if (!data) return;
		this.set(data);
	}

	export() {
		return this.#thumbnail;
	}

	get url() {
		const resourceId = this.#thumbnail?.resourcePk;

		const resource = this.#state.resources.get(resourceId);

		if (!resource) {
			return null;
		}

		return resource.file.url;
	}

	get(): IParsedThumbnail | null {
		if (!this.#thumbnail) {
			return null;
		}

		const resource = this.#state.resources.get(this.#thumbnail.resourcePk) as ThumbnailResource;

		if (!resource) {
			console.error(
				`Thumbnail ${this.#thumbnail.pk} resource ${this.#thumbnail.resourcePk} not found.`,
			);
			return null;
		}

		return {
			...this.#thumbnail,
			resource,
		};
	}

	useThumbnailResource(resourcePk: string) {
		const existingThumbnailResource = this.#thumbnail?.resourcePk;

		if (existingThumbnailResource) {
			this.#state.resources.remove(existingThumbnailResource);
		}

		if (!this.#state.resources.has(resourcePk)) return;

		this.set({
			pk: this.pk ?? `${AssetType.THUMBNAIL}#${generateId()}`,
			type: AssetType.THUMBNAIL,
			resourcePk,
		});
	}

	clearThumbnail() {
		const resource = this.#thumbnail?.resourcePk;

		if (resource) {
			this.#state.resources.remove(resource);
		}

		this.#thumbnail = null;

		this.#state.markDirty();
	}

	set(data: IThumbnail) {
		this.#thumbnail = structuredClone(data);

		this.#state.markDirty();

		return this.#thumbnail;
	}
}
