import { Topic } from "@jamango/helpers";
import * as GameLifeCycle from "./GameLifeCycle";

export type IParams = {
	token: string | null;
	username: string | null;
	worldId: string | null;
	versionId: string | null;
	serverId: string | null;
	matchmake: string | null;
};

type Options = { isStandalone?: boolean };

let currentOptions: Options = {};

export function init(options: Options = {}) {
	currentOptions = options;

	navigationTopic.add(() => GameLifeCycle.onChangePage());

	window.addEventListener("beforeunload", GameLifeCycle.onPageUnload);
}

export function isPlayPage() {
	return !!currentOptions.isStandalone || location.pathname.includes("/play");
}

export function isLandingPage() {
	return location.pathname === "/";
}

export function pushHistory(path: string) {
	history.pushState("", "", path);
}

export function replaceHistory(path: string) {
	window.history.replaceState(null, "", path);
}

export const navigationCommandTopic = new Topic<[path: string]>();
export const navigationTopic = new Topic<[path: string]>();

export function navigate(path: string) {
	navigationCommandTopic.emit(path);
}

export function getParams(): IParams {
	const params = new URLSearchParams(document.location.search);

	return {
		token: params.get("token"),
		username: params.get("username"),
		worldId: params.get("world"),
		versionId: params.get("versionId"),
		serverId: params.get("game"),
		matchmake: params.get("matchmake"),
	};
}
