import React from "react";

import { WorldPreviewDialog } from "@components/landing/WorldPreviewDialog";
import type { LoaderFunction } from "react-router";
import { createBrowserRouter, redirect } from "react-router";
import { navigationCommandTopic, navigationTopic } from "./game/Router";
import { Root } from "./Root";
import { useAuthUserStore } from "./stores/auth-user";
import { Jacy } from "@jacy-client";

const LandingPage = React.lazy(() => import("@pages/LandingPage"));
const GamePage = React.lazy(() => import("@pages/GamePage"));
const LoginPage = React.lazy(() => import("@pages/LoginPage"));
const RegisterPage = React.lazy(() => import("@pages/RegisterPage"));
const ProfilePage = React.lazy(() => import("@pages/AccountProfilePage"));
const AboutPage = React.lazy(() => import("@pages/AboutPage"));
const ErrorPage = React.lazy(() => import("@pages/ErrorPage"));
const ForgotPasswordPage = React.lazy(() => import("@pages/ForgotPassword/ForgotPasswordPage"));
const VerifyEmailPage = React.lazy(() => import("@pages/VerifyEmailPage"));
const StyleguidePage = React.lazy(() => import("@pages/StyleguidePage"));

const playPageLoader: LoaderFunction = async ({ request }) => {
	const [, searchParams] = request.url.split("?");
	const hasGameSearchParam = new URLSearchParams(searchParams).has("game");

	// allow guests to join games, but not to host them
	if (hasGameSearchParam) return null;

	await Jacy.initializePromise;

	const isGuest = !useAuthUserStore.getState().userId;

	if (isGuest) {
		return redirect("/signin");
	}

	return null;
};

const accountRequiredLoader: LoaderFunction = async () => {
	await Jacy.initializePromise;

	const isGuest = !useAuthUserStore.getState().userId;

	if (isGuest) {
		return redirect("/signin");
	}

	return null;
};

const alreadyLoggedInLoader: LoaderFunction = async () => {
	await Jacy.initializePromise;

	const isGuest = !useAuthUserStore.getState().userId;

	if (!isGuest) {
		return redirect("/");
	}

	return null;
};

export const router = createBrowserRouter([
	{
		element: <Root />,
		children: [
			{
				path: "/",
				element: <LandingPage />,
				children: [
					{
						path: "/game/:worldId",
						element: <WorldPreviewDialog />,
					},
				],
			},
			{
				path: "/about",
				element: <AboutPage />,
			},
			{
				path: "/play",
				element: <GamePage />,
				loader: playPageLoader,
			},
			{
				path: "/signin",
				element: <LoginPage />,
				loader: alreadyLoggedInLoader,
			},
			{
				path: "/signup",
				element: <RegisterPage />,
				loader: alreadyLoggedInLoader,
			},
			{
				path: "/profile",
				element: <ProfilePage />,
				loader: accountRequiredLoader,
			},
			{
				path: "/forgot-password",
				element: <ForgotPasswordPage />,
			},
			{
				path: "/verify-email",
				element: <VerifyEmailPage />,
			},
		],
	},
	{
		path: "/styleguide",
		element: <StyleguidePage />,
	},
	{
		path: "/use-bypass-token",
		element: null,
		loader: ({ request }) => {
			const url = new URL(request.url);
			const token = url.searchParams.get("token");
			if (token) {
				try {
					if (typeof localStorage !== "undefined") {
						localStorage.setItem("x-jamango-bypass-basic-auth", token);
					}
				} catch {}
			}

			return redirect("/");
		},
	},
	{
		path: "/*",
		element: <ErrorPage />,
	},
]);

navigationCommandTopic.add((path) => {
	router.navigate(path);
});

router.subscribe((state) => {
	navigationTopic.emit(state.location.pathname);
});

export const StandaloneRouter = createBrowserRouter([
	{
		element: <Root />,
		children: [
			{
				path: "/*",
				element: <GamePage />,
			},
		],
	},
]);
