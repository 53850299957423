import { generateId } from "@jamango/content-client";
import { getDefaultPermissions, type PeerMetadata } from "@jamango/engine/PeerMetadata.ts";
import { create } from "zustand";

import { useControlsStore } from "./controls";
import { useEditorModal } from "./dialogs";
import { useItemStore } from "./hud/item";
import { useInventorySelectorStore } from "./hud/inventory-selector";
import { useWrenchStore } from "./wrench";

export const MODE = {
	default: "default",
	building: "building",
	spawner: "spawner",
	fighting: "fighting",
	pencil: "pencil",
	wrench: "wrench",

	creator: "creator",
};

interface IPlayerState {
	username: string;
	mode: string;
	health: number;
	maxHealth: number;

	setMode: (mode: string) => void;
	setUsername: (username?: string | null) => void;
	setHealth: (health: number) => void;
	setMaxHealth: (health: number) => void;
}

export const usePlayerStore = create<IPlayerState>((set) => ({
	username: getGuestUsername(),
	mode: MODE.default,
	health: 100,
	maxHealth: 100,

	// Handlers
	setMode: (mode) => {
		if (!(mode in MODE)) throw new Error(`Invalid mode: ${mode}`);

		set({ mode });
	},
	setUsername: (username) => set({ username: username ?? getGuestUsername() }),
	setHealth: (health) => set({ health }),
	setMaxHealth: (health) => set({ maxHealth: health }),
}));

function getGuestUsername() {
	return `Guest ${generateId().substring(0, 6)}`;
}

export enum Mode {
	DEFAULT,
	CREATOR,
	PLAYER,
}

interface IPermissionState {
	permissions: PeerMetadata["permissions"];
	setPermissions: (permissions: Partial<PeerMetadata["permissions"]>) => void;
}

// Current player permission during gameplay.
export const usePermissionStore = create<IPermissionState>((set, get) => ({
	permissions: getDefaultPermissions(),

	// Handlers
	setPermissions: (newPermissions = {}) => {
		const prvCanUseWrench = get().permissions.canUseWrench;
		const prvHasToolWheel = useItemStore.getState().toolWheel.length > 0;
		const prvRenderSelector = useInventorySelectorStore.getState().renderSelector;

		set((state) => ({
			permissions: {
				...state.permissions,
				...newPermissions,
			},
		}));

		//client side effects
		useItemStore.getState().recomputeEquippables();
		useInventorySelectorStore.getState().recomputeRenderSelector();

		const curHasToolWheel = useItemStore.getState().toolWheel.length > 0;
		const curRenderSelector = useInventorySelectorStore.getState().renderSelector;

		if (prvCanUseWrench && newPermissions.canUseWrench === false) {
			useEditorModal.getState().setOpen(false);
			useWrenchStore.getState().close();
		}

		if (prvHasToolWheel && !curHasToolWheel) useControlsStore.getState().toggleToolWheel(false);
		if (!prvRenderSelector && curRenderSelector)
			useInventorySelectorStore.getState().activateBuildModeSlot();
	},
}));

export default {
	usePermission: usePermissionStore.getState,
	usePlayer: usePlayerStore.getState,
};
