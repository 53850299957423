import { getStaticFile } from "@jamango/content-client";

export default `
    @font-face {
        font-family: 'Archivo';
        src: url(${getStaticFile("assets/frontend/fonts/Archivo/Archivo-SemiBold.woff2")}) format('woff2'); 
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Archivo';
        src: url(${getStaticFile("assets/frontend/fonts/Archivo/Archivo-Regular.woff2")}) format('woff2'); 
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Archivo';
        src: url(${getStaticFile("assets/frontend/fonts/Archivo/Archivo-Bold.woff2")}) format('woff2'); 
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Archivo';
        src: url(${getStaticFile("assets/frontend/fonts/Archivo/Archivo-Light.woff2")}) format('woff2'); 
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
`;
