import { useEffect } from "react";

export function useClickOutside(ref: any, onClickOutside: () => void) {
	useEffect(() => {
		/**
		 * Invoke Function onClick outside of element
		 */
		function handleClickOutside(event: React.MouseEvent<HTMLDivElement> | MouseEvent) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside();
			}
		}
		// Bind
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// dispose
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, onClickOutside]);
}
