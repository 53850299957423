import { generatePk } from "../../lib/helpers/general";
import type { IBundle } from "../../lib/types";
import { AssetType, type IAssetKey } from "../../lib/types";
import type { ItemAsset } from "../../lib/types/item";
import {
	ItemAnimationType,
	ItemBehaviourType,
	ItemColliderType,
	ItemMeshType,
} from "../../lib/validations/item";
import type { JacyContentState } from "../JacyContentState";

export class JacyItemsState {
	items: NonNullable<IBundle["assets"]["items"]>;
	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.items = {};
	}

	import(data?: IBundle["assets"]["items"]) {
		if (!data) return;

		this.items = Object.assign(this.items, data);
		this.#state.markDirty();
	}

	export() {
		return this.items;
	}

	makeEmptyItem(): ItemAsset {
		return {
			pk: generatePk(AssetType.ITEM),
			type: AssetType.ITEM,
			name: "New Item",
			mesh: {
				type: ItemMeshType.NONE,
			},
			collider: {
				type: ItemColliderType.BOX,
				size: [1, 1, 1],
			},
			itemBehaviour: {
				type: ItemBehaviourType.CUSTOM,
				cooldown: 0,
			},
			itemAnimation: {
				type: ItemAnimationType.ONE_HANDED_TOOL,
			},
		};
	}

	set(data: ItemAsset) {
		this.items[data.pk] = data;
		this.#state.markDirty();
	}

	get(pk: IAssetKey) {
		return this.items[pk];
	}

	delete(pk: IAssetKey) {
		delete this.items[pk];
		this.#state.markDirty();
	}

	getAll(): ItemAsset[] {
		return Object.values(this.items);
	}
}
