import type { ApiResult } from "rest-client";

export const swrFetcher = <R extends ApiResult<any>>(fn: () => Promise<R>) => {
	return async () => {
		const result: R = await fn();

		if (!result.ok) {
			// throw an error for retryable errors, so swr will retry
			if (result.retryable) {
				throw new Error(result.error.message, { cause: result.error });
			}

			return result;
		}

		return result;
	};
};
