import type { EngineAPI } from "@jamango/engine/Runtime";
import type { World } from "@jamango/engine/Runtime/base/world/World.js";
import { createRequestPromise, type IPromise } from "@jamango/helpers";
import { create } from "zustand";

type IEngineState = {
	promise: IPromise<EngineAPI>;
	engine: EngineAPI;
	world: World | null;
	loading: boolean;
	setAsLoaded: () => void;
	init: (expose: EngineAPI) => void;
};

export const enginePromise = createRequestPromise<EngineAPI>();

export const useEngineStore = create<IEngineState>((set, get) => ({
	promise: enginePromise,
	engine: null!,
	world: null,
	loading: true,
	setAsLoaded: () => {
		set({ loading: false });
	},
	init: (expose: EngineAPI) => {
		set({ engine: expose });
		get().promise.resolve(expose);
	},
}));

export const useEngine = useEngineStore.getState;

export default {
	useEngine,
};
