import { isNullish } from "@jamango/helpers";
import { create } from "zustand";

export type INotification = {
	type?: "info" | "success" | "error";
	message: string | string[];
};

export enum Loading {
	SAVE_WORLD = "save-world",
	LOAD_WORLD = "load-world",
	RELOAD_WORLD = "reload-world",
	HOST_WORLD = "host-world",
	STOP_HOSTING = "stop-hosting",
	JOIN_WORLD = "join-world",
	RENAME_WORLD = "rename-world",
}

export enum RemixStatus {
	ONGOING = "ongoing",
	SUCCESS = "success",
	FAILED = "failed",
}

export const REMIX_TEXT = {
	[RemixStatus.ONGOING]: "Saving...",
	[RemixStatus.SUCCESS]: "Saved world successfully",
	[RemixStatus.FAILED]: "Saving world failed",
};

export const LOADING_TEXT = {
	[Loading.SAVE_WORLD]: "Please wait while we save your world",
	[Loading.LOAD_WORLD]: "Please wait while we load your world",
	[Loading.RELOAD_WORLD]: "Please wait while we reload your world",
	[Loading.HOST_WORLD]: "Please wait while we host your world",
	[Loading.STOP_HOSTING]: "Please wait while we stop hosting your world",
	[Loading.JOIN_WORLD]: "Please wait while we join the world",
	[Loading.RENAME_WORLD]: "Please wait while we rename your world",
};

type IGameClientState = {
	loading: Loading | null;
	notification: null | INotification;
	remixStatus: null | RemixStatus;

	setLoading: (loading: Loading | null) => void;
	setRemixStatus: (status: RemixStatus | null) => void;
	setNotification: (notification: INotification | null, autoclear?: boolean) => void;
};

let timeoutID: NodeJS.Timer | null = null;

export const useGameClientStore = create<IGameClientState>((set) => ({
	loading: null,
	remixStatus: null,
	notification: null,
	setLoading: (loading) => set({ loading }),
	setRemixStatus: (status) => set({ remixStatus: status }),

	setNotification: (notification, autoclear = true) => {
		if (timeoutID) {
			clearTimeout(timeoutID);
		}

		if (!isNullish(notification) && autoclear) {
			timeoutID = setTimeout(() => set({ notification: null }), 5000);
		}

		set({ notification });
	},
}));
