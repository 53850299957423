import { generatePk } from "../../lib/helpers/general";
import type { IBundle, IScript } from "../../lib/types";
import { AssetType, ScriptType, type IAssetKey } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";

export class JacyScriptsState {
	scripts: NonNullable<IBundle["assets"]["scripts"]>;
	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
		this.scripts = {};
	}

	import(data?: IBundle["assets"]["scripts"]) {
		if (!data) return;

		this.scripts = Object.assign(this.scripts, data);
		this.#state.markDirty();
	}

	export() {
		return this.scripts;
	}

	makeEmptyScript(name: string): IScript {
		const pk = generatePk(AssetType.SCRIPT);

		return {
			pk,
			type: AssetType.SCRIPT,
			scriptType: ScriptType.UTILITY,
			enabled: true,
			name,
			description: "",
			icon: "🔧",
			events: {
				nodes: {},
				frames: {},
				comments: {},
				connections: [],
			},
		};
	}

	set(data: IScript) {
		this.scripts[data.pk] = data;
		this.#state.markDirty();
	}

	get(id: IAssetKey) {
		return this.scripts[id];
	}

	delete(id: IAssetKey) {
		delete this.scripts[id];
		this.#state.markDirty();
	}

	getAll(): IScript[] {
		return Object.values(this.scripts);
	}

	isScriptEmpty(script: IScript) {
		return (
			Object.keys(script.events.nodes).length === 0 &&
			Object.keys(script.events.frames).length === 0 &&
			Object.keys(script.events.comments).length === 0 &&
			script.events.connections.length === 0
		);
	}
}
