import type { IEngineWorldData, JacyContent, WorldUserRole } from "@jamango/content-client";
import type { JamangoEngineOptions } from "@jamango/engine/EngineOptions.ts";
import { BB } from "base/BB";

export async function initJamangoEngine(options: JamangoEngineOptions): Promise<EngineAPI> {
	return await BB.init(options);
}

export async function startNewWorld(content: JacyContent, role: WorldUserRole) {
	return await BB.router.startWorld(content, role, null);
}

export async function startExistingWorld(content: JacyContent, role: WorldUserRole) {
	const mapBlob = await content.state.map.getMapArrayBuffer();

	if (!mapBlob) {
		throw new Error("Failed to load the world, missing map file.");
	}

	return await BB.router.startWorld(content, role, mapBlob);
}

export async function joinWorld(content: JacyContent, worldDef: IEngineWorldData) {
	return await BB.router.joinWorld(content, worldDef);
}

export function getMapBuffer() {
	return BB.world.scene.saveMap() as ArrayBuffer;
}

export async function reloadWorld(content: JacyContent, role: WorldUserRole) {
	const mapBlob = getMapBuffer();
	await content.state.map.update(mapBlob);

	await startExistingWorld(content, role);

	content.state.characters.onWorldReload();
	content.state.avatars.onWorldReload();
}

type Prettify<T> = {
	[K in keyof T]: T[K];
} & {};

export type EngineAPI = Prettify<Awaited<ReturnType<typeof BB.init>>>;

export { SelectorTargetType } from "base/world/entity/component/CharacterSelector";
export * from "base/world/SceneTree";
export { PencilMode } from "client/world/tools/Pencil.js";
export { WrenchMode } from "client/world/tools/Wrench.js";
export { ItemPencil } from "mods/defs/ItemPencil";
export { ItemWrench } from "mods/defs/ItemWrench";
export type { World } from "base/world/World";
export * as BlockGroupsRouter from "router/world/block/BlockGroups";
export * as SpawnerRouter from "router/world/tools/Spawner";
