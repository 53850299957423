import type { MutableRefObject } from "react";
import { useState, useEffect } from "react";

export function useVisible<T extends MutableRefObject<HTMLElement | null>>(
	ref: T,
	initialIsVisible?: boolean,
) {
	const [visible, setVisible] = useState(initialIsVisible);

	const hide = (evt: KeyboardEvent) => {
		if (evt.key === "Escape") setVisible(false);
	};

	const handleClickOutside = (evt: MouseEvent) => {
		if (ref.current && !ref.current.contains(evt.target as any)) setVisible(false);
	};

	useEffect(() => {
		document.addEventListener("keydown", hide, true);
		document.addEventListener("click", handleClickOutside, true);

		return () => {
			document.removeEventListener("keydown", hide, true);
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return [visible, setVisible] as const;
}
