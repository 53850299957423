import { generateId } from "../../lib/helpers/general";
import { DEFAULT_CUSTOM_UI_COMPONENTS } from "../../lib/constants/custom-ui";
import type { CustomUIComponent, PrebuiltUIComponentIndexes } from "../../lib/types";
import { AssetType, type IAssetKey, type IBundle, type ICustomUI } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";

export class JacyCustomUIState {
	#customUI: Record<IAssetKey, ICustomUI> = {};
	#state: JacyContentState;

	constructor(state: JacyContentState) {
		this.#state = state;
	}

	import(data?: IBundle["assets"]["customUI"]) {
		if (!data) return;

		this.#customUI = Object.assign(this.#customUI, data);
		this.#state.markDirty();
	}

	export() {
		return this.#customUI;
	}

	getAll() {
		return Object.values(this.#customUI);
	}

	getAllComponents() {
		return this.getAll().map((asset) => asset.ui);
	}

	get(pk: string) {
		return this.#customUI[pk];
	}

	getById(id: string) {
		return Object.values(this.#customUI).find((asset) => asset.id === id);
	}

	createComponent(id: string, component: ICustomUI["ui"]) {
		const pk = `${AssetType.CUSTOM_UI}#${id}`;

		component.show = false;

		this.set({
			pk,
			id,
			type: AssetType.CUSTOM_UI,
			ui: component,
		});

		return pk;
	}

	updateComponent(id: string, component: ICustomUI["ui"]) {
		const asset = this.getById(id);

		if (!asset) return;

		component.show = false;
		asset.ui = component;

		this.set(asset);
	}

	set(data: ICustomUI) {
		this.#customUI[data.pk] = data;
		this.#state.markDirty();
	}

	deleteComponent(id: string) {
		const asset = this.getById(id);

		if (!asset) return;

		this.delete(asset.pk);
	}

	delete(pk: IAssetKey) {
		delete this.#customUI[pk];

		this.#state.markDirty();
	}

	getInitialComponent(uiComponentType: PrebuiltUIComponentIndexes) {
		return {
			...structuredClone(DEFAULT_CUSTOM_UI_COMPONENTS[uiComponentType]),
			id: generateId(),
		} satisfies CustomUIComponent;
	}
}
