import type React from "react";
import * as BaseTooltip from "@radix-ui/react-tooltip";
import { cn } from "@lib/helpers/cn";

const portalElement = document.getElementById("portal");

type IProps = {
	arrow?: React.ReactNode;
	children: React.ReactNode;
	content?: React.ReactNode;
	className?: string;
	open?: boolean;
	side?: BaseTooltip.TooltipContentProps["side"];
	triggerProps?: React.ComponentProps<typeof BaseTooltip.Trigger>;
	contentProps?: React.ComponentProps<typeof BaseTooltip.Content>;
};

export function Tooltip({
	side,
	arrow,
	children,
	content,
	className,
	open,
	triggerProps,
	contentProps,
}: IProps) {
	if (!content || typeof content === "boolean") {
		return <>{children}</>;
	}

	return (
		<BaseTooltip.Provider delayDuration={100}>
			<BaseTooltip.Root open={open}>
				<BaseTooltip.Trigger asChild {...triggerProps}>
					{children}
				</BaseTooltip.Trigger>
				<BaseTooltip.Portal container={portalElement}>
					<BaseTooltip.Content
						className={cn(
							"z-10 max-w-xs select-none rounded bg-gray-900/50 px-2 py-1 text-sm text-white shadow",
							className,
						)}
						sideOffset={10}
						side={side}
						{...contentProps}
					>
						{content}
						{arrow ?? <BaseTooltip.Arrow className="fill-gray-900/50" />}
					</BaseTooltip.Content>
				</BaseTooltip.Portal>
			</BaseTooltip.Root>
		</BaseTooltip.Provider>
	);
}

Tooltip.Arrow = BaseTooltip.Arrow;
