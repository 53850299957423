import type { Resource } from "@jamango/content-client";
import { AssetType } from "@jamango/content-client";
import { createLogger } from "@jamango/helpers";
import { useJacyRerenderStore } from "../../stores/jacy/rerender";
import type { JacyClient } from "../JacyClient";
import { requestUploadResource, ws } from "rest-client";
import type { PubEventByName } from "@jamango/content-service/types/index.ts";

const logger = createLogger("UploadResource");

const DEFAULT_UPLOAD_TIMEOUT = 2 * 60 * 1000;

export const uploadAndCreateResource = async (
	jacy: JacyClient,
	{
		name,
		resourceType,
		file,
	}: {
		name: string;
		resourceType: Resource["resourceType"];
		file: File;
	},
	{ asBuiltIn }: { asBuiltIn?: boolean } = {},
) => {
	const { resource, resourceId, requestId } = await uploadResource(jacy, {
		resourceType,
		file,
	});

	const commonFields = {
		type: AssetType.RESOURCE,
		resourceType,
		name,
		file: {
			name: resource.name,
			size: resource.size,
			url: resource.url,
			path: resource.path,
			mimeType: resource.mimeType,
		},
		globalResourceId: resourceId,
	} as const;

	const newResource = jacy.content.state.resources.createResource(
		commonFields.resourceType === "audio"
			? {
					...commonFields,
					duration: resource.duration ?? -1,
					resourceType: commonFields.resourceType,
				}
			: {
					...commonFields,
					resourceType: commonFields.resourceType,
				},
	);

	if (asBuiltIn) {
		newResource.isBuiltIn = true;
	}

	jacy.content.state.resources.addResource(newResource);

	return {
		requestId: requestId,
		resource,
		pk: newResource.pk,
	};
};

export const uploadResource = async (
	jacy: JacyClient,
	{ file, resourceType }: { file: File; resourceType: Resource["resourceType"] },
) => {
	jacy.ongoingUploads.add(file);
	useJacyRerenderStore.getState().forceRerenderUploadStatus();

	try {
		const requestUploadResult = await requestUploadResource({
			name: file.name,
			type: resourceType,
		});

		if (!requestUploadResult.data) throw new Error(`no result`);

		const requestUpload = requestUploadResult.data;

		logger.info(`start uploading resource ${file.name}...`);
		await uploadFileToS3({
			uploadUrl: requestUpload.uploadUrl,
			file,
		});

		logger.info(`waiting for resource ${file.name}...`);

		const sub = ws.client.subscribe("asset:save", { requestId: requestUpload.requestId });

		let lastMessage: PubEventByName<"asset:save"> | undefined;

		sub.listen((msg) => {
			lastMessage = msg;
			logger.debug(msg);
		});

		const error = await sub.disposedPromise;

		if (error) {
			throw error;
		}

		if (!lastMessage?.result) {
			throw new Error(lastMessage?.error ?? `Couldn't save resource`);
		}

		return {
			resource: lastMessage.result,
			requestId: requestUpload.requestId,
			resourceId: requestUpload.resourceId,
		};
	} finally {
		jacy.ongoingUploads.delete(file);
		useJacyRerenderStore.getState().forceRerenderUploadStatus();
	}
};

const uploadFileToS3 = ({ uploadUrl, file }: { uploadUrl: string; file: File }) => {
	return new Promise<{ success: true }>((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.timeout = DEFAULT_UPLOAD_TIMEOUT;

		xhr.upload.addEventListener("progress", (_event) => {
			// todo: show progress
		});

		xhr.upload.addEventListener("loadend", () => resolve({ success: true }));
		xhr.upload.addEventListener("error", reject);

		xhr.open("PUT", uploadUrl);
		xhr.send(file);
	});
};
