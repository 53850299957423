import type { IBundle } from "../index";
import { JSZip } from "../index";
import { getExtension, getFileType } from "../lib/helpers/files";
import type { JacyContentState } from "./JacyContentState";

const SAVE_JSON_NAME = `save.json`;
const FILES_FOLDER = `files`;

export type SaveData = {
	timestamp: string;
	state: IBundle;
	files: Array<{ pk: string; filename: string }>;
};

export async function serializeJamfileSaveZip({
	state,
	bundle,
}: {
	state: JacyContentState;
	bundle: IBundle;
}) {
	const now = new Date().toISOString();

	const resourcesWithBuffer = Object.values(bundle.assets.resources ?? {})
		.map((r) => ({
			pk: r.pk,
			filename: r.file.name,
			file: state.resources.get(r.pk)?.file?.buffer,
		}))
		.filter((r) => !!r.file);

	const resources = resourcesWithBuffer.map((resource) => {
		return {
			pk: resource.pk,
			filename: resource.filename,
			file: resource.file as File,
		} as const;
	});

	const zip = new JSZip();

	const zipFilesFolder = zip.folder(FILES_FOLDER);

	if (!zipFilesFolder) {
		throw new Error(`Failed to create '${FILES_FOLDER}' folder in zip`);
	}

	for (const { pk, file, filename } of resources) {
		const path = `${pk}/${filename}`;

		zipFilesFolder.file(path, file, { base64: true });
	}

	const save = {
		timestamp: now,
		state: bundle,
		files: resources.map(({ file: _file, ...fileDetails }) => fileDetails),
	} satisfies SaveData;

	const formattedSave = JSON.stringify(save);

	zip.file(SAVE_JSON_NAME, formattedSave, { base64: false });

	const zipContent = await zip.generateAsync({ type: "uint8array" });

	return zipContent;
}

export async function deserializeJamfileSaveZip(zipContent: Uint8Array) {
	const zip = await JSZip.loadAsync(zipContent);
	const filesInZip = Object.values(zip.files);

	let save: SaveData | undefined;
	const files: Record<string, File> = {};

	for (const fileInZip of filesInZip) {
		if (fileInZip.dir) continue;

		const fileName = fileInZip.name;

		if (fileName === SAVE_JSON_NAME) {
			save = JSON.parse(await fileInZip.async("string")) as SaveData;
		} else if (fileName.startsWith(FILES_FOLDER + "/")) {
			const [_filesFolder, pk, name] = fileName.split("/");
			const extension = getExtension(name);

			files[pk] = new File([await fileInZip.async("uint8array")], name, {
				type: getFileType(extension),
			});
		}
	}

	if (!save) {
		throw new Error(`Failed to find '${SAVE_JSON_NAME}' in zip`);
	}

	for (const saveFileMeta of save.files) {
		if (!files[saveFileMeta.pk]) {
			throw new Error(`Failed to find file '${saveFileMeta.pk}' in the zip folder ${FILES_FOLDER}`);
		}
	}

	return {
		save,
		files,
	};
}
